import React, { MouseEventHandler } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { divideWithComas } from '../../../../tools/formatString';
import { Btn, Cnt, ValueCnt } from './styledComponents';
import Tooltips from '../../../../components/tooltips';
import { PlusIcon } from './PlusIcon';
import { useAuth } from '../../../../context/AuthCtx';

interface Props {
  isOpen: boolean;
  clickHandler: MouseEventHandler;
  requestsBalance: number;
}

export const BuyReqsBadge = (props: Props) => {
  const { isOpen, clickHandler, requestsBalance } = props;
  const { isAuth } = useAuth();
  const navTo = useNavigate();
  const { pathname } = useLocation();
  const activeClassName =
    pathname.replace('/', '') === 'payment' ? 'active' : '';

  return (
    <Cnt
      className={isOpen ? 'opened' : 'closed'}
      onClick={(e) => {
        clickHandler(e);
        navTo(isAuth ? 'payment' : 'auth');
      }}
    >
      <Tooltips.Hover text="Amount of requests left">
        <ValueCnt
          className={isOpen ? 'opened tooltipChild' : 'closed tooltipChild'}
        >
          <span>{divideWithComas(requestsBalance)}</span>
          <span>reqs.</span>
        </ValueCnt>
      </Tooltips.Hover>

      <Btn
        className={
          isOpen ? `opened ${activeClassName}` : `closed ${activeClassName}`
        }
      >
        <span>Buy more</span>
        <PlusIcon />
      </Btn>
    </Cnt>
  );
};
