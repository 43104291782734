import React from 'react';
import fromMs from '../../../../../tools/dates/fromMs';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';

export interface IExecutionTimeCell extends ITableCellBase {
  executionTime: number;
}

export const ExecutionTimeCell: React.FC<IExecutionTimeCell> = ({
  executionTime = 0,
  width = 0,
}) => <td style={{ width: `${width}%` }}>{fromMs(+executionTime)}</td>;
