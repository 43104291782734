import { css } from 'styled-components';
import { colours } from '../../../styles/variables';

export const lgLabelFont = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.muted};
`;

export const smLabelFont = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const errFont = css`
  font-weight: 400;
  ${smLabelFont}
  color: ${colours.danger2};
`;
