import { css } from 'styled-components';
import { colours } from '../../styles/variables';

export const fieldBaseStyle = css`
  width: 100%;
  padding: 23px 16px 7px 0;
  border: unset;
  border-bottom: 1px solid ${colours.inputUnfocused};
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 1px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.secondary};
  background-color: transparent;

  &:focus {
    outline: none;
    border-bottom-width: 2px;
    border-bottom-color: 1px;
    margin-bottom: 0;
  }
`;
