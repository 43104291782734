import React from 'react';
import { useNavigate } from 'react-router-dom';
import Svgs from '../../../../components/svgs';
import { HeaderWrapper } from './styles';

interface Props {
  handleClick: React.MouseEventHandler;
}

export const ExbotLogo: React.FC<Props> = ({ handleClick }) => {
  const navTo = useNavigate();

  return (
    <HeaderWrapper
      onClick={(event) => {
        handleClick(event);
        navTo('/');
      }}
    >
      <Svgs.Logos.Exbot />
    </HeaderWrapper>
  );
};
