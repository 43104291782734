import React from 'react';
import { useLocation } from 'react-router-dom';
import { TimemachineIcon } from './TimemachineIcon';
import { BtnCnt } from './styledComponents';
import { Number } from '../../../../components/badges/Number';
import { useTaskData } from '../../../../context/extraction/TaskData';

interface Props {
  setExtractStatus: any;
  switchTab: any;
  processingTasksNum: any;
}

export const HistoryBtn = (props: Props) => {
  const { setExtractStatus, switchTab, processingTasksNum } = props;
  const { pathname } = useLocation();
  const { resetData, setUrl } = useTaskData();

  return (
    <BtnCnt
      onClick={(e) => {
        e.preventDefault();
        setUrl('');
        resetData();
        setExtractStatus();
        switchTab();
      }}
      className={pathname === '/' ? 'active' : ''}
    >
      <TimemachineIcon />
      <Number num={processingTasksNum} />
    </BtnCnt>
  );
};
