import React from 'react';
import { Link } from '../../links/Link';
import { RequestsHistoryTable } from './styledComponents';

// Tables.RequestsHistory
export const RequestsHistory = (props: {
  data: { _id: string; requestsUsed: number }[];
  fullReportUrl?: string;
}) => {
  const { data, fullReportUrl } = props;

  return (
    <RequestsHistoryTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Requests used</th>
          <th>
            <Link href={fullReportUrl} target="_blank">
              Download full report
            </Link>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((historyItem: any) => (
          <tr key={historyItem._id}>
            <td>{historyItem._id}</td>
            <td>{historyItem.requestsUsed}</td>
            <td></td>
          </tr>
        ))}
      </tbody>
    </RequestsHistoryTable>
  );
};
