import styled from 'styled-components';
import { moveLeft, moveRight, scale } from '../../../../styles/keyframes';

export const SvgCnt = styled.svg`
  &.hovered {
    g.top {
      path {
        transform-origin: center;
        animation: ${scale} 300ms ease-in-out alternate;
      }
    }
    g.left {
      path {
        animation: ${moveRight} 300ms ease-in-out alternate;
      }
    }
    g.right {
      path {
        animation: ${moveLeft} 300ms ease-in-out alternate;
      }
    }
  }
`;
