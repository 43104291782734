import React from 'react';
import Svgs from '../../../components/svgs';
import { IFeedbackGiven } from '../interfaces/feedback-given.interface';
import { Wrapper } from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement>, IFeedbackGiven {}

export const WarningBtn: React.FC<Props> = (props) => (
  <Wrapper {...props}>
    <Svgs.UI.WarningIcon />
  </Wrapper>
);
