import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Link } from '../../../components/links/Link';
import { validateCurrentPass, validateEmail } from '../../../tools/validation';
import { FloatingLabelInput } from '../../../components/inputFields/FloatingLabelInput';
import { ActionGroup, FieldGroup } from '../styledComponents';
import { useAuth } from '../../../context/AuthCtx';
import { ILoginCredentials } from '../../../types';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';

export const LoginFormik = () => {
  const navTo = useNavigate();
  const auth = useAuth();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, actions) => {
        auth.validateCredentials(values, actions);
      }}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(props: FormikProps<ILoginCredentials>) => {
        const emailMeta = props.getFieldMeta('email');
        const passMeta = props.getFieldMeta('pass');

        return (
          <Form autoComplete="off">
            <FieldGroup>
              <FloatingLabelInput
                type="email"
                autoComplete="email"
                name="email"
                placeholder="E-mail"
                validate={validateEmail}
                classProp={!emailMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="off"
                name="password"
                placeholder="Password"
                validate={validateCurrentPass}
                classProp={!passMeta.error}
              />
            </FieldGroup>
            <ActionGroup>
              <Link className="muted" onClick={() => navTo('reset')}>
                Reset password
              </Link>
              <SubmitBtn
                values={props.values}
                errors={props.errors}
                setValidateOnChange={setValidateOnChange}
              >
                Log in
              </SubmitBtn>
            </ActionGroup>
          </Form>
        );
      }}
    </Formik>
  );
};
