import React from 'react';

export const Npm = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="17" fill="#C12127" />
      <path
        d="M11.4021 22.5201H15.153V20.6447H18.904V13.1428H11.4021V22.5201ZM15.153 15.0183H17.0285V18.7691H15.153V15.0183ZM20.4043 13.1428V20.6447H24.1552V15.0183H26.0306V20.6447H27.9061V15.0183H29.7816V20.6447H31.657V13.1428H20.4043ZM2.3999 20.6447H6.15082V15.0183H8.02625V20.6447H9.90173V13.1428H2.3999V20.6447Z"
        fill="white"
      />
    </svg>
  );
};
