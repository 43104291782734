import React from 'react';
import Links from '../../../../../../components/links';
import { useDashboard } from '../../../../../../context/dashboard/DashboardCtx';

export const DownloadLink: React.FC = () => {
  const { exportCsv } = useDashboard();

  return (
    <Links.Link onClick={exportCsv} onTouchEnd={exportCsv}>
      Download
    </Links.Link>
  );
};
