import React from 'react';
import { Cnt } from './styledComponents';
import checkIcon from '../../../assets/icons/check-2.svg';
import { SetStatusType, StatusType } from '../../../types';

interface Props {
  status: StatusType;
  setStatus: SetStatusType;
  successMes: string;
  errorMes: string;
}

export const Status = (props: Props) => {
  const { status, setStatus, successMes, errorMes } = props;

  const switcher = {
    success: [successMes, checkIcon],
    error: [errorMes], // # add error icon?
  };

  return (
    <>
      {!['loading', undefined].includes(status) && (
        <Cnt
          onClick={() => setStatus(undefined)}
          onTouchEnd={() => setStatus(undefined)}
        >
          <div>
            <header>
              {status === 'success' && <img src={switcher[status][1]} />}
              <span>{switcher[status as 'success' | 'error'][0]}</span>
            </header>
          </div>
        </Cnt>
      )}
    </>
  );
};
