import React, { FC, createContext, useContext, useState } from 'react';
import { Client } from '../../../api/client';
import { StatusType } from '../../../types';
import { IApiTokenCtx } from './interfaces';

const ApiTokenCtx = createContext<IApiTokenCtx>({} as IApiTokenCtx);
export const useApiToken = () => useContext(ApiTokenCtx);

export const TokenProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const client = new Client();

  const [rotateTokenStatus, setRotateTokenStatus] =
    useState<StatusType>(undefined);

  const rotateToken = async () => {
    setRotateTokenStatus('loading');
    const response = await client.ROTATE_TOKEN();

    if (!response.ok) return setRotateTokenStatus('error');

    setRotateTokenStatus('success');
  };

  return (
    <ApiTokenCtx.Provider value={{ rotateToken, rotateTokenStatus }}>
      {children}
    </ApiTokenCtx.Provider>
  );
};
