export const writeCookie = (
  key: string,
  value?: string | number,
  ttl = 60
): void => {
  document.cookie = `${key}=${value};max-age=${ttl}`;
};

export const readCookie = (key: string): string | undefined => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
};
