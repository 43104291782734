import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import googleIcon from '../../../assets/icons/companies/google-icon.svg';
import facebookIcon from '../../../assets/icons/companies/facebook-icon.svg';
import githubIcon from '../../../assets/icons/companies/github-icon.svg';
import { Link } from '../../../components/links/Link';
import { ShadowCnt } from '../../../components/containers/ShadowCnt';
import { LoginFormik } from './LoginFormik';
import { AuthHeading, Footer, Header, SocialBtnCnt } from '../styledComponents';
import { OAuthBtn } from '../../../features/OAuthBtn';
import { useAuth } from '../../../context/AuthCtx';

export const LoginPage = () => {
  const navTo = useNavigate();
  const auth = useAuth();

  if (auth.isAuth !== false) return <Navigate to="/" />;
  return (
    <ShadowCnt>
      <Header>
        <AuthHeading>Log in</AuthHeading>
        <AuthHeading className="inactive" onClick={() => navTo('signup')}>
          Sign up
        </AuthHeading>
      </Header>
      <LoginFormik />
      <SocialBtnCnt>
        <OAuthBtn auth="google" mode="login" icon={googleIcon} />
        {/* <OAuthBtn auth="facebook" mode="login" icon={facebookIcon} /> */}
        <OAuthBtn auth="github" mode="login" icon={githubIcon} />
      </SocialBtnCnt>
      <Footer>
        No account?
        <Link onClick={() => navTo('signup')}>Create one</Link>
      </Footer>
    </ShadowCnt>
  );
};
