import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const Button = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  background-color: ${colours.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${colours.white};
  cursor: pointer;

  &:hover {
    background-color: ${colours.primaryDark};
  }

  &:disabled {
    background: ${colours.lightGray};
    color: ${colours.white};
    pointer-events: none;
    user-select: none;
  }
`;
