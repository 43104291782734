import React from 'react';
import { dropZeros } from '../../../tools/formatString';
import { IBillingPlan } from '../../../types';
import { InvoiceTable } from './styledComponents';

// Tables.RequestsHistory
interface Props {
  plan: IBillingPlan | undefined;
  email: string | undefined;
}

export const Invoice = (props: Props) => {
  return (
    <InvoiceTable>
      <thead>
        <tr>
          <th>Account name</th>
          <th>{props.email}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dropZeros(props.plan?.requestsLimit || 0)} requests option</td>
          <td>
            {props.plan?.price.currency === 'usd' && '$'}
            {props.plan?.price.amountBeforeDiscount || props.plan?.price.amount}
          </td>
        </tr>
        {!!props.plan?.price.discount && (
          <tr>
            <td>Discount</td>
            <td>{props.plan?.price.discount}%</td>
          </tr>
        )}
        <tr>
          <td>You will be charged now</td>
          <td>
            {props.plan?.price.currency === 'usd' && '$'}
            {props.plan?.price.amount}
          </td>
        </tr>
      </tbody>
    </InvoiceTable>
  );
};
