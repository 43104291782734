import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { NotificationContainer } from 'react-notifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import './styles/popup.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthCtx';
import { UIProvider } from './context/UICtx';
import config from './config';
import { SupportProvider } from './context/SupportCtx';
import { ErrorBoundaryProvider } from './context/ErrorBoundaryCtx';
import { SseProvider } from './context/SseCtx';
import { ExtractionProviders } from './context/extraction';
import { UserProviders } from './context/user';
import { DashboardProviders } from './context/dashboard';
import { PaymentProviders } from './context/payment';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ErrorBoundaryProvider>
    <GoogleOAuthProvider clientId={config.googleOAuthClientId}>
      <BrowserRouter>
        <UserProviders>
          <AuthProvider>
            <SseProvider>
              <ExtractionProviders>
                <DashboardProviders>
                  <UIProvider>
                    <PaymentProviders>
                      <SupportProvider>
                        <App />
                      </SupportProvider>
                    </PaymentProviders>
                  </UIProvider>
                </DashboardProviders>
              </ExtractionProviders>
            </SseProvider>
          </AuthProvider>
        </UserProviders>
      </BrowserRouter>
    </GoogleOAuthProvider>
    <NotificationContainer />
  </ErrorBoundaryProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
