import styled from 'styled-components';
import { fadeIn } from '../../../styles/keyframes';
import { pos } from '../../../styles/mixins';
import { breakpoints, colours } from '../../../styles/variables';

export const Cnt = styled.div`
  height: fit-content;
  width: 100%;
  padding: 22px 24px 24px 19px; // # pp
  border: 1px solid ${colours.lightGray};
  border-radius: 8px;
  margin-bottom: calc(51px - 114px);
  font-size: 16px;
  word-wrap: break-all;
  position: relative;

  .span,
  img {
    padding: 8px;
    ${pos({ p: 'absolute', t: 16, r: 16 })}
  }

  .span {
    border-radius: 4px;
    color: ${colours.white};
    background-color: ${colours.lightGray};
    pointer-events: none;
    cursor: none;
  }

  img {
    display: none;
    backdrop-filter: blur(6px);
    cursor: pointer;
    animation: ${fadeIn} ease-in-out 400ms;
  }

  &:hover {
    .span,
    img {
      display: block;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 24px; // # pp
    font-size: 14px;
    letter-spacing: 0.25px;
  }
`;
