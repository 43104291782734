import styled, { css } from 'styled-components';
import { fadeIn } from '../../styles/keyframes';
import { flex, pos } from '../../styles/mixins';
import { resetBtn } from '../../styles/placeholders';
import { colours } from '../../styles/variables';

const base = css`
  height: fit-content;
  width: fit-content;

  ${flex({ ai: 'center' })}

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      border-color: #7879f1;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const SelectWrapper = styled.div`
  ${flex({})}
`;

export const SelectCnt = styled.div`
  ${base}
  position: relative;
  overflow: hidden;
  appearance: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.44px;
  color: #7879f1;

  & > button {
    ${resetBtn}
    display: inline-flex;
  }

  svg {
    path {
      fill: ${colours.primary};
    }
    rect {
      fill: transparent;
    }
  }
  &.focused,
  &.altered {
    svg {
      path {
        fill: ${colours.white};
      }
      rect {
        fill: ${colours.primary};
      }
    }
  }
`;

export const Dropdown = styled.div`
  width: fit-content;
  padding: 4px 0px;
  border-radius: 2px;

  ${flex({ dir: 'column' })}
  ${pos({ p: 'absolute', t: '100%' })}
  z-index: 2;

  background: #ffffff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  animation: ${fadeIn} 150ms ease-out;

  span {
    width: 117px;
    padding: 5px 26px 5px 12px;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      color: ${colours.primary};
    }

    &.inactive {
      color: ${colours.lightGray};
    }

    &:hover {
      background-color: ${colours.gray};
    }
  }
`;
