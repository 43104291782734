import styled from 'styled-components';
import { fadeIn } from '../../../styles/keyframes';
import { colours } from '../../../styles/variables';

export const Cnt = styled.div`
  max-width: fit-content;
  margin: auto;

  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: ${colours.lightGray};
  }

  // first dot
  span:nth-last-child(3) {
    animation: ${fadeIn} 700ms ease-in infinite forwards alternate;
  }
  // second dot
  span:nth-last-child(2) {
    animation: ${fadeIn} 700ms ease-in 350ms infinite forwards alternate;
  }
  // third dot
  span:nth-last-child(1) {
    animation: ${fadeIn} 700ms ease-in 700ms infinite forwards alternate;
  }
`;
