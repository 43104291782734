import config from '../../config';
import { IExtractionClassFeedback } from './interfaces/extraction-class-feedback.interface';

// @ GET
// @ products/feedbacks/:taskId
// @ PRIVATE
export const FIND_EXTRACTION_MANY_CLASSES_FEEDBACK = async (
  taskId: string
): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const response = await fetch(
      `${config.baseUrl}/products/feedbacks/${taskId}?token=${apiToken}`,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ PUT
// @ products/feedbacks/:taskId
// @ PRIVATE
export const CREATE_EXTRACTION_ONE_CLASS_FEEDBACK = async (
  taskId: string,
  body: IExtractionClassFeedback
): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const { ok } = await fetch(
      `${config.baseUrl}/products/feedbacks/${taskId}?token=${apiToken}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    );

    return { ok };
  } catch (error) {
    return {
      ok: false,
      errorMessage: typeof error === 'string' ? error : 'Fetching error',
    };
  }
};
