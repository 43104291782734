import { css } from 'styled-components';

let isSafari = false;
const userAgent = navigator.userAgent.toLowerCase();
if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
  isSafari = true;
}

// Flex
type AlignType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'stretch';

interface IFlexOptions {
  dir?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  wrap?: 'wrap' | 'nowrap';
  jc?: AlignType;
  ai?: AlignType;
  gap?: number | string;
}

export const flex = (options: IFlexOptions) => {
  const { dir, wrap, jc, ai, gap } = options;
  const formattedGap = `${gap || 0}${typeof gap === 'number' ? 'px' : ''}`;

  let gapForSafari: any;
  if (isSafari) {
    if (typeof gap === 'number') {
      if (dir === 'column') {
        gapForSafari = css`
          & > * + * {
            margin-top: ${formattedGap};
          }
        `;
      } else {
        gapForSafari = css`
          & > * + * {
            margin-left: ${formattedGap};
          }
        `;
      }
    }
    if (typeof gap === 'string') {
      const gapArr = gap.split(' ');
      gapForSafari = css`
        & > * + * {
          margin-top: ${gapArr[0]};
          margin-left: ${gapArr[1]};
        }
      `;
    }
  }

  return css`
    display: flex;
    flex-direction: ${dir || 'row'};
    flex-wrap: ${wrap || 'nowrap'};
    justify-content: ${jc || 'flex-start'};
    align-items: ${ai || 'stretch'};
    gap: ${!isSafari ? formattedGap : 0};

    ${() => isSafari && gapForSafari}
  `;
};

export const gap = (g: number[]) => {
  if (!g || ![1, 2].includes(g.length)) return '';

  return css`
    margin-top: -${g[1] || g[0]}px;
    margin-left: -${g[0]}px;
    margin-bottom: ${(g[1] || g[0]) * 2}px;
    margin-right: 0;
    & > * {
      margin-top: ${g[1] || g[0]}px;
      margin-left: ${g[0]}px;
    }
  `;
};

// Position
interface IPosOptions {
  p?: 'absolute' | 'relative' | 'static' | 'fixed';
  t?: number | string;
  r?: number | string;
  b?: number | string;
  l?: number | string;
}

const addVendorPrefix = (
  ruleKey: 'top' | 'right' | 'bottom' | 'left',
  ruleValue: number | string | undefined
) => {
  let value = `${ruleKey}: unset;`;

  if (typeof ruleValue === 'number') {
    value = `${ruleKey}: ${ruleValue}px;`;
  }
  if (typeof ruleValue === 'string' && !ruleValue.includes('calc')) {
    value = `${ruleKey}: ${ruleValue};`;
  }
  if (typeof ruleValue === 'string' && ruleValue.includes('calc')) {
    value = `
    ${ruleKey}: -webkit-${ruleValue};
    ${ruleKey}: -moz-${ruleValue};
    ${ruleKey}: ${ruleValue};
    `;
  }

  return value;
};

export const pos = (options: IPosOptions) => {
  const { p, t, r, b, l } = options;

  const top = addVendorPrefix('top', t);
  const right = addVendorPrefix('right', r);
  const bottom = addVendorPrefix('bottom', b);
  const left = addVendorPrefix('left', l);

  return `
    position: ${p || 'absolute'};
    ${top}
    ${right}
    ${bottom}
    ${left}
  `;
};

export const textOverflow = (dir?: 'ltr' | 'rtl') => {
  return `
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ${dir || 'ltr'};
  `;
};
