import styled from 'styled-components';
import { flex, pos } from '../../../styles/mixins';
import { defaultBorder } from '../../../styles/placeholders';
import { breakpoints, colours } from '../../../styles/variables';

export const Cnt = styled.div`
  height: 100vh;
  width: 100vw;
  ${pos({ p: 'absolute', t: 0, r: 0, b: 0, l: 0 })}
  background-color: ${colours.gray}95;

  div {
    width: fit-content;
    height: fit-content;
    padding: 40px;
    ${defaultBorder}
    margin: 40vh auto 0;
    ${pos({ p: 'absolute', t: 0, r: 0, b: 0, l: 0 })}

    background: ${colours.white};
    box-shadow: 0px 4px 8px ${colours.shadow}, 0px 6px 12px ${colours.shadow},
      0px 1px 16px ${colours.shadow};

    header {
      ${flex({ ai: 'center' })}

      & > *:nth-child(2) {
        margin-left: 16px;
      }

      span {
        color: ${colours.fontDark};
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.xss}) {
    div {
      width: 95%;
    }
  }
`;
