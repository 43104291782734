import { DocsBadge } from './DocsBadge';
import { MetricsBadge } from './MetricsBadge';
import { StatusBadge } from './StatusBadge';
import { Number } from './Number';
import { Info } from './Info';
import { TagColored } from './TagColored';
import { Date } from './Date';
import { Username } from './Username';

export default {
  DocsBadge,
  MetricsBadge,
  StatusBadge,
  Number,
  Info,
  TagColored,
  Date,
  Username,
};
