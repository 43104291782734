import React, { useState } from 'react';
import { ILimitState } from '../../../interfaces/limit-state.interface';
import { LimitInputField, Wrapper } from './styles';

export const LimitInput: React.FC<Partial<ILimitState>> = ({ limit, setLimit }) => {
  if (!limit || !setLimit) {
    return null;
  }

  const [size, setSize] = useState(40);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setLimit(target.valueAsNumber);
    setSize(target.value.length * 10 + 10);
  };

  return (
    <Wrapper>
      <span>per page:&nbsp;</span>
      <LimitInputField
        type="number"
        value={limit}
        onFocus={({ target }) => target.select()}
        onChange={handleChange}
        style={{ width: `${size}px` }}
      />
    </Wrapper>
  );
};
