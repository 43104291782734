import { css } from 'styled-components';
import { colours } from '../../../styles/variables';

export const mdFont = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.fontDark};
`;

export const smFont = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
