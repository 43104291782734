import styled from 'styled-components';
import { rotate } from '../../../styles/keyframes';
import { flex } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const MainHeader = styled.div`
  height: 72px;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;

  ${flex({ gap: 8 })}
  background-color: ${colours.gray};
  cursor: pointer;

  &:hover {
    background-color: ${colours.lighterGray};
  }

  & + * {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  img.isTurned {
    animation: ${rotate} 200ms ease-out forwards;
  }
`;

export const MainHeading = styled.h5`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${colours.muted};
`;
