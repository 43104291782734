import React from 'react';
import { HelpFormik } from './HelpFormik';
import { SocialLink } from '../../../components/links/SocialLink';
import { FormCnt, SocialGroup } from './styledComponents';
import { Section } from '../../../components/containers/Section';
import { Heading } from '../../../components/headings/Heading';
import Svgs from '../../../components/svgs';

export const HelpSection = () => {
  return (
    <Section>
      <div>
        <Heading>Help &amp; support</Heading>
        <FormCnt>
          <HelpFormik />
        </FormCnt>
        <SocialGroup>
          <SocialLink
            Icon={Svgs.Whatsapp}
            name="WhatsApp"
            href="https://wa.me/+19299994119"
          />
          <SocialLink
            Icon={Svgs.Telegram}
            name="Telegram"
            href="https://t.me/GoLoginSupportBot"
          />
          <SocialLink
            Icon={Svgs.Messenger}
            name="Messenger"
            href="https://m.me/GoLessTeam"
          />
          <SocialLink
            Icon={Svgs.Gmail}
            name="Email"
            href="mailto:support@goless.com"
          />
        </SocialGroup>
      </div>
    </Section>
  );
};
