import React, { useState } from 'react';
import Modals from '../../components/modals';
import Tooltips from '../../components/tooltips';
import { useTaskData } from '../../context/extraction/TaskData';
import { IExtractionClassFeedback } from './interfaces/extraction-class-feedback.interface';
import { Modal } from './Modal';
import isFeedbackGivenUtil from './utils/is-feedback-given.util';
import { WarningBtn } from './WarningBtn';

interface Props extends IExtractionClassFeedback {
  isShown: boolean;
}

export const ClassFeedbackBtn: React.FC<Props> = ({ isShown, classType, checkboxes }) => {
  // TODO
  // we should be anything but nor `json` neither `data`
  // so we probably need to rethink the task interface
  // on both backend and frontend.
  // and definitely (at least) need to create the interface (instead of using `any`)

  const { json } = useTaskData();

  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const isFeedbackGiven = isFeedbackGivenUtil(
    classType,
    (json as any)?.extractionClassFeedbacks
  );

  if (!isShown && !isFeedbackGiven) {
    return null;
  }

  const handleClick: React.MouseEventHandler = () => {
    // TODO
    // here we should invoke FIND_EXTRACTION_CLASS_FEEDBACK
    // if `isFeedbackGiven` is true
    // to render the existing feedback data
    setIsModalShown(true);
  };

  return (
    <>
      <Tooltips.Hover
        text={isFeedbackGiven ? 'Change feedback' : 'Leave feedback'}
        position="left"
      >
        <WarningBtn
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
          isFeedbackGiven={isFeedbackGiven}
        />
      </Tooltips.Hover>

      <Modals.Generic
        isShown={isModalShown}
        hide={() => setIsModalShown(false)}
      >
        <Modal
          classType={classType}
          checkboxes={checkboxes}
          hide={() => setIsModalShown(false)}
        />
      </Modals.Generic>
    </>
  );
};
