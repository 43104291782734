import styled from 'styled-components';
import { textOverflow } from '../../../../../../styles/mixins';
import { colours } from '../../../../../../styles/variables';

export const Cnt = styled.div`
  position: relative;
`;

export const H4 = styled.h4`
  max-height: 14px;
  max-width: fit-content;
  margin-right: 48px;
  
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colours.muted};

  ${textOverflow('rtl')}
  white-space: nowrap;
`;
