import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const StatusBadge = styled.span`
  max-height: 24px;
  padding: 2.4px 6px;
  border-radius: 4px;
  letter-spacing: 0.0025em;

  &.success {
    background-color: #e6ebff;
    color: ${colours.primary} !important;
  }
  &.failure,
  &.bugReported {
    background-color: ${colours.danger3};
    color: ${colours.danger4} !important;
  }
  &.processing {
    background-color: ${`${colours.warn}20`};
    color: ${colours.warn} !important;
  }
`;
