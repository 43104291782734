import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const Date = styled.span`
  max-height: 24px;
  width: 106px;
  padding: 2.6px 11px;
  border: 1px solid ${colours.lightGray};
  border-radius: 12px;

  font-size: 14px;
  line-height: 16px;
  color: ${colours.fontDark};
  white-space: nowrap;
`;
