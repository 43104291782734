import styled from 'styled-components';
import { colours } from '../../styles/variables';
import { fieldBaseStyle } from './styles';

export const Input = styled.input`
  height: fit-content;
  ${fieldBaseStyle}
  &::placeholder {
    // # for customised inputs only
    color: ${colours.muted};
    display: none;
  }
  &:disabled {
    color: #808080;
    pointer-events: none;
    &::placeholder {
      color: #808080;
    }
  }
  // Error states
  &.false {
    border-bottom-color: ${colours.danger};

    &:focus {
      border-bottom-color: ${colours.danger};
    }
  }
`;
