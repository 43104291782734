import React, { DispatchWithoutAction, ReactNode } from 'react';
import { Cnt } from './styledComponents';

interface Props {
  isShown: boolean;
  hide: DispatchWithoutAction;
  children: ReactNode;
}

export const Generic: React.FC<Props> = ({ isShown, hide, children }) => {
  if (!isShown) {
    return null;
  }

  return (
    <Cnt onClick={hide}>
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </Cnt>
  );
};
