import styled from 'styled-components';
import { flex, pos } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const Grid = styled.main`
  width: 100%;
  display: grid;
  grid-template-areas:
    'images-and-controllers header'
    'images-and-controllers article';
  grid-template-rows: auto 1fr;
  gap: 32px;

  & > header {
    grid-area: header;
  }

  & > aside {
    width: 350px;
    grid-area: images-and-controllers;
  }

  & > article {
    grid-area: article;
    ${flex({ dir: 'column', jc: 'flex-start' })}

    section:first-of-type {
      margin-top: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    width: calc(350px + 32px + 594px);
  }

  @media screen and (max-width: 1280px) {
    grid-template-areas:
      'header'
      'images-and-controllers'
      'article';

    article:last-of-type {
      width: unset;
    }

    & > aside {
      width: 100%;
      grid-area: images-and-controllers;
    }
  }
`;

export const TooltipBtnWrapper = styled.div`
  ${pos({ p: 'absolute', t: 0, r: 0 })}
`;
