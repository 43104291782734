import React from 'react';
import Links from '../../../../../components/links';
import config from '../../../../../config';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';
import { Td } from '../styles';

export interface IIdCell extends ITableCellBase {
  taskId: string;
}

export const IdCell: React.FC<IIdCell> = ({ taskId, width = 0 }) => {
  const href = `${config.redirectUri}dashboard/${taskId}`;

  return (
    <Td style={{ width: `${width}%` }}>
      <Links.Link href={href} target="_blank" style={{ direction: 'rtl' }}>
        {taskId}
      </Links.Link>
    </Td>
  );
};
