import { css } from 'styled-components';
import { colours } from '../../../../styles/variables';

export const smFont = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const mdFont = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${colours.fontDark};
`;

export const lgFont = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${colours.fontDark};
`;
