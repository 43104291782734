import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { FloatingLabelInput } from '../../../../components/inputFields/FloatingLabelInput';
import { InputsCnt } from '../../styledComponents';
import {
  validateAddress,
  validateCompany,
  validateFullname,
  validatePhone,
} from '../../../../tools/validation';
import { useUserData } from '../../../../context/user/UserData';
import { IUserInfo } from '../../../../types';
import { SubmitBtn } from '../../../../components/buttons/SubmitBtn';
import Loadings from '../../../../components/loadings';
import { useUserMethods } from '../../../../context/user/UserMethods';

interface Props {
  email: string;
}

export const UserFormik = (props: Props) => {
  const { data: user, getInfoStatus } = useUserData();
  const { getInfo, updateInfo } = useUserMethods();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  useEffect(() => {
    getInfo();
  }, []);

  if (getInfoStatus === 'loading') return <Loadings.ThreeDots name="data" />;
  if (getInfoStatus === 'error') return <span>Fetching error...</span>;
  return (
    <Formik
      initialValues={{
        fullName: user.info?.fullName || '',
        email: props.email || '',
        address: user.info?.address || '',
        phone: user.info?.phone || '',
        company: user.info?.company || '',
      }}
      onSubmit={async (values) => {
        updateInfo(values);
      }}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(formikProps: FormikProps<IUserInfo>) => {
        const fullNameMeta = formikProps.getFieldMeta('fullName');
        const emailMeta = formikProps.getFieldMeta('email');
        const addressMeta = formikProps.getFieldMeta('address');
        const phoneMeta = formikProps.getFieldMeta('phone');
        const companyMeta = formikProps.getFieldMeta('company');

        return (
          <Form>
            <InputsCnt>
              <FloatingLabelInput
                type="text"
                autoComplete="name"
                name="fullName"
                placeholder="Full name"
                validate={validateFullname}
                classProp={!fullNameMeta.error}
              />
              {user.email && (
                <FloatingLabelInput
                  type="email"
                  autoComplete="email"
                  name="email"
                  placeholder="E-mail"
                  disabled
                  classProp={!emailMeta.error}
                />
              )}
              <FloatingLabelInput
                type="text"
                autoComplete="street-address"
                name="address"
                placeholder="Address"
                validate={validateAddress}
                classProp={!addressMeta.error}
              />
              <FloatingLabelInput
                type="tel"
                autoComplete="tel"
                name="phone"
                placeholder="Phone"
                validate={validatePhone}
                classProp={!phoneMeta.error}
              />
              <FloatingLabelInput
                type="text"
                autoComplete="organization"
                name="company"
                placeholder="Company"
                validate={validateCompany}
                classProp={!companyMeta.error}
              />
              <SubmitBtn
                values={formikProps.values}
                errors={formikProps.errors}
                setValidateOnChange={setValidateOnChange}
                disabled={!formikProps.dirty}
              >
                Update profile
              </SubmitBtn>
            </InputsCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
