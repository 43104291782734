/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { IPaymentHistoryItem } from '../../../context/payment/PaymentCtx/interfaces';
import { Link } from '../../links/Link';
import { RecordsWithUrlCnt, HeadRow, BodyRow } from './styledComponents';

export const PaymentHistory = ({ data }: { data: IPaymentHistoryItem[] }) => {
  return (
    <RecordsWithUrlCnt>
      <thead>
        <HeadRow>
          <th>Date</th>
          <th>Payment</th>
          <th>Price per req.</th>
          <th>Request balance</th>
          <th>Receipt</th>
        </HeadRow>
      </thead>
      <tbody>
        {data.map((payment: any, ind: number) => (
          <BodyRow key={ind}>
            <td>{payment.date}</td>
            <td>
              {new Intl.NumberFormat('us-US', {
                style: 'currency',
                currency: payment.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(payment.amount / 100)}
            </td>
            <td>
              {!!payment.amount && !!payment.reqsPaid
                ? new Intl.NumberFormat('us-US', {
                    style: 'currency',
                    currency: payment.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(payment.amount / payment.reqsPaid)
                : 'N/D'}
              <span>&nbsp;price per req.</span>
            </td>
            <td>
              {payment.balanceAfter !== null
                ? `${new Intl.NumberFormat('ru-RU', {
                    style: 'decimal',
                  }).format(payment.balanceAfter)} reqs.`
                : 'N/D'}
            </td>
            <td>
              {payment.receiptUrl ? (
                <Link href={payment.receiptUrl} target="_blank">
                  View receipt
                </Link>
              ) : (
                ''
              )}
            </td>
          </BodyRow>
        ))}
      </tbody>
    </RecordsWithUrlCnt>
  );
};
