/* eslint-disable no-bitwise */
export default (ms?: number): string => {
  if (!ms) {
    return '0 m 0 s';
  }

  const min = Math.floor((ms / 1000 / 60) << 0);
  const sec = Math.floor((ms / 1000) % 60);
  return `${min} m ${sec} s`;
};
