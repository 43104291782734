/* eslint-disable camelcase */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { colours } from '../../styles/variables';
import { IMetricsCount } from '../../context/dashboard/interfaces/metrics-count.interfaces';
import { TaskStatusType } from '../../types';

export default (
  rootId: string,
  data: IMetricsCount[],
  isLoading: boolean,
  filterStatus?: (option: TaskStatusType) => void
) => {
  // base
  const root = am5.Root.new(rootId);
  root.setThemes([am5themes_Animated.new(root)]);

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      width: am5.percent(100),
      height: am5.percent(95),
      panX: true,
      panY: true,
      wheelX: 'panX',
      wheelY: 'zoomX',
    })
  );

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      groupData: true,
      baseInterval: {
        timeUnit: 'hour',
        count: 1,
      },
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  const createSeries = (field: string) => {
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: field,
        valueXField: 'boundary',
        valueYGrouped: 'sum',
        legendLabelText: field,
        width: am5.percent(100),
      })
    );

    series.data.setAll(data.map((count) => {
      const boundary = new Date(new Date(count.boundary).toISOString()).getTime();

      return { ...count, boundary };
    }));

    // tooltip
    series.columns.template.setAll({
      tooltipText: '{valueYField}, {valueX.formatDate("MMMM d, hh:mm")}, {valueY}',
    });

    // styles
    if (field === 'success') {
      series.set('stroke', am5.color(colours.primary));
      series.set('fill', am5.color(colours.primary));
    }
    if (field === 'failure') {
      series.set('stroke', am5.color(colours.danger4));
      series.set('fill', am5.color(colours.danger4));
    }
    if (field === 'feedback') {
      series.set('stroke', am5.color(colours.warn));
      series.set('fill', am5.color(colours.warn));
    }
  };

  createSeries('success');
  createSeries('failure');
  createSeries('feedback');

  const legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.percent(-15),
    y: am5.percent(105),
    layout: root.horizontalLayout,
    draggable: false,
  }));

  try {
    legend.itemContainers.template.events.on('click', ({ target }) => {
      const { legendLabelText = '' } = (target.dataItem?.dataContext as any)._settings;
      if (!filterStatus || !['success', 'failure'].includes(legendLabelText)) {
        return;
      }

      filterStatus(legendLabelText);
    });
  } catch (error) {
    console.error(error);
  }

  legend.data.setAll(chart.series.values);

  // loading state
  const indicator = root.container.children.push(
    am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layer: 1000,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0xffffff),
        fillOpacity: 0.75,
      }),
    })
  );
  indicator.children.push(
    am5.Label.new(root, {
      text: 'Loading...',
      fontSize: 24,
      fontFamily: 'Roboto',
      fill: am5.color(colours.fontDark),
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
    })
  );

  if (isLoading) {
    indicator.show();
  } else {
    indicator.hide();
  }

  return root;
};
