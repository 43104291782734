import React from 'react';

export const StripeLogo = () => {
  return (
    <svg
      width="61"
      height="25"
      viewBox="0 0 61 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28694 9.79639C4.28694 9.14553 4.82917 8.89519 5.72722 8.89519C7.015 8.89519 8.64167 9.27904 9.92944 9.96328V6.04139C8.52306 5.49065 7.13361 5.2737 5.72722 5.2737C2.2875 5.2737 0 7.04272 0 9.99666C0 14.6028 6.43889 13.8685 6.43889 15.8545C6.43889 16.6222 5.76111 16.8725 4.81222 16.8725C3.40583 16.8725 1.60972 16.3051 0.186389 15.5374V19.5093C1.76222 20.1769 3.355 20.4606 4.81222 20.4606C8.33667 20.4606 10.7597 18.7417 10.7597 15.7543C10.7428 10.781 4.28694 11.6656 4.28694 9.79639ZM15.7414 1.93591L11.6069 2.80374L11.59 16.1716C11.59 18.6415 13.4708 20.4606 15.9786 20.4606C17.3681 20.4606 18.3847 20.2103 18.9439 19.9099V16.522C18.4017 16.739 15.7244 17.5067 15.7244 15.0367V9.11215H18.9439V5.55741H15.7244L15.7414 1.93591ZM24.2136 6.79239L23.9425 5.55741H20.2825V20.1602H24.5186V10.2637C25.5183 8.97864 27.2128 9.21228 27.7381 9.39586V5.55741C27.1958 5.35714 25.2133 4.98999 24.2136 6.79239ZM28.7717 5.55741H33.0247V20.1602H28.7717V5.55741ZM28.7717 4.28905L33.0247 3.38785V0L28.7717 0.884513V4.28905ZM41.8697 5.2737C40.2092 5.2737 39.1417 6.04139 38.5486 6.57543L38.3283 5.54072H34.6006V25L38.8367 24.1155L38.8536 19.3925C39.4636 19.8264 40.3617 20.4439 41.8528 20.4439C44.8858 20.4439 47.6478 18.0407 47.6478 12.7503C47.6308 7.91055 44.835 5.2737 41.8697 5.2737ZM40.8531 16.7724C39.8533 16.7724 39.2603 16.4219 38.8536 15.988L38.8367 9.79639C39.2772 9.31242 39.8872 8.97864 40.8531 8.97864C42.395 8.97864 43.4625 10.6809 43.4625 12.8672C43.4625 15.1035 42.4119 16.7724 40.8531 16.7724ZM61 12.9172C61 8.64486 58.8989 5.2737 54.8831 5.2737C50.8503 5.2737 48.4103 8.64486 48.4103 12.8838C48.4103 17.9072 51.2908 20.4439 55.4253 20.4439C57.4417 20.4439 58.9667 19.9933 60.1189 19.3591V16.0214C58.9667 16.5888 57.645 16.9393 55.9675 16.9393C54.3239 16.9393 52.8667 16.3718 52.6803 14.4025H60.9661C60.9661 14.1856 61 13.3178 61 12.9172ZM52.6294 11.3318C52.6294 9.44593 53.7986 8.66155 54.8661 8.66155C55.8997 8.66155 57.0011 9.44593 57.0011 11.3318H52.6294Z"
      />
    </svg>
  );
};
