import React from 'react';

export const TableFilter = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" fill="#5578F6" />
      <path
        d="M4.25 8C4.25 7.55817 4.60817 7.2 5.05 7.2H10.95C11.3918 7.2 11.75 7.55817 11.75 8V8C11.75 8.44183 11.3918 8.8 10.95 8.8H5.05C4.60817 8.8 4.25 8.44183 4.25 8V8ZM3 4.8C3 4.35817 3.35817 4 3.8 4H12.2C12.6418 4 13 4.35817 13 4.8V4.8C13 5.24183 12.6418 5.6 12.2 5.6H8H3.8C3.35817 5.6 3 5.24183 3 4.8V4.8ZM5.5 11.2C5.5 10.7582 5.85817 10.4 6.3 10.4H9.7C10.1418 10.4 10.5 10.7582 10.5 11.2V11.2C10.5 11.6418 10.1418 12 9.7 12H6.3C5.85817 12 5.5 11.6418 5.5 11.2V11.2Z"
        fill="white"
      />
    </svg>
  );
};
