import React, { useEffect } from 'react';
import { useBilling } from '../../../../context/payment/BillingCtx';
import { Cnt, Thumb } from './styledComponents';
import { getSelectionLine, getThumb } from './utils/getElements';
import getPointsValues from './utils/getPointsValues';
import isCloseTo from './utils/isCloseTo';

export const SelectionLine = () => {
  const { selectedPlanIndex, setSelectedPlanIndex } = useBilling();

  const handleMouseMove = (e: MouseEvent) => {
    document.body.style.cursor = 'grabbing';

    const [thumb, selectionLine] = [getThumb(), getSelectionLine()];
    const { offsetLeft, offsetWidth } = selectionLine;
    const newOffsetLeft = e.clientX - offsetLeft;

    if (newOffsetLeft > 0 && newOffsetLeft < offsetWidth - 24) {
      thumb.style.transform = `translateX(${newOffsetLeft}px)`;
    }

    const points = getPointsValues();
    if (points) {
      points.forEach((point, ind) => {
        const isClose = isCloseTo(newOffsetLeft, point);
        if (isClose) {
          thumb.style.transform = `translateX(${point}px)`;
          setSelectedPlanIndex(ind);
        }
      });
    }
  };

  const handleMouseDown = () => {
    const preventSelect = (e: Event) => e.preventDefault();

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('selectstart', preventSelect);

    window.addEventListener('mouseup', () => {
      document.body.style.cursor = 'default';

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('selectstart', preventSelect);
    });
  };

  useEffect(() => {
    getThumb().addEventListener('mousedown', handleMouseDown);
  }, []);

  useEffect(() => {
    const thumb = getThumb();
    const points = getPointsValues();

    if (points) {
      thumb.style.transform = `translateX(${points[selectedPlanIndex]}px)`;
    }
  }, [selectedPlanIndex]);

  return (
    <Cnt id="selection-line" className={`_${selectedPlanIndex}`}>
      <Thumb id="billing-plan-thumb" />
    </Cnt>
  );
};
