import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ExtractionCnt } from './features/ExtractionCnt';
import { PageCnt } from './features/PageCnt';
import { LoginPage } from './pages/Auth/LoginPage';
import { ResetPassPage } from './pages/Auth/ResetPassPage';
import { SignupPage } from './pages/Auth/SignupPage';
import { AccountSettingsPage } from './pages/Settings/AccountSettingsPage';
import { PaymentSettingsPage } from './pages/Settings/PaymentSettingsPage';
import { ApiSettingsPage } from './pages/Settings/ApiSettingsPage';
import { PaymentPage } from './pages/Payment/PaymentPage';
import { ConfirmNewPassPage } from './pages/Auth/ConfirmNewPassPage';
import { RequireAdmin } from './context/AuthCtx';
import { AuthWrapper } from './components/containers/AuthWrapper';
import { OverviewPage } from './pages/Dashboard/OverviewPage';
import { ExtractionTabs } from './features/ExtractionCnt/components/ExtractionTabs';
import { HistoryPage } from './pages/Extraction/HistoryPage';
import { SupportPage } from './pages/Support';
import { DashboardTabs } from './features/Dashboard/DashboardTabs';
import { DashboardCnt } from './features/Dashboard';

const App = () => {
  return (
    <Routes>
      {/* Public */}
      <Route path="auth" element={<AuthWrapper />}>
        <Route index element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="reset" element={<ResetPassPage />} />
        <Route path="confirm-new-password" element={<ConfirmNewPassPage />} />
      </Route>

      {/* Private */}
      <Route path="" element={<PageCnt />}>
        <Route path="" element={<ExtractionCnt />}>
          <Route index element={<HistoryPage />} />
          <Route path="extraction" element={<ExtractionTabs />} />
          <Route path="extraction/:taskId" element={<ExtractionTabs />} />
        </Route>
        <Route path="settings">
          <Route index element={<AccountSettingsPage />} />
          <Route path="payment" element={<PaymentSettingsPage />} />
          <Route path="api" element={<ApiSettingsPage />} />
        </Route>
        <Route path="payment" element={<PaymentPage />} />
        <Route path="support" element={<SupportPage />} />

        {/* Admin */}
        <Route path="dashboard" element={<RequireAdmin />}>
          <Route index element={<OverviewPage />} />
          <Route path=":taskId" element={<DashboardCnt />}>
            <Route index element={<DashboardTabs />} />
          </Route>
        </Route>
      </Route>

      {/* Not existing route */}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

export default App;
