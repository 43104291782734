import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const Payment = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <g clipPath="url(#clip0_14_800)">
        <path
          d="M11 11.5C11.8284 11.5 12.5 10.8284 12.5 10C12.5 9.17157 11.8284 8.5 11 8.5C10.1716 8.5 9.5 9.17157 9.5 10C9.5 10.8284 10.1716 11.5 11 11.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 4.5H15.5L15.5 15.5H9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M0.5 2.5V13.5C0.5 14.0304 0.710714 14.5391 1.08579 14.9142C1.46086 15.2893 1.96957 15.5 2.5 15.5H9M0.5 2.5C0.5 3.03043 0.710714 3.53914 1.08579 3.91421C1.46086 4.28929 1.96957 4.5 2.5 4.5H9M0.5 2.5C0.5 1.96957 0.710714 1.46086 1.08579 1.08579C1.46086 0.710714 1.96957 0.5 2.5 0.5H11.5V2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_14_800">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgCnt>
  );
};
