import React from 'react';
import { HistoryRecord } from '../HistoryTable/HistoryRecord';
import { HeadRow, TableCnt, TableHead } from '../styles';

interface Props {
  data: any[];
}

export const ProcessingTasksTable: React.FC<Props> = ({ data }) => (
  <TableCnt>
    <TableHead>
      <HeadRow>
        <div>Queue</div>
      </HeadRow>
    </TableHead>

    {data.map((table, tInd) => (
      <div key={tInd}>
        {data.length &&
          table.records.map((record: any, rInd: number) => (
            <HistoryRecord key={rInd} record={record} />
          ))}
      </div>
    ))}
  </TableCnt>
);
