import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const Link = styled.a`
  color: ${colours.primary};
  cursor: pointer;

  &:hover {
    color: ${colours.primaryDark};
  }
`;
