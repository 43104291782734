import React from 'react';

export const Github = () => {
  return (
    <svg
      width="256"
      height="250"
      viewBox="0 0 256 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_900)">
        <path
          d="M128.001 0C57.317 0 0 57.307 0 128.001C0 184.555 36.676 232.536 87.535 249.461C93.932 250.646 96.281 246.684 96.281 243.303C96.281 240.251 96.161 230.168 96.107 219.473C60.497 227.215 52.983 204.37 52.983 204.37C47.16 189.575 38.77 185.64 38.77 185.64C27.157 177.696 39.646 177.86 39.646 177.86C52.499 178.762 59.267 191.05 59.267 191.05C70.684 210.618 89.212 204.961 96.516 201.69C97.665 193.418 100.982 187.77 104.643 184.574C76.212 181.338 46.325 170.362 46.325 121.316C46.325 107.341 51.325 95.922 59.513 86.958C58.184 83.734 53.803 70.716 60.753 53.084C60.753 53.084 71.502 49.644 95.963 66.205C106.173 63.369 117.123 61.947 128.001 61.898C138.879 61.947 149.838 63.368 160.067 66.205C184.498 49.645 195.232 53.085 195.232 53.085C202.199 70.715 197.816 83.735 196.487 86.958C204.694 95.922 209.66 107.341 209.66 121.316C209.66 170.479 179.716 181.304 151.213 184.473C155.804 188.445 159.895 196.235 159.895 208.177C159.895 225.303 159.747 239.087 159.747 243.303C159.747 246.71 162.051 250.701 168.539 249.443C219.37 232.5 256 184.537 256 128.002C256 57.307 198.691 0 128.001 0Z"
          fill="#161614"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_900">
          <rect width="256" height="250" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
