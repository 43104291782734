import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IExtractionQuery } from '../../../api/types';
import { createUrlWithParams } from '../../../tools/urls/createUrlWithParams';
import { ProviderProps } from '../../types';
import { useHistory } from '../History';
import { useTaskData } from '../TaskData';
import { baseEndpoint } from '../TaskMethods/constants';
import { IUtilsCtx } from './interfaces';

const { debug } = console;

const UtilsCtx = createContext<IUtilsCtx>({} as IUtilsCtx);
export const useUtils = () => useContext(UtilsCtx);

export const UtilsProvider: React.FC<ProviderProps> = ({ children }) => {
  const navTo = useNavigate();
  const { resetData, setUrl } = useTaskData();
  const { setHistoryData } = useHistory();

  const constructRequestUrl = (query: IExtractionQuery) => {
    const requestUrl = createUrlWithParams(baseEndpoint, {
      ...query,
      token: localStorage.getItem('api_token'),
    });

    debug('Extraction', 'Request url:', requestUrl);
    return requestUrl;
  };

  const resetDataAndUrl = () => {
    resetData();
    setHistoryData([]);
    debug('Extraction', 'Resetting url...');
    setUrl('');
    navTo('/');
  };

  return (
    <UtilsCtx.Provider value={{ constructRequestUrl, resetDataAndUrl }}>
      {children}
    </UtilsCtx.Provider>
  );
};
