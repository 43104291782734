import React, { MouseEventHandler } from 'react';
import { SvgCnt } from './styledComponents';

interface Props {
  handleClick: MouseEventHandler;
}

export const Repeat = (props: Props) => {
  return (
    <SvgCnt
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.handleClick}
    >
      <g>
        <path
          d="M0.534086 8.93105H4.46609C4.51359 8.93103 4.56011 8.91748 4.60019 8.89198C4.64027 8.86648 4.67225 8.83009 4.6924 8.78707C4.71254 8.74405 4.72002 8.69618 4.71394 8.64907C4.70786 8.60195 4.68849 8.55755 4.65809 8.52105L2.69209 6.16105C2.66862 6.13291 2.63926 6.11028 2.60609 6.09474C2.57291 6.07921 2.53672 6.07116 2.50009 6.07116C2.46345 6.07116 2.42726 6.07921 2.39409 6.09474C2.36091 6.11028 2.33155 6.13291 2.30809 6.16105L0.342086 8.52105C0.311684 8.55755 0.29231 8.60195 0.286234 8.64907C0.280157 8.69618 0.287629 8.74405 0.307775 8.78707C0.327921 8.83009 0.359907 8.86648 0.399987 8.89198C0.440067 8.91748 0.486583 8.93103 0.534086 8.93105Z"
          fill="#818181"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.10001 8.93105C3.28932 9.85567 3.73636 10.7079 4.38949 11.3892C5.04261 12.0705 5.87517 12.5531 6.79097 12.7813C7.70677 13.0095 8.66845 12.974 9.5649 12.6788C10.4613 12.3835 11.256 11.8407 11.857 11.113C11.898 11.06 11.9492 11.0157 12.0076 10.9828C12.066 10.9499 12.1304 10.9291 12.197 10.9216C12.2636 10.9141 12.3311 10.9201 12.3953 10.9392C12.4596 10.9583 12.5193 10.9901 12.5711 11.0328C12.6228 11.0754 12.6654 11.128 12.6963 11.1875C12.7273 11.2469 12.746 11.312 12.7513 11.3788C12.7566 11.4457 12.7484 11.5129 12.7273 11.5765C12.7061 11.6401 12.6723 11.6988 12.628 11.749C11.8958 12.6355 10.9244 13.293 9.82934 13.6434C8.73427 13.9937 7.56161 14.0222 6.45083 13.7254C5.34006 13.4285 4.33791 12.8189 3.5636 11.969C2.78928 11.119 2.27538 10.0646 2.08301 8.93105H3.10001Z"
          fill="#818181"
        />
        <path
          d="M11.5341 7.07116H15.4661C15.5136 7.07118 15.5601 7.08473 15.6002 7.11023C15.6403 7.13573 15.6723 7.17212 15.6924 7.21514C15.7125 7.25816 15.72 7.30603 15.7139 7.35314C15.7079 7.40025 15.6885 7.44466 15.6581 7.48116L13.6921 9.84116C13.6686 9.8693 13.6393 9.89193 13.6061 9.90746C13.5729 9.923 13.5367 9.93105 13.5001 9.93105C13.4635 9.93105 13.4273 9.923 13.3941 9.90746C13.3609 9.89193 13.3315 9.8693 13.3081 9.84116L11.3421 7.48116C11.3117 7.44466 11.2923 7.40025 11.2862 7.35314C11.2802 7.30603 11.2876 7.25816 11.3078 7.21514C11.3279 7.17212 11.3599 7.13573 11.4 7.11023C11.4401 7.08473 11.4866 7.07118 11.5341 7.07116Z"
          fill="#818181"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.03705 3.06895C6.48505 3.06895 5.09705 3.77595 4.18005 4.88695C4.13911 4.94002 4.08791 4.98432 4.02949 5.01721C3.97108 5.0501 3.90666 5.07091 3.84004 5.0784C3.77343 5.08588 3.70599 5.0799 3.64174 5.06081C3.57748 5.04171 3.51772 5.00989 3.46601 4.96723C3.4143 4.92458 3.3717 4.87196 3.34073 4.81251C3.30976 4.75305 3.29107 4.68798 3.28575 4.62116C3.28044 4.55434 3.28862 4.48713 3.3098 4.42353C3.33098 4.35993 3.36473 4.30124 3.40905 4.25095C4.14124 3.36448 5.11264 2.70696 6.20772 2.35661C7.30279 2.00625 8.47545 1.9778 9.58623 2.27464C10.697 2.57148 11.6991 3.18111 12.4735 4.03104C13.2478 4.88096 13.7617 5.9354 13.9541 7.06895H12.9371C12.7062 5.93976 12.0924 4.92494 11.1996 4.1961C10.3067 3.46725 9.18961 3.06909 8.03705 3.06895Z"
          fill="#818181"
        />
      </g>
    </SvgCnt>
  );
};
