import React, { useState } from 'react';
import { FilterNameType } from '../../context/dashboard/Pagination/interfaces';
import { toUpperCaseFirstChar } from '../../tools/formatString';
import Svgs from '../svgs';
import { Dropdown, SelectCnt, SelectWrapper } from './styles';

interface Props {
  name: FilterNameType;
  availableOptions: string[];
  optionsSelected: string[];
  filterOptions: (option: string) => void;
}

export const TableFilter: React.FC<Props> = ({
  name,
  availableOptions = [],
  optionsSelected,
  filterOptions,
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <SelectWrapper>
      <div
        onMouseOver={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        onTouchEnd={() => setFocused(!isFocused)}
        onScrollCapture={() => setFocused(false)}
        style={{ position: 'relative' }}
      >
        <SelectCnt className={isFocused ? 'focused' : ''} defaultValue={name}>
          <button>
            <Svgs.TableFilter />
          </button>
        </SelectCnt>

        {isFocused && (
          <Dropdown>
            {availableOptions.map((option: string, index: number) => (
              <span
                title={option || ''}
                key={index}
                onClick={() => filterOptions(option || '')}
                onTouchEnd={() => filterOptions(option || '')}
                className={optionsSelected.includes(option) ? 'active' : 'inactive'}
              >
                {toUpperCaseFirstChar(option)}
              </span>
            ))}
          </Dropdown>
        )}
      </div>
    </SelectWrapper>
  );
};
