import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useUI } from '../../context/UICtx';
import { Section } from '../../components/containers/Section';
import BadgesCntStyled from './BadgesCnt.styled';
import Buttons from '../../components/buttons';
import MainCntStyled from './MainCnt.styled';
import Lists from '../../components/lists';
import { useDashboard } from '../../context/dashboard/DashboardCtx';
import toDdMmHhMmSs from '../../tools/dates/toDdMmHhMmSs';
import fromMs from '../../tools/dates/fromMs';
import Badges from '../../components/badges';
import MenuWrpStyled from './MenuWrp.styled';
import { Header } from '../../pages/Dashboard/Header';
import { JsonPage } from '../../pages/Extraction/JsonPage';
import isFeedbackUtil from '../../tools/isFeedback.util';

export const DashboardCnt = () => {
  const {
    loadOneMetric,
    loadOneMetricExtraction,
    loadOneMetricJson,
    oneMetric,
    oneMetricJson,
    oneMetricExtractionStatus,
    oneMetricJsonStatus,
  } = useDashboard();

  const { dashboardTab, setDashboardTab } = useUI();

  const { taskId } = useParams();
  useEffect(() => {
    if (taskId) {
      loadOneMetric(taskId);
      loadOneMetricExtraction(taskId);
      loadOneMetricJson(taskId);
    }
  }, [taskId]);

  const isFeedback = isFeedbackUtil(oneMetric.metrics);

  return (
    <>
      <Section>
        <Header
          taskId={taskId || ''}
          startedAt={oneMetric?.metrics?.startedAt || 0}
          bugReported={isFeedback}
        />
        <BadgesCntStyled>
          <Badges.MetricsBadge
            keyName="created"
            value={toDdMmHhMmSs(oneMetric?.metrics?.startedAt)}
            isLoading={oneMetricExtractionStatus === 'loading'}
          />
          <Badges.MetricsBadge
            keyName="execution time"
            value={fromMs(oneMetric?.metrics?.executionTime || 0)}
            isLoading={oneMetricExtractionStatus === 'loading'}
          />
          <Badges.MetricsBadge
            keyName="total time"
            value={fromMs(oneMetric?.metrics?.executionTime || 0)}
            isLoading={oneMetricExtractionStatus === 'loading'}
          />
        </BadgesCntStyled>
      </Section>

      <Section>
        {oneMetric?.task_report?.type === 'success' && (
          <MenuWrpStyled>
            <Lists.Tabs>
              <Buttons.Tab
                switchToPageName="Visual"
                pageSwitcher={setDashboardTab}
                active={dashboardTab === 'Visual'}
              />
              <Buttons.Tab
                switchToPageName="JSON"
                pageSwitcher={setDashboardTab}
                active={dashboardTab === 'JSON'}
              />
            </Lists.Tabs>
          </MenuWrpStyled>
        )}
        <MainCntStyled>
          {oneMetric?.task_report?.type === 'success' ? (
            <Outlet />
          ) : (
            <JsonPage status={oneMetricJsonStatus} data={oneMetricJson} />
          )}
        </MainCntStyled>
      </Section>
    </>
  );
};
