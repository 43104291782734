import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { removeScrollbar } from '../../../../../styles/placeholders';
import { colours } from '../../../../../styles/variables';

export const Cnt = styled.div`
  width: 100%;
  border-radius: 8px 0 0 8px;
  ${flex({ jc: 'stretch' })}
  & > *:not(:first-of-type) {
    margin-left: 4px;
  }

  // Scrollbar
  overflow-x: auto;
  ${removeScrollbar}
`;

export const Col = styled.div`
  padding: 12px 20px;
  flex-grow: 1;
  ${flex({ dir: 'column', ai: 'flex-start' })}
  background-color: ${colours.lighterGray};

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }

  & > div {
    width: max-content;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #000000;

    h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0;
      color: ${colours.muted};
    }
  }
`;
