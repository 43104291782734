import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';

export const Cnt = styled.nav`
  ${flex({ dir: 'column' })}
  margin-top: 38px;
  background-color: ${colours.gray};

  @media screen and (max-width: ${breakpoints.sm}) {
    &.opened {
      width: 100%;
      margin-top: calc(38px - 16px); // minus 16px flex gap
    }

    &.closed {
      display: none;
    }
  }
`;

export const Section = styled.div`
  padding: 20px 0 20px 16.5px; // # pp
  ${flex({ dir: 'column', gap: 15 })}

  // Cutting top and bottom paddings
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colours.lightGray};
  }

  button:disabled {
    span {
      line-height: 19px; // different heights for enabled & disabled buttons in Figma
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    button:disabled {
      span {
        line-height: 24px; // different heights for enabled & disabled buttons in Figma
      }
    }
  }
`;
