import React from 'react';

export const DoubleArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_28)">
        <path
          d="M4.00016 7.99997C4.00016 7.76797 4.08016 7.53697 4.24116 7.34897L10.8912 -0.410034L12.4102 0.891966L6.31716 7.99997L12.4102 15.108L10.8912 16.41L4.24116 8.65097C4.08016 8.46297 4.00016 8.23197 4.00016 7.99997Z"
          fill="#5578F6"
        />
        <path
          d="M0.000156035 7.99997C0.000156045 7.76797 0.0801562 7.53697 0.241156 7.34897L6.89116 -0.410034L8.41016 0.891966L2.31716 7.99997L8.41016 15.108L6.89116 16.41L0.241156 8.65097C0.0801562 8.46297 0.000156025 8.23197 0.000156035 7.99997Z"
          fill="#5578F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_28">
          <rect
            width="16"
            height="16"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
