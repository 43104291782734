import React from 'react';
import { IHistoryTask } from '../../../../../context/dashboard/DashboardCtx/interfaces';
import { TaskSubmenu } from '../../../../TaskSubmenu';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';
import { Cell } from './styles';

export interface IDetailsCell extends ITableCellBase {
  task: IHistoryTask;
}

export const DetailsCell: React.FC<IDetailsCell> = ({ task, width = 0 }) => {
  const optionsBase = { taskId: task._id };
  const submenuOptions = task.query?.url ? { ...optionsBase, url: task.query.url } : optionsBase;

  return (
    <Cell style={{ width: `${width}%` }}>
      <TaskSubmenu direction="left" data={submenuOptions} />
    </Cell>
  );
};
