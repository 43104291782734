import React, { MouseEventHandler } from 'react';
import { InfoBadge } from './styledComponents';

interface Props {
  handleClick?: MouseEventHandler;
  children: string;
  style?: 'info' | 'error' | 'warn';
}

export const Info: React.FC<Props> = ({ handleClick, children, style }) => (
  <InfoBadge onClick={handleClick} className={style || 'info'}>
    {children}
  </InfoBadge>
);
