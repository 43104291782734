import { ICompletedTaskReport } from '../../context/extraction/TaskMethods/interfaces';
import { IModifiedTaskReport } from '../../context/extraction/TaskData/interfaces';
import { HeadlessTableRowName, MetaTag, TableWithHeadName } from '../../types';

export default (inputData: ICompletedTaskReport): IModifiedTaskReport => {
  // (1) meta tags (brand, color, ean, mpn)
  const metaTagsNames = ['brand', 'color', 'ean', 'mpn'];
  const metaTags: [MetaTag, string][] = [];
  (metaTagsNames as MetaTag[]).forEach((tagName) => {
    if (
      typeof inputData !== 'string' &&
      tagName in inputData &&
      inputData[tagName]
    ) {
      metaTags.push([tagName, inputData[tagName]]);
    }
  });

  // (2) top table without head (features, dimensions, weight)
  const headlessTableRowNames = ['dimensions', 'weight'];
  const headlessTableRows: [HeadlessTableRowName, string | string[]][] = [];
  (headlessTableRowNames as HeadlessTableRowName[]).forEach((rowName) => {
    if (
      typeof inputData !== 'string' &&
      rowName in inputData &&
      inputData[rowName]
    ) {
      if (
        typeof inputData[rowName] !== 'string' &&
        inputData[rowName].length === 0
      ) {
        return;
      }
      headlessTableRows.push([rowName, inputData[rowName]]);
    }
  });

  // (3) tables with head (details, specifics)
  const tablessWithHeadNames = ['features', 'details', 'specifics', 'controls'];
  const tablessWithHead: {
    name: TableWithHeadName;
    rows: [string, string][];
  }[] = [];
  (tablessWithHeadNames as TableWithHeadName[]).forEach((tableName) => {
    if (
      typeof inputData !== 'string' &&
      tableName in inputData &&
      inputData[tableName]
    ) {
      if (
        typeof inputData[tableName] !== 'string' &&
        inputData[tableName].length === 0
      ) {
        return;
      }
      tablessWithHead.push({
        name: tableName,
        rows: inputData[tableName],
      });
    }
  });

  return {
    images: inputData.images,
    title: inputData.title,
    category: inputData.category,
    offers: inputData.offers,
    metaTags,
    description: inputData.description,
    headlessTableRows,
    tablessWithHead,
    url: inputData.url,
  };
};
