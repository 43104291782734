import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { ILimitState } from '../../interfaces/limit-state.interface';
import Svgs from '../svgs';
import { LimitInput } from './LimitInput';
import {
  ArrowBtn,
  Cnt,
  NumCnt,
  NumInput,
  TotalPages,
} from './styles';

interface Props extends Partial<ILimitState> {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  maxPage: number;
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  maxPage,
  limit,
  setLimit,
}) => {
  const handleChange: ChangeEventHandler = (e) => {
    const value = +(e.target as HTMLInputElement).value;
    if (value <= maxPage && value >= 1) setCurrentPage(value);
    if (value > maxPage) setCurrentPage(maxPage);
    if (value < 1) setCurrentPage(1);
  };

  return (
    <Cnt>
      <LimitInput limit={limit} setLimit={setLimit} />
      <ArrowBtn disabled={currentPage === 1} onClick={() => setCurrentPage(1)}>
        <Svgs.DoubleArrow />
      </ArrowBtn>
      <ArrowBtn
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((cur) => cur - 1)}
        style={{ transform: 'rotate(90deg)' }}
      >
        <Svgs.SingleArrow />
      </ArrowBtn>
      <NumCnt>
        <NumInput
          type="number"
          value={currentPage}
          onFocus={(e) => e.target.select()}
          onChange={handleChange}
        />
        <span>/</span>
        <TotalPages>{maxPage}</TotalPages>
      </NumCnt>
      <ArrowBtn
        className="next"
        disabled={currentPage === maxPage}
        onClick={() => setCurrentPage((cur) => cur + 1)}
        style={{ transform: 'rotate(-90deg)' }}
      >
        <Svgs.SingleArrow />
      </ArrowBtn>
      <ArrowBtn
        className="next"
        disabled={currentPage === maxPage}
        onClick={() => setCurrentPage(maxPage)}
      >
        <Svgs.DoubleArrow />
      </ArrowBtn>
    </Cnt>
  );
};
