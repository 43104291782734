import styled from 'styled-components';

export const HeaderCnt = styled.header`
  /* min-height: 58px; */
  overflow: hidden;

  & > *:not(:first-child) {
    margin-top: 17px; // # pp
  }
`;
