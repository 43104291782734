import styled from 'styled-components';
import { dim } from '../../../styles/keyframes';
import { pos } from '../../../styles/mixins';

export const DisconnectCnt = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 8px 24px;
  border: 1px solid #ff3d3d10;
  border-radius: 8px;
  margin: 0 auto;
  ${pos({ p: 'fixed', t: 24, r: 0, l: 0 })}
  z-index: 10;

  box-shadow: 0px 8px 16px #ff3d3d25;
  background-color: #ffeeee;
  color: #ff3d3d;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.44px;

  &:not(:hover) {
    animation: ${dim(0.75, 1)} 1500ms ease-in-out infinite alternate;
  }
`;
