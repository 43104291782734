import React from 'react';
import { Grid } from './styles';
import { Images } from './componets/Images';
import { Header } from './componets/Header';
import { MetaSection } from './componets/MetaSection';
import { DescSection } from './componets/DescSection';
import { TableSection } from './componets/TableSection';
import Loadings from '../../../components/loadings/index';
import { IModifiedTaskReport } from '../../../context/extraction/TaskData/interfaces';
import { StatusType } from '../../../types';
import { Empty } from '../../../components/Empty';

interface Props {
  status: StatusType;
  data: IModifiedTaskReport;
}

export const VisualPage: React.FC<Props> = ({ status, data }) => ({
  success: data ? (
    <Grid>
      <Header
        heading={data.title}
        subHeading={data.category}
        url={data.url}
      />
      <aside>
        <Images images={data.images} />
      </aside>
      <article>
        {data.offers && (
          <MetaSection price={data.offers} metaTags={data.metaTags} />
        )}
        {data.description && <DescSection desc={data.description} />}
        <TableSection
          headlessTableRows={data.headlessTableRows}
          tablesWithHead={data.tablessWithHead}
        />
      </article>
    </Grid>
  ) : null,
  error: <Empty name="visual" />,
  loading: <Loadings.Visual />,
  undefined: <Empty name="visual" />,
}[`${status}`]);
