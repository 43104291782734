import React from 'react';
import Tables from '../../../../../components/tables';
import { toUpperCaseFirstChar } from '../../../../../tools/formatString';
import { HeadlessTableRowName } from '../../../../../types';
import { TableSectionCnt } from './styles';

interface Props {
  headlessTableRows: [HeadlessTableRowName, string | string[]][];
  tablesWithHead: {
    name: 'details' | 'specifics' | 'controls';
    rows: (string | [string, string])[];
  }[];
}

export const TableSection = (props: Props) => {
  const { headlessTableRows, tablesWithHead } = props;

  return (
    <TableSectionCnt>
      {headlessTableRows.length ? <Tables.Visual rows={headlessTableRows} /> : null}
      {tablesWithHead.length ? tablesWithHead.map((table, ind) => (
        <Tables.VisualWithHeader
          name={toUpperCaseFirstChar(table.name)}
          rows={table.rows}
          key={ind}
        />
      )) : null}
    </TableSectionCnt>
  );
};
