import styled from 'styled-components';
import { flex, gap, textOverflow } from '../../../styles/mixins';
import { resetBtn } from '../../../styles/placeholders';
import { colours } from '../../../styles/variables';

export const HeaderCnt = styled.header`
  margin-bottom: 0 !important;
  ${flex({ dir: 'column', ai: 'flex-start' })}

  svg:hover path {
    fill: ${colours.primary};
  }
`;

export const MetaCnt = styled.div`
  width: 100%;
  padding-top: 16px;
  ${flex({ jc: 'flex-start' })}
  ${gap([16])}
`;

export const CopyBtn = styled.button`
  width: 100%;
  text-align: left;
  ${resetBtn}
  color: ${colours.fontDark};
  font-size: 16px;
  overflow-x: hidden;
  ${textOverflow()}

  &:hover {
    color: ${colours.primary};
  }
`;
