import React from 'react';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import Svgs from '../../svgs';
import { BadgeCnt } from './styles';

interface Props {
  keyName: string;
  value?: string | number;
  isLoading: boolean;
  handleClick?: () => void;
}

export const MetricsBadge: React.FC<Props> = ({ keyName, value = 0, isLoading, handleClick }) => (
  <BadgeCnt
    className={isLoading ? 'loading' : ''}
    role={handleClick ? 'active' : ''}
    onClick={handleClick}
    onTouchEnd={handleClick}
  >
    {!isLoading ? (
      <>
        <span>{value}</span>
        <div>
          <span>{toUpperCaseFirstChar(keyName)}</span>
          <Svgs.Chart />
        </div>
      </>
    ) : null}
  </BadgeCnt>
);
