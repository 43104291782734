import React from 'react';
import { IOffersFormatted } from '../../../../../../context/extraction/TaskMethods/interfaces';
import { ClassFeedbackBtn } from '../../../../../../features/ClassFeedbackBtn';
import formatOffer from '../../../../../../tools/extraction/formatOffer';
import { TooltipBtnWrapper } from '../../../styles';
import { NewPriceWrapper, Wrapper } from './styles';

const checkboxes = ['invalid currency', 'invalid value', 'incorrect amount'];

export const Price: React.FC<IOffersFormatted> = ({ amount = null, currency = '' }) => {
  const { amount: amountFormatted, currency: currencyFormatted = '' } = formatOffer({ amount, currency });
  const text = !amountFormatted || Number.isNaN(amountFormatted) ? '' : `${amountFormatted / 100} ${currencyFormatted}`;

  return (
    <Wrapper>
      <NewPriceWrapper>{text}</NewPriceWrapper>
      <TooltipBtnWrapper>
        <ClassFeedbackBtn
          isShown
          classType="price"
          checkboxes={checkboxes}
        />
      </TooltipBtnWrapper>
    </Wrapper>
  );
};
