import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { FloatingLabelInput } from '../../../../components/inputFields/FloatingLabelInput';
import { InputsCnt } from '../../styledComponents';
import {
  validateCurrentPass,
  validateNewPass,
  validateRepPass,
} from '../../../../tools/validation';
import { SubmitBtn } from '../../../../components/buttons/SubmitBtn';
import { useUserData } from '../../../../context/user/UserData';
import { useUserMethods } from '../../../../context/user/UserMethods';

export const PasswordFormik = () => {
  const {
    data: user,
    changePassStatus,
    setChangePassStatus,
    changePassError,
  } = useUserData();
  const { changePass } = useUserMethods();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        repNewPassword: '',
      }}
      onSubmit={async (values) =>
        changePass({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        })
      }
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(
        props: FormikProps<{
          currentPassword: string;
          newPassword: string;
          repNewPassword: string;
        }>
      ) => {
        const currentPasswordMeta = props.getFieldMeta('currentPassword');
        const currentPasswordHelpers = props.getFieldHelpers('currentPassword');
        const newPasswordMeta = props.getFieldMeta('newPassword');
        const repNewPasswordMeta = props.getFieldMeta('repNewPassword');

        useEffect(() => {
          if (changePassStatus === 'error') {
            currentPasswordHelpers.setError(changePassError);
          }
        }, [changePassStatus]);
        useEffect(() => {
          setChangePassStatus(undefined);
        }, [props.values]);

        return (
          <Form>
            <InputsCnt>
              {user.password && (
                <FloatingLabelInput
                  type="password"
                  autoComplete="off"
                  name="currentPassword"
                  placeholder="Your old password"
                  validate={validateCurrentPass}
                  classProp={!currentPasswordMeta.error}
                />
              )}
              <FloatingLabelInput
                type="password"
                autoComplete="new-password"
                name="newPassword"
                placeholder="Your new password"
                validate={validateNewPass}
                classProp={!newPasswordMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="off"
                name="repNewPassword"
                placeholder="Repeat new password"
                validate={(value: string) =>
                  validateRepPass(value, newPasswordMeta.value as string)
                }
                classProp={!repNewPasswordMeta.error}
              />
              <SubmitBtn
                values={
                  user.password
                    ? props.values
                    : [props.values.newPassword, props.values.repNewPassword]
                }
                errors={props.errors}
                setValidateOnChange={setValidateOnChange}
                status={changePassStatus}
                position="right"
              >
                Change password
              </SubmitBtn>
            </InputsCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
