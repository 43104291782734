import React from 'react';
import { useDashboard } from '../../../context/dashboard/DashboardCtx';
import { useUI } from '../../../context/UICtx';
import { JsonPage } from '../../../pages/Extraction/JsonPage';
import { VisualPage } from '../../../pages/Extraction/VisualPage';

export const DashboardTabs = () => {
  const { oneMetricStatus, oneMetricExtraction, oneMetricJson } = useDashboard();
  const { dashboardTab } = useUI();

  const switcher = {
    Visual: <VisualPage status={oneMetricStatus} data={oneMetricExtraction} />,
    JSON: <JsonPage status={oneMetricStatus} data={oneMetricJson} />,
  };

  return switcher[dashboardTab];
};
