import React from 'react';
import { IconBtnCnt } from './styledComponents';
import googleIcon from '../../../assets/icons/companies/google-icon.svg';
import facebookIcon from '../../../assets/icons/companies/facebook-icon.svg';
import githubIcon from '../../../assets/icons/companies/github-icon.svg';
import { UserFormik } from './UserFormik';
import { PasswordFormik } from './PasswordFormik';
import { Heading } from '../../../components/headings/Heading';
import { Cnt } from '../styledComponents';
import { OAuthBtn } from '../../../features/OAuthBtn';
import { Section } from '../../../components/containers/Section';
import { useUserData } from '../../../context/user/UserData';
import { EmailPasswordFormik } from './EmailPasswordFormik';

export const AccountSettingsPage = () => {
  const { data: user } = useUserData();

  return (
    <Cnt>
      <Section>
        <Heading>User info</Heading>
        <UserFormik email={user.email} />
      </Section>
      <Section>
        {user.email ? (
          <>
            <Heading>
              {user.password ? 'Password change' : 'Setup password'}
            </Heading>
            <PasswordFormik />
          </>
        ) : (
          <>
            <Heading>Credentials</Heading>
            <EmailPasswordFormik />
          </>
        )}
      </Section>
      <Section>
        <Heading>Linked accounts</Heading>
        <IconBtnCnt>
          <OAuthBtn auth="google" mode="link" icon={googleIcon} />
          {/* <OAuthBtn auth="facebook" mode="link" icon={facebookIcon} /> */}
          <OAuthBtn auth="github" mode="link" icon={githubIcon} />
        </IconBtnCnt>
      </Section>
    </Cnt>
  );
};
