import { toUpperCaseFirstChar } from './formatString';

type Field = 'fullName' | 'email' | 'password';

export const toFieldError = (errorMes: any): string[] => {
  const fields: Field[] = ['fullName', 'email', 'password'];
  const switcher = {
    fullName: 'full name',
    email: 'e-mail',
    password: 'password',
  };

  // converting errorMes to string
  let error: string;
  error = typeof errorMes === 'string' ? errorMes : errorMes[0];

  // selecting a field
  let selectedField: Field = 'email';
  fields.forEach((field) => {
    if (error.includes(field)) selectedField = field;
  });

  // formatting error
  error = error.replace(
    selectedField,
    toUpperCaseFirstChar(switcher[selectedField])
  );

  return [selectedField, error];
};
