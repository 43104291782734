import styled from 'styled-components';
import { colours } from '../../../styles/variables';

export default styled.button`
  height: 25px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 2px solid ${colours.primary};

  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: ${colours.fontDark};
  background-color: transparent;
  cursor: pointer;

  &.inactive {
    color: ${colours.muted};
    border-bottom-color: transparent;
  }

  &:hover {
    &.inactive {
      color: ${colours.fontDark};
      border-bottom-color: ${colours.primary}50;
    }
  }
`;
