import React, { FC, createContext, useContext, useState } from 'react';
import { Client } from '../../api/client';
import { StatusType } from '../../types';
import { ISupportCtx } from './interfaces';

const SupportCtx = createContext<ISupportCtx>({} as ISupportCtx);
export const useSupport = () => useContext(SupportCtx);

export const SupportProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const client = new Client();
  const [feedbackStatus, setFeedbackStatus] = useState<StatusType>(undefined);

  const loadFeedback = async (data: any) => {
    setFeedbackStatus('loading');
    const response = await client.SEND_FEEDBACK(data);
    if (!response.ok) return setFeedbackStatus('error');
    setFeedbackStatus('success');
  };

  return (
    <SupportCtx.Provider
      value={{ feedbackStatus, setFeedbackStatus, loadFeedback }}
    >
      {children}
    </SupportCtx.Provider>
  );
};
