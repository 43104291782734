import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { breakpoints } from '../../../../../styles/variables';

export const MainWrapper = styled.section`
  width: 827px;
  margin: 58px auto 0;
  ${flex({ dir: 'column' })}

  @media screen and (max-width: 1265px) {
    width: 650px;
  }
  @media screen and (max-width: 1090px) {
    width: 550px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const RenewFormikCnt = styled.div`
  padding: 32px 0;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 32px;
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    padding: 32px 16px;
  }
`;
