import { breakpoints } from '../../../../../styles/variables';
import getTranslateValue from './getTranslateValue';

export default () => {
  const { innerWidth } = window;

  let thumbPoints;

  if (innerWidth >= 1265) {
    thumbPoints = [0, 1, 2, 3].map((counter) =>
      getTranslateValue(176 + 75, counter as 0 | 1 | 2 | 3)
    );
  } else if (innerWidth >= 1090) {
    thumbPoints = [0, 1, 2, 3].map((counter) =>
      getTranslateValue(193, counter as 0 | 1 | 2 | 3)
    );
  } else if (innerWidth >= Number(breakpoints.md.replace('px', ''))) {
    thumbPoints = [0, 1, 2, 3].map((counter) =>
      getTranslateValue(159, counter as 0 | 1 | 2 | 3)
    );
  } else {
    thumbPoints = null;
  }

  return thumbPoints;
};
