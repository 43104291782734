import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { Cnt } from './styledComponents';
import { Button } from '../../../../components/buttons/Button';
import { delay } from '../../../../tools/dev';
import { createUrlWithParams } from '../../../../tools/urls/createUrlWithParams';
import { baseEndpoint } from '../../../../context/extraction/TaskMethods/constants';
import { useRequestData } from '../../../../context/extraction/RequestData';

export const ApiRequestUrl = () => {
  const { query, apiRequestUrl, setApiRequestUrl } = useRequestData();
  const [isCopied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setApiRequestUrl(
      createUrlWithParams(baseEndpoint, {
        ...query,
        url: query.url ? encodeURIComponent(query.url) : 'SPECIFY_YOUR_URL',
        token: localStorage.getItem('api_token'),
      })
    );
  }, [query]);

  const handleClick = async () => {
    navigator.clipboard.writeText(apiRequestUrl);
    NotificationManager.info(
      'You can now insert in your browser',
      'Request URL copied',
      3000
    );
    setCopied(true);
    await delay(3000);
    setCopied(false);
  };

  return (
    <Cnt className={isCopied ? 'clicked' : ''} onClick={handleClick}>
      <div>
        <span>API Request URL</span>
        <span>{apiRequestUrl}</span>
      </div>
      <Button disabled={isCopied}>Copy</Button>
    </Cnt>
  );
};
