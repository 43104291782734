import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints, colours } from '../../styles/variables';

export const Cnt = styled.div`
  height: 100vh;
  width: 100vw;
  ${flex({ jc: 'center', ai: 'center' })}

  & > div {
    width: fit-content;
    min-width: 449px;
    border-radius: 8px;
    padding: 40px;
    /* margin: 10vh auto 0; // 10% gap on top */

    background: ${colours.white};
    box-shadow: 0px 4px 8px ${colours.shadow}, 0px 6px 12px ${colours.shadow},
      0px 1px 16px ${colours.shadow};

    & > pre {
      margin: 16px 0 32px;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      width: 100%;
      min-width: unset;
      padding: 40px 16px;
      margin: 0;
      box-shadow: none;
    }
  }
`;
