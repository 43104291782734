import styled, { css } from 'styled-components';
import { flex } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

const base = css`
  max-height: 24px;
  width: fit-content;
  padding: 0 15px;
  border: 1px solid #ececec;
  border-radius: 4px;

  ${flex({ ai: 'center' })}

  transition: border-color 300ms;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      border-color: #7879f1;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const SelectWrapper = styled.div`
  ${flex({})}
`;

export const SelectCnt = styled.div`
  ${base}
  position: relative;
  overflow: hidden;
  appearance: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.44px;
  color: #7879f1;

  span {
    white-space: nowrap;
  }

  img {
    margin-left: 4px;
    transition: transform 200ms, top 200ms;
  }

  &.focused {
    border-color: ${colours.primary};
    img {
      transform: scaleY(-1);
    }
  }
`;
