import React, { FC, createContext, useContext, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { Client } from '../../../api/client';
import extractionTransformer from '../../../tools/extraction/extractionFormatter';
import { useAuth } from '../../AuthCtx';
import { useUserData } from '../../user/UserData';
import { useRequestData } from '../RequestData';
import { useTaskData } from '../TaskData';
import { useUtils } from '../Utils';
import { CallerType, ITaskMethodsCtx } from './interfaces';
import { findMockup } from './analyzeMockups';
import { delay } from '../../../tools/dev';
import { ProviderProps } from '../../types';

const { debug } = console;

const TaskMethodsCtx = createContext<ITaskMethodsCtx>({} as ITaskMethodsCtx);
export const useExtraction = () => useContext(TaskMethodsCtx);

export const TaskMethodsProvider: React.FC<ProviderProps> = ({ children }) => {
  const client = new Client();
  const navTo = useNavigate();
  const loc = useLocation();
  const { data: user } = useUserData();
  const { isAuth } = useAuth();
  const {
    setUrl,
    setData,
    setJson,
    setExtractError,
    setExtractStatus,
    setTaskInit,
    setCurrentTaskId,
    setTaskStatus,
    resetData,
  } = useTaskData();
  const { query, setQuery, webhooks } = useRequestData();
  const { constructRequestUrl } = useUtils();

  const doOnSuccess = (response: any) => {
    debug('Extraction', 'Extraction success');
    client.WRITE_LOG({
      action: 'start_extraction',
      action_result: 'success',
      task_status: response.data.status,
    });

    setTaskInit(false);
    setJson(response.data);
    const transformedData = extractionTransformer(response.data);
    setData(transformedData);
    setExtractStatus('success');

    navTo(`../extraction/${response.data._id}`);
  };

  const doOnFailure = (response: any) => {
    setTaskInit(false);
    setQuery((prev) => ({ ...prev, t: Date.now() }));
    setExtractError(response.errorMessage || response.statusText);
    client.WRITE_LOG({
      task_id: undefined,
      action: 'start_extraction',
      action_result: 'error',
      task_status: undefined,
    });
    return setExtractStatus('error');
  };

  const findExample = async (filter: { url?: string; exampleId?: string }) => {
    const mockup = findMockup(filter);

    if (mockup) {
      debug('Extraction', 'Found an example task', mockup.exampleId);

      setUrl(mockup.url);
      setTaskInit(false);
      // await delay(1000);
      setData(mockup.data);
      setJson(mockup.data);
      setExtractStatus('success');

      await delay(1000);
      navTo(`extraction/${mockup.exampleId}`);

      return true;
    }

    return false;
  };

  const retrieveCashed = async (url: string) => {
    debug('Extraction', 'Not authenticated, retrieving cashed task...');
    setExtractError('');
    setQuery((prev) => ({ ...prev, t: Date.now() }));

    const response = await client.RETRIEVE(url);
    if (!response.ok || typeof response.data === 'string') {
      return doOnFailure(response);
    }

    doOnSuccess(response);
  };

  const extract = async (url: string) => {
    if (!localStorage.getItem('api_token')) {
      await client.GET_ME();
    }

    const timestamp = Date.now();
    setQuery((prev) => ({ ...prev, timestamp }));

    const isMockupFound = await findExample({});
    if (isMockupFound) return;

    let response;
    if (webhooks.success && webhooks.failure) {
      debug('Extraction', 'POST request with webhooks');
      response = await client.POST_EXTRACT({ url: query.url, webhooks });
    } else {
      debug('Extraction', 'GET request without webhooks');

      let requestUrl;
      if (!query.url) {
        requestUrl = constructRequestUrl({ ...query, timestamp, url });
      } else {
        requestUrl = constructRequestUrl({ ...query, timestamp });
      }

      response = await client.EXTRACT(requestUrl);
    }
    if (!response.ok || typeof response.data === 'string') {
      doOnFailure(response);
    }

    NotificationManager.info('Extraction started', null, 5000);
    client.WRITE_LOG({
      task_id: response.data.taskId,
      action: 'start_extract',
      action_result: 'success',
      task_status: response.data.status,
    });
    setTaskInit(false);
    debug('Extraction', 'Setting taskId to:', response.data.taskId);
    setCurrentTaskId(response.data.taskId);

    if (loc.pathname.includes('extraction')) {
      navTo(`/extraction/${response.data.taskId}`);
    }
  };

  const getExtractionById = async (id: string, caller: CallerType) => {
    setData(undefined);
    setExtractError(undefined);
    setExtractStatus('loading');

    const response = await client.GET_EXTRACTION_BY_ID({ id });

    if (!response.ok) {
      setExtractError(response.statusText);
      client.WRITE_LOG({
        task_id: id,
        action: 'extract_from_history_by_id',
        action_result: 'error',
        task_status: undefined,
      });
      return setExtractStatus('error');
    }

    client.WRITE_LOG({
      task_id: id,
      action: 'extract_from_history_by_id',
      action_result: 'success',
      task_status: response.data.status,
    });

    if (response.data.status) {
      setTaskStatus(response.data.status);

      setUrl(response.data.query.url || response.data.url || '');

      if (response.data.status === 'success') {
        const { query: queryOnlyData, ...dataWithoutQuery } = response.data;
        setJson(dataWithoutQuery);
        setData(extractionTransformer(dataWithoutQuery));
        setQuery(queryOnlyData);
        setExtractStatus('success');

        if (caller === 'sse') {
          NotificationManager.info(
            `Task id ${id}`,
            'Extraction completed',
            5000
          );
        }
      }
    }
  };

  const initExtract = async (url: string) => {
    debug('Extraction', `Init extraction with url=${url}`);
    resetData();
    setUrl('');

    const isMockupFound = await findExample({ url });
    if (isMockupFound) return;

    if (!isAuth) {
      return retrieveCashed(url);
    }

    await extract(url);
  };

  useEffect(() => {
    if (user.configs.timeout) {
      const { enableProxies, autoPaginate, slowScrolling, timeout } = user.configs;
      setQuery((prev) => ({
        ...prev,
        enableProxies,
        autoPaginate,
        slowScrolling,
        timeout,
      }));
    }
  }, [user.configs]);

  return (
    <TaskMethodsCtx.Provider
      value={{
        initExtract,
        findExample,
        getExtractionById,
      }}
    >
      {children}
    </TaskMethodsCtx.Provider>
  );
};
