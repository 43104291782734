import React from 'react';
import { DropdownItem } from './DropdownItem';
import { Cnt } from './styledComponents';

interface Props {
  QAlist: { question: string; answer: string }[];
}

export const DropdownList = (props: Props) => {
  return (
    <Cnt>
      {props.QAlist.map(({ question, answer }, ind) => (
        <DropdownItem q={question} a={answer} key={ind} />
      ))}
    </Cnt>
  );
};
