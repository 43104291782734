import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const Cnt = styled.main`
  max-width: 100%;
`;

export const Wrapper = styled.div`
  padding-top: 32px;
  margin-bottom: 16px;
  ${flex({ jc: 'flex-end', ai: 'flex-start' })}

  @media screen and (max-width: ${breakpoints.smMd}) {
    padding-top: 0;
  }

  @media screen and (max-width: 330px) {
    ${flex({ dir: 'column', jc: 'flex-start', ai: 'flex-start' })}
  }
`;
