import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const Link = styled.a`
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: ${colours.primary};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colours.primaryDark};
  }

  &.inactive {
    color: ${colours.muted};
    pointer-events: none;
    font-weight: 400;
  }

  &.muted {
    color: ${colours.muted};
    font-weight: 400;

    &:hover {
      color: ${colours.secondary};
    }
  }
`;
