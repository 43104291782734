import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { resetNumInput } from '../../../styles/placeholders';
import { breakpoints, colours } from '../../../styles/variables';

export const Cnt = styled.div`
  width: 100%;
  ${flex({})}

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const MinusBtn = styled.button`
  height: 55px;
  min-width: 55px;
  padding: 4px 13px 11px 15px;
  border: none;
  border-radius: 4px;
  background-color: ${colours.lighterGray};
  fill: ${colours.primary};
  cursor: pointer;

  &:hover {
    fill: ${colours.primaryDark};
  }
`;

export const PlusBtn = styled(MinusBtn)`
  padding: 10px 17px 5px 18px;
`;

export const Input = styled.input`
  height: 55px;
  width: 100%;
  padding: 15px 16px;
  border: none;
  border-bottom: 1px solid ${colours.inputUnfocused};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.44px;
  color: ${colours.secondary};
  background-color: transparent;

  &:focus {
    outline: none;
    padding-bottom: 14px;
    border-bottom-width: 2px;
    border-bottom-color: ${colours.inputUnfocused};
  }

  ${resetNumInput}

  @media screen and (max-width: ${breakpoints.sm}) {
    min-width: unset;
  }
`;
