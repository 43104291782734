import React from 'react';
import { RequestHistorySpan } from './styles';
import clockIcon from '../../../../assets/icons/clock-1.svg';
import { Wrapper } from '../styles';
import { useExtractionPagination } from '../../../../context/extraction/Pagination';
import { useHistory } from '../../../../context/extraction/History';
import { ExampleLinks } from '../../../../features/ExampleLinks';
import { PaginationAndSort } from '../../../../components/PaginationAndSort';

export const Header: React.FC = () => {
  const { getHistory, historyData, processingTasks } = useHistory();
  const paginationProps = useExtractionPagination();

  return (
    <Wrapper>
      <RequestHistorySpan>
        <div>
          <img src={clockIcon} alt="history" />
          <span>Requests history</span>
        </div>
        {(!historyData || historyData.length !== 0) &&
        (!processingTasks || processingTasks?.length !== 0) ? (
            <ExampleLinks />
          ) : null}
      </RequestHistorySpan>

      <PaginationAndSort
        {...{
          ...paginationProps,
          dataLength: historyData?.length,
          dataLengthLimit: 3,
          handleChange: getHistory,
        }}
      />
    </Wrapper>
  );
};
