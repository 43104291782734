import React from 'react';
import { Cnt } from './styledComponents';

interface Props {
  num: number;
}

export const Number = (props: Props) => {
  const { num } = props;

  return num ? (
    <Cnt>
      <span>{num}</span>
    </Cnt>
  ) : (
    <></>
  );
};
