import styled from 'styled-components';
import { rotate } from '../../../../../../styles/keyframes';
import { flex, textOverflow } from '../../../../../../styles/mixins';
import { removeScrollbar, resetBtn } from '../../../../../../styles/placeholders';

export const Wrapper = styled.div`
  ${flex({})}
  cursor: pointer;
`;

export const Heading = styled.h3`
  max-height: 28px;
  max-width: fit-content;
  margin-right: 48px;

  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  &.isOpen {
    max-height: 100%;
    overflow-y: visible;
    ${removeScrollbar}
  }

  &.isClosed {
    ${textOverflow()}
    white-space: nowrap;
  }
`;

export const Button = styled.button`
  ${resetBtn}
  margin-left: 16px;

  img.isTurned {
    animation: ${rotate} 200ms ease-out forwards;
  }
`;
