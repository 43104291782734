import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toDateStr } from '../../../../../../tools/dates/dates';
import { toUpperCaseFirstChar, urlToDomain } from '../../../../../../tools/formatString';
import { Url, TitleSpan, BodyRow, DateCnt, StatusCnt, MenuCnt, TitleCnt, UrlCnt, Favicon } from '../../styles';
import { IHistoryItem } from '../../../../../../context/extraction/History/interfaces';
import logo from '../../../../../../assets/icons/companies/logo192.png';
import Badges from '../../../../../../components/badges';
import { Rect } from '../../../../../../components/loadings/LoadingBlock/styledComponents';
import { TaskSubmenu } from '../../../../../../features/TaskSubmenu';
import { TaskStatusType } from '../../../../../../types';

interface Props {
  record: IHistoryItem;
}

export const HistoryRecord: React.FC<Props> = ({ record }) => {
  const { _id, createdAt, category, url, title = '', status } = record;
  const navTo = useNavigate();

  const [isMenuShown, setMenuShown] = useState(false);

  const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();

    if (status !== 'failure') {
      navTo(`/extraction/${_id}`);
    }
  };

  const switchTitle = (taskTitle: string, taskStatus: TaskStatusType): React.ReactNode => {
    const switcher = {
      success: taskTitle || 'Title N/D',
      failure: 'Title N/D',
      processing: <Rect className="loader" />,
      undefined: 'Title N/D',
    };

    return <>{switcher[`${taskStatus}`]}</>;
  };

  return (
    <BodyRow
      onClick={handleClick}
      onMouseOver={() => setMenuShown(true)}
      onMouseOut={() => setMenuShown(false)}
      className={status}
    >
      <DateCnt>
        <Badges.Date>{toDateStr(createdAt)}</Badges.Date>
      </DateCnt>
      <StatusCnt>
        <Badges.StatusBadge className={status}>
          {toUpperCaseFirstChar(status || 'processing')}
        </Badges.StatusBadge>
      </StatusCnt>
      <MenuCnt>
        {!isMenuShown ? (
          <Favicon
            src={`https://www.google.com/s2/favicons?domain=${urlToDomain(url)}` || logo}
            alt="icon"
          />
        ) : (
          <TaskSubmenu data={{ taskId: _id, url }} />
        )}
      </MenuCnt>
      <TitleCnt className="title">
        <TitleSpan
          title={title}
          className={!title ? 'undefined' : ''}
        >
          {switchTitle(title, status)}
        </TitleSpan>
      </TitleCnt>
      <UrlCnt>
        <Url
          href={url}
          title={url}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {url}
        </Url>
      </UrlCnt>
    </BodyRow>
  );
};
