import styled, { css } from 'styled-components';
import { showTooltip } from '../../../styles/keyframes';
import { pos } from '../../../styles/mixins';
import { breakpoints, colours } from '../../../styles/variables';

const textFont = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colours.white};
`;

export const Tooltip = styled.div`
  min-width: max-content;
  padding: 8px 10.3px;
  border-radius: 4px;

  position: absolute;
  z-index: 1;

  text-align: center;
  background-color: ${colours.secondary};
  ${textFont}

  opacity: 1;
  animation: ${showTooltip} 200ms ease-out;
  pointer-events: none;

  /* &::before {
    content: ' ';
    height: 8.5px;
    width: 8.5px;
    border-radius: 1px;
    background-color: ${colours.secondary};
    transform: rotate(45deg);

    ${pos({ t: 'calc(12px - 50%)', l: 'calc(50% - 4.25px)' })}
  } */

  @media screen and (max-width: ${breakpoints.sm}) {
    &,
    &:hover {
      display: none !important; // override
    }
  }
`;
