import React, { FC } from 'react';
import { LinkCnt } from './styledComponents';

interface Props {
  Icon: string | FC<any>;
  name: string;
  href: string;
}

export const SocialLink = (props: Props) => {
  const { Icon, name, href } = props;

  return (
    <LinkCnt href={href} target="_blank">
      {typeof Icon === 'string' ? <img src={Icon} /> : <Icon />}
      <span>{name}</span>
    </LinkCnt>
  );
};
