import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { colours } from '../../../../styles/variables';

export const Cnt = styled.button`
  border: 0;
  ${flex({ ai: 'center', gap: 10 })}

  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    pointer-events: none;

    span {
      color: ${colours.lightGray};
    }
    svg {
      stroke: ${colours.lightGray};
    }
  }

  &:hover,
  &.isCurrentLocation {
    span {
      color: ${colours.primary};
    }
    svg {
      stroke: ${colours.primary};
    }
  }
`;

export const IconCnt = styled.div`
  height: 16px;
  width: 16px;
  ${flex({ ai: 'center' })}
  stroke: ${colours.muted};
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.fontDark};
`;
