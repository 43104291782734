import React, { MouseEventHandler, TouchEventHandler, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DropdownStyled from '../../components/inputFields/Select/Dropdown.styled';
import Svgs from '../../components/svgs';
import config from '../../config';
import { useExtraction } from '../../context/extraction/TaskMethods';
import { useUserData } from '../../context/user/UserData';
import copyWithNotification from '../../tools/copyWithNotification';
import fromSnakeCase from '../../tools/formatters/fromSnakeCase';
import CntStyled from './Cnt.styled';

type SubmenuActionNameType =
  | 'copy_task_id'
  | 'repeat_task'
  | 'go_to_dashboard'
  | 'go_to_extraction'
  | 'copy_task_url';

interface ISubmenuAction {
  name: SubmenuActionNameType;
  handleAction: (...args: any) => any;
}

interface Props {
  direction?: 'left' | 'right';
  data: { taskId: string; url?: string };
}

export const TaskSubmenu: React.FC<Props> = ({ data, direction }) => {
  const { taskId, url } = data;

  const [isShown, setShown] = useState(false);
  const { initExtract } = useExtraction();
  const { isAdmin } = useUserData();
  const { pathname } = useLocation();

  const extractionPageHref = `${config.redirectUri}extraction/${taskId}`;
  const dashboardPageHref = `${config.redirectUri}dashboard/${taskId}`;

  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setShown(!isShown);
  };

  const handleRepeatClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (!url) {
      return;
    }

    initExtract(url);
  };

  const dashboardPageOptions: ISubmenuAction[] = [
    {
      name: 'go_to_extraction',
      handleAction: () => window.open(extractionPageHref),
    },
  ];

  const extractionPageOptions: ISubmenuAction[] = [
    {
      name: 'go_to_dashboard',
      handleAction: () => window.open(dashboardPageHref),
    },
  ];

  const pageSpecificOptions: ISubmenuAction[] = pathname.includes('dashboard')
    ? dashboardPageOptions
    : extractionPageOptions;

  const userOptions: ISubmenuAction[] = url
    ? [
      {
        name: 'repeat_task',
        handleAction: () => initExtract(url),
      },
      {
        name: 'copy_task_url',
        handleAction: () => copyWithNotification(url, 'Task url copied'),
      },
    ]
    : [];

  const adminOptions: ISubmenuAction[] = [
    ...pageSpecificOptions,
    {
      name: 'copy_task_id',
      handleAction: () => copyWithNotification(taskId, 'Task id copied'),
    },
  ];

  const options = isAdmin ? [...userOptions, ...adminOptions] : userOptions;

  return (
    <CntStyled
      className={direction}
      onClick={handleClick as MouseEventHandler}
      onMouseLeave={() => setShown(false)}
      onTouchEnd={handleClick as TouchEventHandler}
    >
      <Svgs.Repeat handleClick={handleRepeatClick} />

      {options.length !== 0 && (
        <div
          onMouseOver={() => setShown(true)}
          onScrollCapture={() => setShown(false)}
        >
          <Svgs.UI.Submenu />
        </div>
      )}

      {isShown && (
        <DropdownStyled id="submenu-dropdown">
          {options.map((option, ind) => (
            <span
              key={ind}
              onClick={(e) => {
                e.stopPropagation();
                option.handleAction();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                option.handleAction();
              }}
            >
              {fromSnakeCase(option.name)}
            </span>
          ))}
        </DropdownStyled>
      )}
    </CntStyled>
  );
};
