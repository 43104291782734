import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { colours } from '../../styles/variables';

export const Cnt = styled.div`
  max-width: fit-content;
  margin: auto;
  ${flex({ ai: 'center', gap: 16 })}

  img {
    max-height: 16px;
    max-width: 16px;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: ${colours.lightGray};
  }
`;
