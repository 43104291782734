export const createUrlWithParams = (
  baseUrl: string,
  params: object
): string => {
  const entries = Object.entries(params);
  if (entries.length === 0) return baseUrl;

  let paramsStr = entries
    .map((entry) => {
      if (!`${entry[1]}`) return ''; // this line does not break anything
      // if (!entry[1]) return ''; // this line breaks occasionally

      if (typeof entry[1] === 'object') {
        const subEntries = entry[1];

        const subParams = subEntries
          .map(
            (subEntry: string, ind: number) => `${entry[0]}[${ind}]=${subEntry}`
          )
          .join('&');

        return subParams;
      }

      return entry.join('=');
    })
    .join('&');

  if (paramsStr[paramsStr.length - 1] === '&') {
    paramsStr = paramsStr.slice(0, -1);
  }

  return `${baseUrl}?${paramsStr}`;
};
