import { FormikHelpers } from 'formik';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Client } from '../../api/client';
import config from '../../config';
import { getEncodedUrlByKey } from '../../tools/urls/getEncodedUrl';
import { toFieldError } from '../../tools/toFieldError';
import {
  ILoginCredentials,
  ISignupCredentials,
  ISignupFormik,
  OAuthProvider,
} from '../../types';
import { useUserData } from '../user/UserData';
import { AuthCtxType, IProviderStatus } from './interfaces';
import { readCookie } from '../../tools/cookies';
import { useUserMethods } from '../user/UserMethods';

export const AuthCtx = createContext<AuthCtxType>({} as AuthCtxType);

export const useAuth = () => useContext(AuthCtx);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuth, setAuth] = useState<boolean | undefined>(undefined);

  const navTo = useNavigate();
  const { loadUserData } = useUserMethods();
  const client = new Client();

  const signin = () => setAuth(true);
  const signout = () => {
    setAuth(false);
    localStorage.clear();
  };

  /* BASIC AUTH */
  // run when page loaded
  const validateToken = async () => {
    const { ok: isValidToken } = await client.GET_ME();
    setAuth(isValidToken);
  };
  // update user context
  const updateUserInfo = async () => {
    loadUserData();
    signin();
    // navTo('/'); // # keep it commented out for the sake of not unintentional reloading
  };
  // run on login
  const validateCredentials = async (
    credentials: ILoginCredentials,
    actions: FormikHelpers<ILoginCredentials>
  ) => {
    const response = await client.LOGIN_USER(credentials);

    if (response.ok) return updateUserInfo();
    return actions.setFieldError('email', response.errorMessage);
  };
  // run on signup
  const registerCredentials = async (
    credentials: ISignupCredentials,
    actions: FormikHelpers<ISignupFormik>,
    showSmallLink: () => void
  ) => {
    const response = await client.REGISTER_USER(credentials);

    if (response.ok) {
      const loginRes = await client.LOGIN_USER(credentials);
      if (loginRes.ok) return updateUserInfo();
    }

    if (response.status === 409) {
      actions.setFieldError('email', 'This account already exists');
      return showSmallLink();
    }
    if (response.status === 400) {
      const fieldError = toFieldError(response.errorMessage);
      return actions.setFieldError(fieldError[0], fieldError[1]);
    }
    return actions.setFieldError('email', response.errorMessage);
  };

  /* OAUTH (Google, Facebook, Github) */
  // auth
  const authWithGoogle = async (tokenRes: any) => {
    const response = await client.AUTH_WITH_GOOGLE(tokenRes);
    if (response.ok) validateToken();
  };
  const initFacebook = async () => {
    const url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${config.facebookOAuthClientId}&redirect_uri=${config.redirectUri}&scope=email`;
    window.open(url, '_self');
  };
  const initGithub = async () => {
    const url = `https://github.com/login/oauth/authorize?client_id=${config.githubOAuthClientId}&scope=user`;
    window.open(url, '_self');
  };
  const finalizeAuthWithGithub = async (code: string) => {
    const response = await client.AUTH_WITH_GITHUB(code);
    if (response.ok) validateToken();
  };
  const finalizeAuthWithFacebook = async (code: string) => {
    const response = await client.AUTH_WITH_FACEBOOK(code);
    if (response.ok) validateToken();
  };
  // Linking accounts
  const [linkStatus, setLinkStatus] = useState<IProviderStatus>({
    provider: undefined,
    status: undefined,
  });
  const linkGoogle = async (tokenRes: any) => {
    setLinkStatus({
      provider: 'google',
      status: 'loading',
    });
    await client.LINK_GOOGLE({ tokenRes });
    loadUserData();
    setLinkStatus((prev) => ({ ...prev, status: undefined }));
  };
  const finalizeLinkWithGithub = async (code: string) => {
    setLinkStatus({
      provider: 'github',
      status: 'loading',
    });
    await client.LINK_GITHUB(code);
    loadUserData();
    setLinkStatus((prev) => ({ ...prev, status: undefined }));
  };
  const finalizeLinkWithFacebook = async (code: string) => {
    setLinkStatus({
      provider: 'facebook',
      status: 'loading',
    });
    await client.LINK_FACEBOOK(code);
    loadUserData();
    setLinkStatus((prev) => ({ ...prev, status: undefined }));
  };
  // UNlinking accounts
  const [unlinkStatus, setUnlinkStatus] = useState<IProviderStatus>({
    provider: undefined,
    status: undefined,
  });
  const unlinkProvider = async (provider: OAuthProvider) => {
    setUnlinkStatus({
      provider,
      status: 'loading',
    });
    const response = await client.UNLINK_PROVIDER({ provider });

    if (!response.ok) {
      return setUnlinkStatus((prev) => ({ ...prev, status: 'error' }));
    }

    loadUserData();
    setUnlinkStatus((prev) => ({ ...prev, status: 'success' }));
  };

  useEffect(() => {
    validateToken();

    const authCode = getEncodedUrlByKey(window.location.search, 'code');
    if (authCode) {
      if (readCookie('facebookAuthCode') === 'login') {
        finalizeAuthWithFacebook(authCode);
      }

      if (readCookie('githubAuthCode') === 'login') {
        finalizeAuthWithGithub(authCode);
      }

      if (readCookie('facebookAuthCode') === 'link') {
        finalizeLinkWithFacebook(authCode);
      }

      if (readCookie('githubAuthCode') === 'link') {
        finalizeLinkWithGithub(authCode);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuth) updateUserInfo();
  }, [isAuth]);

  return (
    <AuthCtx.Provider
      value={{
        isAuth,
        signin,
        signout,
        validateToken,
        validateCredentials,
        registerCredentials,
        authWithGoogle,
        initFacebook,
        initGithub,
        linkGoogle,
        linkStatus,
        unlinkProvider,
        unlinkStatus,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export const RequireAuth = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isAuth === false) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export const RequireAdmin = () => {
  const { isAdmin } = useUserData();
  const location = useLocation();

  const switcher = {
    true: <Outlet />,
    false: <Navigate to="/" state={{ from: location }} replace />,
    undefined: <></>,
  };

  return switcher[`${isAdmin}`];
};
