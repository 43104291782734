import React from 'react';
import Badges from '../../../../../components/badges';
import { toUpperCaseFirstChar } from '../../../../../tools/formatString';
import { TaskStatusType } from '../../../../../types';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';

export interface IStatusCell extends ITableCellBase {
  status: TaskStatusType;
}

export const StatusCell: React.FC<IStatusCell> = ({ status = 'processing', width = 0 }) => (
  <td style={{ width: `${width}%` }}>
    <Badges.StatusBadge className={status}>
      {toUpperCaseFirstChar(status)}
    </Badges.StatusBadge>
  </td>
);
