import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShadowCnt } from '../../../components/containers/ShadowCnt';
import { ResetPassFormik } from './ResetPassFormik';
import checkIcon from '../../../assets/icons/check-2.svg';
import { SentCnt } from './styledComponents';
import Links from '../../../components/links';
import { SentHeading } from '../styledComponents';
import { useUserData } from '../../../context/user/UserData';

export const ResetPassPage = (): JSX.Element => {
  const navTo = useNavigate();
  const { resetPassStatus } = useUserData();

  return (
    <ShadowCnt>
      {resetPassStatus !== 'success' ? (
        <ResetPassFormik />
      ) : (
        <SentCnt>
          <header>
            <img src={checkIcon} />
            <SentHeading>Email sent!</SentHeading>
          </header>
          <footer>
            <Links.Link onClick={() => navTo('..')}>
              Log in with new password
            </Links.Link>
          </footer>
        </SentCnt>
      )}
    </ShadowCnt>
  );
};
