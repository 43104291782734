export const divideWith = (str: string | number, divider: string) => {
  const arr = Array.from(`${str}`);

  let i = Math.ceil(arr.length / 3) - 1;

  while (i > 0) {
    arr.splice(-i * 3, 0, divider);
    i -= 1;
  }

  return arr;
};

export const divideWithComas = (str: string | number) => {
  return divideWith(str, ',');
};

export const divideWithSpace = (str: string | number) => {
  return divideWith(str, ' ');
};

export const toUpperCaseFirstChar = (str = ''): string => {
  if (!str[0]) {
    return str;
  }

  const theFirst = str[0].toUpperCase();
  const theRest = str.slice(1);

  return `${theFirst}${theRest}`;
};

export const splitByDash = (str: string): string => {
  return toUpperCaseFirstChar(str.split('-').join(' '));
};

export const dropZeros = (number: string | number) => {
  let num = typeof number === 'number' ? `${number}` : number;
  let unit: 'k' | 'M' | '' = '';

  // checking for thousands
  if (num.length > 3) {
    num = num.substring(0, num.length - 3);
    unit = 'k';
  }

  // checking for millions
  if (num.length > 3) {
    num = num.substring(0, num.length - 3);
    unit = 'M';
  }

  return num + unit;
};

export const splitByFirstColon = (str: string): string[] => {
  try {
    if (!str.includes(':')) {
      throw new Error('the string does not containt a colon');
    }
    const colonInd = str.indexOf(':');
    const firstStr = str.slice(0, colonInd).trim();
    const secondStr = str.slice(colonInd + 1, str.length).trim();
    return [firstStr, secondStr];
  } catch (err) {
    // console.error(err);
    return ['', ''];
  }
};

// Credit card
export const formatAsCardNumber = (str: string): string => {
  const strCopy = str.slice();
  const arr: string[] = [];

  Array.from(str).forEach((char, ind) => {
    if ((ind + 1) % 4 === 0) {
      arr.push(strCopy.slice(ind - 3, ind + 1));
    }
  });

  return arr.join(' ');
};

export const hideCvc = (srt: string): string => {
  return Array.from(srt)
    .map(() => '*')
    .join('');
};

export const urlToDomain = (str = ''): string => str.replace('https://', '').replace('http://', '').split('/')[0];
