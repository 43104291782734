import React, { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { AnalyzeURLFormik } from './AnalyzeURLFormik';
import { useExtraction } from '../../context/extraction/TaskMethods';
import { ExtractionWrapper } from '../../components/containers/ExtractionWrapper';
import { useAuth } from '../../context/AuthCtx';
import { useUI } from '../../context/UICtx';
import { useTaskData } from '../../context/extraction/TaskData';
import Lists from '../../components/lists';
import Buttons from '../../components/buttons';
import MainCntStyled from './MainCnt.styled';

export const ExtractionCnt = () => {
  const { isAuth } = useAuth();
  const { findExample, getExtractionById } = useExtraction();
  const { extractStatus, setCurrentTaskId } = useTaskData();
  const { tab, setTab } = useUI();
  const { pathname } = useLocation();
  const { taskId } = useParams();

  useEffect(() => {
    if (taskId) {
      if (
        !['634f873beb61f8d0e622684a', '634f873beb61f8d0e622684b'].includes(
          taskId
        )
      ) {
        setCurrentTaskId(taskId);
        if (isAuth) getExtractionById(taskId, 'manual');
      } else {
        findExample({ exampleId: taskId });
      }
    }
  }, [taskId]);

  // (3) Redirecting to Visual if there is
  // a response to the previous analyze request
  // *** 1-2 are at the AuthPage
  useEffect(() => {
    if (extractStatus === 'success' || extractStatus === 'loading') {
      setTab('Visual');
    }
  }, [extractStatus]);

  return (
    <>
      <AnalyzeURLFormik />
      {pathname.includes('extraction') && (
        <ExtractionWrapper>
          <Lists.Tabs>
            <Buttons.Tab
              switchToPageName="Visual"
              pageSwitcher={setTab}
              active={tab === 'Visual'}
            />
            <Buttons.Tab
              switchToPageName="JSON"
              pageSwitcher={setTab}
              active={tab === 'JSON'}
            />
            {isAuth && (
              <Buttons.Tab
                switchToPageName="API"
                pageSwitcher={setTab}
                active={tab === 'API'}
              />
            )}
          </Lists.Tabs>
        </ExtractionWrapper>
      )}

      <MainCntStyled>
        <Outlet />
      </MainCntStyled>
    </>
  );
};
