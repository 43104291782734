import styled from 'styled-components';
import { rotate } from '../../styles/keyframes';

export const ControlField = styled.div`
  color: #A0A0A0;
  font-size: 16px;
  margin-bottom: 10px;
  //white-space:nowrap;
  //text-overflow:ellipsis;
  //word-break: break-all;
  
  & span {
    color: #1B1B1B;
    word-break: break-all;
    //width: 100px;
    //overflow: hidden;
    //vertical-align: bottom;
    //display:inline-block;
  }
`;

export const ShowDetailsField = styled.div`
  color: #A0A0A0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  cursor: pointer;
  //animation: transform 
  & img {
    margin-left: 10px;
    &.isTurned {
      animation: ${rotate} 200ms ease-out forwards;
    }
  }
`;
