import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints, colours } from '../../styles/variables';

// InputBlock
export const InputBlock = styled.div`
  height: 100%;
  width: fit-content;
  ${flex({ ai: 'center' })}

  // Toggle
  div:first-of-type {
    margin-top: 12px;
  }
  // Text
  div:last-of-type {
    margin-left: 8px;
  }

  a {
    text-decoration: none;
    color: ${colours.primary};

    &:hover {
      color: ${colours.primaryDark};
    }
  }

  @media screen and (max-width: 1265px) {
    // Toggle
    div:first-of-type {
      // # pp
      margin-top: 0;
      margin-bottom: -11px;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;

    // Reodering items
    &:first-of-type {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 3;
    }

    &:nth-of-type(3) {
      height: 140px;
      padding-top: 25px;
      order: 2;
    }

    &:nth-of-type(4) {
      order: 4;
    }
  }
`;

// Texts
export const TextBlock = styled.div`
  ${flex({ dir: 'column' })}
`;

export const Label = styled.span`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #000000;
`;
