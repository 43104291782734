import React from 'react';

export const TimemachineIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_767_14904)">
        <path
          d="M12.5 6.5V12.5H18.5"
          stroke="#818181"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.46533 23.3712L2.73633 17.5742L8.42833 19.1602"
          stroke="#818181"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.737 17.5738C3.97619 19.961 6.04709 21.8115 8.55815 22.7753C11.0692 23.7392 13.8464 23.7496 16.3646 22.8047C18.8828 21.8597 20.9676 20.0248 22.2247 17.647C23.4817 15.2691 23.8241 12.5131 23.1869 9.9C22.5497 7.28687 20.9772 4.99776 18.7666 3.46546C16.5561 1.93316 13.8608 1.26388 11.1902 1.58414C8.51965 1.90441 6.05899 3.19204 4.27345 5.20357C2.48791 7.2151 1.50124 9.81112 1.5 12.5008"
          stroke="#818181"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_767_14904">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
