import React, { createContext, useContext, useState, useEffect } from 'react';
import { Client } from '../../../api/client';
import { DOWNLOAD_METRICS } from '../../../features/Dashboard/api';
import extractionFormatter from '../../../tools/extraction/extractionFormatter';
import { StatusType, TaskStatusType } from '../../../types';
import { useUserData } from '../../user/UserData';
import { GET_EXTRACTIONS, GET_METRICS } from '../api';
import { IMetricsData } from '../interfaces/metrics-data.interfaces';
import { useDashboardPagination } from '../Pagination';
import { IFilter } from '../Pagination/interfaces';
import { IDashboardCtx, IHistoryTask } from './interfaces';

const statusOptions: TaskStatusType[] = [];
export const feedbackOptions: string[] = [
  'title',
  'category',
  'price',
  'description',
  'details',
  'features',
  'no feedback',
];

const DashboardCtx = createContext<IDashboardCtx>({} as IDashboardCtx);
export const useDashboard = () => useContext(DashboardCtx);

interface Props {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<Props> = ({ children }) => {
  const client = new Client();
  const { isAdmin } = useUserData();
  const { setMaxPage, sort, page, limit } = useDashboardPagination();

  // metrics
  const [metricsData, setMetricsData] = useState<IMetricsData | undefined>(undefined);
  const [metricsStatus, setMetricsStatus] = useState<StatusType>(undefined);
  // extractions
  const [extractions, setExtractions] = useState<IHistoryTask[]>([]);
  const [extractionsStatus, setExtractionsStatus] = useState<StatusType>(undefined);
  // one metrics
  const [oneMetric, setOneMetric] = useState<any>('');
  const [oneMetricStatus, setOneMetricStatus] = useState<StatusType>(undefined);
  // one extraction
  const [oneMetricExtraction, setOneMetricExtraction] = useState<any>('');
  const [oneMetricExtractionStatus, setOneMetricExtractionStatus] =
    useState<StatusType>(undefined);
  // one json
  const [oneMetricJson, setOneMetricJson] = useState<object>({});
  const [oneMetricJsonStatus, setOneMetricJsonStatus] =
    useState<StatusType>(undefined);
  // period
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  // filters
  const [errorsSelected, setErrorsSelected] = useState<string[]>([]);
  const [isErrorsOnly, setIsErrorsOnly] = useState<boolean>(false);
  const [statusesSelected, setStatusesSelected] = useState<TaskStatusType[]>(statusOptions);
  const [feedbacksSelected, setFeedbacksSelected] = useState<string[]>(feedbackOptions);

  const errorsFilter: IFilter = { name: 'errors', options: errorsSelected };
  const statusesFilter: IFilter = { name: 'status', options: statusesSelected };
  const feedbacksFilter: IFilter = { name: 'feedbacks', options: feedbacksSelected };
  const filtersBase = [statusesFilter, feedbacksFilter];
  const filtersData = errorsSelected.length !== errors.length
    ? [...filtersBase, errorsFilter]
    : filtersBase;

  const loadExtractionsForTable = async () => {
    setExtractionsStatus('loading');
    if (!startDate) {
      return;
    }

    const extractionsObject = await GET_EXTRACTIONS({
      page: page || 1,
      sort: sort.value || -1,
      limit,
      startDate: startDate || Date.now() - 24 * 60 * 60 * 1000,
      endDate: endDate || Date.now(),
      filters: filtersData,
    });

    if (!extractionsObject) {
      setExtractionsStatus('error');

      return;
    }

    const { tasks, pages } = extractionsObject;

    setMaxPage(pages);
    setExtractions(tasks);
    setExtractionsStatus('success');
  };

  const loadMetricsForChart = async () => {
    setMetricsStatus('loading');

    const response = await GET_METRICS({
      startDate: startDate || Date.now() - 24 * 60 * 60 * 1000,
      endDate: endDate || Date.now(),
    });

    if (!response.ok) {
      return setMetricsStatus('error');
    }

    const responseData: IMetricsData = response.data;
    setMetricsStatus('success');
    setMetricsData(responseData);

    if (!responseData || !responseData.errors || !responseData.errors.length) {
      return;
    }

    const errorsMapped = responseData.errors.map((errorObject) => errorObject.message);
    setErrors(errorsMapped);
    setErrorsSelected(errorsMapped);
  };

  const updateErrorFieldsChecked = (checkbox: string) => {
    setErrorsSelected((prevSelected) => {
      if (!prevSelected.includes(checkbox)) {
        return [...prevSelected, checkbox];
      }

      return prevSelected.filter(
        (prevOneSelected) => prevOneSelected !== checkbox
      );
    });
  };

  const filterStatus = (option: TaskStatusType) => {
    setStatusesSelected((prevSelected) => {
      if (!prevSelected.includes(option)) {
        return [...prevSelected, option];
      }

      return prevSelected.filter(
        (prevOneSelected) => prevOneSelected !== option
      );
    });
  };

  const filterFeedback = (option: string) => {
    setFeedbacksSelected((prevSelected) => {
      if (!prevSelected.includes(option)) {
        return [...prevSelected, option];
      }

      return prevSelected.filter(
        (prevOneSelected) => prevOneSelected !== option
      );
    });
  };

  // @ load one
  const loadOneMetric = async (taskId: string) => {
    setOneMetricStatus('loading');
    const response = await client.GET_ONE_METRIC({ taskId });

    if (!response.ok) return setOneMetricStatus('error');

    setOneMetric(response.data);
    setOneMetricStatus('success');
  };

  const loadOneMetricExtraction = async (taskId: string) => {
    setOneMetricExtractionStatus('loading');
    const response = await client.GET_METRIC_EXTRACTION({ taskId });

    if (!response.ok) return setOneMetricExtractionStatus('error');
    const transformedData = extractionFormatter(response.data);
    setOneMetricExtraction(transformedData);
    setOneMetricExtractionStatus('success');
  };

  const loadOneMetricJson = async (taskId: string) => {
    setOneMetricJsonStatus('loading');
    const response = await client.GET_METRIC_JSON({ taskId });

    if (!response.ok) {
      setOneMetricJsonStatus('error');
      return setOneMetricJson(response.statusText);
    }

    setOneMetricJson(response.data);
    setOneMetricJsonStatus('success');
  };

  const loadData = async () => {
    await Promise.all([loadExtractionsForTable(), loadMetricsForChart()]);
  };

  const exportCsv = async () => {
    await DOWNLOAD_METRICS({ filters: filtersData, startDate, endDate });
  };

  useEffect(() => {
    if (!sort.value || !page) {
      return;
    }

    loadExtractionsForTable();
  }, [
    sort.value,
    page,
    limit,
    isErrorsOnly,
    errorsSelected,
    statusesSelected,
    feedbacksSelected,
  ]);

  useEffect(() => {
    if (!isAdmin || !startDate) {
      return;
    }

    loadData();
  }, [startDate, endDate]);

  return (
    <DashboardCtx.Provider
      value={{
        // stats
        metricsData,
        loadMetricsForChart,
        metricsStatus,
        // extractions
        extractions,
        extractionsStatus,
        // one metric
        oneMetric,
        loadOneMetric,
        oneMetricStatus,
        // one extraction
        oneMetricExtraction,
        loadOneMetricExtraction,
        oneMetricExtractionStatus,
        // one json
        oneMetricJson,
        loadOneMetricJson,
        oneMetricJsonStatus,
        // period
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        // export
        exportCsv,
        errors,
        // filters
        errorsSelected,
        setErrorsSelected,
        setIsErrorsOnly,
        updateErrorFieldsChecked,
        filterStatus,
        statusesSelected,
        setStatusesSelected,
        filterFeedback,
        feedbacksSelected,
      }}
    >
      {children}
    </DashboardCtx.Provider>
  );
};
