import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { colours } from '../../../../styles/variables';

export const Cnt = styled.div`
  width: 100%;
  ${flex({ dir: 'column' })}

  h6,
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: ${colours.fontDark};
  }

  & > div {
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  img {
    margin-left: 16px;
  }
`;

export const Head = styled.div`
  min-height: 48px;
  padding: 12px 24px;
  ${flex({ jc: 'space-between' })}

  background-color: ${colours.gray};
  box-shadow: 0px 0px 1px ${colours.shadow3}, 0px 1px 2px ${colours.shadow},
    0px 1px 2px ${colours.shadow4};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    padding: 10px 22px;
    border: 2px solid ${colours.primary};

    h6 {
      color: ${colours.primary};
    }
  }
`;

export const Body = styled.div`
  max-height: 0;
  transition: max-height 500ms, padding-top 500ms, padding-bottom 500ms;

  & > p {
    display: none;
  }

  &.opened {
    max-height: 300px;
    padding: 16px 24px 16px;

    & > p {
      display: block;
    }
  }
`;
