import React from 'react';

export const WarningIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19.5C12.8284 19.5 13.5 18.8284 13.5 18C13.5 17.1716 12.8284 16.5 12 16.5C11.1716 16.5 10.5 17.1716 10.5 18C10.5 18.8284 11.1716 19.5 12 19.5Z"
    />
    <path
      d="M12 6.75V14.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.11155 19.1652L10.4551 1.81316C10.6053 1.53425 10.8283 1.30123 11.1003 1.13881C11.3723 0.976385 11.6832 0.890625 12.0001 0.890625C12.3169 0.890625 12.6278 0.976385 12.8998 1.13881C13.1718 1.30123 13.3948 1.53425 13.5451 1.81316L22.8886 19.1652C23.0323 19.4325 23.1042 19.7325 23.0973 20.0359C23.0904 20.3393 23.005 20.6357 22.8494 20.8963C22.6937 21.1568 22.4731 21.3725 22.2092 21.5224C21.9453 21.6722 21.647 21.751 21.3436 21.7512H2.65655C2.35307 21.751 2.0548 21.6722 1.79088 21.5224C1.52696 21.3725 1.3064 21.1568 1.15075 20.8963C0.995096 20.6357 0.909663 20.3393 0.902794 20.0359C0.895924 19.7325 0.967852 19.4325 1.11155 19.1652V19.1652Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
