import React from 'react';
import { RectImg } from '../LoadingBlock/styledComponents';
import { VisualCnt } from './styledComponents';
import * as LoadingBlock from '../LoadingBlock';

export const Visual = () => {
  return (
    <VisualCnt>
      <RectImg />
      <div>
        <LoadingBlock.VisualTop />
        <LoadingBlock.VisualBottom />
        <LoadingBlock.VisualBottom />
      </div>
    </VisualCnt>
  );
};
