import React from 'react';

export const Chart = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.4667 7.4668H6.53337C6.35662 7.467 6.18717 7.5373 6.06219 7.66228C5.9372 7.78726 5.8669 7.95671 5.8667 8.13346V15.3335C5.8669 15.5102 5.9372 15.6797 6.06219 15.8046C6.18717 15.9296 6.35662 15.9999 6.53337 16.0001H9.4667C9.64345 15.9999 9.8129 15.9296 9.93788 15.8046C10.0629 15.6797 10.1332 15.5102 10.1334 15.3335V8.13346C10.1332 7.95671 10.0629 7.78726 9.93788 7.66228C9.8129 7.5373 9.64345 7.467 9.4667 7.4668Z" />
      <path d="M3.6 4H0.666667C0.489918 4.0002 0.320467 4.07051 0.195486 4.19549C0.070506 4.32047 0.000202911 4.48992 0 4.66667V15.3333C0.000202911 15.5101 0.070506 15.6795 0.195486 15.8045C0.320467 15.9295 0.489918 15.9998 0.666667 16H3.6C3.77675 15.9998 3.9462 15.9295 4.07118 15.8045C4.19616 15.6795 4.26646 15.5101 4.26667 15.3333V4.66667C4.26646 4.48992 4.19616 4.32047 4.07118 4.19549C3.9462 4.07051 3.77675 4.0002 3.6 4Z" />
      <path d="M15.6 0H12.6667C12.4899 0.000202911 12.3205 0.070506 12.1955 0.195486C12.0705 0.320467 12.0002 0.489918 12 0.666667V15.3333C12.0002 15.5101 12.0705 15.6795 12.1955 15.8045C12.3205 15.9295 12.4899 15.9998 12.6667 16H15.6C15.7767 15.9998 15.9462 15.9295 16.0712 15.8045C16.1962 15.6795 16.2665 15.5101 16.2667 15.3333V0.666667C16.2665 0.489918 16.1962 0.320467 16.0712 0.195486C15.9462 0.070506 15.7767 0.000202911 15.6 0Z" />
    </svg>
  );
};
