import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const LoginCnt = styled.div`
  max-width: 100%;
  ${flex({ jc: 'space-between', ai: 'center' })}

  &.login {
    width: 100%;
    ${flex({ jc: 'center', ai: 'center' })}

    & > button {
      margin: 0 auto;
      height: 36px;
      padding: 3px 16px;
      border-radius: 8px;
    }
  }

  &.logout {
    width: 100%;
    margin-right: 16px;
    ${flex({ ai: 'center' })}

    & > button {
      height: 17px;
      width: fit-content;
      padding: 0;
      margin-left: 10px;

      background-color: transparent;

      svg {
        transform: rotate(180deg);
        path {
          stroke: ${colours.muted};
        }

        &:hover {
          path {
            stroke: ${colours.primary};
          }
        }
      }
    }
  }
`;
