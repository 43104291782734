import React, { useEffect, useState } from 'react';
import { Link } from '../styledComponents';
// show-more rows-3/6/9 link/arrow
export const ShowMore = (props: { children: string; rows: number }) => {
  const { children, rows } = props;
  const [height, setHeight] = useState(0);
  const [lineHeight, setLineHeight] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const id = `${Math.random()}`;

  useEffect(() => {
    const el = document.getElementById(id) as HTMLElement;
    const computedStyles = window.getComputedStyle(el);
    const heightInt = +computedStyles.height.replace('px', '');
    const lineHeightInt = +computedStyles.lineHeight.replace('px', '');
    setHeight(heightInt);
    setLineHeight(lineHeightInt);
  }, []);

  return (
    <span
      id={id}
      style={{
        maxHeight: `${lineHeight * rows}px`,
        overflow: isOpen ? 'visible' : 'hidden',
      }}
    >
      {isOpen ? children : children.slice(0, -35)}
      {(height > lineHeight * rows || Number.isNaN(height)) && (
        <>
          {!isOpen && <>&nbsp;... </>}
          <Link
            onClick={() => setOpen(!isOpen)}
            style={{ display: isOpen ? 'block' : 'inline' }}
          >
            {isOpen ? 'show less' : 'show more'}
          </Link>
        </>
      )}
    </span>
  );
};
