import React, { FC, createContext, useContext, useState } from 'react';
import { ChartType, IChartsCtx } from './interfaces';

const ChartsCtx = createContext<IChartsCtx>({} as IChartsCtx);
export const useCharts = () => useContext(ChartsCtx);

export const ChartsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentChart, setCurrentChart] = useState<ChartType>('metrics');

  return (
    <ChartsCtx.Provider value={{ currentChart, setCurrentChart }}>
      {children}
    </ChartsCtx.Provider>
  );
};
