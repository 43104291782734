import React, { MouseEvent, TouchEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { BadgeCnt } from './styledComponents';

interface Props {
  username?: string;
}

export const Username = (props: Props) => {
  const { username } = props;
  const navTo = useNavigate();

  const handleClick = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    navTo('settings');
  };

  return (
    <BadgeCnt
      title={username}
      onClick={(e: MouseEvent) => handleClick(e)}
      onTouchEnd={(e: TouchEvent) => handleClick(e)}
    >
      {username || ''}
    </BadgeCnt>
  );
};
