import React from 'react';
import {
  CardCnt,
  Number,
  ExpiryDate,
  Cvc,
  CardRemoveBtn,
} from './styledComponents';
import deleteIcon from '../../../../assets/icons/card-delete.svg';
import { formatAsCardNumber } from '../../../../tools/formatString';
import { usePayment } from '../../../../context/payment/PaymentCtx';
import { ICard } from '../../../../context/payment/PaymentCtx/interfaces';

export const CardInfo = (props: ICard) => {
  const { id, last4 } = props;
  const { removeCard, removeCardStatus } = usePayment();

  return (
    <CardCnt className={removeCardStatus === 'loading' ? 'isDeleting' : ''}>
      <Number>{formatAsCardNumber(`************${last4}`)}</Number>
      {/* <Cardholder>{cardholder}</Cardholder> */}
      <ExpiryDate>** / ****</ExpiryDate>
      <Cvc>***</Cvc>
      <CardRemoveBtn onClick={() => removeCard(id)}>
        <img src={deleteIcon} alt="remove" />
      </CardRemoveBtn>
    </CardCnt>
  );
};
