import styled from 'styled-components';
import { Button } from '../../../components/buttons/Button';
import { Feedback } from '../../../components/inputFields/FloatingLabelInput/styledComponents';
import { Input } from '../../../components/inputFields/Input';
import { flex, textOverflow } from '../../../styles/mixins';
import { resetBtn, resetInputBgColor } from '../../../styles/placeholders';
import { breakpoints, colours } from '../../../styles/variables';
import { smFont } from '../styles';

// Top Container
export const Cnt = styled.div`
  ${flex({ dir: 'column' })}
  position: relative; // for load bar
`;

// URL Input & Buttons container
export const UrlCnt = styled.div`
  height: 81px;
  width: 100%;
  border-bottom: 1px solid ${colours.lighterGray};
  ${flex({ ai: 'center' })}

  // Preventing 'jumping' of input and buttons
  &.isFocused {
    border-bottom-width: 3px;
    padding-top: 2px;
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    height: 65px;
  }
`;

// URL Input
export const UrlInput = styled(Input)`
  height: 100%;
  padding: 28px 0 28px 32px;
  border: none;
  ${textOverflow()}

  &,
  &:focus {
    margin: 0;
  }

  ${resetInputBgColor}

  @media screen and (max-width: ${breakpoints.smMd}) {
    height: 100%;
    &::placeholder {
      ${smFont}
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 24px 0 24px 16px;
    ${smFont}
  }
`;

// History and Analyze buttons' container
export const BtnCnt = styled.div`
  padding: 0 32px 0 16px;
  ${flex({ jc: 'flex-end', gap: 32 })}

  @media screen and (max-width: ${breakpoints.md}) {
    padding-right: 16px;
    gap: 16px;
  }
  @media screen and (max-width: ${breakpoints.smMd}) {
    padding-left: 8px;
    bottom: 49px;
  }
`;

// Buttons
export const AnalyzeBtn = styled(Button)`
  height: 48px;
  width: 125px;
  padding: 0;
  line-height: 24px;
  letter-spacing: 0.44px;

  @media screen and (max-width: ${breakpoints.smMd}) {
    margin: auto 0;
    max-height: 32px;
    max-width: 93px;
    border-radius: 4px;
    ${smFont}
  }
`;

// Url Input feedback
export const UrlFeedback = styled(Feedback)`
  margin-left: 32px;
`;
