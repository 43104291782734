import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints } from '../../styles/variables';

export default styled.div`
  padding: 0 0 32px;
  ${flex({ jc: 'space-between' })}

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0 0 24px;
  }
`;
