import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import googleIcon from '../../../assets/icons/companies/google-icon.svg';
import facebookIcon from '../../../assets/icons/companies/facebook-icon.svg';
import githubIcon from '../../../assets/icons/companies/github-icon.svg';
import giftIcon from '../../../assets/icons/gift-icon.svg';
import { Link } from '../../../components/links/Link';
import { BadgeWithIcon } from './BadgeWithIcon';
import { ShadowCnt } from '../../../components/containers/ShadowCnt';
import { SignupFormik } from './SignupFormik';
import { AuthHeading, Footer, Header, SocialBtnCnt } from '../styledComponents';
import { OAuthBtn } from '../../../features/OAuthBtn';
import { useAuth } from '../../../context/AuthCtx';

export const SignupPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  // (2-a) Redirecting to protected pages if authorised
  if (auth.isAuth === undefined) return <></>;
  if (auth.isAuth) return <Navigate to="/" />;

  return (
    <ShadowCnt>
      <BadgeWithIcon icon={giftIcon}>
        Sign up now for 1000 free requests
      </BadgeWithIcon>
      <Header>
        <AuthHeading className="inactive" onClick={() => navigate('..')}>
          Log in
        </AuthHeading>
        <AuthHeading>Sign up</AuthHeading>
      </Header>
      <SignupFormik />
      <SocialBtnCnt>
        <OAuthBtn auth="google" mode="login" icon={googleIcon} />
        {/* <OAuthBtn auth="facebook" mode="login" icon={facebookIcon} /> */}
        <OAuthBtn auth="github" mode="login" icon={githubIcon} />
      </SocialBtnCnt>
      <Footer>
        Already have an account?
        <Link onClick={() => navigate('..')}>Log in</Link>
      </Footer>
    </ShadowCnt>
  );
};
