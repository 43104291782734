/* TODO create NPM from NestJS' libs/types to avoid dublication */
import { IOffersFormatted } from '../../context/extraction/TaskMethods/interfaces';

const normalizeOffer = (offerStr: string): number => {
  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  return Number(
    offerStr
      .split('')
      .map((char) => {
        return numbers.includes(char) ? char : '';
      })
      .join('')
  );
};

export default (offers: IOffersFormatted): IOffersFormatted => {
  const result: IOffersFormatted = { amount: null, currency: '' };

  if (typeof offers === 'string') {
    result.amount = normalizeOffer(offers);
    return result;
  }

  if (offers instanceof Array) {
    result.amount = normalizeOffer(offers.join(''));
    return result;
  }

  if (offers.currency) {
    result.currency = offers.currency;
  }

  if (offers.amount) {
    if (typeof offers.amount === 'string') {
      result.amount = normalizeOffer(offers.amount);
      return result;
    }

    if (typeof offers.amount === 'number') {
      result.amount = +`${offers.amount}`.replace('.', '');
      return result;
    }
  }

  return result;
};
