import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { validateEmail, validateFullname } from '../../../tools/validation';
import { FloatingLabelInput } from '../../../components/inputFields/FloatingLabelInput';
import { FieldGroup } from './styledComponents';
import { IFeedback } from '../../../types';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';
import { useSupport } from '../../../context/SupportCtx';
import Modals from '../../../components/modals';
import { resizeTextarea } from '../../../tools/resizeTextarea';

export const HelpFormik = () => {
  const { loadFeedback, feedbackStatus, setFeedbackStatus } = useSupport();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{ emailFrom: '', nameFrom: '', message: '' }}
      onSubmit={async (values) => {
        loadFeedback(values);
      }}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(props: FormikProps<IFeedback>) => {
        const emailMeta = props.getFieldMeta('emailFrom');
        const nameMeta = props.getFieldMeta('nameFrom');
        const messageMeta = props.getFieldMeta('message');
        const messageHelpers = props.getFieldHelpers('message');

        useEffect(() => {
          if (feedbackStatus === 'error') {
            messageHelpers.setError('Feedback error');
          }
        }, [feedbackStatus]);

        return (
          <>
            <Form>
              <FieldGroup>
                <FloatingLabelInput
                  type="email"
                  autoComplete="email"
                  name="emailFrom"
                  placeholder="Your email"
                  validate={validateEmail}
                  classProp={!emailMeta.error}
                />
                <FloatingLabelInput
                  type="text"
                  autoComplete="name"
                  name="nameFrom"
                  placeholder="Your name"
                  validate={validateFullname}
                  classProp={!nameMeta.error}
                />
                <FloatingLabelInput
                  isTextarea
                  type="text"
                  autoComplete="off"
                  name="message"
                  placeholder="Your message"
                  handleKeyDown={resizeTextarea}
                  classProp={!messageMeta.error}
                />
              </FieldGroup>
              <SubmitBtn
                values={props.values}
                errors={props.errors}
                setValidateOnChange={setValidateOnChange}
                status={feedbackStatus}
                hideSuccess={true}
              >
                Contact us
              </SubmitBtn>
            </Form>
            <Modals.Status
              status={feedbackStatus}
              setStatus={setFeedbackStatus}
              successMes="Feedback sent"
              errorMes="Feedback error"
            />
          </>
        );
      }}
    </Formik>
  );
};
