import React from 'react';
import { ApiRequestUrl } from './ApiRequestUrl';
import { SettingsForm } from './SettingsForm';

export const ApiPage = () => {
  return (
    <main>
      <ApiRequestUrl />
      <SettingsForm />
    </main>
  );
};
