import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';
import { GenericCnt } from '../styledComponents';

// Loading.Visual
export const VisualCnt = styled(GenericCnt)`
  max-width: fit-content;
  /* margin-top: 67px; */
  ${flex({ gap: '0 32px' })}

  & > div:last-of-type {
    width: 572px;
  }

  @media screen and (max-width: ${breakpoints.lg}) {
    max-width: 100%;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    min-width: 100%;
    /* margin-top: 30px; */
    ${flex({ dir: 'column', gap: '24px 0' })}

    & > div:first-of-type,
    & > div:last-of-type {
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 0;
  }
`;
