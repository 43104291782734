import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';
import { GenericCnt } from '../styledComponents';

// Loading.Status
export const StatusCnt = styled(GenericCnt)`
  margin-top: 34px;
  padding: 0 32px;
  ${flex({ jc: 'space-between', gap: '0 32px' })}

  & > div:first-of-type {
    width: 247px;
  }
  & > div:nth-of-type(2) {
    width: 283px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 24px;
    & > div:nth-of-type(2) {
      display: none;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 16px;
  }
`;
