import { IUserData } from './interfaces';

const linkedAccount = {
  id: '',
  fullName: '',
  avatar: '',
  accountName: '',
};

const linkedAccounts = {
  google: linkedAccount,
  facebook: linkedAccount,
  github: linkedAccount,
};

export const defaultUser: IUserData = {
  _id: '',
  email: '',
  password: false,
  requestsBalance: 0,
  apiToken: '',
  apiTokenUpdatedAt: '',
  info: {
    fullName: '',
    address: '',
    phone: '',
    company: '',
  },
  configs: {
    enableProxies: false,
    slowScrolling: false,
    autoPaginate: false,
    timeout: 0,
  },
  billing: { billingPlanId: '', renewReqs: false },
  linkedAccounts,
  roles: ['user'],
};
