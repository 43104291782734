import React, { useState } from 'react';
import { ClassFeedbackBtn } from '../../../../../../features/ClassFeedbackBtn';
import { IHeading } from '../interfaces/heading.interface';
import { TooltipBtnWrapper } from '../../../styles';
import { Cnt, H4 } from './styles';

const checkboxes = ['wrong field data', 'invalid data type', 'empty field'];

export const Subheading: React.FC<IHeading> = ({ text, isHeaderOpen }) => {
  const [isHovered, setHovered] = useState<boolean>(true);

  const textProps = text
    ? { className: isHeaderOpen ? 'isOpen' : '', title: text }
    : {};

  return (
    <Cnt
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
    >
      <H4 {...textProps}>{text || 'Category N/D'}</H4>

      <TooltipBtnWrapper>
        <ClassFeedbackBtn
          isShown={isHovered}
          classType="category"
          checkboxes={checkboxes}
        />
      </TooltipBtnWrapper>
    </Cnt>
  );
};
