import React from 'react';
import {
  HistoryBlock,
  VisualTopBlock,
  VisualBottomBlock,
  Rect16,
  Rect24,
} from './styledComponents';

// Loading.History
export const History = () => {
  return (
    <HistoryBlock>
      <Rect24 />
      <Rect16 />
      <Rect16 />
      <Rect16 />
      <Rect16 />
      <Rect16 />
    </HistoryBlock>
  );
};

// Loading.Visual
export const VisualTop = () => {
  return (
    <VisualTopBlock>
      <Rect24 />
      <Rect24 />
      <Rect24 />
    </VisualTopBlock>
  );
};

export const VisualBottom = () => {
  return (
    <VisualBottomBlock>
      <div>
        <Rect16 />
        <Rect16 />
        <Rect16 />
      </div>
      <div>
        <Rect16 />
        <Rect16 />
        <Rect16 />
      </div>
      <div>
        <Rect16 />
        <Rect16 />
        <Rect16 />
        <Rect16 />
      </div>
    </VisualBottomBlock>
  );
};
