import React, { FC } from 'react';
import { BadgeCnt, ImgCnt } from './styledComponents';

interface Props {
  text: string;
  url: string;
  Icon: string | FC<any>;
}

export const DocsBadge = (props: Props) => {
  const { text, url, Icon } = props;

  return (
    <BadgeCnt href={url} target="_blank">
      {typeof Icon === 'string' ? (
        <img src={Icon} alt="" />
      ) : (
        <ImgCnt>
          <Icon />
        </ImgCnt>
      )}
      {text}
    </BadgeCnt>
  );
};
