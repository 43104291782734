import React from 'react';

export const Telegram = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6651 3.71706L2.93509 10.5541C1.72509 11.0401 1.73209 11.7151 2.71309 12.0161L7.26509 13.4361L17.7971 6.79106C18.2951 6.48806 18.7501 6.65106 18.3761 6.98306L9.8431 14.6841H9.84109L9.8431 14.6851L9.5291 19.3771C9.9891 19.3771 10.1921 19.1661 10.4501 18.9171L12.6611 16.7671L17.2601 20.1641C18.1081 20.6311 18.7171 20.3911 18.9281 19.3791L21.9471 5.15106C22.2561 3.91206 21.4741 3.35106 20.6651 3.71706V3.71706Z"
        fill="black"
      />
    </svg>
  );
};
