import React, { FC } from 'react';
import { BillingProvider } from './BillingCtx';
import { PaymentProvider } from './PaymentCtx';

export const PaymentProviders: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <BillingProvider>
      <PaymentProvider>{children}</PaymentProvider>
    </BillingProvider>
  );
};
