import config from '../../config';
import { serializeFilter } from '../../context/dashboard/utils';
import { IGetExtractionsPayload } from '../../interfaces/get-extractions-payload.interface';

// @ GET
// @ dashboard/export/csv
// @ ADMIN
export const DOWNLOAD_METRICS = async ({
  startDate,
  endDate,
  filters,
}: Partial<IGetExtractionsPayload>) => {
  const accessToken = localStorage.getItem('access_token');

  const path = new URL(`${config.baseUrl}/dashboard/export/csv`);
  path.searchParams.append('startDate', `${startDate}`);
  path.searchParams.append('endDate', `${endDate || Date.now()}`);

  let filterQueryString;
  if (filters && filters.length) {
    filterQueryString = serializeFilter(filters);
  }

  const pathString = filterQueryString
    ? `${path.toString()}&${filterQueryString}`
    : path.toString();

  const response = await fetch(pathString, {
    headers: {
      // 'Content-Type': 'text/csv',
      // 'Content-Disposition': 'attachment; filename="metrics.csv"',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  });

  const blob = await response.blob();
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `metrics-${new Date()}.csv`;

  document.body.appendChild(a);
  a.click();
  a.remove();
};
