import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import isURL from 'validator/lib/isURL';
import { AnalyzeBtn, BtnCnt, Cnt, UrlCnt, UrlInput } from './styledComponents';
import Loadings from '../../../components/loadings';
import { useExtraction } from '../../../context/extraction/TaskMethods';
import { HistoryBtn } from '../components/HistoryBtn';
import { useUI } from '../../../context/UICtx';
import { useHistory } from '../../../context/extraction/History';
import { useTaskData } from '../../../context/extraction/TaskData';
import { useRequestData } from '../../../context/extraction/RequestData';

export const AnalyzeURLFormik = () => {
  // UI
  const loc = useLocation();
  const navTo = useNavigate();
  const { setTab } = useUI();
  const { setExtractError } = useTaskData();
  const [isFocused, setFocused] = useState<boolean>(false);

  // Extract
  const { initExtract } = useExtraction();
  const { query } = useRequestData();
  const { url, setUrl, extractStatus, setExtractStatus, extractError } =
    useTaskData();
  const { processingTasks } = useHistory();

  // form & event handlers
  const formik = useFormik({
    initialValues: { url },
    onSubmit: async (values, actions) => {
      setFocused(false);

      if (!isURL(url, { require_protocol: false })) {
        return setExtractError('Please, insert a valid URL');
      }

      initExtract(url);

      if (extractStatus === 'error') {
        return actions.setFieldError('url', extractError);
      }

      setTab('Visual');
    },
  });

  const handleChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    // formik.setFieldValue('url', value);
    setUrl(value);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      if (query.url) formik.handleSubmit();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!loc.pathname.includes('extraction')) {
      formik.setFieldValue('url', '');
    }
  }, [loc]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Cnt>
        <UrlCnt
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className={isFocused ? 'isFocused' : ''}
        >
          <UrlInput
            type="url"
            autoComplete="url"
            name="url"
            placeholder="Enter a URL for any web page..."
            value={url}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            // disabled={extractStatus === 'loading'}
          />
          <BtnCnt className="btn-cnt">
            {loc.pathname.includes('extraction') && (
              <HistoryBtn
                setExtractStatus={() => setExtractStatus(undefined)}
                switchTab={() => navTo('/')}
                processingTasksNum={processingTasks?.length}
              />
            )}
            <AnalyzeBtn type="submit" disabled={!url}>
              Analyze URL
            </AnalyzeBtn>
          </BtnCnt>
        </UrlCnt>
        <Loadings.LoadBar />
      </Cnt>
    </form>
  );
};
