import React, { createContext, useContext, useState } from 'react';
import { StatusType } from '../../../types';
import { ProviderProps } from '../../types';
import { defaultUser } from './defaults';
import { Error as ErrorType, IUserDataCtx, IUserData } from './interfaces';

export const UserDataCtx = createContext<IUserDataCtx>({} as IUserDataCtx);
export const useUserData = () => useContext(UserDataCtx);

export const UserDataProvider: React.FC<ProviderProps> = ({ children }) => {
  const [data, setData] = useState<IUserData>(defaultUser);
  // add credentials
  const [addCredentialsStatus, setAddCredentialsStatus] =
    useState<StatusType>(undefined);
  const [addCredentialsError, setAddCredentialsError] =
    useState<ErrorType>(undefined);
  // resetPass
  const [resetPassStatus, setResetPassStatus] = useState<StatusType>(undefined);
  const [resetPassError, setResetPassError] = useState<ErrorType>(undefined);
  // confirmPass
  const [confirmPassStatus, setConfirmPassStatus] =
    useState<StatusType>(undefined);
  const [confirmPassError, setConfirmPassError] =
    useState<ErrorType>(undefined);
  // changePass
  const [changePassStatus, setChangePassStatus] =
    useState<StatusType>(undefined);
  const [changePassError, setChangePassError] = useState<ErrorType>(undefined);
  // info
  const [getInfoStatus, setGetInfoStatus] = useState<StatusType>(undefined);
  const [updateInfoStatus, setUpdateInfoStatus] =
    useState<StatusType>(undefined);
  // admin
  const [isAdmin, setAdmin] = useState<boolean | undefined>(undefined);

  return (
    <UserDataCtx.Provider
      value={{
        data,
        setData,
        addCredentialsStatus,
        setAddCredentialsStatus,
        addCredentialsError,
        setAddCredentialsError,
        resetPassStatus,
        setResetPassStatus,
        resetPassError,
        setResetPassError,
        confirmPassStatus,
        setConfirmPassStatus,
        confirmPassError,
        setConfirmPassError,
        changePassStatus,
        setChangePassStatus,
        changePassError,
        setChangePassError,
        getInfoStatus,
        setGetInfoStatus,
        updateInfoStatus,
        setUpdateInfoStatus,
        isAdmin,
        setAdmin,
      }}
    >
      {children}
    </UserDataCtx.Provider>
  );
};
