import React from 'react';
import { Section } from '../../../components/containers/Section';
import { Heading } from '../../../components/headings/Heading';
import { DropdownList } from './DropdownList';
import faqJson from './faq.json';

export const FAQSection = () => {
  const qaListData = JSON.parse(JSON.stringify(faqJson)).QAlist;

  return (
    <Section>
      <div>
        <Heading>FAQ</Heading>
        <DropdownList QAlist={qaListData} />
      </div>
    </Section>
  );
};
