import config from '../../config';
import {
  IAddCredentials,
  IConfirmCredentials,
  ILoginCredentials,
  IResetCredentials,
  ISignupCredentials,
} from '../../types';
import {
  IExtractConfigs,
  IUserUpdateInfo,
} from '../../context/user/UserData/interfaces';

// @ POST
// @ users/login
// @ PUBLIC
export const LOGIN_USER = async (data: ILoginCredentials): Promise<any> => {
  try {
    const response = await fetch(`${config.baseUrl}/users/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 201) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ users/register
// @ PUBLIC
export const REGISTER_USER = async (data: ISignupCredentials): Promise<any> => {
  try {
    const response = await fetch(`${config.baseUrl}/users/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 201) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ users/credentials
// @ PRIVATE
export const ADD_CREDENTIALS = async (data: IAddCredentials) => {
  const accessToken = localStorage.getItem('access_token');

  try {
    const response = await fetch(`${config.baseUrl}/users/credentials`, {
      method: 'POST',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 201) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ users/password/reset
// @ PUBLIC
export const RESET_PASS = async (data: IResetCredentials) => {
  try {
    const response = await fetch(`${config.baseUrl}/users/password/reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 201) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ users/password/confirm
// PUBLIC
export const CONFIRM_NEW_PASS = async (
  data: IConfirmCredentials,
  token: string
) => {
  try {
    const response = await fetch(
      `${config.baseUrl}/users/password/confirm?token=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      }
    );

    const json = await response.json();

    if (response.status === 201) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
// @ POST
// @ user/password/change
// @ PRIVATE
export const UPDATE_PASS = async (data: {
  currentPassword: string;
  newPassword: string;
}): Promise<any> => {
  const accessToken = localStorage.getItem('access_token');

  try {
    const response = await fetch(`${config.baseUrl}/users/password/change`, {
      method: 'POST',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 201) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
// @ GET
// @ /users/info
// @ PRIVATE
export const GET_INFO = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/users/info`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    // console.error(err);
  }
};
// @ POST
// @ /users/info
// @ PRIVATE
export const UPDATE_INFO = async (
  updateInfo: IUserUpdateInfo
): Promise<any> => {
  const accessToken = localStorage.getItem('access_token');

  const filteredInfo: any = {};
  Object.entries(updateInfo).forEach((entry) => {
    if (entry[1]) filteredInfo[entry[0]] = entry[1];
  });

  const response = await fetch(`${config.baseUrl}/users/info`, {
    method: 'POST',
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filteredInfo),
  });

  const json = await response.json();

  return {
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    data: json || null,
  };
};
// @ GET
// @ users/me
// @ PRIVATE
export const GET_ME = async (): Promise<any> => {
  try {
    // # need to test without token in strg
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/users/me`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (response.status === 200) {
      // Saving API token to localStorage
      if (json.apiToken) {
        localStorage.setItem('api_token', json.apiToken);
      }

      return {
        ok: true,
        status: json.statusCode || response.status,
        statusText: json.message || response.statusText,
        data: json || null,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: err,
    };
  }
};

// @ PUT
// @ users/renew-reqs
// @ PUBLIC
export const TOGGLE_RENEW_REQS = async (data: { renewReqs: boolean }) => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/users/renew-reqs`, {
      method: 'PUT',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
