import { IExtractionConfig } from '../../types';
import config from '../../config';

// @ GET
// @ configs/api
// @ PRIVATE
export const RETRIEVE_EXTRACTION_SETTINGS = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/configs/api`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    // console.error(err);
  }
};
// @ PUT
// @ configs/api
// @ PRIVATE
export const UPDATE_EXTRACTION_SETTINGS = async (
  data: IExtractionConfig
): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/configs/api`, {
      method: 'PUT',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    // console.error(err);
  }
};
// @ PUT
// @ configs/api/reset-default
// @ PRIVATE
export const RESET_EXTRACTION_SETTINGS = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/configs/api/reset-default`,
      {
        method: 'PUT',
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    // console.error(err);
  }
};
