import React, { createContext, useContext, useState } from 'react';
import { ISelected } from '../../../components/inputFields/Select';
import { ProviderProps } from '../../types';
import { IPaginationCtx } from './interfaces';

const sortOptions = [
  { name: 'latest first', value: -1 },
  { name: 'oldest first', value: 1 },
];

const PAGE_DEFAULT = 1;
const SORT_DEFAULT = sortOptions[0];
const LIMIT_DEFAULT = 100;

const PaginationCtx = createContext<IPaginationCtx>({} as IPaginationCtx);
export const useDashboardPagination = () => useContext(PaginationCtx);

export const PaginationProvider: React.FC<ProviderProps> = ({ children }) => {
  const [page, setPage] = useState(PAGE_DEFAULT);
  const [maxPage, setMaxPage] = useState(PAGE_DEFAULT);
  const [sort, setSort] = useState<ISelected>(SORT_DEFAULT);
  const [limit, setLimit] = useState(LIMIT_DEFAULT);

  return (
    <PaginationCtx.Provider
      value={{
        page,
        setPage,
        maxPage,
        setMaxPage,
        sort,
        sortOptions,
        setSort,
        limit,
        setLimit,
      }}
    >
      {children}
    </PaginationCtx.Provider>
  );
};
