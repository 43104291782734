import styled from 'styled-components';
import { breakpoints, colours } from '../../../../styles/variables';

export const HeaderWrapper = styled.header`
  height: 30.09px;
  width: 147px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  svg {
    fill: ${colours.fontDark};
  }

  &:hover {
    svg {
      fill: ${colours.primary};
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    width: 136.77px;
    height: 28px;
    gap: 12px;

    & > svg:first-of-type {
      width: 61px; // # pp
    }
  }
`;
