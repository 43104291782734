import styled from 'styled-components';
import { textOverflow } from '../../styles/mixins';
import { colours } from '../../styles/variables';

export const ExtractedLink = styled.a`
  max-height: 24px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: ${colours.muted};
  flex-shrink: 1;
  cursor: pointer;

  ${textOverflow()}
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
