import React from 'react';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import { Cnt } from './styledComponents';

interface Props {
  name: string;
}

export const ThreeDots = (props: Props) => {
  return (
    <Cnt>
      <span>{toUpperCaseFirstChar(props.name)} loading </span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </Cnt>
  );
};
