import React, { MouseEventHandler } from 'react';
import { MenuItem } from '../MenuItem';
import { Cnt, Section } from './styledComponents';
import { useAuth } from '../../../../context/AuthCtx';
import { useUserData } from '../../../../context/user/UserData';
import { useUtils } from '../../../../context/extraction/Utils';
import Svgs from '../../../../components/svgs';
import Buttons from '../../../../components/buttons';

interface Props {
  isOpen: boolean;
  clickHandler: MouseEventHandler;
}

export const Navigation = (props: Props) => {
  const { resetDataAndUrl } = useUtils();
  const { isAuth } = useAuth();
  const { data: user } = useUserData();
  const isAdmin = user.roles?.includes('admin');

  return (
    <Cnt
      className={props.isOpen ? 'opened' : 'closed'}
      onClick={props.clickHandler}
    >
      <Section>
        {isAuth && isAdmin && (
          <MenuItem navTo="dashboard" disabled={false} Icon={Svgs.User}>
            Dashboard
          </MenuItem>
        )}
        <MenuItem
          navTo="/"
          disabled={false}
          Icon={Svgs.Extraction}
          clickHandler={resetDataAndUrl}
        >
          Extraction
        </MenuItem>
      </Section>
      {isAuth && (
        <Section>
          <MenuItem navTo="settings" disabled={false} Icon={Svgs.User}>
            Account settings
          </MenuItem>
          <MenuItem
            navTo="settings/payment"
            disabled={false}
            Icon={Svgs.Payment}
          >
            Payment settings
          </MenuItem>
          <MenuItem
            navTo="settings/api"
            disabled={false}
            Icon={Svgs.ApiSettings}
          >
            API settings
          </MenuItem>
        </Section>
      )}
      <Section>
        <MenuItem navTo="support" disabled={false} Icon={Svgs.Help}>
          Help &amp; FAQ
        </MenuItem>
      </Section>
      <Section>
        <Buttons.Login />
      </Section>
    </Cnt>
  );
};
