import React from 'react';
import { IHistoryTask } from '../../../context/dashboard/DashboardCtx/interfaces';
import { RecordsWithUrlCnt } from './styles';
import { TableHead } from './TableHead';
import { IdCell, IIdCell } from './BodyRow/IdCell';
import { IUrlCell, UrlCell } from './BodyRow/UrlCell';
import { IStatusCell, StatusCell } from './BodyRow/StatusCell';
import { FeedbackCell, IFeedbackCell } from './BodyRow/FeedbackCell';
import { ExecutionTimeCell, IExecutionTimeCell } from './BodyRow/ExecutionTimeCell';
import { IStartedAtCell, StartedAtCell } from './BodyRow/StartedAtCell';
import { DetailsCell, IDetailsCell } from './BodyRow/DetailsCell';
import { ICell } from './interfaces/cell.interface';
import { BodyRow } from './BodyRow';
import { Placeholder } from './Placeholder';
import { FeedbackHeadCell } from './TableHead/FeedbackHeadCell';
import { ITableCellBase } from './interfaces/table-cell-base.interface';

const getCells = (taskData?: IHistoryTask): ICell[] => [
  {
    HeadComponent: () => <th style={{ width: `${20}%` }}>ID</th>,
    BodyComponent: taskData
      ? ({ taskId = taskData._id, width = 20 }: IIdCell) => (
        <IdCell {...{ taskId, width }} />
      )
      : () => <></>,
  },
  {
    HeadComponent: () => <th style={{ width: `${15}%` }}>Url</th>,
    BodyComponent: taskData
      ? ({ url = taskData?.query?.url, width = 15 }: IUrlCell) => (
        <UrlCell {...{ url, width }} />
      )
      : () => <></>,
  },
  {
    HeadComponent: () => <th style={{ width: `${15}%` }}>Status</th>,
    BodyComponent: taskData
      ? ({
        status = taskData?.task_report?.type,
        width = 15,
      }: IStatusCell) => <StatusCell {...{ status, width }} />
      : () => <></>,
  },
  {
    HeadComponent: ({ width = 15 }: ITableCellBase) => <FeedbackHeadCell {...{ width }} />,
    BodyComponent: taskData
      ? ({
        feedbacks = taskData?.metrics?.extractionClassFeedbacks,
        width = 15,
      }: IFeedbackCell) => <FeedbackCell {...{ feedbacks, width }} />
      : () => <></>,
  },
  {
    HeadComponent: () => <th style={{ width: `${15}%` }}>Execution time</th>,
    BodyComponent: taskData
      ? ({
        executionTime = taskData?.metrics?.executionTime,
        width = 15,
      }: IExecutionTimeCell) => <ExecutionTimeCell {...{ executionTime, width }} />
      : () => <></>,
  },
  {
    HeadComponent: () => <th style={{ width: `${15}%` }}>Created</th>,
    BodyComponent: taskData
      ? ({
        startedAt = taskData?.metrics?.startedAt,
        width = 15,
      }: IStartedAtCell) => <StartedAtCell {...{ startedAt, width }} />
      : () => <></>,
  },
  {
    HeadComponent: () => <th style={{ width: `${5}%` }}></th>,
    BodyComponent: taskData
      ? ({ task = taskData, width = 5 }: IDetailsCell) => <DetailsCell {...{ task, width }} />
      : () => <></>,
  },
];

interface Props {
  data: IHistoryTask[];
}

export const TaskHistory: React.FC<Props> = ({ data = [] }) => (
  <RecordsWithUrlCnt>
    <TableHead HeadComponents={getCells().map(({ HeadComponent }) => HeadComponent)} />

    <tbody>
      {data.length ? data.map((task: IHistoryTask, taskIndex: number) => (
        <BodyRow
          BodyComponents={getCells(task).map(({ BodyComponent }) => BodyComponent)}
          key={taskIndex}
        />
      )) : <Placeholder />}
    </tbody>
  </RecordsWithUrlCnt>
);
