import styled from 'styled-components';
import { colours } from '../../../../styles/variables';

export default styled.svg`
  &:hover {
    path {
      fill: ${colours.primary};
    }
  }
`;
