import styled from 'styled-components';
import { ShadowCnt } from '../../../components/containers/ShadowCnt';
import { Heading } from '../../../components/headings/Heading';

export const ModalWrapper = styled(ShadowCnt)`
  & > * + * {
    margin-top: 24px;
  }
`;

export const ModalHeading = styled(Heading)`
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
`;
