import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { LgHeading } from '../../../components/headings/LgHeading';
import { PaymentLayout } from './components/PaymentLayout';
import { Cnt } from './styledComponents';
import { loadStripePromise } from '../../../context/payment/PaymentCtx/tools';
import configs from '../../../config';
import Loadings from '../../../components/loadings';

// load stripe
let stripePromise: PromiseLike<Stripe>;
(async () => {
  try {
    const promise = await loadStripePromise(configs.stripePublishableKey);
    stripePromise = promise as unknown as PromiseLike<Stripe>;
  } catch (err) {
    console.warn(err);
  }
})();

export const PaymentPage = () => {
  return stripePromise ? (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            // # https://github.com/stripe-archive/react-stripe-elements/issues/285
            // family: 'Roboto',
            // src: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
            // weight: '400',
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
          },
        ],
      }}
    >
      <Cnt>
        <LgHeading>Buy more requests</LgHeading>
        <PaymentLayout />
      </Cnt>
    </Elements>
  ) : (
    <Cnt>
      <Loadings.ThreeDots name="payment" />
    </Cnt>
  );
};
