import styled from 'styled-components';
import { fadeIn } from '../../../styles/keyframes';
import { flex, pos } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export default styled.div`
  width: fit-content;
  padding: 4px 0px;
  border-radius: 2px;

  ${flex({ dir: 'column' })}
  ${pos({ p: 'absolute', t: '100%' })}
  z-index: 5;

  background: #ffffff;
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  animation: ${fadeIn} 150ms ease-out;

  span {
    width: 100%;
    min-width: 117px;
    padding: 5px 26px 5px 12px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: ${colours.gray};
    }
  }
`;
