import styled from 'styled-components';
import { dim } from '../../../../styles/keyframes';
import { flex } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';

// Flex container
export const CardCnt = styled.div`
  width: 100%;
  margin-top: 48px;
  ${flex({ ai: 'center' })}

  &.isDeleting {
    animation: ${dim()} 1500ms ease-in-out alternate infinite;
    pointer-events: none;
  }

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    border: none;
    flex-wrap: wrap;

    margin-top: -16px;
    margin-left: -16px;
    & > * {
      margin-top: 16px;
      margin-left: 16px;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    margin-top: -18px;
    & > * {
      margin-top: 18px;
    }
  }
`;

// Flex items
export const CardItem = styled.span`
  padding: 14px 15px;
  &:not(:first-of-type),
  &:not(:last-of-type) {
    border: 1px solid ${colours.lighterGray};
    border-left: none;
    border-right: none;
  }
  &:first-of-type {
    border-left: 1px solid ${colours.lighterGray};
    border-radius: 4px 0 0 4px;
  }
  &:nth-last-of-type(2) {
    padding-left: 0;
  }
  &:last-of-type {
    border-right: 1px solid ${colours.lighterGray};
    border-radius: 0 4px 4px 0;
  }

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.secondary};

  @media screen and (max-width: ${breakpoints.md}) {
    // # for specifity
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      padding: 14px 15px;
      border: 1px solid ${colours.lighterGray};
      border-radius: 4px;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 144px;
    }
  }

  @media screen and (max-width: 850px) {
    // # for specifity
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: fit-content;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    // # for specifity
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 144px;
    }
  }

  @media screen and (max-width: 420px) {
    // # for specifity
    &:nth-of-type(3),
    &:nth-of-type(4) {
      flex-grow: 1;
    }
  }

  @media screen and (max-width: 375px) {
    // # for specifity
    &:nth-of-type(3) {
      width: 144px;
    }
    &:nth-of-type(4) {
      width: 143px;
    }
  }
`;

export const Number = styled(CardItem)`
  flex-grow: 1;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;
export const Cardholder = styled(CardItem)`
  display: none;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    display: block;
  }
`;
export const ExpiryDate = styled(CardItem)`
  width: 118px;
  @media screen and (max-width: ${breakpoints.md}) {
    width: fit-content;
  }
`;
export const Cvc = styled(CardItem)`
  width: 118px;
  padding-left: 17px; // # pp
  @media screen and (max-width: ${breakpoints.md}) {
    width: fit-content;
    padding-left: 15px;
  }
`;

// Remove button
export const CardRemoveBtn = styled.button`
  height: fit-content;
  width: fit-content;
  border: none;
  margin-left: 19px;
  background-color: transparent;
  display: inline;
  cursor: pointer;
`;
