export default (date: Date | string) => {
  const dateObj = new Date(date);
  const datePart = `${dateObj.getUTCDate()}/${dateObj.getUTCMonth() + 1}`;
  const hhPart = `${dateObj.getUTCHours()}`;
  const mmPart = `${dateObj.getUTCMinutes()}`;
  const ssPart = `${dateObj.getUTCSeconds()}`;
  const hh = hhPart.length < 2 ? `0${hhPart}` : hhPart;
  const mm = mmPart.length < 2 ? `0${mmPart}` : mmPart;
  const ss = ssPart.length < 2 ? `0${ssPart}` : ssPart;
  const dateStr = `${datePart} ${hh}:${mm}:${ss}`;
  return dateStr;
};
