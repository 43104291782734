import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { removeScrollbar } from '../../../styles/placeholders';

export const Cnt = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  padding: 0;
  ${flex({})}
  overflow-y: scroll;
  ${removeScrollbar}

  @media screen and (max-height: 1080px) and (min-width: 700px) {
    padding: 10vh 0;
  }
`;
