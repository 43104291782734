import React, { Dispatch, SetStateAction } from 'react';
import { ILimitState } from '../../interfaces/limit-state.interface';
import { StatusType } from '../../types';
import InputFields from '../inputFields';
import { ISelected } from '../inputFields/Select';
import Loadings from '../loadings';
import { Pagination } from '../Pagination';
import { Wrapper } from './styles';

interface Props extends Partial<ILimitState> {
  sort: ISelected;
  setSort: Dispatch<SetStateAction<ISelected>>;
  sortOptions: ISelected[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  maxPage: number;
  handleChange?: () => void;
  status?: StatusType;
}

export const PaginationAndSort: React.FC<Props> = ({
  sort,
  setSort,
  sortOptions,
  page,
  setPage,
  maxPage,
  handleChange,
  status,
  limit,
  setLimit,
}) => {
  const isControlVisible = !!(maxPage >= 1);

  if (!isControlVisible) {
    return null;
  }

  return (
    <Wrapper>
      <Loadings.Spinner status={status} />

      <InputFields.Select
        selected={sort}
        setSelected={setSort}
        options={sortOptions}
        handleChange={handleChange}
      />

      <Pagination
        currentPage={page}
        maxPage={maxPage}
        setCurrentPage={setPage}
        limit={limit}
        setLimit={setLimit}
      />
    </Wrapper>
  );
};
