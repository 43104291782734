import styled from 'styled-components';
import { flex, pos } from '../../../../styles/mixins';
import { breakpoints } from '../../../../styles/variables';
import { mdFont, smFont } from '../fonts';

export const RequestHistorySpan = styled.div`
  flex-grow: 1;

  span {
    ${mdFont};
  }

  & > div:first-of-type {
    ${flex({ ai: 'flex-start' })}

    & img {
      height: 16px;
      width: 16px;
      margin-right: 12px;
    }

    & span {
      height: 16px;
      vertical-align: top;
      white-space: nowrap;
      ${pos({ p: 'relative', b: 3 })}// poor text vertical-align
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    span,
    a {
      ${smFont}
    }
  }

  @media screen and (max-width: 330px) {
    span {
      margin-bottom: 16px;
    }
  }
`;

export const PaginationCnt = styled.div`
  ${flex({ wrap: 'wrap', jc: 'flex-end', ai: 'flex-start' })}

  & > * {
    margin-left: 32px;

    &:first-of-type {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 330px) {
    ${flex({ wrap: 'nowrap', jc: 'space-between', ai: 'flex-start' })}

    & > * {
      margin-left: 0;

      &:first-of-type {
        margin-bottom: 16px;
        margin-right: 16px;
      }
    }
  }
`;
