import styled, { css } from 'styled-components';
import { flex } from '../../styles/mixins';
import { colours } from '../../styles/variables';

const headingFont = css`
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-decoration: none;
  color: ${colours.fontDark};
`;

const footerFont = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${colours.fontDark};
`;

// Toggled Header & Headings
export const Header = styled.header`
  width: fit-content;
`;

export const AuthHeading = styled.a`
  min-width: fit-content;
  margin-right: 16px;
  ${headingFont}
  text-decoration: none;
  pointer-events: none;
  // if it's not the selected tab
  &.inactive {
    float: right;
    pointer-events: all;
    cursor: pointer;
    color: ${colours.lightGray};
  }
`;

export const SentHeading = styled.h6`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${colours.fontDark};
`;

export const ResetHeading = styled.h6`
  ${headingFont}
`;

// Inputs' container
export const FieldGroup = styled.div`
  margin-top: 40px;
  ${flex({ dir: 'column', gap: 12 })}
  // # pp -1px to leverage 1px margin for input

  // Feedbacks
  p {
    position: relative;
    top: 9px;
  }
`;

// Reset pass & Log in / Sign up
export const ActionGroup = styled.div`
  margin-top: 16px;
  ${flex({ jc: 'space-between' })}
`;

export const BtnCnt = styled(ActionGroup)`
  justify-content: flex-end;
`;

// Google, Github, Facebook
export const SocialBtnCnt = styled.div`
  margin-top: 40px;
  ${flex({ dir: 'column' })}

  & > div:not(:first-of-type) {
    margin-top: 16px;
  }
`;

// Footer & links
export const Footer = styled.footer`
  width: 100%;
  margin: 32px 0 0 -1px; // # pp to leverage not perfectly centered footer in Figma
  ${flex({ jc: 'center' })}
  ${footerFont};

  a {
    margin-left: 12px;
  }
`;
