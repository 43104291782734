import React from 'react';
import { FAQSection } from './FAQSection';
import { HelpSection } from './HelpSection';
import { Cnt } from './styledComponents';

export const SupportPage = () => {
  return (
    <Cnt>
      <HelpSection />
      <FAQSection />
    </Cnt>
  );
};
