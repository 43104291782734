import React from 'react';
import { useBilling } from '../../context/payment/BillingCtx';
import { PlanCard } from './components/PlanCard';
import { SelectionLine } from './components/SelectionLine';
import { CardCnt, PlanCnt } from './styledComponents';

export const PlanSelection = () => {
  const { plans, selectedPlanIndex, setSelectedPlanIndex } = useBilling();

  return (
    <PlanCnt>
      <SelectionLine />
      <CardCnt>
        {plans.map((plan, ind) => (
          <PlanCard
            key={ind}
            plan={plan}
            isSelected={ind === selectedPlanIndex}
            clickHandler={() => setSelectedPlanIndex(ind)}
          />
        ))}
      </CardCnt>
    </PlanCnt>
  );
};
