import { keyframes } from 'styled-components';

// Tooltip
export const showTooltip = keyframes`
  from {
    opacity: 0;
  }
`;

// Rotate 180deg
export const rotate = keyframes`
  to {
    transform: rotate(-180deg);
  }
`;

// Spinner and loading-ok (after spinner)
export const rotate360 = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const spin = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

export const dash = keyframes`
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

// fading
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// dim
export const dim = (start = 0.1, end = 0.5) => keyframes`
  0% {
    opacity: ${start};
  }
  100% {
    opacity: ${end};
  }
`;

// gradient
export const glare = keyframes`
  0% {
    background-position: 0% 51%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 51%
  }
`;

// svgs
export const moveDown = keyframes`
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const moveRight = keyframes`
  50% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const moveLeft = keyframes`
  50% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const moveTopRight = keyframes`
  50% {
    transform: translate(1px, -1px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const moveBottomLeft = keyframes`
  50% {
    transform: translate(-1px, 1px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const scale = keyframes`
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
`;

//

export const upAndDown = keyframes`
  50% {
    left: 2px;
  }
  100% {
    left: -2px;
  }
`;

export const dropGradually = keyframes`
  100% {
    max-height: 100%;
    opacity: 1;
  }
`;
