import styled, { css } from 'styled-components';
import { glare } from '../../../styles/keyframes';
import { flex } from '../../../styles/mixins';
import { breakpoints, colours } from '../../../styles/variables';

/* Common styles */
const flexBlock = css`
  ${flex({ dir: 'column', gap: 8 })}
`;

const GenericBlock = styled.div`
  ${flexBlock}
`;

/* Building rects */
export const Rect = styled.div`
  border-radius: 2px;

  background: linear-gradient(
    131deg,
    ${colours.lightGray},
    ${colours.gray},
    ${colours.lightGray}
  );
  background-size: 600% 600%;

  -webkit-animation: ${glare} 5s ease-in-out infinite;
  -moz-animation: ${glare} 5s ease-in-out infinite;
  animation: ${glare} 5s ease-in-out infinite;
`;
export const Rect16 = styled(Rect)`
  height: 16px;
`;
export const Rect24 = styled(Rect)`
  height: 24px;
`;
export const RectImg = styled(Rect)`
  height: 281px;
  width: 300px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 318px;
  }
`;

/* Building blocks for different pages */
// Loading.History
export const HistoryBlock = styled(GenericBlock)`
  & > div:first-child {
    width: 20%;
  }
  & > div {
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    & > div:first-child {
      width: 50%;
    }
  }
`;

// Loading.Visual
export const VisualTopBlock = styled(GenericBlock)`
  // Small rects' inside gap
  margin-bottom: 68px;
  // Calculating small rects width in %
  & > div:nth-of-type(1) {
    width: calc(504 / 572 * 100%);
  }
  & > div:nth-of-type(2) {
    width: 100%;
  }
  & > div:nth-of-type(3) {
    width: calc(446 / 572 * 100%);
  }

  @media screen and (max-width: ${breakpoints.md}) {
    // # must be refactored
    margin-bottom: 57px;
    // Calculating small rects width in %
    & > div:nth-of-type(1) {
      width: calc(269 / 343 * 100%);
    }
    & > div:nth-of-type(2) {
      width: calc(321 / 343 * 100%);
    }
    & > div:nth-of-type(3) {
      width: calc(177 / 343 * 100%);
    }
  }
`;

export const VisualBottomBlock = styled.div`
  // Gap between big blocks
  &:not(:first-of-type) {
    margin-top: 57px;
  }
  // Small blocks
  & > div {
    ${flexBlock}
  }
  // Small blocks' gap and rects' widths
  // 1st and 2nd blocks
  & > div:nth-of-type(1),
  & > div:nth-of-type(2) {
    margin-top: 57px;
    div:nth-of-type(1) {
      width: calc(374 / 572 * 100%);
    }
    & > div:nth-of-type(2) {
      width: calc(438 / 572 * 100%);
    }
    & > div:nth-of-type(3) {
      width: calc(334 / 572 * 100%);
    }
  }
  // 3rd block
  & > div:nth-of-type(3) {
    margin-top: 52px;
    div:nth-of-type(1) {
      width: calc(374 / 572 * 100%);
    }
    & > div:nth-of-type(2) {
      width: calc(438 / 572 * 100%);
    }
    & > div:nth-of-type(3) {
      width: calc(334 / 572 * 100%);
    }
    & > div:nth-of-type(4) {
      width: calc(170 / 572 * 100%);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    // Small blocks' gap and rects' widths
    // 1st and 2nd blocks
    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      margin-top: 57px;
      div:nth-of-type(1) {
        width: calc(280.93 / 343 * 100%);
      }
      & > div:nth-of-type(2) {
        width: calc(329 / 343 * 100%);
      }
      & > div:nth-of-type(3) {
        width: calc(250.88 / 343 * 100%);
      }
    }
    // 3rd block
    & > div:nth-of-type(3) {
      margin-top: 52px;
      div:nth-of-type(1) {
        width: calc(280.93 / 343 * 100%);
      }
      & > div:nth-of-type(2) {
        width: calc(329 / 343 * 100%);
      }
      & > div:nth-of-type(3) {
        width: calc(250.88 / 343 * 100%);
      }
      & > div:nth-of-type(4) {
        width: calc(127.69 / 343 * 100%);
      }
    }
  }
`;
