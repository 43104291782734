import React, { useState } from 'react';
import { IListShowMore } from '../interfaces';
import { Link } from '../styledComponents';
import { Cnt } from './styledComponents';

export const List = (props: IListShowMore) => {
  const { lineHeight, rows, children } = props;
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <Cnt
        style={{
          maxHeight: isOpen ? '100%' : `${rows * lineHeight}px`,
          overflow: isOpen ? 'visible' : 'hidden',
        }}
      >
        {children}
      </Cnt>
      <Link
        onClick={() => setOpen(!isOpen)}
        style={{ display: isOpen ? 'block' : 'inline' }}
      >
        {isOpen ? 'show less' : 'show more'}
      </Link>
    </>
  );
};
