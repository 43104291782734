import React from 'react';
import Links from '../../../../../components/links';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';
import { Td } from '../styles';

export interface IUrlCell extends ITableCellBase {
  url?: string;
}

export const UrlCell: React.FC<IUrlCell> = ({ url, width = 0 }) => (
  <Td style={{ width: `${width}%` }}>
    {url ? (
      <Links.Link href={url} target="_blank" rel="noreferrer">
        {url}
      </Links.Link>
    ) : (
      <span>No url found</span>
    )}
  </Td>
);
