import styled from 'styled-components';

export const SentCnt = styled.div`
  margin-top: 73px;

  header {
    width: 100%;
    margin-left: 0.5px; // not perfectly sentered in Figma
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  footer {
    margin-top: 71px;
    margin-bottom: -17px;
  }
`;
