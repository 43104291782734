import React, { Dispatch, SetStateAction } from 'react';
import { ExtractionTabType } from '../../../context/UICtx/interfaces';
import CntStyled from './Cnt.styled';

interface Props {
  switchToPageName: Partial<ExtractionTabType>;
  pageSwitcher: Dispatch<SetStateAction<any>>;
  active: boolean;
}

export const Tab = (props: Props) => {
  const { switchToPageName, pageSwitcher, active } = props;

  return (
    <CntStyled
      onClick={() => pageSwitcher(switchToPageName)}
      className={active ? 'active' : 'inactive'}
    >
      {switchToPageName}
    </CntStyled>
  );
};
