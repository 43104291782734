import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const IconBtnCnt = styled.div`
  ${flex({})}

  & > div:not(:last-of-type) {
    margin: 0 24px 0 0;
  }

  & > * {
    min-height: 72px;
    width: 295px;
  }

  @media screen and (max-width: 1075px) {
    & > * {
      flex-grow: 1;
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    & > div:not(:last-of-type) {
      margin: 0 0 32px 0;
    }
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    flex-wrap: nowrap;
    div:not(:last-of-type) {
      margin: 0 24px 0 0;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    flex-wrap: wrap;
    div:not(:last-of-type) {
      margin: 0 0 32px 0;
    }
  }
`;
