import React from 'react';
import { Section } from '../../../../components/containers/Section';
import { useDashboard } from '../../../../context/dashboard/DashboardCtx';
import { TaskHistory } from '../../../../features/Dashboard/TaskHistory';
import { MainContentSection } from './styles';
import { TableSectionHeader } from './TableSectionHeader';

export const TableSection: React.FC = () => {
  const { extractions, extractionsStatus } = useDashboard();

  return (
    <Section>
      <TableSectionHeader
        extractionsLength={extractions.length}
        status={extractionsStatus}
      />

      <MainContentSection>
        <TaskHistory data={extractions} />
      </MainContentSection>
    </Section>
  );
};
