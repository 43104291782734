import React, { useState } from 'react';
import { HeaderCnt } from './styles';
import Links from '../../../../../components/links';
import { Subheading } from './Subheading';
import { Title } from './Title';

interface Props {
  heading?: string;
  subHeading?: string;
  url?: string;
}

export const Header: React.FC<Props> = ({ heading = '', subHeading, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderCnt className={isOpen ? 'isOpen' : ''}>
      <Title {...{ heading, isOpen, setIsOpen }} />
      <Subheading text={subHeading} isHeaderOpen={isOpen} />
      {url && <Links.ExtractedLink>{url}</Links.ExtractedLink>}
    </HeaderCnt>
  );
};
