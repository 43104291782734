import styled, { css } from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints, colours } from '../../../styles/variables';
import { border, borderTable, commonFont } from '../styles';

const fontStrong = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colours.fontDark};
`;
const emailAndTotalFont = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colours.fontDark};
`;

export const InvoiceTable = styled.table`
  width: 100%;
  margin-top: 40px;
  ${borderTable}

  /* Row styles */
  tr {
    margin: -2px; // instead of border-collapse
    ${flex({ ai: 'center' })}
  }
  thead tr {
    height: 71px;
    border-bottom: ${border};
  }

  // Reqs. option & discount rows
  tbody {
    tr:nth-of-type(1) {
      margin-top: 23px;
    }
    tr:nth-of-type(2) {
      margin-top: 14px;
    }
    tr:last-of-type {
      margin-top: 26px;
      margin-bottom: 21px;
    }

    tr:nth-of-type(1),
    tr:nth-of-type(2) {
      ${commonFont}
      color: ${colours.muted};
    }
  }

  /* Cell styles */
  th:first-of-type,
  td:first-of-type {
    width: calc(420 / 827 * 100%);
    padding-left: 23px;
    text-align: left;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 23px;
  }

  // 'Account name' & 'You will be charged now' cells
  th:first-of-type,
  tr:last-of-type td:first-of-type {
    ${fontStrong}
  }

  // Email & total price cells
  th:last-of-type,
  tr:last-of-type td:last-of-type {
    ${emailAndTotalFont}
  }
  tr:last-of-type td:last-of-type {
    font-weight: 600;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    width: unset;
    margin: 32px 28px 0;
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    /* Row styles */
    tr {
      ${flex({ jc: 'space-between', ai: 'center' })}
    }
    /* Cell styles */
    th:first-of-type,
    td:first-of-type {
      width: unset;
    }
    // Email cell
    th:last-of-type {
      font-size: 14px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 20px 16px;
  }
`;
