import React from 'react';
import { IExtractionClassFeedback } from '../../../../ClassFeedbackBtn/interfaces/extraction-class-feedback.interface';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';
import { FeedbackBadge } from './FeedbackBadge';
import { Cell } from './styles';

export interface IFeedbackCell extends ITableCellBase {
  feedbacks: IExtractionClassFeedback[];
}

export const FeedbackCell: React.FC<IFeedbackCell> = ({ feedbacks, width = 0 }) => (
  <Cell style={{ width: `${width}%` }}>
    {feedbacks.map((feedback, index) => <FeedbackBadge {...feedback} key={index} />)}
  </Cell>
);
