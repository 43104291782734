import React from 'react';
import { BadgeCnt, Icon, InnerCnt, Text } from './styledComponents';

interface Props {
  children: React.ReactNode;
  icon: string;
}

export const BadgeWithIcon = (props: Props): JSX.Element => {
  return (
    <BadgeCnt>
      <InnerCnt>
        <Icon src={props.icon} alt="icon" />
        <Text>{props.children}</Text>
      </InnerCnt>
    </BadgeCnt>
  );
};
