import React, { createContext, useContext, useEffect, useState } from 'react';
import Modals from '../../components/modals';
import config from '../../config';
import { ProviderProps } from '../types';
import { useUserData } from '../user/UserData';
import { useUserMethods } from '../user/UserMethods';
import { IEventData, ISseCtx } from './interfaces';

const SseCtx = createContext<ISseCtx>({} as ISseCtx);
export const useSse = () => useContext(SseCtx);

export const SseProvider: React.FC<ProviderProps> = ({ children }) => {
  const { data: user, setData: setUserData } = useUserData();
  const { setRequestsBalance } = useUserMethods();

  const [eventData, setEventData] = useState<IEventData | undefined>(undefined);
  const [showDisconnect, setShowDisconnect] = useState(false);

  useEffect(() => {
    if (!user || !user._id) {
      return;
    }

    const eventSrc = new EventSource(`${config.baseUrl}/sse/${user._id}`);
    eventSrc.onopen = () => setShowDisconnect(false);
    eventSrc.onmessage = (message) => {
      try {
        if (!message.data) {
          return;
        }

        const dataParsed = JSON.parse(message.data);
        setEventData(dataParsed);
        console.info('[SSE] updating eventData');

        if (user.requestsBalance === dataParsed.requestsBalance) {
          return;
        }

        setRequestsBalance(dataParsed.requestsBalance);
      } catch (err) {
        setShowDisconnect(true);
        console.error('[SSE] onmessage error', err);
      }
    };

    eventSrc.onerror = (error) => {
      setShowDisconnect(true);
      console.error('[SSE] onerror', error);
    };

    return () => eventSrc.close();
  }, [user._id]);

  useEffect(() => {
    if (
      !user ||
      !user._id ||
      !eventData ||
      !eventData.requestsBalance ||
      user.requestsBalance === eventData.requestsBalance
    ) {
      return;
    }

    setUserData({
      ...user,
      requestsBalance: eventData?.requestsBalance,
    });
  }, [eventData]);

  return (
    <div style={{ width: '100%' }}>
      <SseCtx.Provider value={{ eventData }}>{children}</SseCtx.Provider>
      {showDisconnect && <Modals.Disconnect />}
    </div>
  );
};
