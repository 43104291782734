import React, { useEffect } from 'react';
import { useUI } from '../../../context/UICtx';
import { Cnt, Input, MinusBtn, PlusBtn } from './styledComponents';

interface Props {
  name: string;
  timeout: number;
  setValue: (val: number) => void;
}

export const NumberInput = (props: Props) => {
  const { configTimeout, setConfigTimeout } = useUI().configsTimeout;
  const step = 500;

  useEffect(() => {
    setConfigTimeout(props.timeout);
  }, []);
  useEffect(() => {
    props.setValue(configTimeout);
  }, [configTimeout]);

  return (
    <Cnt>
      <MinusBtn
        onClick={(e) => {
          e.preventDefault();
          setConfigTimeout((prev) => {
            if (prev !== 0 && prev >= step) return prev - step;
            return 0;
          });
        }}
      >
        <svg
          width="23"
          height="3"
          viewBox="0 0 23 3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M22.7891 0.168945V2.69238H0.609375V0.168945H22.7891Z" />
        </svg>
      </MinusBtn>
      <Input
        type="number"
        value={configTimeout !== 0 ? configTimeout : ''}
        min={0}
        max={60 * 3 * 1000}
        // step={step}
        id={props.name}
        name={props.name}
        placeholder="Timeout"
        onChange={(e) => {
          setConfigTimeout(+e.target.value);
        }}
      />
      <PlusBtn
        onClick={(e) => {
          e.preventDefault();
          setConfigTimeout((prev) => {
            if (prev === 0) return step;
            return prev + step;
          });
        }}
      >
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.2178 7.53418V10.4229H0.649414V7.53418H17.2178ZM10.4775 0.478516V18.0762H7.40625V0.478516H10.4775Z" />
        </svg>
      </PlusBtn>
    </Cnt>
  );
};
