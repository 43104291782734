import { loadStripe, Stripe } from '@stripe/stripe-js';

export const loadStripePromise = async (
  publishableKey: string
): Promise<Stripe | undefined> => {
  try {
    return loadStripe(publishableKey) as PromiseLike<Stripe>;
  } catch (err) {
    console.error(err);
  }
};

export const checkPaymentProcessStatus = (cardPaymentRes: any) => {
  const error = cardPaymentRes.error;
  if (error) throw new Error(error.message || error);

  const paymentIntentObj = cardPaymentRes.paymentIntent;
  if (!paymentIntentObj) throw new Error('No payment process created');

  const status = paymentIntentObj.status;
  if (status === 'requires_payment_method') {
    throw new Error('No 3D secure supported. Try again or change card');
  }

  return {
    status,
    redirectUrl: paymentIntentObj.next_action?.redirect_to_url?.url,
  };
};
