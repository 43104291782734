import { IExtractionClassFeedback } from '../interfaces/extraction-class-feedback.interface';
import { ExtractionClassType } from '../types';

export default (
  classType: ExtractionClassType,
  extractionClassFeedbacks?: IExtractionClassFeedback[]
): boolean => {
  return !!extractionClassFeedbacks?.find(
    (feedback: IExtractionClassFeedback) => feedback.classType === classType
  );
};
