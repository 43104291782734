import styled from 'styled-components';
import { errFont, lgLabelFont, smLabelFont } from './fonts';

export const Cnt = styled.div`
  width: 100%;
  position: relative;
`;

export const FloatingLabel = styled.label`
  width: max-content;
  
  position: absolute;
  bottom: 100%;
  right: 100%;
  top: 15px;
  left: 0;
  
  pointer-events: none;
  ${lgLabelFont}

  transition: all 200ms;

  &.isFocused {
    top: 7px;
    ${smLabelFont}
  }
`;

export const Feedback = styled.p`
  width: fit-content;
  /* margin-left: 16px; */
  margin-bottom: 8px;
  position: absolute;
  top: calc(100% + 9px);
  ${errFont}

  a {
    display: inline;
    text-decoration: underline;
    ${errFont}
  }
`;
