import styled from 'styled-components';
import { breakpoints, colours } from '../../../../../styles/variables';

export const DescSectionCnt = styled.section`
  margin-top: 36px; // # pp

  & > *:last-child {
    margin-top: 16px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const DescHeading = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${colours.muted};
`;

export const Desc = styled.div`
  font-size: 14px;
  line-height: 20px;
`;
