import styled from 'styled-components';
import { colours } from '../../../styles/variables';

export const Cnt = styled.div`
  height: 24px;
  width: 40px;
  position: relative;
  display: inline-block;
`;

export const Checkbox = styled.input`
  display: none;

  &:checked + label::before {
    background-color: ${colours.primary};
    opacity: 0.24;
    transition: opacity 0.25 ease;
  }

  &:checked + label::after {
    left: 19px;
    background: ${colours.primary};
    box-shadow: 0px 1px 2px #304ffe8a;
  }
`;

export const Label = styled.label`
  height: 14px;
  width: 34px;
  display: block;
  color: transparent;
  user-select: none;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    cursor: pointer;
  }

  &::before {
    height: 14px;
    width: 34px;
    border-radius: 34px;

    background-color: #221f1f3d;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
  }

  &::after {
    height: 20px;
    width: 20px;
    border-radius: 50%;

    top: 0;
    left: -3px;
    transform: translate(0, -3px);

    background-color: #f1f1f1;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.237602);

    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
  }
`;
