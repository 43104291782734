import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { HeadingWrapperBase } from '../../../styles';

export const Wrapper = styled.div`
  ${flex({ jc: 'flex-end' })}
`;

export const HeadingWrapper = styled(HeadingWrapperBase)`
  flex-grow: 1;
  ${flex({ ai: 'flex-end' })}

  a {
    margin-left: 12px;
    line-height: 28px;
  }
`;
