import React from 'react';
import { HistoryRecord } from './HistoryRecord';
import { TableCnt, TableHead, HeadRow } from '../styles';

interface Props {
  data: any[];
}

export const HistoryTable: React.FC<Props> = ({ data }) => (
  <>
    {data.map(({ date, records }, tInd) => (
      <TableCnt key={tInd}>
        <TableHead>
          <HeadRow>
            <div>{date.split(' ').join(', ')}</div>
          </HeadRow>
        </TableHead>
        <div>
          {
            records.map((record: any, rInd: number) => (
              <HistoryRecord key={rInd} record={record} />
            ))
          }
        </div>
      </TableCnt>
    ))}
  </>
);
