import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useAuth } from '../../../context/AuthCtx';
import { useUtils } from '../../../context/extraction/Utils';
import { useUI } from '../../../context/UICtx';
import { useUserData } from '../../../context/user/UserData';
import { useUserMethods } from '../../../context/user/UserMethods';
import { LoginCnt } from './styledComponents';
import Buttons from '..';
import Badges from '../../badges';
import Svgs from '../../svgs';

export const Login = () => {
  const { setTab } = useUI();
  const { resetDataAndUrl } = useUtils();
  const { isAuth, signout } = useAuth();
  const { data: user } = useUserData();
  const { resetData } = useUserMethods();
  const navTo = useNavigate();

  const [isHovered, setHovered] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  const handleHover = (value: boolean) => {
    setHovered(value);
    setShowBtn(value);
  };

  const handleAuthClick = () => {
    resetDataAndUrl();

    if (isAuth) {
      setTab('Visual');
      navTo('/');
      signout();
      NotificationManager.info(
        'You can use our service in a limited mode',
        'Signed out',
        5000
      );
      resetData();
    } else {
      navTo('auth');
    }
  };

  return (
    <>
      {isAuth ? (
        <LoginCnt
          onMouseOver={() => handleHover(true)}
          onMouseOut={() => handleHover(false)}
          className="logout"
          onClick={(e) => {
            e.stopPropagation();
            handleAuthClick();
          }}
        >
          {user.email && <Badges.Username username={user.email} />}
          {showBtn && (
            <Buttons.Button title="Log out">
              <Svgs.Logout state={isHovered ? 'hovered' : ''} />
            </Buttons.Button>
          )}
        </LoginCnt>
      ) : (
        <LoginCnt
          className="login"
          onClick={(e) => {
            e.stopPropagation();
            handleAuthClick();
          }}
        >
          <Buttons.Button>Log in</Buttons.Button>
        </LoginCnt>
      )}
    </>
  );
};
