import React from 'react';
import { Empty } from '../../../../../components/Empty';
import { ChartType } from '../../../../../context/dashboard/Charts/interfaces';
import { useDashboard } from '../../../../../context/dashboard/DashboardCtx';
import { IMetricsData } from '../../../../../context/dashboard/interfaces/metrics-data.interfaces';
import { toUpperCaseFirstChar } from '../../../../../tools/formatString';
import { Wrapper } from './styles';

interface Props {
  chartType: ChartType;
  metrics?: IMetricsData;
}

export const ChartPlaceholder: React.FC<Props> = ({ chartType, metrics }) => {
  const { metricsStatus } = useDashboard();

  if (metricsStatus === 'loading') {
    return null;
  }

  let isVisible = false;
  if (chartType === 'errors' && !metrics?.errors?.length) {
    isVisible = true;
  } else if (chartType === 'metrics' && !metrics?.counts?.length) {
    isVisible = true;
  }

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper>
      <Empty name={toUpperCaseFirstChar(chartType)} />
    </Wrapper>
  );
};
