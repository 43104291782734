/* eslint-disable operator-linebreak */
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toUpperCaseFirstChar } from '../../tools/formatString';
import { LinkedAccountCnt, LoginBtnCnt, Span } from './styledComponents';
import { useAuth } from '../../context/AuthCtx';
import { useUserData } from '../../context/user/UserData';
import { LogoutIcon } from './LogoutIcon';
import { OAuthProvider } from '../../types';
import { writeCookie } from '../../tools/cookies';

interface Props {
  auth: OAuthProvider;
  mode: 'login' | 'link';
  icon: string;
}

export const OAuthBtn = (props: Props): JSX.Element => {
  const { auth, mode, icon } = props;

  /* Login & link with new account logic */
  const { data: user } = useUserData();
  const {
    authWithGoogle,
    initFacebook,
    initGithub,
    linkGoogle,
    linkStatus,
    unlinkProvider,
    unlinkStatus,
  } = useAuth();

  // Google
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenRes) => authWithGoogle(tokenRes),
    onError: (errorRes) => console.log(errorRes),
    flow: 'auth-code',
    scope: 'email profile',
  });
  const linkWithGoogle = useGoogleLogin({
    onSuccess: async (tokenRes) => linkGoogle(tokenRes),
    onError: (errorRes) => console.log(errorRes),
    flow: 'auth-code',
    scope: 'email profile',
  });

  const switcher = {
    login: {
      google: loginWithGoogle,
      facebook: initFacebook,
      github: initGithub,
    },
    link: {
      google: linkWithGoogle,
      facebook: initFacebook,
      github: initGithub,
    },
  };

  /* Already linked accounts logic */
  const { linkedAccounts } = useUserData().data;
  const { id, fullName, avatar, accountName } = linkedAccounts[auth];

  if (mode === 'link' && id) {
    return (
      <LinkedAccountCnt
        className={
          unlinkStatus.provider === auth && unlinkStatus.status === 'loading'
            ? 'isLoading'
            : ''
        }
      >
        <img
          src={avatar}
          alt=""
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = icon;
          }}
        />
        <div>
          <span title={fullName}>{fullName}</span>
          <span title={accountName}>
            {accountName ? toUpperCaseFirstChar(accountName) : ''}
          </span>
        </div>
        {user.email && (
          <button title="Log out" onClick={() => unlinkProvider(auth)}>
            <LogoutIcon />
          </button>
        )}
      </LinkedAccountCnt>
    );
  }

  return (
    <LoginBtnCnt
      onClick={() => {
        writeCookie(`${auth}AuthCode`, mode);
        switcher[mode][auth]();
      }}
      className={
        linkStatus.provider === auth && linkStatus.status === 'loading'
          ? 'isLoading'
          : ''
      }
    >
      <img src={icon} alt="icon" />
      <Span>
        {mode === 'login' ? 'Log in with ' : 'Link with '}
        {toUpperCaseFirstChar(auth)}
      </Span>
    </LoginBtnCnt>
  );
};
