import React from 'react';
import { Link } from '../../components/links/Link';
import { Cnt } from './styledComponents';
import { useExtraction } from '../../context/extraction/TaskMethods';
import { useRequestData } from '../../context/extraction/RequestData';

export const ExampleLinks = () => {
  const { initExtract } = useExtraction();
  const { setQuery } = useRequestData();

  const mockupAnalyze = (url: string) => {
    setQuery((prev) => ({ ...prev, url }));
    initExtract(url);
  };

  return (
    <Cnt>
      <div>
        <span>ex.&nbsp;</span>
        <Link
          onClick={() =>
            mockupAnalyze(
              'https://www.amazon.com/Sony-WH-1000XM5-Canceling-Headphones-Hands-Free/dp/B09XS7JWHH/ref=sr_1_1?crid=2OS7DUWK6FEZT&keywords=sony+wh-1000xm5&qid=1661554306&sprefix=Sony+WH-1000XM5%2Caps%2C332&sr=8-1'
            )
          }
        >
          Sony WH-1000XM5&nbsp;
        </Link>
      </div>
      <div>
        <span>or&nbsp;</span>
        <Link
          onClick={() =>
            mockupAnalyze(
              'https://www.amazon.com/2022-Apple-MacBook-Laptop-chip/dp/B0B3C57XLR/ref=sr_1_1?crid=Y2J75G0C4ORD&keywords=apple+macbook+pro&qid=1661216154&sprefix=Apple+Macbook+Pro%2Caps%2C323&sr=8-1'
            )
          }
        >
          Apple Macbook Pro
        </Link>
      </div>
    </Cnt>
  );
};
