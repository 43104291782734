/* eslint-disable operator-linebreak */
export default process.env.NODE_ENV === 'development'
  ? {
    socketUrl: 'http://localhost:3004',
    baseUrl: 'http://localhost:3004/v1',
    // baseUrl: 'https://276a-66-181-174-92.ap.ngrok.io/v1',
    googleOAuthClientId:
      '1094068855475-2bcu66vvv5hvlgkd8c27356260s4958d.apps.googleusercontent.com',
    facebookOAuthClientId: '3282731025339783',
    redirectUri: 'http://localhost:3000/',
    githubOAuthClientId: '44771b42e24ee4d18c1b',
    stripePublishableKey:
      'pk_test_51LXJWgFzlCoYTKVGemf8EUiSg036aEaSN1rC91BWdPGOpCAv9r2soEQ8MBlpaaUHPt9PqqXPKZtMlsC1KAF4tgxX00OsZp96NX',
  }
  : {
    socketUrl: 'https://api.app.exbot.ai',
    baseUrl: 'https://api.app.exbot.ai/v1',
    googleOAuthClientId:
      '713507725115-0kubkl1ufh8mu9uauh1jfgt7l3m2d42q.apps.googleusercontent.com',
    facebookOAuthClientId: '3282731025339783',
    redirectUri: 'https://app.exbot.ai/',
    githubOAuthClientId: '1ce552e4a1c8ed8bc1b7',
    stripePublishableKey:
      'pk_test_51LXJWgFzlCoYTKVGemf8EUiSg036aEaSN1rC91BWdPGOpCAv9r2soEQ8MBlpaaUHPt9PqqXPKZtMlsC1KAF4tgxX00OsZp96NX',
  };
