import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { resetBtn } from '../../../styles/placeholders';
import { breakpoints, colours } from '../../../styles/variables';
import { border, tdFont, thFont } from '../styles';

export const TokenTable = styled.table`
  width: 594px;
  border: ${border};
  border-radius: 8px;

  tr {
    height: 67px;
    margin: -2px;
    ${flex({ ai: 'center' })}
    text-align: left;

    &:first-of-type {
      border-bottom: ${border};
    }
    &:last-of-type {
      height: 68px; // overlapping border
      th,
      td {
        padding-top: 25px;
      }
    }
  }

  th,
  td {
    padding: 23px;
  }
  th {
    min-width: 160px;
    border-right: ${border};
    ${thFont}
  }
  td {
    ${tdFont}
    letter-spacing: 1px; // # pp
    padding-right: 15px;
  }

  @media screen and (max-width: 1045px) {
    width: 100%;
    th {
      min-width: calc((160 / 594) * 100%);
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    tr {
      height: fit-content;
      padding: 23px;
      ${flex({ dir: 'column', jc: 'center', ai: 'flex-start' })}

      &:last-of-type {
        height: fit-content;
        th,
        td {
          // # pp
          padding-top: 2px;
          margin-bottom: -1px;
        }
      }
    }
    th,
    td {
      padding: 0;
      letter-spacing: 0.25px; // # pp
    }
    th {
      min-width: unset;
      border-right: none;
    }
  }
`;

export const TokenCell = styled.td`
  width: 100%;
  ${flex({ jc: 'space-between', ai: 'center' })}

  span {
    padding: 4px 8px;
    border-radius: 8px;
    margin-left: -8px;
  }

  & span.clicked {
    color: ${colours.white};
    background-color: ${colours.lightGray};
    pointer-events: none;
  }

  &:hover {
    span {
      color: ${colours.white};
      background-color: ${colours.primary};
    }
  }

  div {
    ${flex({ jc: 'flex-end', ai: 'center' })}

    button:last-of-type {
      margin-left: 16px;
    }
  }

  button {
    ${resetBtn}
  }

  @media screen and (max-width: ${breakpoints.md}) {
    button {
      display: none;
    }
  }
`;

export const LoadingWraper = styled.td`
  padding: 23px;
  white-space: nowrap;
`;
