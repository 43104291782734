import React, { FC, createContext, useContext, useState } from 'react';
import { IBillingPlan } from '../../../types';
import { IBillingCtx } from './interfaces';

const BillingCtx = createContext<IBillingCtx>({} as IBillingCtx);

export const useBilling = () => useContext(BillingCtx);

export const BillingProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [plans, setPlans] = useState<IBillingPlan[] | []>([]);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(1);

  return (
    <BillingCtx.Provider
      value={{
        plans,
        setPlans,
        selectedPlanIndex,
        setSelectedPlanIndex,
      }}
    >
      {children}
    </BillingCtx.Provider>
  );
};
