import React, { useState } from 'react';
import { IControl } from './interfaces/control.interface';
import { ControlField, ShowDetailsField } from './styledComponents';
import thinArrow from '../../assets/icons/thin-arrow.svg';

interface Props {
  // details: IDetailsControl,
  details: IControl
  // isOpenShowDetails: boolean
}

export const ShowDetails = (props: Props) => {
  const { details } = props;
  const [isOpenShowDetails, setIsOpenShowDetails] = useState<boolean>(false);


  return (
    <>
      <ControlField>Class: <span>{details.name}</span></ControlField>
      {isOpenShowDetails ? (
        <>
          <ControlField>
            {/* <div style={{ whiteSpace: 'nowrap' }}>Path: <span>{details.path}</span></div> */}
            <div>X Center: <span>{details.x_center}</span></div>
            <div>Y Center: <span>{details.y_center}</span></div>
            <div>Width: <span>{details.width}</span></div>
            <div>Height: <span>{details.height}</span></div>
          </ControlField>
          <ShowDetailsField
            onClick={() => setIsOpenShowDetails((prev) => !prev)}
          >
            Hide Details
            <img
              className={!isOpenShowDetails ? 'isTurned' : 'isNotTurned'}
              src={thinArrow}
              alt="dropdown"
            />
          </ShowDetailsField>
        </>
      ) : (
        <ShowDetailsField
          onClick={() => setIsOpenShowDetails((prev) => !prev)}
        >
          Show Details
          <img
            className={!isOpenShowDetails ? 'isTurned' : 'isNotTurned'}
            src={thinArrow}
            alt="dropdown"
          />
        </ShowDetailsField>
      )}
    </>
  );
};
