import { CardNumberElement } from '@stripe/react-stripe-js';
import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Client } from '../../../api/client';
import { StatusType } from '../../../types';
import { useBilling } from '../BillingCtx';
import { useUserData } from '../../user/UserData';
import { useUserMethods } from '../../user/UserMethods';
import {
  ICardsObj,
  ICardValidation,
  IPaymentCtx,
  IPaymentHistoryItem,
  IStripeOptions,
} from './interfaces';
import { checkPaymentProcessStatus } from './tools';

const PaymentCtx = createContext<IPaymentCtx>({} as IPaymentCtx);

export const usePayment = () => useContext(PaymentCtx);

export const PaymentProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const client = new Client();
  const { data: user } = useUserData();
  const { loadUserData } = useUserMethods();
  const { plans, setPlans, selectedPlanIndex } = useBilling();

  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistoryItem[]>(
    []
  );
  const [paymentHistoryStatus, setPaymentHistoryStatus] =
    useState<StatusType>(undefined);

  const [cardsObj, setCardsObj] = useState<ICardsObj>({
    cards: [],
    has_more: false,
  });
  const [cardsStatus, setCardsStatus] = useState<StatusType>(undefined);
  const [removeCardStatus, setRemoveCardStatus] =
    useState<StatusType>(undefined);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [url, setUrl] = useState<string>('');
  const [isThreeDSModalOpen, setThreeDSModalOpen] = useState<boolean>(false);
  const [isFormDisabled, setFormDisabled] = useState<boolean>(false);
  const [isBtnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [isConfirmingPayment, setConfirmingPayment] = useState<boolean>(false);

  const [paymentStatus, setPaymentStatus] = useState<StatusType>(undefined);
  const [validation, setValidation] = useState<ICardValidation>({
    cardNumber: {
      isValid: false,
      error: undefined,
    },
    cardExpiry: {
      isValid: false,
      error: undefined,
    },
    cardCvc: {
      isValid: false,
      error: undefined,
    },
  });

  useEffect(() => {
    setBtnDisabled(
      !validation.cardNumber.isValid ||
        !validation.cardExpiry.isValid ||
        !validation.cardCvc.isValid
    );
    // console.log('re-render when re-validation');
  }, [
    validation.cardNumber.isValid,
    validation.cardExpiry.isValid,
    validation.cardCvc.isValid,
  ]);

  const listPaymentHistory = async () => {
    if (paymentHistory.length !== 0) {
      return setPaymentHistoryStatus('success');
    }

    setPaymentHistoryStatus('loading');
    const response = await client.GET_PAYMENTS();
    if (!response.ok) {
      setPaymentHistoryStatus('error');
      return setPaymentHistory([]);
    }

    setPaymentHistoryStatus('success');
    setPaymentHistory(response.data);
  };

  const listAllCards = async (limit?: number) => {
    setCardsStatus('loading');
    const response = await client.LIST_ALL_CARDS(limit);
    if (!response.ok) {
      setCardsStatus('error');
      return setCardsObj({ cards: [], has_more: false });
    }

    setCardsStatus('success');
    setCardsObj(response.data);
  };

  const removeCard = async (cardId: string) => {
    setRemoveCardStatus('loading');

    const response = await client.REMOVE_CARD({ paymentMethodId: cardId });
    if (response.ok) {
      setRemoveCardStatus('success');
      listAllCards();
    }

    setRemoveCardStatus('error');
  };

  const loadPlans = async () => {
    if (isSuccess) return;
    const response = await client.GET_BILLING_PLANS();
    setPlans(response.data);
    setSuccess(true);
  };

  const createIntent = async (stripeOptions: IStripeOptions) => {
    try {
      // (0) Confriming payment UI
      setFormDisabled(true);
      setBtnDisabled(true);
      setConfirmingPayment(true);
      setPaymentStatus(undefined);

      const { stripe, elements } = stripeOptions;
      // (1) fetching client_secret
      const paymentIntentRes = await client.CREATE_INTENT({
        billingPlanId: plans[selectedPlanIndex]._id,
      });
      if (!paymentIntentRes?.ok) return setPaymentStatus('error');

      // (2) make sure stripe & elements are loaded
      if (!stripe || !elements) return setPaymentStatus('error');

      // (3) make sure cardNumberEl is loaded
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) return setPaymentStatus('error');

      // (4) send card details & confirm payment
      const cardPaymentRes = await stripe.confirmCardPayment(
        paymentIntentRes.data.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              email: user.email,
            },
          },
          setup_future_usage: 'off_session',
          return_url: 'https://easync.io/loading-payment',
        },
        { handleActions: false }
      );
      if (cardPaymentRes.error) {
        setConfirmingPayment(false);
        setPaymentStatus('error');
      }
      // console.log('cardPaymentRes', cardPaymentRes);

      const { status, redirectUrl } = checkPaymentProcessStatus(cardPaymentRes);
      if (status === 'requires_action') {
        window.addEventListener(
          'message',
          async function receiveMessageFromIFrame(e: any) {
            if (e.data && e.data?.message !== '3DS-authentication-complete') {
              return;
            }

            console.log(e);
            setFormDisabled(false);
            setConfirmingPayment(false);
            // setThreeDSModalOpen(false);

            window.removeEventListener(
              'message',
              receiveMessageFromIFrame,
              false
            );

            const { paymentIntent } = await stripe.retrievePaymentIntent(
              paymentIntentRes.data.clientSecret
            );
            // console.log(paymentIntent);
            if (
              paymentIntent &&
              !['processing', 'succeeded'].includes(paymentIntent.status)
            ) {
              return setPaymentStatus('error');
            }
            loadUserData();
            return setPaymentStatus('success');
          },
          false
        );

        setUrl(redirectUrl);
        // setThreeDSModalOpen(true);
        await listAllCards();
      }
    } catch (err) {
      setPaymentStatus('error');
    }
  };

  return (
    <PaymentCtx.Provider
      value={{
        paymentHistory,
        listPaymentHistory,
        paymentHistoryStatus,
        cardsObj,
        listAllCards,
        removeCard,
        removeCardStatus,
        cardsStatus,
        loadPlans,
        isSuccess,
        createIntent,
        isFormDisabled,
        isBtnDisabled,
        url,
        isThreeDSModalOpen,
        setThreeDSModalOpen,
        paymentStatus,
        setPaymentStatus,
        validation,
        setValidation,
        isConfirmingPayment,
      }}
    >
      {children}
    </PaymentCtx.Provider>
  );
};
