import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { removeScrollbar } from '../../styles/placeholders';

export default styled.div`
  ${flex({ wrap: 'nowrap' })}
  overflow-x: scroll;
  overflow-y: hidden;
  ${removeScrollbar}

  & > * {
    width: 33.33%;

    & + * {
      margin-left: 16px;
    }
  }
`;
