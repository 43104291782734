import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { GenericCnt } from '../styledComponents';

// Loading.History
export const HistoryCnt = styled(GenericCnt)`
  width: 976px;
  max-width: 100%;
  ${flex({ dir: 'column', gap: '32px 0' })}
`;
