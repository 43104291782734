import React, { createContext, useContext, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { Client } from '../../../api/client';
import { findMissingItems } from '../../../tools/arrays/findMissingItems';
import { StatusType } from '../../../types';
import { useSse } from '../../SseCtx';
import { useExtractionPagination } from '../Pagination';
import { useTaskData } from '../TaskData';
import { IHistoryCtx, IHistoryItem } from './interfaces';
import extractionTransformer from '../../../tools/extraction/extractionFormatter';
import { useRequestData } from '../RequestData';

const HistoryCtx = createContext<IHistoryCtx>({} as IHistoryCtx);
export const useHistory = () => useContext(HistoryCtx);

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = new Client();
  const navTo = useNavigate();
  const { pathname } = useLocation();
  const { eventData } = useSse();
  const { page, sort, setMaxPage } = useExtractionPagination();
  const { setUrl, setData, setJson, setExtractStatus, setTaskStatus } =
    useTaskData();
  const { setQuery } = useRequestData();

  const [historyStatus, setHistoryStatus] = useState<StatusType>(undefined);
  const [processingTasks, setProcessingTasks] = useState<IHistoryItem[]>([]);
  const [historyData, setHistoryData] = useState<IHistoryItem[] | undefined>(undefined);

  const getHistory = async () => {
    // setHistoryStatus('loading'); // disturbing loader
    const response = await client.GET_HISTORY({ page, sort: sort.value });

    if (!response.ok) {
      return setHistoryStatus('error');
    }

    setHistoryStatus('success');
    setMaxPage(response.data.pages);
    setProcessingTasks(response.data.processing);
    setHistoryData(response.data.history);
  };

  const getTaskById = async (id: string) => {
    const response = await client.GET_EXTRACTION_BY_ID({ id });

    if (response.ok) {
      return response.data;
    }

    return null;
  };

  const updateTasksProcessing = () => {
    if (!eventData) {
      return;
    }

    const missingTasks = findMissingItems(
      processingTasks,
      eventData.processingTasks
    );

    missingTasks.forEach(async (task) => {
      const taskRetrieved = await getTaskById(task._id);
      if (!taskRetrieved) {
        return;
      }

      if (taskRetrieved.status === 'success') {
        NotificationManager.info(
          `Task id ${task._id}`,
          'Extraction succeeded',
          5000
        );

        setTaskStatus('success');

        setUrl(taskRetrieved.url || task.url || '');

        const { query: queryOnlyData, ...dataWithoutQuery } = taskRetrieved;
        setJson(dataWithoutQuery);
        setData(extractionTransformer(dataWithoutQuery));
        setQuery(queryOnlyData);
        setExtractStatus('success');
      }

      if (taskRetrieved.status === 'failure') {
        NotificationManager.info(
          `Task id ${task._id}`,
          'Failed to extract',
          5000
        );
        if (pathname.includes('extraction')) {
          navTo('/');
        }
      }
    });
  };

  useEffect(() => {
    if (!eventData || !eventData?.processingTasks || !processingTasks) {
      return;
    }

    if (eventData.processingTasks.length < processingTasks.length) {
      updateTasksProcessing();
      getHistory();
    }

    setProcessingTasks(eventData.processingTasks);
  }, [eventData]);

  useEffect(() => {
    setHistoryStatus(historyData ? 'success' : 'loading');
  }, [historyData]);

  return (
    <HistoryCtx.Provider
      value={{
        setHistoryData,
        getHistory,
        historyData,
        processingTasks,
        setProcessingTasks,
        historyStatus,
      }}
    >
      {children}
    </HistoryCtx.Provider>
  );
};
