import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Form } from './styledComponents';
import { ElementWrapper } from './ElementWrapper';
import { cardCvcOptions, cardExpiryOptions, cardNumberOptions } from './styles';
import { Feedback } from '../../../../../components/inputFields/FloatingLabelInput/styledComponents';
import { usePayment } from '../../../../../context/payment/PaymentCtx';
import { Footer } from './Footer';

export const CardPaymentForm = () => {
  const {
    validation,
    isFormDisabled,
    createIntent,
    isBtnDisabled,
    isConfirmingPayment,
  } = usePayment();
  const { cardNumber: num, cardExpiry: exp, cardCvc: cvc } = validation;
  // Stripe hooks
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        createIntent({ stripe, elements });
      }}
    >
      <span>Enter your payment details</span>
      <div>
        <ElementWrapper
          Component={CardNumberElement}
          options={{ ...cardNumberOptions, disabled: isFormDisabled }}
        />
        <ElementWrapper
          Component={CardExpiryElement}
          options={{ ...cardExpiryOptions, disabled: isFormDisabled }}
        />
        <ElementWrapper
          Component={CardCvcElement}
          options={{ ...cardCvcOptions, disabled: isFormDisabled }}
        />
      </div>
      {(num.error || exp.error || cvc.error) && (
        <Feedback style={{ position: 'relative', margin: '11px 0 0' }}>
          {num.error || exp.error || cvc.error}
        </Feedback>
      )}
      <Footer
        isBtnDisabled={isBtnDisabled}
        isConfirmingPayment={isConfirmingPayment}
      />
    </Form>
  );
};
