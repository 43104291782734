import React from 'react';
import { toUpperCaseFirstChar } from '../../../../../tools/formatString';
import { MetaTag } from '../../../../../types';
import { Cnt, Col } from './styledComponents';

interface Props {
  metaTags: [MetaTag, string][];
}

export const MetaTable = (props: Props) => {
  const { metaTags } = props;
  const upperCaseTags = ['ean', 'mpn'];

  return (
    <Cnt>
      {metaTags.map((entries, ind) => (
        <Col key={ind}>
          <div>
            <h6>
              {!upperCaseTags.includes(entries[0]) ? toUpperCaseFirstChar(entries[0])
                : entries[0].toUpperCase()}
            </h6>
          </div>
          <div>
            <span>{entries[1]}</span>
          </div>
        </Col>
      ))}
    </Cnt>
  );
};
