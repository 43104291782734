import styled from 'styled-components';
import { Button } from '../../../../components/buttons/Button';
import { resetBtn } from '../../../../styles/placeholders';
import { colours } from '../../../../styles/variables';

export const BtnCnt = styled(Button)`
  ${resetBtn}
  margin-bottom: -2px; // # pp
  position: relative;

  &.active {
    path {
      stroke: ${colours.primary};
    }
  }

  &:hover {
    background-color: transparent;
    path {
      stroke: ${colours.primary};
    }
  }
`;
