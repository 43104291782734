import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { validateEmail } from '../../../tools/validation';
import { FloatingLabelInput } from '../../../components/inputFields/FloatingLabelInput';
import { BtnCnt, FieldGroup, ResetHeading } from '../styledComponents';
import { IResetCredentials } from '../../../types';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';
import { useUserMethods } from '../../../context/user/UserMethods';
import { useUserData } from '../../../context/user/UserData';

export const ResetPassFormik = (): JSX.Element => {
  const { resetPass } = useUserMethods();
  const { resetPassStatus, setResetPassStatus, resetPassError } = useUserData();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values) => resetPass(values)}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(formikProps: FormikProps<IResetCredentials>) => {
        const emailMeta = formikProps.getFieldMeta('email');
        const emailHelpers = formikProps.getFieldHelpers('email');

        useEffect(() => {
          if (resetPassStatus === 'error') {
            emailHelpers.setError(resetPassError);
          }
        }, [resetPassStatus]);
        useEffect(() => {
          setResetPassStatus(undefined);
        }, [formikProps.values]);

        return (
          <Form>
            <header>
              <ResetHeading>Reset password</ResetHeading>
            </header>
            <FieldGroup>
              <FloatingLabelInput
                type="email"
                autoComplete="email"
                name="email"
                placeholder="E-mail"
                validate={validateEmail}
                classProp={!emailMeta.error}
              />
            </FieldGroup>
            <BtnCnt>
              <SubmitBtn
                values={formikProps.values}
                errors={formikProps.errors}
                setValidateOnChange={setValidateOnChange}
                status={resetPassStatus}
              >
                Reset password
              </SubmitBtn>
            </BtnCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
