import React from 'react';
import Badges from '../../../../components/badges';

interface Props {
  taskId: string;
  startedAt: number;
}

export const LogsBadge: React.FC<Props> = ({ taskId, startedAt }) => {
  const handleClick = () => {
    const date = new Date(startedAt);
    const from = new Date(date.getTime() - 1000 * 60 * 60)
      .toISOString()
      .replaceAll(' ', '');
    const to = new Date(date.getTime() + 1000 * 60 * 60)
      .toISOString()
      .replaceAll(' ', '');

    const baseUrl = 'http://elk.easync.io:5601/s/exbot/app/discover#';

    window.open(
      `${baseUrl}/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${from}',to:'${to}'))&_a=(columns:!(json.message,json.level,json.event.pod,json.ecs.version),filters:!(),index:'logs-*',interval:auto,query:(language:kuery,query:'json.task._id%20:%20${taskId}'),sort:!(!('@timestamp',desc)))`,
      '_blank'
    );
  };

  return <Badges.Info handleClick={handleClick}>Logs</Badges.Info>;
};
