import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { breakpoints, colours } from '../../../../../styles/variables';

export const Form = styled.form`
  padding: 23px;
  border: 1px solid ${colours.lightGray};
  border-radius: 8px;
  margin-top: 40px;

  & > span {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: ${colours.muted};
  }

  & > div {
    margin-top: 15px; // # pp
    ${flex({})}
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin: 32px 28px 0;
  }
  @media screen and (max-width: 855px) {
    & > div {
      flex-wrap: wrap;
      row-gap: 14px;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 12px 16px 0;
    & > div {
      flex-wrap: nowrap;
    }
  }
  @media screen and (max-width: 455px) {
    & > div {
      flex-wrap: wrap;
    }
  }
`;
