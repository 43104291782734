import React from 'react';
import CntStyled from './Cnt.styled';

export const Submenu = () => {
  return (
    <CntStyled
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.5C0 2.08579 0.381441 1.75 1 1.75H13C13.6186 1.75 14 2.08579 14 2.5C14 2.91421 13.6186 3.25 13 3.25H7H1C0.381441 3.25 0 2.91421 0 2.5Z"
        fill="#818181"
      />
      <path
        d="M0 2.5C0 2.08579 0.381441 1.75 1 1.75H13C13.6186 1.75 14 2.08579 14 2.5C14 2.91421 13.6186 3.25 13 3.25H7H1C0.381441 3.25 0 2.91421 0 2.5Z"
        fill="#818181"
      />
      <path
        d="M0 7C0 6.58579 0.381441 6.25 1 6.25H13C13.6186 6.25 14 6.58579 14 7C14 7.41421 13.6186 7.75 13 7.75H7H1C0.381441 7.75 0 7.41421 0 7Z"
        fill="#818181"
      />
      <path
        d="M0 7C0 6.58579 0.381441 6.25 1 6.25H13C13.6186 6.25 14 6.58579 14 7C14 7.41421 13.6186 7.75 13 7.75H7H1C0.381441 7.75 0 7.41421 0 7Z"
        fill="#818181"
      />
      <path
        d="M0 11.5C0 11.0858 0.381441 10.75 1 10.75H13C13.6186 10.75 14 11.0858 14 11.5C14 11.9142 13.6186 12.25 13 12.25H7H1C0.381441 12.25 0 11.9142 0 11.5Z"
        fill="#818181"
      />
      <path
        d="M0 11.5C0 11.0858 0.381441 10.75 1 10.75H13C13.6186 10.75 14 11.0858 14 11.5C14 11.9142 13.6186 12.25 13 12.25H7H1C0.381441 12.25 0 11.9142 0 11.5Z"
        fill="#818181"
      />
    </CntStyled>
  );
};
