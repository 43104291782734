import React, { useState } from 'react';
import { Cnt } from './styles';
import logo512 from '../../../../../assets/icons/companies/logo512.png';
import { ImageZoomed } from './ImageZoomed';
import { handleError } from './handleError';
import { ClassFeedbackBtn } from '../../../../../features/ClassFeedbackBtn';
import { TooltipBtnWrapper } from '../../styles';

const checkboxes = ['screenshot does not include anything', 'no screenshot', 'no images'];
interface Props {
  images: string[];
}

export const Images: React.FC<Props> = ({ images = [] }) => {
  const [firstImgInd, setFirstImgInd] = useState(0);

  return (
    <Cnt>
      {images.length ? (
        images.map((img: string, ind: number) => {
          const imgSrc = img.replace('/mnt/exchange', 'https://app.exbot.ai/content'); // TODO change for .env variable, and write filenames only to DB

          if (ind === firstImgInd) {
            return <ImageZoomed key={ind} src={imgSrc} />;
          }

          return (
            <img
              src={imgSrc}
              alt="image"
              key={ind}
              className="small"
              onClick={() => setFirstImgInd(ind)}
              onError={handleError}
            />
          );
        })
      ) : (
        <img
          src={logo512}
          alt="image"
          className="big placeholder"
          title="No images extracted"
        />
      )}
      <TooltipBtnWrapper>
        <ClassFeedbackBtn
          isShown
          classType="images"
          checkboxes={checkboxes}
        />
      </TooltipBtnWrapper>
    </Cnt>
  );
};
