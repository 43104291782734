import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';

export const Cell = styled.td`
  ${flex({ wrap: 'wrap' })}

  & > * {
    margin: 0 4px 4px 0;
  }
`;
