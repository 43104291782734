import { css } from 'styled-components';
import { breakpoints, colours } from '../../styles/variables';

// Border
export const border = `1px solid ${colours.lightGray}`;

// Fonts
export const commonFont = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${colours.fontDark};
`;

export const tdFont = css`
  font-weight: 400;
  ${commonFont}
`;

export const thFont = css`
  font-weight: 700;
  ${commonFont}
`;

export const mutedFont = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colours.muted};
`;

// Placeholders
export const borderTable = css`
  border: ${border};
  border-radius: 8px;
`;

export const historyTable = css`
  ${borderTable}
  ${tdFont}

  th {
    ${thFont}
  }

  @media screen and (max-width: ${breakpoints.md}) {
    border: none;
  }
`;

export const historyHeadTr = css`
  padding: 23px 0 16px 23px;
  border-bottom: ${border};
  display: flex;
  flex-flow: row nowrap;
`;
