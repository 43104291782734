import React from 'react';
import Badges from '../../../../../components/badges';
import { useCharts } from '../../../../../context/dashboard/Charts';
import { useDashboard } from '../../../../../context/dashboard/DashboardCtx';
import fromMs from '../../../../../tools/dates/fromMs';
import toPercent from '../../../../../tools/formatters/toPercent';
import { Wrapper } from './styles';

export const MetricsBadges: React.FC = () => {
  const {
    metricsData,
    metricsStatus,
    setIsErrorsOnly,
    statusesSelected,
    setStatusesSelected,
  } = useDashboard();
  const { setCurrentChart } = useCharts();

  return (
    <Wrapper>
      <Badges.MetricsBadge
        keyName="total"
        value={metricsData?.stats.total}
        isLoading={metricsStatus === 'loading'}
        handleClick={() => {
          setIsErrorsOnly(false);
          setCurrentChart('metrics');

          if (statusesSelected.length < 3) {
            setStatusesSelected(['success', 'failure', 'processing']);
          }
        }}
      />
      <Badges.MetricsBadge
        keyName="success"
        value={metricsData?.stats.success}
        isLoading={metricsStatus === 'loading'}
      />
      <Badges.MetricsBadge
        keyName="failure"
        value={metricsData?.stats.failure}
        isLoading={metricsStatus === 'loading'}
        handleClick={() => {
          setIsErrorsOnly(true);
          setCurrentChart('errors');
          setStatusesSelected(['failure']);
        }}
      />
      <Badges.MetricsBadge
        keyName="aet"
        value={fromMs(metricsData?.stats.aet)}
        isLoading={metricsStatus === 'loading'}
      />
      <Badges.MetricsBadge
        keyName="aqt"
        value={fromMs(metricsData?.stats.aet)}
        isLoading={metricsStatus === 'loading'}
      />
      <Badges.MetricsBadge
        keyName="success"
        value={toPercent(metricsData?.stats.successRate)}
        isLoading={metricsStatus === 'loading'}
      />
    </Wrapper>
  );
};
