import React from 'react';
import { Outlet } from 'react-router-dom';
import { Cnt } from './styledComponents';

export const AuthWrapper = () => {
  return (
    <Cnt>
      <Outlet />
    </Cnt>
  );
};
