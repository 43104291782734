import React, { useState } from 'react';
import { Body, Head } from './styledComponents';
import cntrlDownIcon from '../../../../assets/icons/ctrl-down.svg';

export const DropdownItem = (props: { q: string; a: string }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div>
      <Head onClick={() => setOpen(!isOpen)}>
        <h6>{props.q}</h6>
        <img
          style={isOpen ? { rotate: '0.5turn' } : {}}
          src={cntrlDownIcon}
          alt="toggle open"
        />
      </Head>
      <Body className={isOpen ? 'opened' : ''}>
        <p>{props.a}</p>
      </Body>
    </div>
  );
};
