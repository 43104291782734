import React from 'react';
import { Cnt } from './styles';
import { Header } from './Header';
import { Table } from './Table';

export const HistoryPage = () => (
  <Cnt>
    <Header />
    <Table />
  </Cnt>
);
