import styled from 'styled-components';
import links from '../../../../components/links';
import { flex, textOverflow } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';

export const TableCnt = styled.div`
  width: 100%;
  border-spacing: 0;
  margin: 0;

  &:not(:first-of-type) {
    margin-top: 24px; // gap between tables
  }
`;

export const TableHead = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colours.fontDark};

  @media screen and (max-width: ${breakpoints.sm}) {
    font-weight: 600;
    letter-spacing: 0.44px;
  }
`;

export const HeadRow = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colours.lighterGray};

  & > *:not(:first-of-type) {
    margin-left: 32px;
    cursor: pointer;
  }

  &:hover {
    span {
      color: ${colours.primary};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    & > *:not(:first-of-type) {
      margin-bottom: 8px;
    }

    & > *:first-of-type {
      order: 2;
    }
  }
`;

// Grid and grid items
export const BodyRow = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colours.lighterGray};

  display: grid;
  grid-template-areas: 'date status menu title url';
  grid-template-columns: 153px 96px 72px auto auto;
  align-items: center;

  &.success,
  &.processing {
    cursor: pointer;
  }

  &.success:hover {
    background-color: ${colours.primary}07;
  }
  &.failure:hover {
    background-color: ${colours.danger4}07;
  }
  &.processing:hover {
    background-color: ${colours.warn}07;
  }

  @media screen and (max-width: 1200px) {
    grid-template-areas:
      'menu title title'
      'menu url url'
      'menu date status';
    grid-template-rows: fit-content(100%) 20px 34px;
    grid-template-columns: 32px 153px auto;
    row-gap: 8px;

    &.failure {
      grid-template-areas:
        'menu url url'
        'menu date status';
      grid-template-rows: 20px 34px;

      .title {
        display: none;
      }
    }
  }
`;

export const DateCnt = styled.div`
  grid-area: date;
`;
export const StatusCnt = styled.div`
  margin-left: 24px;
  grid-area: status;

  ${flex({ jc: 'center' })}

  @media screen and (max-width: 1200px) {
    margin-left: 6px;
    justify-content: flex-start;
  }
`;
export const MenuCnt = styled.div`
  width: 100%;
  grid-area: menu;
  ${flex({ jc: 'flex-end' })}

  @media screen and (max-width: 1200px) {
    width: 24px;
    height: 100%;
    padding: 3px 3px 0;
    ${flex({ dir: 'column', jc: 'flex-start' })}
  }
`;
export const TitleCnt = styled.div`
  margin-left: 8px;
  grid-area: title;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`;
export const UrlCnt = styled.div`
  margin-left: 32px;
  grid-area: url;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`;

// Nested items

export const Favicon = styled.img`
  max-height: 24px;
  max-width: 24px;
`;
export const TitleSpan = styled.span`
  max-height: 24px;
  line-height: 24px;

  ${textOverflow()}
  white-space: nowrap;

  &.undefined {
    color: ${colours.muted};
  }

  div.loader {
    height: 24px;
    width: 100%;
    min-width: 172px;
  }

  @media screen and (max-width: 1200px) {
    height: fit-content;
    max-height: 48px;
    white-space: normal;

    div.loader {
      height: 24px;
      width: 50%;
      min-width: 72px;
    }
  }
`;
export const Url = styled(links.ExtractedLink)`
  text-align: right;

  @media screen and (max-width: 1200px) {
    text-align: left;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`;
