import React from 'react';
import Badges from '../../../../../../components/badges';
import Tooltips from '../../../../../../components/tooltips';
import { IExtractionClassFeedback } from '../../../../../ClassFeedbackBtn/interfaces/extraction-class-feedback.interface';

export const FeedbackBadge: React.FC<IExtractionClassFeedback> = ({
  classType,
  message,
  checkboxes,
}) => {
  let styles: { backgroundColor: string; color: string } = {
    backgroundColor: 'rgb(238, 238, 241)',
    color: 'rgba(34, 34, 34, 0.7)',
  };

  switch (classType) {
    case 'title':
      styles = {
        backgroundColor: '#ffd6e1',
        color: 'rgba(34, 34, 34, 0.7)',
      };
      break;

    case 'category':
      styles = {
        backgroundColor: '#ffefaf',
        color: 'rgba(34, 34, 34, 0.7)',
      };
      break;

    case 'price':
      styles = {
        backgroundColor: '#beebde',
        color: 'rgba(34, 34, 34, 0.7)',
      };
      break;

    case 'features':
      styles = {
        backgroundColor: '#c8e0ff',
        color: 'rgba(34, 34, 34, 0.7)',
      };
      break;

    case 'details':
      styles = {
        backgroundColor: '#e5d8f2',
        color: 'rgba(34, 34, 34, 0.7)',
      };
      break;

    default:
      break;
  }

  return (
    <Badges.TagColored {...styles}>
      <Tooltips.Hover
        text={JSON.stringify({ message, checkboxes })}
        position="left"
      >
        {classType}
      </Tooltips.Hover>
    </Badges.TagColored>
  );
};
