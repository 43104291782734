import { ChartType } from '../../context/dashboard/Charts/interfaces';
import { IMetricsData } from '../../context/dashboard/interfaces/metrics-data.interfaces';
import { StatusType, TaskStatusType } from '../../types';
import createPieChart from './createPieChart';
import createXYChart from './createXYChart';
import { IChartErrorsData } from './interfaces/chart-errors-data.interface';

export default (
  { counts, errors }: IMetricsData,
  metricsStatus: StatusType,
  type: ChartType,
  errorsSelected: string[],
  updateErrorFieldsChecked?: (checkbox: string) => void,
  filterStatus?: (options: TaskStatusType) => void
) => {
  const isRootLoaded = !!document.getElementById('chartdiv');
  if (!isRootLoaded) {
    return;
  }

  if (type === 'metrics') {
    const root = createXYChart(
      'chartdiv',
      counts,
      metricsStatus === 'loading',
      filterStatus
    );

    return () => root.dispose();
  }

  if (type === 'errors') {
    const data: IChartErrorsData[] = errors
      .filter((error) => errorsSelected.includes(error.message))
      .map(({ message, count }) => ({
        errorType: message || 'Frontend did not find any error message',
        value: count,
      }));

    const root = createPieChart(
      'chartdiv',
      data,
      metricsStatus === 'loading',
      updateErrorFieldsChecked
    );

    return () => root.dispose();
  }
};
