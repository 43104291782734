import styled from 'styled-components';
import { moveLeft } from '../../../../styles/keyframes';

export const SvgCnt = styled.svg`
  &.hovered {
    g {
      path {
        animation: ${moveLeft} 300ms ease-in-out alternate;
      }
    }
  }
`;
