import styled from 'styled-components';
import { historyHeadTr, historyTable } from '../../../components/tables/styles';
import { flex } from '../../../styles/mixins';

export const RecordsWithUrlCnt = styled.table`
  ${historyTable}
  ${flex({ dir: 'column' })}
  
  thead tr,
  tbody tr {
    ${historyHeadTr}
    text-align: left;
    ${flex({})}
  }

  tbody tr:last-of-type {
    border-bottom: none;
  }

  th {
    ${flex({ ai: 'center' })}
    & > * + * {
      margin-left: 8px;
    }
  }

  th,
  td {
    &:nth-of-type(1) {
      width: 14%;
      margin: 0 2% 0 0;
    }
    &:nth-of-type(2) {
      width: 14%;
      margin: 0 2% 0 0;
      overflow-x: hidden;
    }
    &:nth-of-type(3) {
      width: 14%;
      margin: 0;
    }
    &:nth-of-type(4) {
      width: 12%;
      margin: 0;
    }
    &:nth-of-type(5) {
      width: 14%;
      margin: 0;
    }
    &:nth-of-type(6) {
      width: 14%;
      margin: 0;
    }
    &:nth-of-type(7) {
      width: 14%;
      margin: 0;
      ${flex({ jc: 'flex-end' })}

      & > * {
        margin-right: 16px;
        white-space: nowrap;
      }
    }
  }
`;
