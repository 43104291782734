import { IHistoryItem } from '../context/extraction/History/interfaces';
import { toDateArr } from './dates/dates';

export const toEntries = (obj: object): any[] => Object.entries(obj);
export const toEntriesArr = (objArr: object[]) => {
  return objArr.map((obj) => toEntries(obj));
};

export const checkedToBooleans = (all: string[], checked: string[]): object => {
  const allObj = Object.fromEntries(all.map((key) => [key, false]));
  const checkedObj = Object.fromEntries(checked.map((key) => [key, true]));
  return { ...allObj, ...checkedObj };
};

export const booleansToChecked = (boolsObj: object): string[] => {
  if (!boolsObj) return [];

  return Object.entries(boolsObj)
    .map((boolsEntry) => {
      if (boolsEntry[1] === true) return boolsEntry[0];
      return '';
    })
    .filter((str) => str !== '');
};

export const historyMapper = (data: IHistoryItem[]) => {
  const mappedData: any[] = [];

  data.forEach((item, ind, arr) => {
    if (
      arr[ind - 1] === undefined ||
      toDateArr(item.createdAt)[0] !== toDateArr(arr[ind - 1].createdAt)[0]
    ) {
      // if new or first date
      return mappedData.push({
        date: toDateArr(item.createdAt)[0],
        records: [item],
      });
    }
    // if repeat date
    mappedData
      .find((mappedObj) => mappedObj.date === toDateArr(item.createdAt)[0])
      .records.push(item);
  });

  return mappedData;
};
