import styled from 'styled-components';
import { Link } from '../../../components/links/Link';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const Header = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  // 'Show more' button
  & + * + * {
    margin-left: calc(270px - 32px + 3px); // # pp
    margin-right: 0;
  }

  @media screen and (max-width: 1045px) {
    // 'Show more' button
    & + * + * {
      margin-top: 18px; // # pp
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const BadgesCnt = styled.div`
  ${flex({ wrap: 'wrap', ai: 'center' })}

  margin-top: -20px;
  margin-left: -20px;
  & > * {
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export const ReportLink = styled(Link)`
  display: none;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-bottom: 2px; // the difference between Heading bottom and Link bottom
    display: inline;
  }
`;
