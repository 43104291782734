import React from 'react';
import { Button } from '../../components/buttons/Button';
import { Heading } from '../../components/headings/Heading';
import { IErrorFallback } from './interfaces';
import { Cnt } from './styledComponents';

export const Fallback = (props: IErrorFallback) => {
  const { error, resetErrorBoundary } = props;

  console.error(error);

  return (
    <Cnt role="alert">
      <div>
        <Heading>Something went wrong:</Heading>
        <pre>{error.toString()}</pre>
        <Button onClick={resetErrorBoundary}>Home</Button>
      </div>
    </Cnt>
  );
};
