import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints } from '../../styles/variables';

export const Tabs = styled.div`
  ${flex({ gap: 32 })}

  @media screen and (max-width: 700px) {
    ${flex({ gap: 16 })}
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    ${flex({ gap: 32 })}
  }
`;
