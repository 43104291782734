import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

export const Cnt = styled.main`
  padding: 40px 0;

  @media screen and (max-width: ${breakpoints.smMd}) {
    h3 {
      padding-left: 28px;
      text-align: left;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    h3 {
      padding-left: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 32px;
  }
  @media screen and (max-width: ${breakpoints.xss}) {
    h3 {
      padding-right: 9px; // # pp
      letter-spacing: 0;
    }
  }
`;
