import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import { Button } from '../../buttons/Button';

// ShowMore.Table
export const ShowMoreBtn = styled(Button)`
  margin: 31px auto 0; // defaults to center
  @media screen and (max-width: ${breakpoints.xs}) {
    margin-top: 23px;
  }
`;
