import styled from 'styled-components';
import { colours } from '../../../styles/variables';

export const InfoBadge = styled.span`
  /* min-width: max-content; */
  padding: 7px 8px;
  border-radius: 4px;
  /* letter-spacing: 0.0025em; */
  cursor: pointer;

  &.info {
    background-color: #e6ebff;
    color: ${colours.primary};
  }
  &.error {
    background-color: ${colours.danger3};
    color: ${colours.danger4} !important;
  }
  &.warn {
    background-color: ${`${colours.warn}20`};
    color: ${colours.warn} !important;
  }

  transition: box-shadow 300ms ease-in;
  &:hover {
    box-shadow: 0px 2px 4px ${colours.lightGray}, 0px 3px 4px ${colours.lightGray},
      0px 1px 16px ${colours.lightGray};
  }
`;
