import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { FloatingLabelInput } from '../../../../components/inputFields/FloatingLabelInput';
import { InputsCnt } from '../../styledComponents';
import {
  validateEmail,
  validateNewPass,
  validateRepPass,
} from '../../../../tools/validation';
import { SubmitBtn } from '../../../../components/buttons/SubmitBtn';
import { useUserData } from '../../../../context/user/UserData';
import { useUserMethods } from '../../../../context/user/UserMethods';

export const EmailPasswordFormik = () => {
  const { addCredentialsStatus, setAddCredentialsStatus, addCredentialsError } =
    useUserData();
  const { addCredentials } = useUserMethods();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        repPassword: '',
      }}
      onSubmit={async (values) =>
        addCredentials({
          email: values.email,
          password: values.password,
        })
      }
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(
        props: FormikProps<{
          email: string;
          password: string;
          repPassword: string;
        }>
      ) => {
        const emailMeta = props.getFieldMeta('email');
        const emailHelpers = props.getFieldHelpers('email');
        const passwordMeta = props.getFieldMeta('password');
        const repPasswordMeta = props.getFieldMeta('repPassword');

        useEffect(() => {
          if (addCredentialsStatus === 'error') {
            emailHelpers.setError(addCredentialsError);
          }
        }, [addCredentialsStatus]);
        useEffect(() => {
          setAddCredentialsStatus(undefined);
        }, [props.values]);

        return (
          <Form>
            <InputsCnt>
              <FloatingLabelInput
                type="email"
                autoComplete="email"
                name="email"
                placeholder="E-mail"
                validate={validateEmail}
                classProp={!emailMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="new-password"
                name="password"
                placeholder="Set password"
                validate={validateNewPass}
                classProp={!passwordMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="off"
                name="repPassword"
                placeholder="Repeat password"
                validate={(value: string) =>
                  validateRepPass(value, passwordMeta.value as string)
                }
                classProp={!repPasswordMeta.error}
              />
              <SubmitBtn
                values={props.values}
                errors={props.errors}
                setValidateOnChange={setValidateOnChange}
                status={addCredentialsStatus}
                position="right"
              >
                Set credentials
              </SubmitBtn>
            </InputsCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
