import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints } from '../../styles/variables';

export default styled.div`
  min-height: 55vh;
  margin: 0 32px;
  position: relative;

  @media screen and (min-width: ${breakpoints.lg}) {
    ${flex({ jc: 'center' })}
    & > * {
      max-width: 976px;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}) {
    & > * {
      max-width: 100%;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 24px 16px;
  }
`;

// export const ExampleLinksCnt = styled.div`
//   margin: 16px 0 -4px 32px;

//   @media screen and (max-width: ${breakpoints.smMd}) {
//     margin-left: 16px;
//   }
// `;
