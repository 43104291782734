import React from 'react';
import { TableFilter } from '../../../../../components/TableFilter';
import { feedbackOptions, useDashboard } from '../../../../../context/dashboard/DashboardCtx';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';

export const FeedbackHeadCell: React.FC<ITableCellBase> = ({ width }) => {
  const { feedbacksSelected, filterFeedback } = useDashboard();

  return (
    <th style={{ width: `${width}%` }}>
      <span>Feedback</span>
      <TableFilter
        name="feedbacks"
        availableOptions={feedbackOptions}
        optionsSelected={feedbacksSelected}
        filterOptions={filterFeedback}
      />
    </th>
  );
};
