/* eslint-disable no-promise-executor-return */
import config from '../../config';
import { IPaginationPayload } from '../../interfaces/pagination-payload.interface';
import { createUrlWithParams } from '../../tools/urls/createUrlWithParams';
import { IExtractionBody } from '../types';

// @ GET
// @ products/extract
// @ PRIVATE
export const EXTRACT = async (url: string): Promise<any> => {
  try {
    const response = await fetch(url);

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ products/extract
// @ PRIVATE
export const POST_EXTRACT = async (data: IExtractionBody): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const encodedUrl = createUrlWithParams(
      `${config.baseUrl}/products/extract`,
      { token: apiToken, timestamp: Date.now() }
    );

    const response = await fetch(encodedUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...data, client_notes: {} }),
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ products/retrieve
// @ PUBLIC
export const RETRIEVE = async (url: string): Promise<any> => {
  try {
    const encodedUrl = `${config.baseUrl}/products/retrieve?url=${encodeURIComponent(url)}`;
    const response = await fetch(encodedUrl);
    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ products/history
// @ PRIVATE
export const GET_HISTORY = async (query: IPaginationPayload): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const encodedUrl = createUrlWithParams(
      `${config.baseUrl}/products/history`,
      { ...query, token: apiToken }
    );

    const response = await fetch(encodedUrl, {
      headers: { 'Content-Type': 'application/json' },
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ products/history/:taskDocId
// @ PRIVATE
export const GET_EXTRACTION_BY_ID = async (data: {
  id: string;
}): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const response = await fetch(
      `${config.baseUrl}/products/history/${data.id}?token=${apiToken}`,
      { headers: { 'Content-Type': 'application/json' } }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ /stats
// @ PRIVATE
export const GET_STATS = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/stats`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
