import React from 'react';

export const LogoutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 12.5H21.5"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 16.5L21.5 12.5L17.5 8.5"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 4.5V1.5H5.5V23.5H19.5V20.5"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
