import React, { useEffect, useState } from 'react';
import Svgs from '../../svgs';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import { Wrapper, Input, Label } from './styles';

interface Props {
  name: string;
  isCheckedByDefault?: boolean;
  updateFieldsChecked: (checkbox: string) => void;
}

export const Checkbox: React.FC<Props> = ({
  name,
  isCheckedByDefault,
  updateFieldsChecked,
}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(
    isCheckedByDefault
  );

  const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();

    setIsChecked((prev) => !prev);
  };

  // TODO check the reason why {name: 'TimeoutError'} occurs instead of 'TimeoutError'
  const normalizeName = (nameArg: any): string => {
    if (typeof name === 'string') {
      return nameArg;
    }

    const nameString = JSON.stringify(nameArg);

    return nameString;
  };

  useEffect(() => {
    if (isChecked === undefined) {
      return;
    }

    const nameString = normalizeName(name);
    updateFieldsChecked(nameString);
  }, [isChecked]);

  return (
    <Wrapper onClick={handleClick}>
      <Input
        name={normalizeName(name)}
        type="checkbox"
        value={normalizeName(name)}
        checked={!!isChecked}
        onChange={(event) => event.preventDefault()}
      />

      <Svgs.UI.Checkbox isChecked={!!isChecked} />

      <Label
        htmlFor={normalizeName(name)}
        onSelectCapture={(event) => event.preventDefault()}
      >
        {toUpperCaseFirstChar(normalizeName(name))}
      </Label>
    </Wrapper>
  );
};
