import styled from 'styled-components';
import { breakpoints } from '../../../../../../styles/variables';

export const Wrapper = styled.div`
  position: relative;
`;

export const NewPriceWrapper = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1280px) {
    padding-right: 48px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-left: 10px;
  }
`;
