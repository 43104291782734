import React from 'react';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import ShowMores from '../../showMores';
import { VisualCnt, Row, Cell, Heading, List } from './styledComponents';

interface Props {
  isOpen?: boolean;
  rows: (string | [string, string | string[]])[];
}

export const Visual = (props: Props) => {
  const { isOpen = true, rows } = props;

  return (
    <VisualCnt
      title={rows.length.toString()}
      className={isOpen ? 'opened' : ''}
    >
      <div>
        {rows.map((row, ind) => {
          if (typeof row === 'string') {
            return (
              <Row
                key={ind}
                title={ind.toString()}
                className={isOpen ? 'opened' : ''}
              >
                <Cell style={{ width: '100%' }}>{row}</Cell>
              </Row>
            );
          }

          if (!row[1] || row[1].length === 0) return;

          return (
            <Row
              key={ind}
              title={ind.toString()}
              className={isOpen ? 'opened' : ''}
            >
              <Cell>
                <Heading>{toUpperCaseFirstChar(row[0])}</Heading>
              </Cell>

              <Cell>
                {typeof row[1] === 'string' ? (
                  row[1]
                ) : (
                  <ShowMores.List rows={4} lineHeight={24}>
                    <List>
                      {row[1].map((line: string, lineInd: number) => (
                        <li key={lineInd}>{line}</li>
                      ))}
                    </List>
                  </ShowMores.List>
                )}
              </Cell>
            </Row>
          );
        })}
      </div>
    </VisualCnt>
  );
};
