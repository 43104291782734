import styled from 'styled-components';
import { colours } from '../../styles/variables';

export const LgHeading = styled.h3`
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${colours.fontDark};
`;
