import React from 'react';
import { Heading } from '../../../../../components/headings/Heading';
import { PaginationAndSort } from '../../../../../components/PaginationAndSort';
import { useDashboardPagination } from '../../../../../context/dashboard/Pagination';
import { StatusType } from '../../../../../types';
import { DownloadLink } from './DownloadLink';
import { HeadingWrapper, Wrapper } from './styles';

interface Props {
  extractionsLength: number;
  status?: StatusType;
}

export const TableSectionHeader: React.FC<Props> = ({ extractionsLength, status }) => {
  const paginationProps = useDashboardPagination();

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading>Task history</Heading>
        <DownloadLink />
      </HeadingWrapper>
      <PaginationAndSort
        {...{
          ...paginationProps,
          dataLength: extractionsLength,
          dataLengthLimit: 10,
          status,
        }}
      />
    </Wrapper>
  );
};
