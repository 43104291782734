import React, { useEffect } from 'react';
import Tables from '../../../../../components/tables';
import { useUserData } from '../../../../../context/user/UserData';
import { CardPaymentForm } from '../CardPaymentForm';
import { MainWrapper, RenewFormikCnt } from './styledComponents';
import { usePayment } from '../../../../../context/payment/PaymentCtx';
import { ThreeDSecureModal } from '../ThreeDSecureModal';
import Loadings from '../../../../../components/loadings';
import { RenewFormik } from '../../../../../features/RenewFormik';
import Modals from '../../../../../components/modals';
import { PlanSelection } from '../../../../../features/PlanSelection';
import { useBilling } from '../../../../../context/payment/BillingCtx';

export const PaymentLayout = () => {
  const { data: user } = useUserData();
  const { loadPlans, isSuccess, paymentStatus, setPaymentStatus } =
    usePayment();
  const { plans, selectedPlanIndex } = useBilling();

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <MainWrapper>
      {isSuccess ? (
        <>
          <PlanSelection />
          <Tables.Invoice plan={plans[selectedPlanIndex]} email={user.email} />
          <CardPaymentForm />
          <RenewFormikCnt>
            <RenewFormik />
          </RenewFormikCnt>

          <ThreeDSecureModal />
          <Modals.Status
            status={paymentStatus}
            setStatus={setPaymentStatus}
            successMes="Successful payment"
            errorMes="Payment failure"
          />
        </>
      ) : (
        <Loadings.ThreeDots name="payment" />
      )}
    </MainWrapper>
  );
};
