import React from 'react';
import { HistoryCnt } from './styledComponents';
import * as LoadingBlock from '../LoadingBlock';

export const History = () => {
  return (
    <HistoryCnt>
      <LoadingBlock.History />
      <LoadingBlock.History />
      <LoadingBlock.History />
    </HistoryCnt>
  );
};
