export const colours = {
  fontDark: '#1b1b1b',
  inputFocused: '#2980B9',
  inputUnfocused: '#1b1b1b61',
  // main
  primary: '#5578F6',
  primary2: '#5578f666', // OAuth buttons inline border
  primaryDark: '#3458DD', // mouseover
  secondary: '#263238',
  muted: '#818181', // inactive links
  danger: '#F54E1C',
  danger2: '#E74C3C',
  danger3: '#FFE0E0',
  danger4: '#EB5757',
  warn: '#FFA90C',
  white: '#FFFFFF',
  lighterGray: '#EBEBEB',
  lightGray: '#d2d2d2',
  gray: '#F5F5F5',
  gray2: '#959595',
  gray3: '#CDCDCD',
  yellow: '#ffa90c',
  // shadows
  shadow: '#0000001f',
  shadow2: '#0000001a',
  shadow3: '#00000029',
  shadow4: '#0000003d',
};

export const breakpoints = {
  xl: '2400px',
  lg: '1400px',
  md: '990px',
  smMd: '800px',
  sm: '800px',
  xs: '500px',
  xss: '375px',
};
