import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { removeScrollbar } from '../../styles/placeholders';
import { breakpoints, colours } from '../../styles/variables';
// Top container
export const Cnt = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;

  overflow: hidden;

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-flow: column nowrap;
  }
`;
// Side pane with logo, open/close menu buttons, navigation and Buy More CTA
export const SidePane = styled.div`
  height: 100%;
  width: 310px; // minus 10px for shadow of the Main Pane
  padding: 40px 27px 40px 40px;
  position: fixed;
  background-color: ${colours.gray};
  // Scrollbar
  overflow-x: hidden;
  overflow-y: auto;
  ${removeScrollbar}

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    height: 64px;
    padding: 18px 16px;
    z-index: 4;
    overflow-y: hidden;

    /* ${flex({ ai: 'center', gap: 16 })} */
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;

    &.opened {
      min-height: 100vh;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      overflow-y: auto;
    }
  }
`;
// Burger (open) menu button & logo wrapper
export const Corner = styled.div`
  ${flex({ ai: 'center', gap: 16 })}
`;

export const BurgerBtn = styled.button`
  height: 24px;
  width: 24px;
  border: none;
  display: none;

  background-color: transparent;
  cursor: pointer;

  & img[alt='close menu'] {
    display: none;
  }
  @media screen and (max-width: ${breakpoints.smMd}) {
    & img[alt='close menu'] {
      display: block;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    display: block;
  }
`;
// Main pane with Main content (pages)
export const ManePane = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 56px;
  margin-left: 310px;
  margin-right: 30px;
  z-index: 5;
  // Scrollbar
  overflow-x: hidden;
  overflow-y: auto;
  ${removeScrollbar}

  // Scrolled content
  & > div {
    min-height: 100%;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 114px;
    z-index: 6;
    background-color: ${colours.white};
    box-shadow: 0px 0px 5px ${colours.shadow2};
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-right: 20px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;

    & > div {
      width: 100%;
      box-shadow: none;
      min-height: calc(100% - 64px);
      margin: 0;
      margin-top: 64px;
      border-radius: unset;
    }
  }
`;
