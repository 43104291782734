import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { NativeFloatingLabelInput } from '../../../../components/inputFields/NativeFloatingLabelInput';
import { ButtonWrapper, FormWrapper } from './styles';
import Buttons from '../../../../components/buttons';
import { useTaskData } from '../../../../context/extraction/TaskData';
import { CREATE_EXTRACTION_ONE_CLASS_FEEDBACK } from '../../api';
import InputFields from '../../../../components/inputFields';
import { StatusType } from '../../../../types';
import { IExtractionClassModal } from '../../interfaces/extraction-class-modal.interface';

const textareaCustomStyles = {
  paddingLeft: '16px',
};

export const Form: React.FC<IExtractionClassModal> = ({
  classType,
  checkboxes,
  hide,
}) => {
  const { currentTaskId } = useTaskData();

  const [fieldsChecked, setFieldsChecked] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const [status, setStatus] = useState<StatusType>(undefined);

  const updateFieldsChecked = (checkbox: string) => {
    setFieldsChecked((prevFields) => {
      if (!fieldsChecked.includes(checkbox)) {
        return [...prevFields, checkbox];
      }

      return prevFields.filter((prevField) => prevField !== checkbox);
    });
  };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();
    setStatus('loading');

    const { ok } = await CREATE_EXTRACTION_ONE_CLASS_FEEDBACK(currentTaskId, {
      classType,
      checkboxes: fieldsChecked,
      message: inputValue,
    });

    setStatus(ok ? 'success' : 'error');
    NotificationManager.info(
      ok ? 'Feedback sent successfully' : 'Error while sending feedback',
      '',
      2000
    );

    setInputValue('');
    setFieldsChecked([]);

    hide();
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    handleSubmit(event);
  };

  return (
    <FormWrapper
      name={classType}
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
    >
      {checkboxes?.length && (
        <div>
          {checkboxes.map((checkbox, index) => (
            <InputFields.Checkbox
              name={checkbox}
              updateFieldsChecked={updateFieldsChecked}
              key={index}
            />
          ))}
        </div>
      )}

      <NativeFloatingLabelInput
        type="text"
        isTextarea
        name="feedback"
        placeholder="Please leave feedback"
        value={inputValue}
        setValue={setInputValue}
        inputCustomStyles={textareaCustomStyles}
        labelCustomStyles={textareaCustomStyles}
      />

      <ButtonWrapper>
        <Buttons.SubmitBtn status={status}>Send</Buttons.SubmitBtn>
      </ButtonWrapper>
    </FormWrapper>
  );
};
