import styled from 'styled-components';
import { moveDown, scale, upAndDown } from '../../../../styles/keyframes';
import { breakpoints, colours } from '../../../../styles/variables';
import { setStepWidth } from './setStepWidth';

export const Cnt = styled.div`
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: ${colours.lighterGray};
  cursor: auto;

  ${setStepWidth(176 + 75)}
  @media screen and (max-width: 1265px) {
    ${setStepWidth(193)}
  }
  @media screen and (max-width: 1090px) {
    ${setStepWidth(159)}
  }
  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const Thumb = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  position: relative;
  bottom: 8px;
  display: block;

  cursor: grab;
  background-color: ${colours.primary};
  box-shadow: 0px 4px 4px rgba(85, 120, 246, 0.5);
  transition: 500ms;

  &:hover {
    box-shadow: 0px 0px 4px rgba(85, 120, 246, 0.5);
    animation: ${upAndDown} 300ms;
  }

  &:-webkit-drag {
    display: none;
    cursor: grabbing;
  }
`;
