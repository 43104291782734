/* eslint-disable @typescript-eslint/indent */
import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { NotificationManager } from 'react-notifications';
import { StatusType, TaskStatusType } from '../../../types';
import { ITaskDataCtx } from './interfaces';

const { debug } = console;

const TaskDataCtx = createContext<ITaskDataCtx>({} as ITaskDataCtx);
export const useTaskData = () => useContext(TaskDataCtx);

export const TaskDataProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [url, setUrl] = useState<string>('');
  const [data, setData] = useState<any>(undefined);
  const [json, setJson] = useState<object>({});
  const [extractError, setExtractError] = useState<any>(undefined);
  const [extractStatus, setExtractStatus] = useState<StatusType>(undefined);
  const [taskStatus, setTaskStatus] = useState<TaskStatusType>(undefined);
  const [currentTaskId, setCurrentTaskId] = useState<string>('');
  const [isTaskInit, setTaskInit] = useState<boolean | undefined>(undefined);

  const resetData = () => {
    debug('Extraction', 'Resetting data...');

    setData(undefined);
    setJson({});
    setExtractError(undefined);
    setExtractStatus(undefined);
    setTaskStatus(undefined);
    setCurrentTaskId('');
    setTaskInit(undefined);
  };

  useEffect(() => {
    if (extractError) {
      const formattedError =
        typeof extractError === 'string'
          ? extractError
          : extractError.message
              .map((err: any, ind: number) => `${ind + 1}. ${err}`)
              .join(', ') || null;
      NotificationManager.error(formattedError, 'Extraction error', 10000);
      setExtractError('');
    }
  }, [extractError]);

  return (
    <TaskDataCtx.Provider
      value={{
        url,
        setUrl,
        data,
        setData,
        json,
        setJson,
        currentTaskId,
        setCurrentTaskId,
        extractStatus,
        setExtractStatus,
        extractError,
        setExtractError,
        taskStatus,
        setTaskStatus,
        isTaskInit,
        setTaskInit,
        resetData,
      }}
    >
      {children}
    </TaskDataCtx.Provider>
  );
};
