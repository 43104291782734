import React from 'react';

export const Thumb = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="carbon:thumbs-up">
        <path
          id="Vector"
          d="M26 12H20V6C19.9992 5.20459 19.6829 4.44199 19.1204 3.87956C18.558 3.31712 17.7954 3.00079 17 3H14.867C14.3862 3.00204 13.922 3.1764 13.5587 3.49143C13.1954 3.80646 12.9571 4.24128 12.887 4.717L12.042 10.634L8.465 16H2V30H23C24.8559 29.9979 26.6351 29.2597 27.9474 27.9474C29.2597 26.6351 29.9979 24.8559 30 23V16C29.9987 14.9395 29.5768 13.9229 28.827 13.173C28.0771 12.4232 27.0605 12.0013 26 12ZM8 28H4V18H8V28ZM28 23C27.9984 24.3256 27.4711 25.5964 26.5338 26.5338C25.5964 27.4711 24.3256 27.9984 23 28H10V17.303L13.958 11.366L14.868 5H17C17.2652 5 17.5196 5.10536 17.7071 5.29289C17.8946 5.48043 18 5.73478 18 6V14H26C26.5303 14.0005 27.0387 14.2114 27.4136 14.5864C27.7886 14.9613 27.9995 15.4697 28 16V23Z"
          fill="#818181"
        />
      </g>
    </svg>
  );
};
