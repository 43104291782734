import styled from 'styled-components';
import { NumInput } from '../styles';

export const Wrapper = styled.div`
  margin-right: 16px;
`;

export const LimitInputField = styled(NumInput)`
  min-width: 40px;
  max-width: 100px;
`;
