import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';
import isMobilePhone from 'validator/es/lib/isMobilePhone';

const validateEmptyStr = (errorMes: string) => {
  return (value: string) => {
    let error;

    if (!value) {
      error = errorMes;
    }

    return error;
  };
};

// Authorisation

export const validateFullname = (value: string) => {
  return validateEmptyStr('Full name required')(value);
  // if (!isAlpha(value, 'en-US', { ignore: ' -' })) {
  //   error = 'Wrong full name format';
  // }
};

export const validateEmail = (value: string) => {
  let error;

  if (!value) {
    return 'Email required';
  }

  if (!isEmail(value)) {
    error = 'Wrong E-mail format';
  }

  return error;
};

export const validateCurrentPass = (value: string) => {
  return validateEmptyStr('Password required')(value);
};

export const validateNewPass = (value: string) => {
  let error;

  if (!value) {
    error = 'Password required';
  }

  if (value.includes(' ')) {
    error = 'Password should not include spaces';
  }

  if (value.length < 6) {
    error = 'Password should include at least 6 characters';
  }

  // if (!isStrongPassword(value, { minSymbols: 0 })) {
  //   error = 'Weak password';
  // }

  return error;
};

export const validateRepPass = (value: string, origPass: string) => {
  let error;

  validateEmptyStr('Password required')(value);

  if (value !== origPass) {
    error = 'Passwords do not match';
  }

  return error;
};

// Extraction

export const validateUrl = (value: string) => {
  let error;

  validateEmptyStr('Please, fill in your URL')(value);

  if (!isURL(value)) {
    error = 'Incorrect URL format';
  }

  return error;
};

// Settings

export const validateAddress = (value: string) => {
  return validateEmptyStr('Please, fill in your address')(value);
};

export const validatePhone = (value: string) => {
  let error;

  // validateEmptyStr('Please, fill in your phone')(value);

  // if (!isMobilePhone(value)) {
  //   error = 'Incorrect phone format';
  // }

  return undefined;
};

export const validateCompany = (value: string) => {
  // return validateEmptyStr('Please, fill in your company name')(value);
  return undefined;
};
