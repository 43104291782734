import React, { useEffect, useState } from 'react';
import { DisconnectCnt } from './styledComponents';

export const Disconnect = () => {
  const [reconnectIn, setReconnectIn] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (reconnectIn !== 0) {
        setReconnectIn((prev) => prev - 1);
      } else {
        setReconnectIn(120);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [reconnectIn]);

  return (
    <DisconnectCnt>
      Connection lost. Reconnecting in {reconnectIn} seconds.
    </DisconnectCnt>
  );
};
