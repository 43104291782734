import styled from 'styled-components';
import { flex } from '../../styles/mixins';

export default styled.div`
  width: 100%;
  position: relative; // for dropdown
  ${flex({ dir: 'row-reverse', jc: 'flex-start' })}

  & > * + * {
    margin-right: 8px;
  }

  svg:hover {
    cursor: pointer;
  }

  #submenu-dropdown {
    left: 18px;
  }

  &.left {
    #submenu-dropdown {
      left: -125px;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 100%;
    ${flex({ dir: 'column', jc: 'space-between', ai: 'center' })}

    & > * + * {
      margin-right: 0;
      margin-bottom: 5px;
    }

    #submenu-dropdown {
      left: 0;
    }
  }
`;
