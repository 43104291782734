import styled from 'styled-components';
import { colours } from '../../../../styles/variables';

export const BadgeCnt = styled.div`
  height: 50px;
  width: 333px;
  padding: 12px 18px 14px 19px;
  border-radius: 25px;
  margin-bottom: 40px;
  background-color: ${colours.primary};
`;

export const InnerCnt = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: 8px;
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

export const Text = styled.span`
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  margin: 0 auto;
  position: relative;
  top: 2px;
  color: ${colours.white};
`;
