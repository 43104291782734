import React from 'react';
import { Cell, Row, VisualCnt } from '../../components/tables/Visual/styledComponents';
import { IControl } from './interfaces/control.interface';
import { ShowDetails } from './ShowDetails';
import { ControlImage } from './ControlImage';

interface Props {
  isOpen?: boolean;
  rows: IControl[];
}

export const Controls = (props: Props) => {
  const { isOpen = true, rows } = props;

  return (
    <VisualCnt
      title={rows.length.toString()}
      className={isOpen ? 'opened' : ''}
    >
      {rows.map((row, ind) => {
        return (
          <Row
            key={ind}
            title={ind.toString()}
            className={isOpen ? 'opened' : ''}
          >
            <Cell style={{ width: '40%', borderRight: 'none' }}>
              <ControlImage
                path={row.path}
              />
            </Cell>
            <Cell style={{ width: '60%', borderLeft: 'none' }}>
              <ShowDetails
                key={ind}
                details={row}
              />
            </Cell>
          </Row>
        );
      })}
    </VisualCnt>
  );
};
