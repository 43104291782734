import React from 'react';

export const PlusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.5V17.5"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12.5H7.5"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 23.5C18.5751 23.5 23.5 18.5751 23.5 12.5C23.5 6.42487 18.5751 1.5 12.5 1.5C6.42487 1.5 1.5 6.42487 1.5 12.5C1.5 18.5751 6.42487 23.5 12.5 23.5Z"
        stroke="#5578F6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
