import config from '../../config';

// @ GET
// @ dashboard/metrics/:taskId
// @ ADMIN
export const GET_ONE_METRIC = async (data: {
  taskId: string;
}): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/dashboard/metrics/${data.taskId}`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ dashboard/history/:taskId
// @ ADMIN
export const GET_METRIC_EXTRACTION = async (data: {
  taskId: string;
}): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/dashboard/history/${data.taskId}`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ dashboard/json/:taskId
// @ ADMIN
export const GET_METRIC_JSON = async (data: {
  taskId: string;
}): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/dashboard/json/${data.taskId}`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
