import React from 'react';
import { NotificationManager } from 'react-notifications';
import ReactJson from 'react-json-view';
import { Cnt } from './styledComponents';
import { StatusType } from '../../../types';
import Loadings from '../../../components/loadings';
import copyIcon from '../../../assets/icons/copy.svg';
import { Empty } from '../../../components/Empty';

interface Props {
  status: StatusType;
  data: object;
}

export const JsonPage = (props: Props) => {
  const { status, data } = props;

  const click = async () => {
    navigator.clipboard.writeText(JSON.stringify(data));
    NotificationManager.info('JSON copied', null, 2000);
  };

  const switcher = {
    success: (
      <>
        <Cnt>
          <ReactJson src={data || {}} name={null} displayDataTypes={false} />
          <img src={copyIcon} alt="copy" onClick={() => click()} />
        </Cnt>
      </>
    ),
    error: <Empty name="JSON" />,
    loading: <Loadings.ThreeDots name="JSON" />,
    undefined: <Empty name="JSON" />,
  };

  return switcher[`${status}`];
};
