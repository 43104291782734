import React from 'react';
import { MetricsSection } from './MetricsSection';
import { TableSection } from './TableSection';

export const OverviewPage: React.FC = () => {
  return (
    <main>
      <MetricsSection />
      <TableSection />
    </main>
  );
};
