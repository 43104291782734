import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { breakpoints } from '../../../../../styles/variables';

export const Cnt = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  ${flex({ wrap: 'wrap', ai: 'flex-start', gap: 16 })}

  img {
    height: 106px;
    width: 106px;
    order: 2;
    object-fit: scale-down;
    cursor: pointer;
    transition: filter 200ms;

    &.big {
      height: 350px;
      width: 350px;
      margin-bottom: 16px;
      order: 1;
    }
    &.small:hover {
      filter: opacity(50%);
    }
    &.placeholder {
      filter: grayscale(1);
    }
  }

  @media screen and (max-width: 1280px) {
    /* margin: 24px auto 0; */
    margin: 0 auto;
    ${flex({ wrap: 'wrap', jc: 'center', ai: 'flex-start', gap: 16 })}
  }

  @media screen and (max-width: ${breakpoints.md}) {
    ${flex({ wrap: 'wrap', jc: 'center', gap: 8 })}

    img {
      height: 79.75px;
      width: 79.75px;
    }

    img.big {
      height: 343px;
      width: 343px;
      margin-bottom: 8.25px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin: -22px auto 0; // # pp
  }
`;
