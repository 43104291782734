import React, { FC, useState } from 'react';
import { ShowMoreBtn } from './styledComponents';

// ShowMores.Table
export const Table = ({
  data,
  fullReportUrl,
  TableComponent,
}: {
  data: any;
  fullReportUrl?: string; // # specific to API Settings
  TableComponent: FC<{ data: any; fullReportUrl?: string }>;
}) => {
  const [showNumber, setShowNumber] = useState<number>(5);

  return (
    <>
      <TableComponent
        data={data.slice(0, showNumber)}
        fullReportUrl={fullReportUrl}
      />
      <ShowMoreBtn
        onClick={() => setShowNumber(showNumber + 5)}
        style={{ display: data.length <= showNumber ? 'none' : 'block' }}
      >
        Show more
      </ShowMoreBtn>
    </>
  );
};
