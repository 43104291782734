import React, { FC, createContext, useContext, useState } from 'react';
import { Client } from '../../../api/client';
import { StatusType } from '../../../types';
import { IStatsCtx, IStatsItem } from './interfaces';

const StatsCtx = createContext<IStatsCtx>({} as IStatsCtx);
export const useStats = () => useContext(StatsCtx);

export const StatsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const client = new Client();

  const [statsData, setStatsData] = useState<IStatsItem[] | undefined>(
    undefined
  );
  const [statsStatus, setStatsStatus] = useState<StatusType>(undefined);

  const getStats = async () => {
    setStatsStatus('loading');
    const response = await client.GET_STATS();

    if (!response.ok) return setStatsStatus('error');

    setStatsStatus('success');
    setStatsData(response.data);
  };

  return (
    <StatsCtx.Provider value={{ getStats, statsData, statsStatus }}>
      {children}
    </StatsCtx.Provider>
  );
};
