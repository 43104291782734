import React from 'react';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import { IExtractionClassModal } from '../interfaces/extraction-class-modal.interface';
import { Form } from './Form';
import { ModalHeading, ModalWrapper } from './styles';

export const Modal: React.FC<IExtractionClassModal> = (props) => {
  const renderHeading = (): string =>
    `${toUpperCaseFirstChar(props.classType)} field feedback`;

  return (
    <ModalWrapper>
      <ModalHeading>{renderHeading()}</ModalHeading>
      <Form {...props} />
    </ModalWrapper>
  );
};
