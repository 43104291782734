import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';
import { lgFont, mdFont, smFont } from './fonts';

export const CardLabel = styled.label`
  margin-top: 16px;
  ${flex({ dir: 'column' })}
  cursor: pointer;

  // ↓ leveraging gaps according to Figma
  &:nth-of-type(2) {
    position: relative;
    left: 13px;
  }
  &:nth-of-type(3) {
    position: relative;
    left: 15px;
  }

  // reqNumber
  span:nth-of-type(1) {
    ${lgFont}
  }
  // reqs units
  span:nth-of-type(2) {
    ${smFont}
    color: ${colours.muted};
  }
  // price
  span:nth-of-type(3) {
    margin-top: 8px;
    ${mdFont}
  }
  // discount
  span:nth-of-type(4) {
    width: max-content;
    padding: 4px 12px;
    border-radius: 18px;
    margin-top: 8px;

    position: relative;
    right: 12px;

    ${smFont}
    color: ${colours.primary};
    background-color: #ebeffc;
  }

  &:hover,
  &.selected {
    // reqNumber
    span:nth-of-type(1) {
      ${lgFont}
      color: ${colours.primary};
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    height: 159px;
    width: 142px;
    padding: 24px;
    margin-top: 0;
    border-radius: 8px;
    box-shadow: 0px 1px 4px #00000003, 0px 4px 8px #00000005,
      0px 1px 12px ${colours.shadow};

    // reqs units + price
    &:nth-of-type(2),
    &:nth-of-type(3) {
      position: static;
    }
    // discount
    span:nth-of-type(4) {
      position: static;
    }

    &:hover,
    &.selected {
      background-color: ${colours.primary};
      // reqNumber + price
      span:nth-of-type(1),
      span:nth-of-type(3) {
        color: ${colours.white};
      }
      // reqs units
      span:nth-of-type(2) {
        color: #a6b8f9;
      }
    }
  }
`;
