import styled from 'styled-components';
import { fadeIn } from '../../../styles/keyframes';
import { pos } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const Cnt = styled.div`
  ${pos({ p: 'absolute', b: '60%', l: '67%' })}

  & > span {
    padding: 2px 6px;
    border-radius: 51px;
    background: ${colours.yellow};

    font-size: 10px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: ${colours.white};

    animation: ${fadeIn} 300ms ease-in;
  }
`;
