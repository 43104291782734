/* eslint-disable lines-between-class-members */
import {
  ADD_CREDENTIALS,
  CONFIRM_NEW_PASS,
  GET_INFO,
  GET_ME,
  LOGIN_USER,
  REGISTER_USER,
  RESET_PASS,
  TOGGLE_RENEW_REQS,
  UPDATE_INFO,
  UPDATE_PASS,
} from './requests/users';
import {
  CREATE_INTENT,
  GET_BILLING_PLANS,
  LIST_ALL_CARDS,
  REMOVE_CARD,
  GET_PAYMENTS,
  GET_PUBLISHABLE_KEY,
} from './requests/payments';
import {
  EXTRACT,
  GET_HISTORY,
  GET_STATS,
  GET_EXTRACTION_BY_ID,
  RETRIEVE,
  POST_EXTRACT,
} from './requests/products';
import { SEND_FEEDBACK } from './requests/support';
import {
  RESET_EXTRACTION_SETTINGS,
  RETRIEVE_EXTRACTION_SETTINGS,
  UPDATE_EXTRACTION_SETTINGS,
} from './requests/configs';
import {
  AUTH_WITH_FACEBOOK,
  AUTH_WITH_GITHUB,
  AUTH_WITH_GOOGLE,
  LINK_FACEBOOK,
  LINK_GITHUB,
  LINK_GOOGLE,
  UNLINK_PROVIDER,
} from './requests/oauth';
import {
  GET_METRIC_EXTRACTION,
  GET_METRIC_JSON,
  GET_ONE_METRIC,
} from './requests/dashboard';
import { ROTATE_TOKEN } from './requests/token';
import { WRITE_LOG } from './requests/logs';

export class Client {
  // USERS
  LOGIN_USER;
  REGISTER_USER;
  ADD_CREDENTIALS;
  RESET_PASS;
  CONFIRM_NEW_PASS;
  GET_ME;
  GET_INFO;
  UPDATE_INFO;
  UPDATE_PASS;
  TOGGLE_RENEW_REQS;
  // OAUTH
  AUTH_WITH_GOOGLE;
  AUTH_WITH_GITHUB;
  LINK_GOOGLE;
  LINK_GITHUB;
  LINK_FACEBOOK;
  AUTH_WITH_FACEBOOK;
  UNLINK_PROVIDER;
  // EXTRACTION
  EXTRACT;
  POST_EXTRACT;
  GET_HISTORY;
  GET_EXTRACTION_BY_ID;
  ROTATE_TOKEN;
  GET_STATS;
  RETRIEVE;
  // DASHBOARD
  GET_ONE_METRIC;
  GET_METRIC_EXTRACTION;
  GET_METRIC_JSON;
  // CONFIGS
  RETRIEVE_EXTRACTION_SETTINGS;
  UPDATE_EXTRACTION_SETTINGS;
  RESET_EXTRACTION_SETTINGS;
  // PAYMENTS
  GET_BILLING_PLANS;
  GET_PUBLISHABLE_KEY;
  GET_PAYMENTS; // Get payment history
  LIST_ALL_CARDS;
  REMOVE_CARD;
  CREATE_INTENT;
  // SUPPORT
  SEND_FEEDBACK;
  // LOGS
  WRITE_LOG;

  constructor() {
    this.LOGIN_USER = LOGIN_USER;
    this.REGISTER_USER = REGISTER_USER;
    this.ADD_CREDENTIALS = ADD_CREDENTIALS;
    this.RESET_PASS = RESET_PASS;
    this.CONFIRM_NEW_PASS = CONFIRM_NEW_PASS;
    this.GET_ME = GET_ME;
    this.GET_INFO = GET_INFO;
    this.UPDATE_INFO = UPDATE_INFO;
    this.UPDATE_PASS = UPDATE_PASS;
    this.TOGGLE_RENEW_REQS = TOGGLE_RENEW_REQS;
    this.AUTH_WITH_GOOGLE = AUTH_WITH_GOOGLE;
    this.AUTH_WITH_GITHUB = AUTH_WITH_GITHUB;
    this.AUTH_WITH_FACEBOOK = AUTH_WITH_FACEBOOK;
    this.LINK_GOOGLE = LINK_GOOGLE;
    this.LINK_GITHUB = LINK_GITHUB;
    this.LINK_FACEBOOK = LINK_FACEBOOK;
    this.UNLINK_PROVIDER = UNLINK_PROVIDER;
    this.EXTRACT = EXTRACT;
    this.POST_EXTRACT = POST_EXTRACT;
    this.GET_HISTORY = GET_HISTORY;
    this.GET_EXTRACTION_BY_ID = GET_EXTRACTION_BY_ID;
    this.ROTATE_TOKEN = ROTATE_TOKEN;
    this.GET_STATS = GET_STATS;
    this.RETRIEVE = RETRIEVE;
    this.GET_ONE_METRIC = GET_ONE_METRIC;
    this.GET_METRIC_EXTRACTION = GET_METRIC_EXTRACTION;
    this.GET_METRIC_JSON = GET_METRIC_JSON;
    this.RETRIEVE_EXTRACTION_SETTINGS = RETRIEVE_EXTRACTION_SETTINGS;
    this.UPDATE_EXTRACTION_SETTINGS = UPDATE_EXTRACTION_SETTINGS;
    this.RESET_EXTRACTION_SETTINGS = RESET_EXTRACTION_SETTINGS;
    this.GET_BILLING_PLANS = GET_BILLING_PLANS;
    this.GET_PUBLISHABLE_KEY = GET_PUBLISHABLE_KEY;
    this.GET_PAYMENTS = GET_PAYMENTS;
    this.LIST_ALL_CARDS = LIST_ALL_CARDS;
    this.REMOVE_CARD = REMOVE_CARD;
    this.CREATE_INTENT = CREATE_INTENT;
    this.SEND_FEEDBACK = SEND_FEEDBACK;
    this.WRITE_LOG = WRITE_LOG;
  }
}
