import styled from 'styled-components';
import { Section } from '../../../components/containers/Section';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const CardsSection = styled(Section)`
  padding-bottom: 32px;

  header {
    ${flex({ jc: 'space-between' })}
  }
`;

export const PaymentsSection = styled(Section)`
  & > h3:first-of-type {
    // pseudo class for specifity
    margin-bottom: 32px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    & > h3:first-of-type {
      margin-bottom: 22px; // # pp
    }
  }
`;

export const RenewReqsCnt = styled.div`
  margin: 24px 0 0;
  ${flex({ jc: 'space-between', wrap: 'wrap' })}

  & > *:last-child {
    margin-top: 24px;
    position: static;
  }
`;
