import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';

export const FormCnt = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  form {
    display: flex;
    flex-flow: column nowrap;

    p {
      position: relative;
      top: 9px;
      margin-bottom: 0;
    }

    button {
      height: 48px;
      width: 113px;
      margin: 32px auto 0;
    }
  }
`;

export const FieldGroup = styled.div`
  ${flex({ dir: 'column', wrap: 'nowrap' })}

  margin-top: -16px;
  & > * {
    margin-top: 16px;
  }
`;

export const SocialGroup = styled.div`
  width: calc(100% - 10px);
  padding-top: 64px;
  ${flex({ jc: 'center', ai: 'center' })}

  margin-top: -40px;
  margin-left: -10px;
  & > * {
    margin-top: 40px;
    margin-left: 40px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    margin-top: -16px;
    margin-left: -12px;
    & > * {
      margin-top: 16px;
      margin-left: 24px;
    }
  }
`;
