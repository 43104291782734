import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client } from '../../../api/client';
import {
  IAddCredentials,
  IChangePass,
  IConfirmCredentials,
  IResetCredentials,
} from '../../../types';
import { ProviderProps } from '../../types';
import { useUserData } from '../UserData';
import { defaultUser } from '../UserData/defaults';
import { IUserData, IUserUpdateInfo } from '../UserData/interfaces';
import { IUserMethodsCtx } from './interfaces';

export const UserMethodsCtx = createContext<IUserMethodsCtx>({} as IUserMethodsCtx);

export const useUserMethods = () => useContext(UserMethodsCtx);

export const UserMethodsProvider: React.FC<ProviderProps> = ({ children }) => {
  const navTo = useNavigate();
  const client = new Client();
  const {
    setData,
    setAdmin,
    setAddCredentialsStatus,
    setAddCredentialsError,
    setResetPassError,
    setResetPassStatus,
    setConfirmPassError,
    setConfirmPassStatus,
    setChangePassError,
    setChangePassStatus,
    setGetInfoStatus,
    setUpdateInfoStatus,
  } = useUserData();

  const loadUserData = async () => {
    const me = await client.GET_ME();
    setData(me.data);
    setAdmin(me.data?.roles?.includes('admin'));
    localStorage.setItem('user_doc_id', me.data._id);
  };

  const setRequestsBalance = (requestsBalance: number) => {
    setData((prev: IUserData) => ({ ...prev, requestsBalance }));
  };

  const addCredentials = async (credentials: IAddCredentials) => {
    setAddCredentialsStatus('loading');
    const response = await client.ADD_CREDENTIALS(credentials);

    if (!response.ok) {
      setAddCredentialsError(response.errorMessage);
      return setAddCredentialsStatus('error');
    }

    setAddCredentialsStatus('success');
    loadUserData();
  };

  const resetPass = async (resetCredentials: IResetCredentials) => {
    setResetPassError(undefined);
    setResetPassStatus('loading');
    const response = await client.RESET_PASS(resetCredentials);
    if (!response.ok) {
      setResetPassStatus('error');
      setResetPassError(response.errorMessage || "The account doesn't exist");
      return;
    }
    setResetPassStatus('success');
  };

  const confirmPass = async (
    confirmCredentials: IConfirmCredentials,
    token: string
  ) => {
    setConfirmPassError(undefined);
    setConfirmPassStatus('loading');
    const response = await client.CONFIRM_NEW_PASS(confirmCredentials, token);
    if (!response.ok) {
      setConfirmPassStatus('error');
      setConfirmPassError(
        response.errorMessage || 'Error during confirming new password'
      );
      return;
    }
    navTo('..');
    setConfirmPassStatus('success');
  };

  const changePass = async (changePassData: IChangePass) => {
    setChangePassError(undefined);
    setChangePassStatus('loading');
    const response = await client.UPDATE_PASS(changePassData);
    if (!response.ok) {
      setChangePassStatus('error');
      setChangePassError(
        response.errorMessage || 'Error during changing new password'
      );
      return;
    }
    setChangePassStatus('success');
  };

  const getInfo = async () => {
    setGetInfoStatus('loading');
    const response = await client.GET_INFO();

    if (!response.ok) return setGetInfoStatus('error');

    setData((prev: IUserData) => ({ ...prev, info: response.data }));
    setGetInfoStatus('success');
  };

  const updateInfo = async (updateInfoData: IUserUpdateInfo) => {
    setUpdateInfoStatus('loading');
    const response = await client.UPDATE_INFO(updateInfoData);

    if (!response.ok) return setUpdateInfoStatus('error');

    setData((prev) => ({ ...prev, info: response.data }));
    setUpdateInfoStatus('success');
  };

  const toggleRenewReqs = async (toggleData: { renewReqs: boolean }) => {
    await client.TOGGLE_RENEW_REQS(toggleData);
  };

  const resetData = () => {
    setData(defaultUser);
  };

  return (
    <UserMethodsCtx.Provider
      value={{
        loadUserData,
        setRequestsBalance,
        addCredentials,
        resetPass,
        confirmPass,
        changePass,
        getInfo,
        updateInfo,
        toggleRenewReqs,
        resetData,
      }}
    >
      {children}
    </UserMethodsCtx.Provider>
  );
};
