import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const LoadBarCnt = styled.div`
  height: 3px;
  width: 100%;
  padding: 0 1px;
  position: absolute;
  top: calc(100% - 3px);
  ${flex({})}
  overflow-x: hidden;
`;

export const LoadBarLoaded = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colours.primary};

  transform-origin: left;
  transform: scaleX(0);
  transition: all 300ms ease-in-out;
`;
