import styled, { css } from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';

const lgFont = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
`;

const smFont = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

// Top & Request value containers
export const Cnt = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 40px;
  z-index: 10;
  background-color: ${colours.gray};
  display: flex;
  cursor: pointer;

  position: relative; // for tooltip

  & > div:first-of-type {
    flex-grow: 1;
    ${flex({ jc: 'flex-end', ai: 'center' })}
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    &.opened {
      margin-top: 14px;
    }

    &.closed {
      margin-top: 0;
      justify-content: flex-end;
      gap: 4px;
    }
  }

  @media screen and (max-width: 340px) {
    &.closed {
      display: none;
    }
  }
`;

export const ValueCnt = styled.div`
  padding: 11px 18px;
  border: 1px solid ${colours.lightGray};
  border-radius: 4px 0 0 4px;
  border-right-color: transparent;
  flex-grow: 1;
  ${lgFont}
  text-align: right;

  &:hover {
    border-color: ${colours.primary};
    color: ${colours.white};
    & > * {
      color: ${colours.primary};
    }
  }

  span {
    // number (of requests)
    &:first-of-type {
      color: ${colours.fontDark};
    }
    // units (req.)
    &:last-of-type {
      margin-left: 4px;
      color: ${colours.muted};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    &.opened {
      flex-grow: 1;
      justify-content: center;
      text-align: center;
    }
    &.closed {
      padding: 0;
      border: none;
      flex-grow: 0;
      ${smFont}
      &:hover {
        padding-left: 0;
        border-right: none;
        span {
          color: ${colours.primaryDark};
        }
      }
    }
  }
`;

// Buy more button
export const Btn = styled.button`
  width: 103px;
  padding: 0 0 1px 1px; // # pp
  border: none;
  border: 1px solid ${colours.lightGray};
  border-radius: 0 4px 4px 0;

  ${lgFont}
  color: ${colours.primary};
  background: transparent;
  cursor: inherit;

  svg {
    display: none;
  }

  &.active,
  &:hover {
    border-color: ${colours.primary};
    color: ${colours.white};
    background-color: ${colours.primary};
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    &.opened {
      flex-grow: 1;
    }
    &.closed {
      width: unset;
      border: none;
      span {
        display: none;
      }
      svg {
        display: block;
      }
      &.active,
      &:hover {
        background-color: transparent;
        path {
          stroke: ${colours.primaryDark};
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    &.opened {
      flex-grow: unset;
    }
  }
`;
