import React from 'react';

interface Props {
  path: string
}

export const ControlImage = (props: Props) => {
  const { path } = props;
  const imgSrc = path.replace('/mnt/exchange', 'https://app.exbot.ai/content');

  return (
    <img src={imgSrc} style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>
  );
};
