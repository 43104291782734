import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
  StripeElementStyleVariant,
} from '@stripe/stripe-js';
import { colours } from '../../../../../styles/variables';

// Common styles
const baseStyles: StripeElementStyleVariant = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.44px',
  color: colours.fontDark,
  '::placeholder': {
    color: colours.muted,
  },
  ':disabled': {
    color: colours.lighterGray,
  },
};
const invalidStyles: StripeElementStyleVariant = {
  color: colours.danger2,
};
const styles = {
  base: baseStyles,
  invalid: invalidStyles,
};

// Specific styles
export const cardNumberOptions: StripeCardNumberElementOptions = {
  style: styles,
  placeholder: 'Card number',
};
export const cardExpiryOptions: StripeCardExpiryElementOptions = {
  style: styles,
};
export const cardCvcOptions: StripeCardCvcElementOptions = {
  style: styles,
};
