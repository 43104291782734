import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { validateNewPass, validateRepPass } from '../../../tools/validation';
import { FloatingLabelInput } from '../../../components/inputFields/FloatingLabelInput';
import { BtnCnt, FieldGroup, ResetHeading } from '../styledComponents';
import { IConfirmFormik } from '../../../types';
import { getEncodedUrlByKey } from '../../../tools/urls/getEncodedUrl';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';
import { useUserData } from '../../../context/user/UserData';
import { useUserMethods } from '../../../context/user/UserMethods';

export const ConfirmNewPassFormik = (): JSX.Element => {
  const { confirmPassStatus, setConfirmPassStatus, confirmPassError } =
    useUserData();
  const { confirmPass } = useUserMethods();
  const location = useLocation();
  const token = getEncodedUrlByKey(location.search, 'token') || '';
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{ newPassword: '', repNewPassword: '' }}
      onSubmit={async (values) => confirmPass(values, token)}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(formikProps: FormikProps<IConfirmFormik>) => {
        const newPasswordMeta = formikProps.getFieldMeta('newPassword');
        const newPasswordHelpers = formikProps.getFieldHelpers('newPassword');
        const repNewPasswordMeta = formikProps.getFieldMeta('repNewPassword');

        useEffect(() => {
          if (confirmPassStatus === 'error') {
            newPasswordHelpers.setError(confirmPassError);
          }
        }, [confirmPassStatus]);
        useEffect(() => {
          setConfirmPassStatus(undefined);
        }, [formikProps.values]);

        return (
          <Form>
            <header>
              <ResetHeading>Confirm new password</ResetHeading>
            </header>
            <FieldGroup>
              <FloatingLabelInput
                type="password"
                autoComplete="new-password"
                name="newPassword"
                placeholder="New password"
                validate={validateNewPass}
                classProp={!newPasswordMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="off"
                name="repNewPassword"
                placeholder="Repeat new password"
                validate={(value: string) =>
                  validateRepPass(value, formikProps.values.newPassword)
                }
                classProp={!repNewPasswordMeta.error}
              />
            </FieldGroup>
            <BtnCnt>
              <SubmitBtn
                values={formikProps.values}
                errors={formikProps.errors}
                setValidateOnChange={setValidateOnChange}
                status={confirmPassStatus}
              >
                Confirm
              </SubmitBtn>
            </BtnCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
