import { IModifiedTaskReport } from '../TaskData/interfaces';

interface IExample {
  exampleId: string;
  url: string;
  data: IModifiedTaskReport;
}

export const analyzeMockups: IExample[] = [
  {
    exampleId: '634f873beb61f8d0e622684a',
    url: 'https://www.amazon.com/2022-Apple-MacBook-Laptop-chip/dp/B0B3C57XLR/ref=sr_1_1?crid=Y2J75G0C4ORD&keywords=apple+macbook+pro&qid=1661216154&sprefix=Apple+Macbook+Pro%2Caps%2C323&sr=8-1',
    data: {
      url: 'https://www.amazon.com/2022-Apple-MacBook-Laptop-chip/dp/B0B3C57XLR/ref=sr_1_1?crid=Y2J75G0C4ORD&keywords=apple+macbook+pro&qid=1661216154&sprefix=Apple+Macbook+Pro%2Caps%2C323&sr=8-1',
      images: [
        'https://m.media-amazon.com/images/I/61L5QgPvgqL._AC_SL1500_.jpg',
      ],
      title:
        '2022 Apple MacBook Pro Laptop with M2 chip: 13-inch Retina Display, 8GB RAM, 256GB ​​​​​​​SSD ​​​​​​​Storage, Touch Bar, Backlit Keyboard, FaceTime HD Camera. Works with iPhone and iPad; Space Gray',
      category: '',
      offers: { amount: 119900, currency: 'USD' },
      metaTags: [],
      description: '',
      headlessTableRows: [
        [
          'features',
          [
            'SUPERCHARGED BY M2 — The 13-inch MacBook Pro laptop is a portable powerhouse. Get more done faster with a next-generation 8-core CPU, 10-core GPU and up to 24GB of unified memory.',
            'UP TO 20 HOURS OF BATTERY LIFE — Go all day and into the night, thanks to the power-efficient performance of the Apple M2 chip.',
            'SUSTAINED PERFORMANCE — Thanks to its active cooling system, the 13-inch MacBook Pro can sustain pro levels of performance, so you can run CPU- and GPU-intensive tasks for hours on end.',
            'BRILLIANT DISPLAY — The 13.3-inch Retina display features 500 nits of brightness and P3 wide colour for vibrant images and incredible detail.',
            'HD CAMERA AND STUDIO-QUALITY MICS — Look sharp and sound great with a FaceTime HD camera and three-mic array.',
            'VERSATILE CONNECTIVITY — Two Thunderbolt ports let you connect and power high-speed accessories.',
            'EASY TO USE — Your Mac feels familiar from the moment you turn it on, and it works seamlessly with all your Apple devices.',
          ],
        ],
      ],
      tablessWithHead: [
        {
          name: 'details',
          rows: [
            'Brand Apple',
            'Model Name MacBook Pro',
            'Screen Size 13.3 Inches',
            'Color Space Gray',
            'Hard Disk Size 256 GB',
            'CPU Model Unknown',
            'Ram Memory Installed Size 8 GB',
            'Operating System Mac OS',
            'Graphics Card Description Integrated',
            'Graphics Coprocessor Apple M2',
          ],
        },
      ],
    },
  },
  {
    exampleId: '634f873beb61f8d0e622684b',
    url: 'https://www.amazon.com/Sony-WH-1000XM5-Canceling-Headphones-Hands-Free/dp/B09XS7JWHH/ref=sr_1_1?crid=2OS7DUWK6FEZT&keywords=sony+wh-1000xm5&qid=1661554306&sprefix=Sony+WH-1000XM5%2Caps%2C332&sr=8-1',
    data: {
      url: 'https://www.amazon.com/Sony-WH-1000XM5-Canceling-Headphones-Hands-Free/dp/B09XS7JWHH/ref=sr_1_1?crid=2OS7DUWK6FEZT&keywords=sony+wh-1000xm5&qid=1661554306&sprefix=Sony+WH-1000XM5%2Caps%2C332&sr=8-1',
      images: [
        'https://m.media-amazon.com/images/I/61+btxzpfDL._AC_SL1500_.jpg',
      ],
      title:
        'Sony WH-1000XM5 Wireless Industry Leading Noise Canceling Headphones with Auto Noise Canceling Optimizer, Crystal Clear Hands-Free Calling, and Alexa Voice Control, Black',
      category:
        'Electronics › Headphones, Earbuds & Accessories › Headphones & Earbuds › Over-Ear Headphones',
      offers: { amount: 39800, currency: 'USD' },
      metaTags: [],
      description: '',
      headlessTableRows: [
        [
          'features',
          [
            'Industry Leading noise cancellation-two processors control 8 microphones for unprecedented noise cancellation. With Auto NC Optimizer, noise canceling is automatically optimized based on your wearing conditions and environment.',
            'Magnificent Sound, engineered to perfection with the new Integrated Processor V1',
            'Crystal clear hands-free calling with 4 beamforming microphones, precise voice pickup, and advanced audio signal processing.',
            'Up to 30-hour battery life with quick charging (3 min charge for 3 hours of playback)',
            'Ultra-comfortable, lightweight design with soft fit leather',
            'Multipoint connection allows you to quickly switch between devices',
            'Carry your headphones effortlessly in the redesigned case.',
          ],
        ],
      ],
      tablessWithHead: [
        {
          name: 'details',
          rows: [
            'Form Factor Over Ear',
            'Material Leather, Plastic, Metal',
            'Connectivity Technology Wireless',
            'Age Range (Description) Adult',
            'Brand Sony',
          ],
        },
      ],
    },
  },
];

export const findMockup = (filter: {
  url?: string;
  exampleId?: string;
}): IExample | undefined => {
  const { url, exampleId } = filter;

  if (url) {
    return analyzeMockups.find((mockup) => mockup.url === url);
  }

  if (exampleId) {
    return analyzeMockups.find((mockup) => mockup.exampleId === exampleId);
  }
};
