import { css } from 'styled-components';
import { colours } from './variables';

// Reset button default styles
export const resetBtn = css`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

// Reset link default styles
export const resetLink = css`
  text-decoration: none;
  color: ${colours.fontDark};
`;

// Remove scrollbar
export const removeScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

// Reset input background colour (blue-ish)
export const resetInputBgColor = css`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px ${colours.white} inset !important;
  }
`;

// Reset number input
export const resetNumInput = css`
  &[type='number']::placeholder {
    color: ${colours.fontDark};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: unset;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  -moz-appearance: textfield;
`;

// Default border styles
export const defaultBorder = css`
  border: 1px solid ${colours.lightGray};
  border-radius: 8px;
`;
