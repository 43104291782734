import React from 'react';

export const Exbot = () => {
  return (
    <svg
      width='3592'
      height='640'
      viewBox='0 0 3592 640'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3007 35C2987.67 35 2972 50.67 2972 70C2972 89.33 2987.67 105 3007 105H3247L3247 570C3247 589.33 3262.67 605 3282 605C3301.33 605 3317 589.33 3317 570L3317 105H3557C3576.33 105 3592 89.33 3592 70C3592 50.67 3576.33 35 3557 35H3282H3007Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2575 70C2436.93 70 2325 181.929 2325 320C2325 458.071 2436.93 570 2575 570C2713.07 570 2825 458.071 2825 320C2825 181.929 2713.07 70 2575 70ZM2255 320C2255 143.269 2398.27 0 2575 0C2751.73 0 2895 143.269 2895 320C2895 496.731 2751.73 640 2575 640C2398.27 640 2255 496.731 2255 320Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1620.11 35.0002L1620 35C1600.67 35 1585 50.67 1585 70L1585 570C1585 589.33 1600.67 605 1620 605L1620.11 605L1620.22 605H1756.22H1983.22H1992.22C1994.9 605 1997.51 604.699 2000.02 604.129C2080.49 595.736 2143.22 527.694 2143.22 445C2143.22 372.765 2095.36 311.711 2029.61 291.827C2050.07 264.946 2062.22 231.392 2062.22 195C2062.22 107.889 1992.61 37.0375 1905.98 35.0432C1905.4 35.0145 1904.81 35 1904.22 35H1902.22H1675.22H1620.22L1620.11 35.0002ZM1675.22 105H1655L1655 285H1675.22H1756.22H1902.22C1951.93 285 1992.22 244.706 1992.22 195C1992.22 145.294 1951.93 105 1902.22 105H1675.22ZM1675.22 355H1655L1655 535H1756.22H1983.22C2032.93 535 2073.22 494.706 2073.22 445C2073.22 395.294 2032.93 355 1983.22 355H1902.22H1756.22H1675.22Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1197.25 250.717C1183.42 264.224 1161.26 263.964 1147.75 250.136C1134.25 236.308 1134.51 214.149 1148.33 200.642L1343.54 9.96251C1357.37 -3.54448 1379.53 -3.28439 1393.04 10.5434C1406.54 24.3713 1406.28 46.5305 1392.46 60.0375L1197.25 250.717ZM1197.25 389.282C1183.42 375.775 1161.26 376.035 1147.75 389.863C1134.25 403.691 1134.51 425.85 1148.33 439.357L1343.54 630.036C1357.37 643.543 1379.53 643.283 1393.04 629.455C1406.54 615.628 1406.28 593.468 1392.46 579.961L1197.25 389.282ZM1009.25 389.863C995.739 376.035 973.58 375.775 959.752 389.282L764.544 579.961C750.716 593.468 750.456 615.628 763.963 629.455C777.47 643.283 799.629 643.543 813.457 630.036L1008.67 439.357C1022.49 425.85 1022.75 403.691 1009.25 389.863ZM959.752 250.717C973.58 264.224 995.739 263.964 1009.25 250.136C1022.75 236.308 1022.49 214.149 1008.67 200.642L813.457 9.96251C799.629 -3.54448 777.47 -3.28439 763.963 10.5434C750.456 24.3713 750.716 46.5305 764.544 60.0375L959.752 250.717Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35 35C15.67 35 0 50.67 0 70L1.16914e-05 319.971C3.89765e-06 319.981 0 319.99 0 320C0 320.01 3.89855e-06 320.019 1.16942e-05 320.029L2.33837e-05 569.959C7.79596e-06 569.973 0 569.986 0 570C0 574.833 0.979377 579.436 2.75047 583.624C8.06376 596.186 20.5025 605 35 605H495C514.33 605 530 589.33 530 570C530 550.67 514.33 535 495 535H70L70 355H383.934C403.264 355 418.934 339.33 418.934 320C418.934 300.67 403.264 285 383.934 285H70L70 105H495C514.33 105 530 89.33 530 70C530 50.67 514.33 35 495 35H35Z'
      />
    </svg>
  );
};
