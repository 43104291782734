import React from 'react';
import Badges from '../../../components/badges';
import copyWithNotification from '../../../tools/copyWithNotification';
import { LogsBadge } from './LogsBadge';
import { HeaderCnt, MetaCnt } from './styledComponents';
import { TimingsBadge } from './TimingsBadge';

interface Props {
  taskId: string;
  startedAt: number;
  bugReported: boolean | undefined;
}

export const Header = (props: Props) => {
  const { taskId, startedAt, bugReported } = props;

  return (
    <HeaderCnt>
      <MetaCnt>
        <Badges.Info
          handleClick={() => copyWithNotification(taskId, 'Task id copied')}
        >
          {taskId}
        </Badges.Info>

        <LogsBadge taskId={taskId} startedAt={startedAt} />

        <TimingsBadge taskId={taskId} startedAt={startedAt} />

        {bugReported && <Badges.Info style="error">reported</Badges.Info>}
      </MetaCnt>
    </HeaderCnt>
  );
};
