import styled from 'styled-components';
import { dash, rotate360, spin } from '../../../styles/keyframes';
import { flex } from '../../../styles/mixins';
import { Button } from '../Button';

export const BtnCnt = styled(Button)`
  ${flex({ ai: 'center' })}
  position: relative;

  svg {
    height: 24px;
    position: absolute;
    right: calc(100% + 16px);
  }

  &.right {
    svg {
      left: calc(100% + 16px);
    }
  }

  svg[name='spinner'] {
    width: 24px;
    animation: ${rotate360} 1000ms ease-in-out infinite;
    circle {
      stroke-linecap: round;
      animation: ${spin} 1000ms ease-in-out infinite;
    }
  }

  svg[name='loading-ok'] {
    width: 30px;
    path {
      stroke-dasharray: 40;
      animation: ${dash} 1000ms ease-in-out infinite;
    }
  }
`;
