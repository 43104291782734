import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toUpperCaseFirstChar } from '../../../tools/formatString';
import { SelectCnt, SelectWrapper } from './styledComponents';
import dropdownIcon from '../../../assets/icons/down-arrow-dropdown.svg';
import DropdownStyled from './Dropdown.styled';

export interface ISelected {
  name: string;
  value: any;
}

interface Props {
  selected: ISelected;
  setSelected: Dispatch<SetStateAction<any>>;
  options: ISelected[];
  handleChange?: (arg?: any) => void;
}

export const Select = (props: Props) => {
  const { selected, options, setSelected, handleChange } = props;
  const [isFocused, setFocused] = useState(false);

  const handleClick = (selectedArg: ISelected) => {
    if (selectedArg !== selected) setSelected(selectedArg);
    setFocused(false);
  };

  useEffect(() => {
    if (handleChange) handleChange();
  }, [selected]);

  return (
    <SelectWrapper>
      <div
        onMouseOver={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        onTouchEnd={() => setFocused(!isFocused)}
        onScrollCapture={() => setFocused(false)}
        style={{ position: 'relative' }}
      >
        <SelectCnt
          className={isFocused ? 'focused' : ''}
          defaultValue={selected.name}
        >
          <span title={selected.name}>
            {toUpperCaseFirstChar(selected.name)}
          </span>
          <img src={dropdownIcon} alt="" />
        </SelectCnt>
        {isFocused && (
          <DropdownStyled>
            {options.map((option: ISelected, ind) => (
              <span
                title={option.name}
                key={ind}
                onClick={() => handleClick(option)}
                onTouchEnd={() => handleClick(option)}
              >
                {toUpperCaseFirstChar(option.name)}
              </span>
            ))}
          </DropdownStyled>
        )}
      </div>
    </SelectWrapper>
  );
};
