import React from 'react';
import {
  Label,
  InputBlock,
  InputsCnt,
  NumberInputBlock,
  Text,
  Heading,
  Subheading,
  ResetBtn,
  BtnGroup,
  Br,
  WebhooksInputBlock,
} from './styledComponents';
import { ToggleSwitch } from '../../../../components/inputFields/ToggleSwitch';
import { NumberInput } from '../../../../components/inputFields/NumberInput';
import { useUI } from '../../../../context/UICtx';
import { Link } from '../../../../components/links/Link';
import { useUserData } from '../../../../context/user/UserData';
import { useRequestData } from '../../../../context/extraction/RequestData';
import { NativeFloatingLabelInput } from '../../../../components/inputFields/NativeFloatingLabelInput';

export const SettingsForm = () => {
  const { query, setQuery, webhooks, setWebhooks, resetData } =
    useRequestData();
  const { data: user } = useUserData();
  const { setConfigTimeout } = useUI().configsTimeout;

  const webhookProps = {
    validate: (value: string) => {
      if (value.includes('http://') || value.includes('https://')) {
        return;
      }
      return 'Valid link must include "http" or "https"';
    },
    labelCustomStyles: { left: 0 },
    inputCustomStyles: { paddingLeft: 0 },
    feedbackCustomStyles: {
      marginLeft: 0,
      marginTop: '8px',
      position: 'relative',
    },
  };

  const handleChange = (e: any) => {
    setQuery((prev) => ({
      ...prev,
      [e.target.value]:
        !prev[
          e.target.value as 'slowScrolling' | 'enableProxies' | 'autoPaginate'
        ],
    }));
  };
  const handleChangeTimeout = (val: number) => {
    setQuery((prev) => ({ ...prev, timeout: val }));
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Heading>Settings</Heading>
      <Subheading>
        Tweak how Goless renders and extracts pages. See&nbsp;
        <Link href="" target="_blank">
          API documentation
        </Link>
        .
      </Subheading>
      <InputsCnt>
        <InputBlock>
          <ToggleSwitch
            value="enableProxies"
            handleChange={handleChange}
            isChecked={query.enableProxies}
          />
          <div>
            <Label>Enable Proxies</Label>
            <Text>
              +1 Credit. More consistent extraction <Br />
              from medium duty sites.
            </Text>
          </div>
        </InputBlock>
        <InputBlock>
          <ToggleSwitch
            value="slowScrolling"
            handleChange={handleChange}
            isChecked={query.slowScrolling}
          />
          <div>
            <Label>Slow Scrolling</Label>
            <Text>
              Helpful for extracting content <Br />
              that loads dynamically on scroll.
            </Text>
          </div>
        </InputBlock>
        <InputBlock>
          <ToggleSwitch
            value="autoPaginate"
            handleChange={handleChange}
            isChecked={query.autoPaginate}
          />
          <div>
            <Label>Auto Paginate Articles</Label>
            <Text>
              +1 Credit Per Concatenated Page. Paginate <Br />
              and completely extract multi-page articles.
            </Text>
          </div>
        </InputBlock>
        <NumberInputBlock>
          <NumberInput
            name="timeout"
            timeout={query.timeout}
            setValue={handleChangeTimeout}
          />
          <Text>
            Maximum time (in milliseconds) to wait for a page to load.
          </Text>
        </NumberInputBlock>
        <WebhooksInputBlock>
          <Label>Webhooks</Label>
          <NativeFloatingLabelInput
            {...webhookProps}
            type="url"
            autoComplete="url"
            value={webhooks.success}
            setValue={(value: string) =>
              setWebhooks((prev) => ({ ...prev, success: value }))
            }
            name="success"
            placeholder="Success"
          />
          <NativeFloatingLabelInput
            {...webhookProps}
            type="url"
            autoComplete="url"
            value={webhooks.failure}
            setValue={(value: string) =>
              setWebhooks((prev) => ({ ...prev, failure: value }))
            }
            name="failure"
            placeholder="Failure"
          />
        </WebhooksInputBlock>
      </InputsCnt>
      <BtnGroup>
        <ResetBtn
          onClick={() => {
            resetData();
            setConfigTimeout(user.configs.timeout);
          }}
        >
          Reset
        </ResetBtn>
      </BtnGroup>
    </form>
  );
};
