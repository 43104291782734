import React, { useEffect } from 'react';
import { Empty } from '../../../../components/Empty';
import Loadings from '../../../../components/loadings';
import { useHistory } from '../../../../context/extraction/History';
import { useExtractionPagination } from '../../../../context/extraction/Pagination';
import { historyMapper } from '../../../../tools/mappers';
import { Wrapper } from '../styles';
import { HistoryTable } from './HistoryTable';
import { ProcessingTasksTable } from './ProcessingTasksTable';

export const Table: React.FC = () => {
  const { getHistory, historyStatus, historyData, processingTasks } =
    useHistory();
  const { page } = useExtractionPagination();

  useEffect(() => {
    getHistory();
  }, [page]);

  const switcher = {
    success:
      historyData && (historyData?.length !== 0 || !processingTasks?.length) ? (
        <HistoryTable data={historyMapper(historyData)} />
      ) : (
        <Wrapper>
          <Empty name="history" />
        </Wrapper>
      ),
    error: (
      <Wrapper>
        <Empty name="history" />
      </Wrapper>
    ),
    loading: (
      <Wrapper>
        <Loadings.History />
      </Wrapper>
    ),
    undefined: (
      <Wrapper>
        <Loadings.History />
      </Wrapper>
    ),
  };

  return (
    <div>
      {processingTasks && processingTasks?.length !== 0 && (
        <ProcessingTasksTable data={historyMapper(processingTasks)} />
      )}
      {switcher[`${historyStatus}`]}
    </div>
  );
};
