import React, { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import {
  validateFullname,
  validateEmail,
  validateNewPass,
  validateRepPass,
} from '../../../tools/validation';
import { FloatingLabelInput } from '../../../components/inputFields/FloatingLabelInput';
import { BtnCnt, FieldGroup } from '../styledComponents';
import { useAuth } from '../../../context/AuthCtx';
import { ISignupFormik } from '../../../types';
import { SubmitBtn } from '../../../components/buttons/SubmitBtn';

export const SignupFormik = () => {
  const auth = useAuth();
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);
  const [isSmallLinkDisplayed, setIsSmallLinkDisplayed] =
    useState<boolean>(false); // 'Log in' link

  return (
    <Formik
      initialValues={{ fullName: '', email: '', password: '', repPassword: '' }}
      onSubmit={async (values, actions) => {
        const { fullName, email, password } = values;
        auth.registerCredentials({ fullName, email, password }, actions, () =>
          setIsSmallLinkDisplayed(true)
        );
      }}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISignupFormik>) => {
        const fullNameMeta = props.getFieldMeta('fullName');
        const emailMeta = props.getFieldMeta('email');
        const passMeta = props.getFieldMeta('password');
        const repPasswordMeta = props.getFieldMeta('repPassword');

        return (
          <Form>
            <FieldGroup>
              <FloatingLabelInput
                type="text"
                autoComplete="name"
                name="fullName"
                placeholder="Full name"
                validate={validateFullname}
                classProp={!fullNameMeta.error}
              />
              <FloatingLabelInput
                type="email"
                autoComplete="email"
                name="email"
                placeholder="E-mail"
                validate={(value: string) => {
                  setIsSmallLinkDisplayed(false);
                  return validateEmail(value);
                }}
                classProp={!emailMeta.error}
                showBtn={isSmallLinkDisplayed}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="new-password"
                name="password"
                placeholder="Password"
                validate={validateNewPass}
                classProp={!passMeta.error}
              />
              <FloatingLabelInput
                type="password"
                autoComplete="off"
                name="repPassword"
                placeholder="Repeat password"
                validate={(value: string) =>
                  validateRepPass(value, props.values.password)
                }
                classProp={!repPasswordMeta.error}
              />
            </FieldGroup>
            <BtnCnt>
              <SubmitBtn
                values={props.values}
                errors={props.errors}
                setValidateOnChange={setValidateOnChange}
              >
                Sign up
              </SubmitBtn>
            </BtnCnt>
          </Form>
        );
      }}
    </Formik>
  );
};
