import React, { useState } from 'react';
import { MainHeader, MainHeading } from './styledComponents';
import caretIcon from '../../../assets/icons/caret.svg';
import { Visual } from '../Visual';
import { Controls } from '../../../features/Controls';
import { IControl } from '../../../features/Controls/interfaces/control.interface';
import { ClassFeedbackBtn } from '../../../features/ClassFeedbackBtn';

const checkboxes = ['wrong field data', 'invalid data type', 'empty field'];

interface Props {
  name: string;
  rows: (string | [string, string | string[]] | IControl)[];
}

export const VisualWithHeader: React.FC<Props> = ({ name = '', rows = [] }) => {
  const [isOpen, setOpen] = useState(true);

  const switcher = {
    features: <Visual isOpen={isOpen} rows={rows as (string | [string, string | string[]])[]} />,
    details: <Visual isOpen={isOpen} rows={rows as (string | [string, string | string[]])[]} />,
    controls: <Controls isOpen={isOpen} rows={rows as IControl[]} />,
  } as const;

  const nameFormatted = name.toLowerCase() as keyof typeof switcher;

  return (
    <div>
      <MainHeader onClick={() => setOpen(!isOpen)}>
        <MainHeading>{name}</MainHeading>
        <img
          className={!isOpen ? 'isTurned' : ''}
          src={caretIcon}
          alt="dropdown"
        />
        <ClassFeedbackBtn
          isShown
          classType={nameFormatted}
          checkboxes={checkboxes}
        />
      </MainHeader>
      {switcher[nameFormatted]}
    </div>
  );
};
