/* eslint-disable camelcase */
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { colours } from '../../styles/variables';
import { IChartErrorsData } from './interfaces/chart-errors-data.interface';

export default (
  rootId: string,
  data: IChartErrorsData[],
  isLoading: boolean,
  updateErrorFieldsChecked?: (checkbox: string) => void
) => {
  // base
  const root = am5.Root.new(rootId);

  const customTheme = am5.Theme.new(root);
  customTheme
    .rule('ColorSet')
    .set('colors', [
      am5.color(colours.primary),
      am5.color(colours.danger),
      am5.color(colours.warn),
      am5.color('#e6ebff'),
      am5.color(colours.danger3),
      am5.color(colours.primaryDark),
    ]);

  root.setThemes([am5themes_Animated.new(root), customTheme]);

  const chart = root.container.children.push(
    am5percent.PieChart.new(root, { layout: root.horizontalLayout })
  );

  const createSeries = () => {
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'errorType',
        legendLabelText: '{category}',
        tooltip: am5.Tooltip.new(root, {
          keepTargetHover: true,
          tooltipText: "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})",
        }),
      })
    );

    // events
    series.slices.template.events.on('click', (ev) => {
      series.slices.each((slice) => {
        if (slice !== ev.target && slice.get('active')) {
          slice.set('active', false);
        }
        if (slice === ev.target) {
          slice.dataItem?.hide();
          slice.set('active', true);
        }
      });
    });

    // legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        y: am5.percent(10),
        x: am5.percent(70),
        layout: root.verticalLayout,
        draggable: true,
      })
    );

    try {
      legend.itemContainers.template.events.on('click', ({ target }) => {
        if (updateErrorFieldsChecked) {
          const { errorType } = (target.dataItem?.dataContext as any).dataContext;
          updateErrorFieldsChecked(errorType);
        }
      });
    } catch (error) {
      console.error(error);
    }

    series.data.setAll(data);
    legend.data.setAll(series.dataItems);

    // labels
    series.labels.template.set('visible', false);
    series.labels.template.set('forceHidden', true);
    series.ticks.template.set('visible', false);
    series.ticks.template.set('forceHidden', true);
  };

  createSeries();

  // loading state
  const indicator = root.container.children.push(
    am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layer: 1000,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0xffffff),
        fillOpacity: 0.75,
      }),
    })
  );

  indicator.children.push(
    am5.Label.new(root, {
      text: 'Loading...',
      fontSize: 24,
      fontFamily: 'Roboto',
      fill: am5.color(colours.fontDark),
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
    })
  );

  if (isLoading) {
    indicator.show();
  } else {
    indicator.hide();
  }

  return root;
};
