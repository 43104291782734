import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const User = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <path
        d="M14.5 13.5703C14.5002 13.4094 14.4616 13.2509 14.3874 13.1081C14.3132 12.9653 14.2057 12.8426 14.074 12.7503C12.2699 11.582 10.1474 11.0033 8 11.0943C5.85262 11.0033 3.73005 11.582 1.926 12.7503C1.79427 12.8426 1.68677 12.9653 1.61261 13.1081C1.53844 13.2509 1.49982 13.4094 1.5 13.5703V16.0943H14.5V13.5703Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_929_2062)">
        <path
          d="M8 8.09473C9.933 8.09473 11.5 6.52772 11.5 4.59473C11.5 2.66173 9.933 1.09473 8 1.09473C6.067 1.09473 4.5 2.66173 4.5 4.59473C4.5 6.52772 6.067 8.09473 8 8.09473Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_929_2062">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.594727)"
          />
        </clipPath>
      </defs>
    </SvgCnt>
  );
};
