import React, { useEffect } from 'react';
import { Heading } from '../../../components/headings/Heading';
import { Cnt } from '../styledComponents';
import Tables from '../../../components/tables';
import { BadgesCnt, Header, ReportLink } from './styledComponents';
import * as ShowsMore from '../../../components/showMores/Table';
import { Empty } from '../../../components/Empty';
import Badges from '../../../components/badges';
import { Section } from '../../../components/containers/Section';
import Loadings from '../../../components/loadings';
import { useStats } from '../../../context/extraction/Stats';
import toHhMmDdMmYyyy from '../../../tools/dates/toHhMmDdMmYyyy';
import Svgs from '../../../components/svgs';
import { useUserData } from '../../../context/user/UserData';

export const ApiSettingsPage = () => {
  const { apiToken, apiTokenUpdatedAt } = useUserData().data;
  const { getStats, statsData, statsStatus } = useStats();

  useEffect(() => {
    getStats();
  }, []);

  const switcher = {
    true: (
      <ShowsMore.Table
        data={statsData}
        fullReportUrl={'/'}
        TableComponent={Tables.RequestsHistory}
      />
    ),
    false: <Empty name="requests stats" />,
  };

  return (
    <Cnt>
      <Section>
        <Heading>API settings</Heading>
        <Tables.Token
          data={{
            apiToken: apiToken || '',
            apiTokenCreatedAt: apiTokenUpdatedAt
              ? toHhMmDdMmYyyy(apiTokenUpdatedAt)
              : '',
          }}
        />
      </Section>
      <Section>
        <Heading>API docs</Heading>
        <BadgesCnt>
          <Badges.DocsBadge
            text="Postman"
            url="https://documenter.getpostman.com/view/17616688/UzR1Li4G"
            Icon={Svgs.Postman}
          />
          <Badges.DocsBadge
            text="Github"
            url="https://github.com/golessapi"
            Icon={Svgs.Github}
          />
          <Badges.DocsBadge
            text="NPM"
            url="https://www.npmjs.com/~goless"
            Icon={Svgs.Npm}
          />
        </BadgesCnt>
      </Section>
      <Section>
        <Header>
          <Heading>Requests History</Heading>
          <ReportLink href="/" target="_blank">
            Download full report
          </ReportLink>
        </Header>
        {statsStatus === 'success' ? (
          switcher[`${statsData?.length !== 0}`]
        ) : (
          <Loadings.ThreeDots name="requests stats" />
        )}
      </Section>
    </Cnt>
  );
};
