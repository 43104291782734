import styled from 'styled-components';
import { flex, pos } from '../../styles/mixins';
import {
  resetBtn,
  resetInputBgColor,
  resetNumInput,
} from '../../styles/placeholders';
import { colours } from '../../styles/variables';

export const Cnt = styled.div`
  ${flex({ ai: 'center' })}
`;

export const ArrowBtn = styled.button`
  height: 16px;
  width: 16px;
  ${resetBtn}
  cursor: pointer;

  &.next {
    transform: rotate(180deg);
  }

  &:disabled {
    cursor: inherit;
    svg path {
      fill: ${colours.lightGray};
    }
  }

  &:not(:disabled):hover {
    cursor: pointer;
    svg path {
      fill: ${colours.primaryDark};
    }
  }
`;

export const NumCnt = styled.div`
  height: 18px;
  ${flex({ ai: 'center' })}
`;

export const NumInput = styled.input`
  border: none;
  width: 30px;

  outline-color: ${colours.gray};
  ${resetInputBgColor}
  ${resetNumInput}

  color: ${colours.fontDark};
  font-size: 16px;
  text-align: center;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    color: ${colours.primary};
  }
`;

export const TotalPages = styled.span`
  width: 30px;
  text-align: center;
  cursor: unset;
`;
