import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';

export const Wrapper = styled.tr`
  width: 100%;
  padding: 16px 0;
  ${flex({ jc: 'center' })}
`;

export const Cell = styled.td`
  width: 100% !important;
  ${flex({ jc: 'center' })}
`;
