import React, { useEffect, useState } from 'react';
import { LoadingWraper, TokenCell, TokenTable } from './styledComponents';
import { delay } from '../../../tools/dev';
import copyIcon from '../../../assets/icons/copy.svg';
import refreshIcon from '../../../assets/icons/refresh.svg';
import Loadings from '../../loadings';
import { useApiToken } from '../../../context/extraction/Token';
import { useUserMethods } from '../../../context/user/UserMethods';

interface Props {
  data: { apiToken: string; apiTokenCreatedAt: string };
}

export const Token = (props: Props) => {
  const { apiToken, apiTokenCreatedAt } = props.data;
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [rotated, setRotated] = useState<boolean>(false);
  const { rotateToken, rotateTokenStatus } = useApiToken();
  const { loadUserData } = useUserMethods();

  const click = async (mes: string) => {
    setMessage(mes);
    await delay(2000);
    setMessage(undefined);
  };

  useEffect(() => {
    if (rotated) {
      if (rotateTokenStatus === 'success') {
        loadUserData();
        click('rotated!');
        setRotated(false);
      }
      if (rotateTokenStatus === 'error') {
        click('error');
        setRotated(false);
      }
    }
  }, [rotateTokenStatus]);

  return (
    <TokenTable>
      <tbody>
        <tr>
          <th>API token</th>
          {apiToken ? (
            <TokenCell>
              <span className={message ? 'clicked' : ''}>
                {!message ? apiToken : message}
              </span>

              <div>
                <button
                  onClick={async () => {
                    rotateToken();
                    setRotated(true);
                  }}
                >
                  <img src={refreshIcon} alt="rotate" title="Rotate" />
                </button>
                <button
                  onClick={async () => {
                    navigator.clipboard.writeText(apiToken);
                    click('copied!');
                  }}
                >
                  <img
                    src={copyIcon}
                    alt="copy"
                    title="Copy to the clipboard"
                  />
                </button>
              </div>
            </TokenCell>
          ) : (
            <LoadingWraper>
              <Loadings.ThreeDots name="API token" />
            </LoadingWraper>
          )}
        </tr>
        <tr>
          <th>Last update</th>
          {apiTokenCreatedAt ? (
            <td>{apiTokenCreatedAt}</td>
          ) : (
            <LoadingWraper>
              <Loadings.ThreeDots name="date" />
            </LoadingWraper>
          )}
        </tr>
      </tbody>
    </TokenTable>
  );
};
