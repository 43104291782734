import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';

export const FormWrapper = styled.form`
  & > * + * {
    margin-top: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  ${flex({ jc: 'flex-end' })}
`;
