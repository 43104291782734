import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useField } from 'formik';
import { Input } from '../Input';
import { Cnt, Feedback, FloatingLabel } from './styledComponents';
import { Link } from '../../links/Link';
import { delay } from '../../../tools/dev';
import { Textarea } from '../Textarea';

type AutoCompleteType =
  | 'name'
  | 'email'
  | 'current-password'
  | 'new-password'
  | 'street-address'
  | 'tel'
  | 'organization'
  | 'off'
  | 'url';

interface Props {
  type: 'email' | 'password' | 'text' | 'tel' | 'url';
  autoComplete?: AutoCompleteType;
  name: string;
  placeholder?: string;
  validate?: (value: string) => string | undefined;
  classProp?: boolean | undefined;
  showBtn?: boolean;
  disabled?: boolean;
  isTextarea?: boolean;
  handleKeyDown?: KeyboardEventHandler;
  labelCustomStyles?: object;
  inputCustomStyles?: object;
}

export const FloatingLabelInput = (props: Props) => {
  const {
    name,
    placeholder,
    classProp,
    showBtn,
    handleKeyDown,
    isTextarea,
    labelCustomStyles,
    inputCustomStyles,
  } = props;
  const updatedProps = { ...props };
  delete updatedProps.placeholder;

  const [field, meta] = useField(updatedProps);

  const navigate = useNavigate();
  const [isFocused, setFocused] = useState<boolean>(false || !!field.value);
  const inputEl = useRef(null);

  useEffect(() => {
    delay(500).then(() => {
      // && navigator.userAgent.includes('Chrome')
      if (inputEl.current) {
        const bgColor = window.getComputedStyle(
          inputEl.current
        ).backgroundColor;
        if (!['rgb(255, 255, 255)', 'rgba(0, 0, 0, 0)'].includes(bgColor)) {
          // console.log(window.getComputedStyle(inputEl.current).backgroundColor);
          setFocused(true);
        }
      }
    });
  }, [inputEl]);

  const componentProps = {
    ...field,
    ...updatedProps,
    onInput: () => setFocused(true),
    onFocus: () => setFocused(true),
    onBlur: () => {
      if (!field.value) setFocused(false);
    },
    onKeyDown: handleKeyDown,
    ref: inputEl,
    className: `${classProp}`,
  };

  return (
    <Cnt>
      {isTextarea ? (
        <Textarea {...componentProps} />
      ) : (
        <Input {...componentProps} style={inputCustomStyles || {}} />
      )}
      <FloatingLabel
        htmlFor={name}
        className={isFocused ? 'isFocused' : ''}
        style={labelCustomStyles || {}}
      >
        {placeholder}
      </FloatingLabel>
      {meta.error && (
        <Feedback>
          {meta.error}
          {showBtn && (
            <>
              &nbsp;
              <Link onClick={() => navigate('..')}>Log in</Link>
            </>
          )}
        </Feedback>
      )}
    </Cnt>
  );
};
