import React from 'react';
import { useFormik } from 'formik';
import { Label, InputBlock, TextBlock } from './styledComponents';
import { ToggleSwitch } from '../../components/inputFields/ToggleSwitch';
import { useUserData } from '../../context/user/UserData';
import { booleansToChecked, checkedToBooleans } from '../../tools/mappers';
import { useUserMethods } from '../../context/user/UserMethods';

export const RenewFormik = () => {
  const { data } = useUserData();
  const { toggleRenewReqs } = useUserMethods();
  const renewReqs = data.billing.renewReqs;

  const formik = useFormik({
    initialValues: {
      checked: booleansToChecked({ renewReqs }),
    },
    onSubmit: (values) => {
      const boolConfigsObj = checkedToBooleans(['renewReqs'], values.checked);
      toggleRenewReqs(boolConfigsObj as any);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputBlock>
        <ToggleSwitch
          value="renewReqs"
          handleChange={(e) => {
            formik.handleChange(e);
            formik.submitForm();
          }}
          isChecked={formik.values.checked.includes('renewReqs')}
        />
        <TextBlock>
          <Label>Automatically renew when used up</Label>
        </TextBlock>
      </InputBlock>
    </form>
  );
};
