import React from 'react';

export const Postman = () => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_880)">
        <path
          d="M254.953 144.253C263.912 74.122 214.384 10.005 144.381 1.047C74.378 -7.912 10.005 41.616 1.047 111.619C-7.912 181.622 41.616 245.867 111.619 254.953C181.75 263.912 245.867 214.384 254.953 144.253Z"
          fill="#FF6C37"
        />
        <path
          d="M174.2 82.184L120.193 136.191L104.963 120.961C158.074 67.851 163.321 72.458 174.199 82.184H174.2Z"
          fill="white"
        />
        <path
          d="M120.193 137.47C119.809 137.47 119.553 137.342 119.297 137.086L103.94 121.857C103.819 121.741 103.722 121.602 103.656 121.449C103.59 121.295 103.556 121.129 103.556 120.961C103.556 120.794 103.59 120.628 103.656 120.474C103.722 120.32 103.819 120.181 103.94 120.066C157.946 66.059 163.577 71.178 174.967 81.416C175.223 81.672 175.351 81.928 175.351 82.312C175.351 82.696 175.223 82.952 174.967 83.208L120.961 137.086C120.833 137.342 120.449 137.47 120.193 137.47V137.47ZM106.756 120.961L120.193 134.399L172.28 82.312C162.81 73.866 156.41 71.306 106.756 120.962V120.961Z"
          fill="#FF6C37"
        />
        <path
          d="M135.678 151.676L120.961 136.958L174.967 82.9519C189.429 97.5419 167.801 121.217 135.678 151.676Z"
          fill="white"
        />
        <path
          d="M135.678 152.956C135.294 152.956 135.038 152.828 134.783 152.572L120.065 137.854C119.809 137.598 119.809 137.342 119.809 136.958C119.809 136.575 119.937 136.318 120.193 136.063L174.2 82.056C174.316 81.9346 174.455 81.838 174.609 81.772C174.763 81.7059 174.928 81.6719 175.096 81.6719C175.264 81.6719 175.429 81.7059 175.584 81.772C175.738 81.838 175.877 81.9346 175.992 82.056C177.626 83.5681 178.917 85.4128 179.778 87.4659C180.639 89.519 181.05 91.7327 180.983 93.958C180.727 108.164 164.603 126.208 136.703 152.572C136.319 152.828 135.935 152.956 135.679 152.956H135.678ZM122.753 136.958C130.943 145.277 134.399 148.604 135.678 149.884C157.178 129.408 178.038 108.42 178.167 93.958C178.295 90.631 177.015 87.303 174.839 84.744L122.753 136.958V136.958Z"
          fill="#FF6C37"
        />
        <path
          d="M105.22 121.345L116.098 132.223C116.354 132.479 116.354 132.735 116.098 132.991C115.97 133.119 115.97 133.119 115.842 133.119L93.3178 137.982C92.1658 138.11 91.1418 137.342 90.8858 136.191C90.7578 135.551 91.0138 134.911 91.3978 134.527L104.452 121.473C104.708 121.217 105.092 121.089 105.22 121.345V121.345Z"
          fill="white"
        />
        <path
          d="M92.9339 139.262C91.0139 139.262 89.6069 137.726 89.6069 135.807C89.6069 134.911 89.9909 134.015 90.6309 133.375L103.684 120.321C104.452 119.681 105.476 119.681 106.244 120.321L117.122 131.199C117.89 131.839 117.89 132.991 117.122 133.759C116.866 134.015 116.61 134.143 116.226 134.271L93.7019 139.134C93.4459 139.134 93.1899 139.262 92.9339 139.262ZM104.836 122.753L92.2939 135.295C92.0379 135.551 91.9099 135.935 92.1659 136.319C92.2939 136.703 92.6779 136.83 93.0619 136.703L114.178 132.095L104.836 122.753V122.753Z"
          fill="#FF6C37"
        />
        <path
          d="M202.738 52.2379C194.548 44.3029 181.366 44.5589 173.432 52.8779C165.497 61.1959 165.753 74.2499 174.072 82.1839C177.356 85.3565 181.597 87.3532 186.134 87.8626C190.671 88.372 195.249 87.3654 199.154 84.9999L184.564 70.4099L202.737 52.2379H202.738Z"
          fill="white"
        />
        <path
          d="M188.405 89.223C176.247 89.223 166.393 79.369 166.393 67.211C166.393 55.053 176.247 45.199 188.405 45.199C194.035 45.199 199.539 47.375 203.635 51.342C203.89 51.598 204.018 51.854 204.018 52.238C204.018 52.622 203.89 52.878 203.634 53.134L186.357 70.41L199.923 83.976C200.435 84.488 200.435 85.256 199.923 85.768L199.667 86.024C196.339 88.071 192.372 89.224 188.405 89.224V89.223ZM188.405 47.887C177.655 47.887 168.952 56.589 169.08 67.339C169.08 78.089 177.783 86.792 188.533 86.664C191.476 86.664 194.42 86.024 197.107 84.616L183.67 71.306C183.414 71.05 183.286 70.794 183.286 70.41C183.286 70.027 183.414 69.77 183.67 69.515L200.819 52.365C197.363 49.422 193.012 47.887 188.405 47.887V47.887Z"
          fill="#FF6C37"
        />
        <path
          d="M203.122 52.622L202.866 52.366L184.566 70.41L199.027 84.872C200.435 83.976 201.842 82.952 202.994 81.8C204.933 79.9008 206.476 77.6358 207.533 75.1361C208.59 72.6364 209.14 69.9517 209.152 67.2377C209.164 64.5237 208.637 61.8343 207.602 59.3254C206.567 56.8165 205.044 54.538 203.122 52.622V52.622Z"
          fill="white"
        />
        <path
          d="M199.155 86.2799C198.771 86.2799 198.515 86.1519 198.259 85.8959L183.669 71.3059C183.414 71.0499 183.286 70.7939 183.286 70.4099C183.286 70.0269 183.414 69.7699 183.67 69.5149L201.842 51.3419C201.958 51.2205 202.097 51.1239 202.251 51.0578C202.405 50.9918 202.571 50.9578 202.738 50.9578C202.906 50.9578 203.072 50.9918 203.226 51.0578C203.38 51.1239 203.519 51.2205 203.634 51.3419L204.018 51.5979C212.593 60.1719 212.593 73.9939 204.146 82.6959C202.866 83.9759 201.459 85.1279 199.923 86.0239C199.539 86.1519 199.283 86.2799 199.155 86.2799V86.2799ZM186.357 70.4099L199.283 83.3359C200.307 82.6959 201.331 81.7999 202.098 81.0329C209.393 73.7379 209.777 61.8359 202.738 54.1569L186.358 70.4099H186.357Z"
          fill="#FF6C37"
        />
        <path
          d="M176.375 84.488C174.898 83.013 172.896 82.1846 170.808 82.1846C168.721 82.1846 166.718 83.013 165.241 84.488L116.994 132.735L125.056 140.798L176.119 96.006C179.447 93.19 179.702 88.199 176.887 84.872C176.631 84.744 176.503 84.616 176.375 84.488V84.488Z"
          fill="white"
        />
        <path
          d="M124.928 142.078C124.544 142.078 124.288 141.95 124.032 141.694L115.97 133.631C115.849 133.515 115.752 133.376 115.686 133.222C115.62 133.068 115.586 132.903 115.586 132.735C115.586 132.567 115.62 132.402 115.686 132.248C115.752 132.094 115.849 131.955 115.97 131.839L164.217 83.592C165.064 82.7403 166.071 82.0644 167.18 81.6032C168.289 81.142 169.479 80.9045 170.68 80.9045C171.881 80.9045 173.071 81.142 174.18 81.6032C175.289 82.0644 176.296 82.7403 177.143 83.592C177.995 84.4391 178.671 85.4461 179.132 86.5553C179.593 87.6644 179.831 88.8538 179.831 90.055C179.831 91.2563 179.593 92.4456 179.132 93.5548C178.671 94.6639 177.995 95.671 177.143 96.518L176.759 96.902L125.696 141.694C125.568 141.95 125.312 142.078 124.928 142.078V142.078ZM118.785 132.735L125.056 139.006L175.223 94.982C178.039 92.678 178.295 88.455 175.991 85.64C173.688 82.824 169.464 82.568 166.649 84.872C166.521 85 166.393 85.128 166.137 85.256L118.785 132.736V132.735Z"
          fill="#FF6C37"
        />
        <path
          d="M80.0078 187.637C79.4968 187.893 79.2408 188.405 79.3678 188.917L81.5438 198.131C82.0558 199.411 81.2878 200.947 79.8798 201.331C78.8568 201.715 77.7048 201.331 77.0648 200.563L62.9868 186.613L108.931 140.67L124.801 140.926L135.551 151.676C132.991 153.851 117.506 168.825 80.0078 187.637V187.637Z"
          fill="white"
        />
        <path
          d="M78.9849 202.61C77.9609 202.61 76.9369 202.227 76.2969 201.459L62.3469 187.509C62.0919 187.253 61.9639 186.997 61.9639 186.614C61.9639 186.23 62.0919 185.974 62.3479 185.718L108.291 139.774C108.547 139.518 108.931 139.39 109.187 139.39L125.057 139.646C125.44 139.646 125.697 139.774 125.952 140.03L136.702 150.78C136.958 151.036 137.086 151.42 137.086 151.804C137.086 152.188 136.958 152.444 136.574 152.7L135.678 153.467C122.113 165.369 103.684 177.271 80.7759 188.661L82.9519 197.748C83.3359 199.411 82.5679 201.203 81.0319 202.098C80.2639 202.483 79.6239 202.611 78.9849 202.611V202.61ZM64.9069 186.614L78.0889 199.667C78.4729 200.307 79.2409 200.563 79.8799 200.179C80.5199 199.795 80.7759 199.027 80.3919 198.387L78.2169 189.173C77.9609 188.021 78.4729 186.997 79.4969 186.486C102.148 175.096 120.449 163.322 133.887 151.676L124.417 142.206L109.699 141.95L64.9069 186.614V186.614Z"
          fill="#FF6C37"
        />
        <path
          d="M52.1101 197.62L63.1151 186.614L79.4971 202.994L53.3891 201.203C52.2371 201.075 51.4691 200.051 51.5981 198.899C51.5981 198.387 51.7261 197.875 52.1091 197.619L52.1101 197.62Z"
          fill="white"
        />
        <path
          d="M79.4971 204.146L53.2611 202.355C51.3411 202.227 50.0611 200.563 50.1901 198.643C50.3181 197.875 50.5741 197.108 51.2141 196.596L62.2201 185.59C62.3356 185.469 62.4746 185.372 62.6285 185.306C62.7824 185.24 62.9481 185.206 63.1156 185.206C63.283 185.206 63.4488 185.24 63.6027 185.306C63.7566 185.372 63.8956 185.469 64.0111 185.59L80.3921 201.97C80.7761 202.355 80.9041 202.867 80.6481 203.378C80.3921 203.89 80.0081 204.146 79.4971 204.146ZM63.1151 188.406L53.0051 198.516C52.6211 198.771 52.6211 199.411 53.0051 199.667C53.1331 199.795 53.2611 199.923 53.5171 199.923L76.1691 201.459L63.1151 188.405V188.406ZM104.452 146.557C103.684 146.557 103.172 145.917 103.172 145.277C103.172 144.893 103.3 144.637 103.556 144.381L115.97 131.967C116.086 131.846 116.225 131.749 116.379 131.683C116.533 131.617 116.698 131.583 116.866 131.583C117.034 131.583 117.2 131.617 117.354 131.683C117.508 131.749 117.647 131.846 117.762 131.967L125.824 140.03C126.208 140.414 126.336 140.798 126.208 141.31C126.08 141.694 125.696 142.078 125.184 142.206L104.708 146.556H104.452V146.557ZM116.866 134.655L108.419 143.101L122.241 140.158L116.866 134.655V134.655Z"
          fill="#FF6C37"
        />
        <path
          d="M124.8 140.926L110.723 143.997C109.699 144.253 108.675 143.613 108.419 142.589C108.291 141.949 108.419 141.309 108.931 140.798L116.738 132.991L124.8 140.926V140.926Z"
          fill="white"
        />
        <path
          d="M110.467 145.277C110.045 145.281 109.627 145.202 109.237 145.042C108.847 144.883 108.492 144.647 108.194 144.349C107.896 144.051 107.661 143.697 107.502 143.307C107.342 142.917 107.262 142.499 107.267 142.077C107.267 141.182 107.651 140.414 108.163 139.774L115.97 131.967C116.085 131.846 116.224 131.749 116.378 131.683C116.532 131.617 116.698 131.583 116.866 131.583C117.033 131.583 117.199 131.617 117.353 131.683C117.507 131.749 117.646 131.846 117.762 131.967L125.824 140.03C126.208 140.414 126.336 140.798 126.208 141.31C126.08 141.694 125.696 142.078 125.184 142.206L111.107 145.277H110.467ZM116.866 134.655L109.955 141.565C109.699 141.822 109.699 142.078 109.827 142.333C109.955 142.589 110.211 142.717 110.595 142.717L122.369 140.157L116.866 134.655V134.655ZM203.25 64.9071C202.994 64.1401 202.098 63.7561 201.33 64.0121C200.563 64.2681 200.179 65.1631 200.435 65.9321C200.435 66.0591 200.563 66.1871 200.563 66.3151C201.331 67.8511 201.075 69.7711 200.051 71.1781C199.539 71.8181 199.667 72.7141 200.179 73.2261C200.819 73.7381 201.714 73.6101 202.226 72.9701C204.146 70.5381 204.53 67.4671 203.25 64.9071V64.9071Z"
          fill="#FF6C37"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_880">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
