import { Invoice } from './Invoice';
import { Visual } from './Visual';
import { VisualWithHeader } from './VisualWithHeader';
import { PaymentHistory } from './PaymentHistory';
import { Token } from './Token';
import { RequestsHistory } from './RequestsHistory';

export default {
  Visual,
  VisualWithHeader,
  PaymentHistory,
  Token,
  RequestsHistory,
  Invoice,
};
