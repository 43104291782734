import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints, colours } from '../../styles/variables';

export const Cnt = styled.div`
  ${flex({})}

  div {
    ${flex({})}

    span,
    a {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      white-space: nowrap;
    }

    span {
      color: ${colours.fontDark};
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    flex-direction: column;
  }
`;
