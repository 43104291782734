import React, { Dispatch, SetStateAction } from 'react';
import { StatusType } from '../../../types';
import Loadings from '../../loadings';
import { BtnCnt } from './styledComponents';

interface Props {
  values?: object;
  errors?: object;
  setValidateOnChange?: Dispatch<SetStateAction<boolean>>;
  children: string;
  disabled?: boolean | undefined;
  status?: StatusType;
  hideLoading?: boolean;
  hideSuccess?: boolean;
  position?: 'left' | 'right';
}

export const SubmitBtn = (props: Props) => {
  const {
    values,
    errors,
    setValidateOnChange,
    children,
    disabled,
    status,
    hideLoading,
    hideSuccess,
    position,
  } = props;

  // isDisabled?
  const valuesArr = Object.values(values || {});
  const errorsArr = Object.values(errors || {});
  const isDisabled =
    disabled === undefined
      ? valuesArr.some((value) => value === '') ||
        errorsArr.some((error) => error !== undefined)
      : disabled;

  return (
    <BtnCnt
      type="submit"
      disabled={isDisabled}
      onClick={() => {
        if (setValidateOnChange) setValidateOnChange(true);
      }}
      className={position}
    >
      <Loadings.Spinner
        status={status}
        hideLoading={hideLoading}
        hideSuccess={hideSuccess}
      />
      {children}
    </BtnCnt>
  );
};
