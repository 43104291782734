import styled, { css } from 'styled-components';
import { fadeIn } from '../../../../../styles/keyframes';
import { flex, pos } from '../../../../../styles/mixins';
import { colours } from '../../../../../styles/variables';
import calendarIcon from '../../../../../assets/icons/calendar.svg';

const base = css`
  padding: 4px 12px;
  border: 1px solid #ececec;
  border-radius: 4px;

  ${flex({ ai: 'center' })}
  transition: border-color 300ms;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      border-color: #7879f1;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const SelectWrapper = styled.div`
  ${flex({})}
`;

export const SelectCnt = styled.div`
  height: fit-content;
  width: fit-content;
  ${base}

  position: relative;
  overflow: hidden;
  appearance: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.44px;
  color: #7879f1;

  img {
    position: relative;
    left: 6px;
    transition: transform 200ms, top 200ms;
  }

  &.focused {
    border-color: ${colours.primary};
    img {
      transform: scaleY(-1);
    }
  }
`;

export const Dropdown = styled.div`
  width: fit-content;
  padding: 4px 0px;
  border-radius: 2px;

  ${flex({ dir: 'column' })}
  ${pos({ p: 'absolute', t: '100%' })}
  z-index: 1;

  background: #ffffff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  animation: ${fadeIn} 150ms ease-out;

  span {
    width: 117px;
    padding: 5px 26px 5px 12px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: ${colours.gray};
    }
  }
`;

export const Dates = styled.div`
  margin-left: 16px;
  ${flex({})}

  img {
    height: 24px;
    width: 24px;
    margin: auto 8px;
  }

  .react-datepicker {
    &__input-container {
      height: 100%;
      width: 130px;

      input {
        height: 100%;
        width: 100%;
        ${base}
        background: url(${calendarIcon}) no-repeat;
        background-position: right 14px top 50%;
      }
    }
  }
`;
