import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { breakpoints } from '../../../../../styles/variables';

export const MetaSectionCnt = styled.section`
  margin-top: 40px;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin-top: 32px;
    ${flex({ dir: 'column', ai: 'flex-start' })}
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 0;
  }
`;
