import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { removeScrollbar } from '../../styles/placeholders';
import { breakpoints } from '../../styles/variables';

export const PlanCnt = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 12px;
    min-width: 100%;
    overflow-x: auto;
    ${removeScrollbar}
  }
`;

export const CardCnt = styled.fieldset`
  border: none;
  ${flex({ jc: 'space-between' })}

  @media screen and (max-width: ${breakpoints.md}) {
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    justify-content: space-between;
    padding: 0 4px;
  }
  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 0 calc(16px - 12px);
  }
`;
