import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';
import React, { FC, useState } from 'react';
import { usePayment } from '../../../../../../context/payment/PaymentCtx';
import { ICardValidation } from '../../../../../../context/payment/PaymentCtx/interfaces';
import { Wrapper } from './styledComponents';

interface Props {
  Component: FC<any>;
  options:
  | StripeCardNumberElementOptions
  | StripeCardExpiryElementOptions
  | StripeCardCvcElementOptions;
}

export const ElementWrapper = (props: Props) => {
  const { Component, options } = props;
  const [isFocused, setFocused] = useState<boolean>(false);
  const { setValidation } = usePayment();

  const handleChange = (e: StripeElementChangeEvent) => {
    const { elementType: fieldName, complete: isValid, error } = e;
    setValidation((prev: ICardValidation) => ({
      ...prev,
      [fieldName]: {
        isValid,
        error: error?.message,
      },
    }));
  };

  return (
    <Wrapper className={isFocused ? 'focused' : ''}>
      <Component
        options={options}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onEscape={() => setFocused(false)}
        onChange={handleChange}
      />
    </Wrapper>
  );
};
