export const toDateArr = (date: Date): string[] => {
  const newDate = new Date(date);

  const formattedDate = newDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const formattedTime = newDate.toLocaleTimeString('en-GB', {
    timeStyle: 'short',
  });

  return [formattedDate, formattedTime];
};

export const toDateStr = (date: Date): string => {
  return toDateArr(date).join(', ');
};
