import styled from 'styled-components';
import { colours } from '../../../styles/variables';

export const Wrapper = styled.span`
  min-height: 24px;
  padding: 2.4px 6px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  transition: box-shadow 300ms ease-in;
  &:hover {
    box-shadow: 0px 2px 4px ${colours.lightGray}, 0px 3px 4px ${colours.lightGray},
      0px 1px 16px ${colours.lightGray};
  }
`;
