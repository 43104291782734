import React from 'react';
import { ShowMore } from '../../../../../components/showMores/ShowMore';
import { Desc, DescHeading, DescSectionCnt } from './styledComponents';

interface Props {
  desc: string;
}

export const DescSection = (props: Props) => {
  const { desc } = props;

  return (
    <DescSectionCnt>
      <DescHeading>Description</DescHeading>
      <Desc>
        <ShowMore rows={6}>{desc}</ShowMore>
      </Desc>
    </DescSectionCnt>
  );
};
