import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { handleError } from './handleError';
import 'react-medium-image-zoom/dist/styles.css';

interface Props {
  src: string;
}

export const ImageZoomed = (props: Props) => {
  return (
    <Zoom>
      <img
        src={props.src}
        alt="image"
        className="big"
        onError={handleError}
      />
    </Zoom>
  );
};
