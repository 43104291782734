import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import { historyHeadTr, historyTable, mutedFont } from '../styles';

export const RequestsHistoryTable = styled.table`
  width: 594px;
  margin-top: -8px; // # pp bc of Figma
  ${historyTable}
  // Headings
  thead tr {
    height: 60px;
    ${historyHeadTr}
    padding-top: 23px;
    margin: -2px; // # border collapse
    text-align: left;
  }
  // Body rows
  tbody tr {
    height: 55px;
    ${historyHeadTr}
    padding: 18px 0 23px 23px; // # pp
    margin: -2px; // # border collapse

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 4px;
    }
  }
  // First column
  th:nth-of-type(1),
  td:nth-of-type(1) {
    width: 74px; // # +1px
    margin-right: 164px; // # -1px
  }
  // Second column
  th:nth-of-type(2),
  td:nth-of-type(2) {
    width: 97px; // # +1px
    min-width: fit-content;
  }
  // Third column
  th:nth-of-type(3),
  td:nth-of-type(3) {
    padding-right: 15px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1045px) {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    // First column
    th:nth-of-type(1),
    td:nth-of-type(1) {
      width: 50%;
      margin-right: 0;
    }
    // Second column
    th:nth-of-type(2),
    td:nth-of-type(2) {
      width: 50%;
    }
    // Third column
    th:nth-of-type(3),
    td:nth-of-type(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${breakpoints.xss}) {
    // # pp the entire @media
    margin-top: -8px;
    // Rows
    thead tr,
    tbody tr {
      height: 55px;
      padding: 18px 16px 14px;
    }
    // Headings
    thead tr {
      height: calc(53px - 20px);
      padding-top: 0;
      th {
        ${mutedFont}
      }
    }
  }
`;
