/* eslint-disable no-promise-executor-return */
import config from '../../config';

// @ GET
// @ /billing-plans
// @ PRIVATE
export const GET_BILLING_PLANS = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/billing-plans`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    // console.error(err);
  }
};

// @ GET
// @ /payments/get-publishable-key
// @ PRIVATE
export const GET_PUBLISHABLE_KEY = async (): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/payments/get-publishable-key`,
      {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
        status: json.statusCode || response.status,
        statusText: json.message || response.statusText,
        data: json || null,
      };
    }

    throw new Error('Unkown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: err,
    };
  }
};

// @ POST
// @ /payments/create-intent
// @ PRIVATE
export const CREATE_INTENT = async (data: { billingPlanId: string }) => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/payments/create-intent`, {
      method: 'POST',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      data: json || null,
    };
  } catch (error) {
    console.error(error);
  }
};

// @ GET
// @ /payments/history
// @ PRIVATE
export const GET_PAYMENTS = async (): Promise<any> => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${config.baseUrl}/payments/history`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ /cards
// @ PRIVATE
export const LIST_ALL_CARDS = async (limit?: number): Promise<any> => {
  const accessToken = localStorage.getItem('access_token');
  const query = limit ? `?limit=${limit}` : '';

  try {
    const response = await fetch(`${config.baseUrl}/cards${query}`, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 404 ||
      response.status === 400 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ DELETE
// @ /cards
// @ PRIVATE
export const REMOVE_CARD = async (data: {
  paymentMethodId: string;
}): Promise<any> => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${config.baseUrl}/cards`, {
      method: 'DELETE',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: true,
        data: json,
      };
    }

    if (
      response.status === 409 ||
      response.status === 400 ||
      response.status === 500
    ) {
      return {
        ok: false,
        status: json.statusCode,
        errorMessage: json.message,
      };
    }

    throw new Error('Unknown error');
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
