import { css } from 'styled-components';
import getTranslateValue from './utils/getTranslateValue';

export const setStepWidth = (step: number) => {
  return css`
    &._0 {
      #billing-plan-thumb {
        transform: translateX(${getTranslateValue(step, 0)}px);
      }
    }
    &._1 {
      #billing-plan-thumb {
        transform: translateX(${getTranslateValue(step, 1)}px);
      }
    }
    &._2 {
      #billing-plan-thumb {
        transform: translateX(${getTranslateValue(step, 2)}px);
      }
    }
    &._3 {
      #billing-plan-thumb {
        transform: translateX(${getTranslateValue(step, 3)}px);
      }
    }
  `;
};
