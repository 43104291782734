import React from 'react';
import { useTaskData } from '../../../../context/extraction/TaskData';
import { useUI } from '../../../../context/UICtx';
import { ApiPage } from '../../../../pages/Extraction/ApiPage';
import { JsonPage } from '../../../../pages/Extraction/JsonPage';
import { VisualPage } from '../../../../pages/Extraction/VisualPage';

export const ExtractionTabs = () => {
  const { extractStatus, data, json } = useTaskData();
  const { tab } = useUI();

  const switcher = {
    Visual: <VisualPage status={extractStatus} data={data} />,
    JSON: <JsonPage status={extractStatus} data={json} />,
    API: <ApiPage />,
  };

  return switcher[tab];
};
