import styled from 'styled-components';
import { dash, fadeOut, rotate360, spin } from '../../styles/keyframes';
import { flex } from '../../styles/mixins';

export const Wrapper = styled.div`
  ${flex({ wrap: 'wrap', jc: 'flex-end', ai: 'flex-start' })}

  & > * {
    margin-left: 32px;

    &:first-of-type {
      margin-bottom: 16px;
    }
  }

  & > svg {
    height: 24px;
  }

  svg[name='spinner'] {
    width: 24px;
    animation: ${rotate360} 1000ms ease-in-out infinite;

    circle {
      stroke-linecap: round;
      animation: ${spin} 1000ms ease-in-out infinite;
    }
  }

  svg[name='loading-ok'] {
    width: 30px;

    path {
      stroke-dasharray: 40;
      animation: ${dash} 1000ms ease-in-out forwards, ${fadeOut} 1000ms 1000ms ease-in-out forwards;
    }
  }

  @media screen and (max-width: 330px) {
    ${flex({ wrap: 'nowrap', jc: 'space-between', ai: 'flex-start' })}

    & > * {
      margin-left: 0;

      &:first-of-type {
        margin-bottom: 16px;
        margin-right: 16px;
      }
    }
  }
`;
