import React from 'react';

export const Gmail = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.73 5.4101L17.45 6.4101L12 10.4601L6.54995 6.3701L5.26995 5.3701C4.96841 5.12064 4.6003 4.96514 4.21124 4.92285C3.82218 4.88056 3.42926 4.95334 3.08116 5.13218C2.73306 5.31102 2.44508 5.58806 2.25289 5.92897C2.06071 6.26988 1.97276 6.65969 1.99995 7.0501V18.6401C1.99995 19.0008 2.14324 19.3467 2.39829 19.6018C2.65334 19.8568 2.99926 20.0001 3.35995 20.0001H6.54995V12.2801L12 16.3701L17.45 12.2801V20.0001H20.64C21.0006 20.0001 21.3466 19.8568 21.6016 19.6018C21.8567 19.3467 22 19.0008 22 18.6401V7.0501C22.0187 6.66306 21.9247 6.2789 21.7292 5.94432C21.5337 5.60974 21.2453 5.33914 20.8989 5.16543C20.5525 4.99171 20.1632 4.92235 19.7781 4.96579C19.3931 5.00923 19.0289 5.16358 18.73 5.4101V5.4101Z"
        fill="black"
      />
    </svg>
  );
};
