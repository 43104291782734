import styled from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { HeadingWrapperBase } from '../../styles';

export const HeadingWrapper = styled(HeadingWrapperBase)`
  ${flex({ jc: 'space-between' })}
`;

export const ChartDiv = styled.div`
  width: 100%;
  height: 50vh;
  margin-top: 24px;

  ${flex({ jc: 'center', ai: 'center' })}
  position: relative;

  & > * {
    width: 100%;
    height: 100%;
  }
`;
