/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../components/headings/Heading';
import { Cnt } from '../styledComponents';
import Tables from '../../../components/tables';
import { CardInfo } from './CardInfo';
import {
  CardsSection,
  PaymentsSection,
  RenewReqsCnt,
} from './styledComponents';
import { Empty } from '../../../components/Empty';
import * as ShowsMore from '../../../components/showMores/Table';
import { usePayment } from '../../../context/payment/PaymentCtx';
import Loadings from '../../../components/loadings';
import {
  ICard,
  ICardsObj,
  IPaymentHistoryItem,
} from '../../../context/payment/PaymentCtx/interfaces';
import { Button } from '../../../components/buttons/Button';
import { RenewFormik } from '../../../features/RenewFormik';

export const PaymentSettingsPage = () => {
  const {
    cardsObj,
    listAllCards,
    cardsStatus,
    paymentHistory,
    paymentHistoryStatus,
    listPaymentHistory,
  } = usePayment();
  const navTo = useNavigate();

  useEffect(() => {
    listAllCards();
    listPaymentHistory();
  }, []);

  // ↓ redirect to billing if empty data
  useEffect(() => {
    const conditions = [
      cardsObj.cards.length === 0,
      paymentHistory.length === 0,
      cardsStatus !== 'loading' && cardsStatus !== undefined,
      paymentHistoryStatus !== 'loading' && paymentHistoryStatus !== undefined,
    ];

    if (conditions.every((con) => con)) {
      navTo('/payment');
    }
  }, [cardsObj, paymentHistory, cardsStatus, paymentHistoryStatus]);

  const cardsSwitcher = {
    undefined: <Loadings.ThreeDots name="cards" />,
    loading: <Loadings.ThreeDots name="cards" />,
    success: (cardsObjArg: ICardsObj) => {
      if (cardsObjArg.cards.length === 0) return <Empty name="cards" />;
      return (
        <>
          {cardsObjArg.cards.map((card: ICard, ind: number) => (
            <CardInfo
              id={card.id}
              last4={card.last4}
              brand={card.brand}
              key={ind}
            />
          ))}
          <RenewReqsCnt>
            <RenewFormik />
            {cardsObj.has_more && (
              <Button onClick={() => listAllCards(cardsObj.cards.length + 3)}>
                Show more
              </Button>
            )}
          </RenewReqsCnt>
        </>
      );
    },
    error: <Empty name="cards" />,
  };

  const historySwitcher = {
    undefined: <Loadings.ThreeDots name="payment history" />,
    loading: <Loadings.ThreeDots name="payment history" />,
    success: (paymentHistoryArr: IPaymentHistoryItem[]) => {
      if (paymentHistoryArr.length === 0) {
        return <Empty name="payment history" />;
      }
      return (
        <ShowsMore.Table
          data={paymentHistoryArr}
          TableComponent={Tables.PaymentHistory}
        />
      );
    },
    error: <Empty name="payment history" />,
  };

  return (
    <Cnt>
      <CardsSection>
        <header>
          <Heading>Cards info</Heading>
          <Button onClick={() => navTo('/payment')}>Billing</Button>
        </header>
        {cardsStatus !== 'success'
          ? cardsSwitcher[`${cardsStatus}`]
          : cardsSwitcher.success(cardsObj)}
      </CardsSection>
      <PaymentsSection>
        <Heading>Payment History</Heading>
        {paymentHistoryStatus !== 'success'
          ? historySwitcher[`${paymentHistoryStatus}`]
          : historySwitcher.success(paymentHistory)}
      </PaymentsSection>
    </Cnt>
  );
};
