import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';
import {
  border,
  historyHeadTr,
  historyTable,
  mutedFont,
} from '../styles';

export const RecordsWithUrlCnt = styled.table`
  width: 100%;
  ${historyTable}
`;

export const HeadRow = styled.tr`
  margin: -2px; // # pp
  ${historyHeadTr}

  & > * {
    width: 20%;
    text-align: left;
    &:nth-of-type(1) {
      width: calc(128px + 30px);
    }
    &:nth-of-type(2) {
      width: calc(128px + 58px);
    }
    &:nth-of-type(3) {
      width: calc(128px + 89px);
    }
    &:nth-of-type(4) {
      width: calc(128px + 109px);
    }
    &:nth-of-type(5) {
      width: max-content;
    }
  }

  @media screen and (max-width: calc(${breakpoints.lg} - 1px)) {
    & > * {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 20%;
      }

      &:nth-of-type(5) {
        padding-right: 23px;
      }
    }
  }

  @media screen and (max-width: calc(${breakpoints.lg} - 1px)) {
    & > * {
      &:nth-of-type(1) {
        width: 18%;
      }
      &:nth-of-type(2) {
        width: 16%;
      }
      &:nth-of-type(3) {
        width: 20%;
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 23%;
      }
      &:nth-of-type(5) {
        padding-right: 23px;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const gap = '45%';
export const BodyRow = styled(HeadRow)`
  padding: 18px 0 23px 23px; // # pp
  border-bottom: none;

  // Invoice link
  a {
    width: fit-content;
  }

  // Units span
  span {
    display: none;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    height: 78px;
    padding: 16px 0 0; // # pp
    border-top: ${border};
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    &:last-child {
      border-bottom: ${border};
    }

    & > td {
      &:nth-of-type(1) {
        height: 100%;
        width: calc((73 + 38) / 343 * 100%);
        border-left: none;
      }
      &:nth-of-type(2) {
        height: ${gap};
        width: calc((114 + 37) / 343 * 100%);
      }
      &:nth-of-type(3) {
        height: ${gap};
        width: calc((114 + 37) / 343 * 100%);
        ${mutedFont}
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        height: ${gap};
        width: calc(81 / 343 * 100%);
        padding-right: 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    // Invoice link
    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    // Units span
    span {
      display: inline;
    }
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    & > td {
      &:nth-of-type(1) {
        width: calc((70 + 25) / 343 * 100%);
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: calc((110 + 35) / 343 * 100%);
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: calc(103 / 343 * 100%);
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    & > td {
      &:nth-of-type(1) {
        width: calc((73 + 38) / 343 * 100%);
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: calc((114 + 37 / 3) / 343 * 100%);
      }
      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: calc((81 + 37 / 3 * 2) / 343 * 100%);
      }
    }
  }
`;
