import { Spinner } from './Spinner';
import { LoadBar } from './LoadBar';
import { Status } from './Status';
import { Visual } from './Visual';
import { History } from './History';
import { ThreeDots } from './ThreeDots';

export default {
  Spinner,
  LoadBar,
  Status,
  Visual,
  History,
  ThreeDots,
};
