import React from 'react';
import { Button } from '../../../../../../components/buttons/Button';
import Svgs from '../../../../../../components/svgs';
import { BtnCnt, FooterCnt } from './styledComponents';

interface Props {
  isBtnDisabled: boolean;
  isConfirmingPayment: boolean;
}

export const Footer = (props: Props) => {
  const { isBtnDisabled, isConfirmingPayment } = props;

  return (
    <FooterCnt>
      <div>
        <span>Powered by</span>
        <Svgs.StripeLogo />
      </div>
      <BtnCnt>
        <svg
          name="spinner"
          style={{ display: isConfirmingPayment ? 'block' : 'none' }}
        >
          <circle
            cx={43 / 2}
            cy={43 / 2}
            r={43 / 2 - 5}
            fill="none"
            stroke="#5578F6"
            strokeWidth="5"
          ></circle>
        </svg>
        <Button type="submit" disabled={isBtnDisabled}>
          Pay now
        </Button>
      </BtnCnt>
    </FooterCnt>
  );
};
