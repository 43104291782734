import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Cnt, ManePane, BurgerBtn, SidePane, Corner } from './styledComponents';
import { Navigation } from './components/Navigation';
import { BuyReqsBadge } from './components/BuyReqsBadge';
import { ExbotLogo } from './components/ExbotLogo';
import openMenuIcon from '../../assets/icons/menu-open.svg';
import closeMenuIcon from '../../assets/icons/menu-close.svg';
import { useUserData } from '../../context/user/UserData';
import { getEncodedUrlByKey } from '../../tools/urls/getEncodedUrl';
import { useAuth } from '../../context/AuthCtx';
import { useExtraction } from '../../context/extraction/TaskMethods';
import { useTaskData } from '../../context/extraction/TaskData';
import { useRequestData } from '../../context/extraction/RequestData';

export const PageCnt = () => {
  // UI state
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  // Hooks
  const loc = useLocation();
  const { isAuth } = useAuth();
  const { data: user } = useUserData();
  const { initExtract } = useExtraction();
  const { setQuery } = useRequestData();
  const { extractStatus } = useTaskData();

  // (1) Reading URL to analyze from current location
  // and sending EXTRACT request to server
  useEffect(() => {
    if (isLoaded && extractStatus !== 'loading') {
      // prevent doubles
      const urlParam = getEncodedUrlByKey(loc.search, 'url');
      if (urlParam && urlParam.includes('http')) {
        setQuery((prev) => ({ ...prev, url: urlParam }));
        initExtract(urlParam);
      }
    }
  }, [isLoaded]);

  // (2-a) Redirecting to public pages if NOT authorised
  if (isAuth === undefined) return <></>;
  // if (isAuth === false) return <Navigate to="/auth" />;
  // The (3) is at ExtractionCnt
  return (
    <Cnt>
      <SidePane className={isOpen ? 'opened' : 'closed'}>
        <Corner>
          <BurgerBtn onClick={() => setOpen(!isOpen)}>
            <img src={openMenuIcon} alt="open menu" />
          </BurgerBtn>
          <ExbotLogo handleClick={() => setOpen(false)} />
        </Corner>
        <BurgerBtn
          onClick={() => setOpen(false)}
          style={{ display: isOpen ? 'block' : 'none' }}
        >
          <img src={closeMenuIcon} alt="close menu" />
        </BurgerBtn>
        <BuyReqsBadge
          isOpen={isOpen}
          clickHandler={() => setOpen(false)}
          requestsBalance={isAuth ? user.requestsBalance : 0}
        />
        <Navigation isOpen={isOpen} clickHandler={() => setOpen(false)} />
      </SidePane>

      <ManePane
        onLoad={() => {
          console.debug('document loaded');
          setLoaded(true);
        }}
      >
        <div>
          <Outlet />
        </div>
      </ManePane>
    </Cnt>
  );
};
