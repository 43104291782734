import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints } from '../../styles/variables';

export const Cnt = styled.main`
  min-height: 80vh;
`;

export const SubmitBtnCnt = styled.div`
  height: 56px;
  ${flex({ ai: 'flex-end' })}
`;

export const InputsCnt = styled.div`
  ${flex({ wrap: 'wrap', ai: 'flex-end' })}

  margin-top: -24px;
  margin-left: -32px;
  & > * {
    width: 295px;
    margin-top: 24px;
    margin-left: 32px;
  }

  input {
    height: 54px;

    &::placeholder {
      padding: 50px;
    }

    &:focus {
      height: 55px;
    }
  }

  button {
    height: fit-content;
    width: fit-content;
  }

  @media screen and (max-width: 1075px) {
    ${flex({ dir: 'column', wrap: 'nowrap', ai: 'flex-start' })}

    & > * {
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    & > * {
      margin-top: 16px;
    }
  }
`;
