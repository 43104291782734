import styled from 'styled-components';

export const Cnt = styled.main`
  section {
    & > div {
      width: 680px;
      margin: 0 auto;

      h3 {
        width: fit-content;
        margin: 0 auto 32px;
      }
    }
  }

  @media screen and (max-width: 1145px) {
    section {
      & > div {
        width: 100%;
      }
    }
  }
`;
