import styled, { css } from 'styled-components';
import { dim } from '../../styles/keyframes';
import { flex, textOverflow } from '../../styles/mixins';
import { resetBtn } from '../../styles/placeholders';
import { colours } from '../../styles/variables';

const commonStyles = css`
  height: fit-content;
  height: 72px;
  border: none;
  border-radius: 4px;

  background: ${colours.white};
  box-shadow: 0px 1px 2px ${colours.shadow3}, 0px 2px 4px ${colours.shadow},
    0px 1px 8px ${colours.shadow2};
`;

export const LoginBtnCnt = styled.div`
  ${commonStyles}
  ${flex({ jc: 'center', ai: 'center' })}
  cursor: pointer;
  transition: border-width 100ms ease-in-out;

  // # pp
  &:nth-of-type(1) > img {
    margin-left: 2px;
  }
  &:nth-of-type(2) > img {
    margin-left: 3px;
  }
  &:nth-of-type(3) > img {
    margin-left: 4px;
  }

  &.isLoading {
    animation: ${dim()} 1000ms ease-in-out infinite alternate-reverse;

    &:hover {
      border: none;
    }
  }

  &:hover {
    border: 4px solid ${colours.primary2};
  }
`;

export const Span = styled.span`
  margin-left: 16px;
  font-size: 16px;
  line-height: 19px;
  color: ${colours.muted};
`;

export const LinkedAccountCnt = styled.div`
  padding: 16px;
  ${commonStyles}
  ${flex({ ai: 'center' })}

  img:first-of-type {
    height: 40px;
    width: 40px;
    border-radius: 50px;

    outline: 4px solid transparent;
    outline-offset: -1px;
    transition: outline-color ease-in-out 300ms;
  }

  div {
    margin-left: 16px;
    ${flex({ dir: 'column' })}
    flex-grow: 1;
    overflow-x: hidden;

    span {
      ${textOverflow()}
      white-space: nowrap;
    }
    span:first-of-type {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: ${colours.fontDark};
    }
    span:last-of-type {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: ${colours.muted};
    }
  }

  button {
    ${resetBtn}

    &:hover {
      svg path {
        stroke: ${colours.primaryDark};
        transition: stroke 300ms;
      }
    }
  }

  &.isLoading {
    animation: ${dim()} 1000ms ease-in-out infinite alternate-reverse;

    &:hover {
      img:first-of-type {
        outline-color: transparent;
      }
    }
  }

  &:hover {
    img:first-of-type {
      /* outline-color: ${colours.primary}; */
    }
  }
`;
