import styled from 'styled-components';
import { textOverflow } from '../../../../styles/mixins';
import { removeScrollbar } from '../../../../styles/placeholders';

export const Td = styled.td`
  max-height: 20px;
  & > * {
    ${textOverflow()}
  }
  ${removeScrollbar}
`;
