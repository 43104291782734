import styled from 'styled-components';
import { flex, pos } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const Cnt = styled.div`
  width: 100vw;
  height: 100vh;

  ${flex({ jc: 'center', ai: 'center' })}

  ${pos({ p: 'fixed', t: 0, r: 0, b: 0, l: 0 })}
  z-index: 112;

  background-color: ${colours.gray}75;
`;
