import React, { useEffect } from 'react';
import { Section } from '../../../../components/containers/Section';
import { Heading } from '../../../../components/headings/Heading';
import { useCharts } from '../../../../context/dashboard/Charts';
import { useDashboard } from '../../../../context/dashboard/DashboardCtx';
import initChart from '../../../../tools/charts/initChart';
import { ChartPlaceholder } from './ChartPlaceholder';
import { MetricsBadges } from './MetricsBadges';
import { DateSelect } from './DateSelect';
import { ChartDiv, HeadingWrapper } from './styles';

export const MetricsSection: React.FC = () => {
  const {
    metricsData,
    metricsStatus,
    errors,
    updateErrorFieldsChecked,
    filterStatus,
  } = useDashboard();
  const { currentChart } = useCharts();

  useEffect(() => {
    if (!document.getElementById('chartdiv') || !metricsData?.counts) {
      return;
    }

    return initChart(
      metricsData,
      metricsStatus,
      currentChart,
      errors,
      updateErrorFieldsChecked,
      filterStatus
    );
  }, [metricsStatus, currentChart]);

  return (
    <Section>
      <HeadingWrapper>
        <Heading>Dashboard</Heading>
        <DateSelect selected="last 24 hours" />
      </HeadingWrapper>
      <MetricsBadges />

      <ChartDiv id="chartdiv">
        <ChartPlaceholder chartType={currentChart} metrics={metricsData} />
      </ChartDiv>
    </Section>
  );
};
