import React from 'react';
import { ShadowCnt } from '../../../components/containers/ShadowCnt';
import { ConfirmNewPassFormik } from './ConfirmNewPassFormik';

export const ConfirmNewPassPage = (): JSX.Element => {
  return (
    <ShadowCnt>
      <ConfirmNewPassFormik />
    </ShadowCnt>
  );
};
