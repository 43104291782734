import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { resetLink } from '../../../styles/placeholders';
import { colours } from '../../../styles/variables';

export const BadgeCnt = styled.a`
  min-width: 155px;
  padding: 7px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  ${flex({ ai: 'center' })}
  cursor: pointer;
  ${resetLink}

  img {
    margin-right: 20px;
  }

  &:hover {
    border-color: ${colours.primary};
    transition: border-color 300ms;
  }
`;

export const ImgCnt = styled.div`
  height: 34px;
  width: 34px;
  margin-right: 20px;

  svg {
    height: 100%;
    width: 100%;
  }
`;
