export default (date: string): string => {
  const dateObj = new Date(date);

  const timeStr = dateObj.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const dateStr = dateObj
    .toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    .replace(' ', '. ');

  return `${timeStr} ${dateStr}`;
};
