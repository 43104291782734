import React, { MouseEventHandler } from 'react';
import { Wrapper } from './styles';

interface Props {
  handleClick?: MouseEventHandler;
  children: JSX.Element | string;
  backgroundColor: string;
  color: string;
}

export const TagColored: React.FC<Props> = ({ handleClick, children, backgroundColor, color }) => (
  <Wrapper onClick={handleClick} style={{ backgroundColor, color }}>
    {children}
  </Wrapper>
);
