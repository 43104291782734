import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { IExtractionQuery } from '../../../api/types';
import { ProviderProps } from '../../types';
import { useUserData } from '../../user/UserData';
import { useTaskData } from '../TaskData';
import { IRequestDataCtx, IWebhooks } from './interfaces';

const RequestDataCtx = createContext<IRequestDataCtx>({} as IRequestDataCtx);
export const useRequestData = () => useContext(RequestDataCtx);

export const RequestDataProvider: React.FC<ProviderProps> = ({ children }) => {
  const { data: user } = useUserData();
  const { url } = useTaskData();

  const [apiRequestUrl, setApiRequestUrl] = useState<string>('SPECIFY_YOUR_URL');
  const [query, setQuery] = useState<IExtractionQuery>({
    ...user.configs,
    url: '',
    timestamp: Date.now(),
  });
  const [webhooks, setWebhooks] = useState<IWebhooks>({
    success: '',
    failure: '',
  });

  useEffect(() => {
    if (user.configs.timeout) {
      const { enableProxies, autoPaginate, slowScrolling, timeout } =
        user.configs;
      setQuery((prev) => ({
        ...prev,
        enableProxies,
        autoPaginate,
        slowScrolling,
        timeout,
      }));
    }
  }, [user.configs]);

  useEffect(() => {
    setQuery((prev) => ({ ...prev, url: encodeURIComponent(url) }));
  }, [url]);

  return (
    <RequestDataCtx.Provider
      value={{
        apiRequestUrl, // ui only
        setApiRequestUrl, // ui only
        query,
        setQuery,
        webhooks,
        setWebhooks,
        resetData: () => {
          setQuery((prev) => ({ ...prev, ...user.configs, url: '' }));
          setWebhooks({
            success: '',
            failure: '',
          });
        },
      }}
    >
      {children}
    </RequestDataCtx.Provider>
  );
};
