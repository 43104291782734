import React from 'react';
import { ProviderProps } from '../types';
import { UserDataProvider } from './UserData';
import { UserMethodsProvider } from './UserMethods';

export const UserProviders: React.FC<ProviderProps> = ({ children }) => (
  <UserDataProvider>
    <UserMethodsProvider>{children}</UserMethodsProvider>
  </UserDataProvider>
);
