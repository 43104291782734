import styled, { css } from 'styled-components';
import { flex } from '../../../../styles/mixins';
import { colours } from '../../../../styles/variables';

interface Props {
  isChecked: boolean;
}

const mapProps = (props: Props) => {
  if (props.isChecked) {
    return;
  }

  return css`
    background-color: ${colours.primary}25;

    path {
      fill: ${colours.primary};
    }
  `;
};

export const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  ${flex({ ai: 'center', jc: 'center' })}
`;

export const Svg = styled.svg<Props>`
  border-radius: 2px;

  path {
    fill: ${colours.primary};
  }

  &:hover {
    path {
      fill: ${colours.primaryDark};
    }

    ${mapProps}
  }
`;
