import React from 'react';

export const SingleArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1000_20787)">
        <path
          d="M7.99984 11.9998C7.76784 11.9998 7.53684 11.9198 7.34884 11.7588L-0.410156 5.10884L0.891844 3.58984L7.99984 9.68284L15.1078 3.58984L16.4098 5.10884L8.65084 11.7588C8.46284 11.9198 8.23184 11.9998 7.99984 11.9998Z"
          fill="#5578F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_1000_20787">
          <rect
            width="16"
            height="16"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
