import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { removeScrollbar } from '../../../../../styles/placeholders';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  ${flex({})}

  overflow-x: scroll;
  ${removeScrollbar}
`;
