import styled from 'styled-components';
import { breakpoints, colours } from '../../styles/variables';

export const Section = styled.section`
  padding: 32px;
  &:not(:last-child) {
    border-bottom: 1px solid ${colours.lighterGray};
  }

  & header,
  & h3 {
    margin-bottom: 32px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 24px 16px;

    & h3 {
      margin-bottom: 24px;
    }
  }
`;
