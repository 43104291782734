import React from 'react';

interface Props {
  HeadComponents: React.FC<any>[]
}

export const TableHead: React.FC<Props> = ({ HeadComponents }) => (
  <thead>
    <tr>
      {HeadComponents.map((HeadComponent, index) => <HeadComponent key={index} />)}
    </tr>
  </thead>
);
