import React from 'react';
import { createPortal } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Client } from '../../api/client';
import { Fallback } from './Fallback';
import { IErrorFallback } from './interfaces';

const ErrorFallback = (props: IErrorFallback) => {
  const { error, resetErrorBoundary } = props;

  return createPortal(
    <Fallback error={error} resetErrorBoundary={resetErrorBoundary} />,
    document.body
  );
};

export const ErrorBoundaryProvider = (props: { children: React.ReactNode }) => {
  const client = new Client();
  const env = {
    lang: window.navigator.language,
    userAgent: window.navigator.userAgent,
    vendor: window.navigator.vendor,
    cookies: document.cookie,
    localStorage,
  };

  const handleError = async (
    error: Error,
    info: { componentStack: string }
  ) => {
    try {
      await client.WRITE_LOG({
        error,
        componentStack: info.componentStack,
        env,
      });
    } catch (err) {
      console.error('Error within ErrorBoundary', err);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.assign('/')}
      onError={handleError}
    >
      {props.children}
    </ErrorBoundary>
  );
};
