import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import { resetLink } from '../../../styles/placeholders';
import { colours } from '../../../styles/variables';

export const LinkCnt = styled.a`
  ${resetLink}
  ${flex({ ai: 'center' })}
  cursor: pointer;

  &:hover {
    path {
      fill: ${colours.primary};
    }

    span {
      color: ${colours.primary};
    }
  }

  img {
    height: 32px;
    width: 32px;
  }

  span {
    margin-left: 8px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: ${colours.fontDark};
  }
`;
