import React from 'react';
import toDdMmHhMmSs from '../../../../../tools/dates/toDdMmHhMmSs';
import { ITableCellBase } from '../../interfaces/table-cell-base.interface';

export interface IStartedAtCell extends ITableCellBase {
  startedAt: string | Date;
}

export const StartedAtCell: React.FC<IStartedAtCell> = ({ startedAt = '0', width = 0 }) => (
  <td style={{ width: `${width}%` }}>
    {toDdMmHhMmSs(startedAt)}
  </td>
);
