import config from '../../config';
import { OAuthProvider } from '../../types';

// @ GET
// @ o-auth/google
// @ PUBLIC
export const AUTH_WITH_GOOGLE = async (tokenRes: any): Promise<any> => {
  try {
    const params = new URLSearchParams(tokenRes).toString();
    const response = await fetch(`${config.baseUrl}/o-auth/google?${params}`);

    const json = await response.json();

    if (response.status === 200) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ o-auth/github
// @ PUBLIC
export const AUTH_WITH_GITHUB = async (code: string): Promise<any> => {
  try {
    const response = await fetch(
      `${config.baseUrl}/o-auth/github?code=${code}`
    );

    const json = await response.json();

    if (response.status === 200) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ GET
// @ o-auth/facebook
// @ PUBLIC
export const AUTH_WITH_FACEBOOK = async (code: string): Promise<any> => {
  try {
    const response = await fetch(
      `${config.baseUrl}/o-auth/facebook?code=${code}`
    );

    const json = await response.json();

    if (response.status === 200) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ o-auth/link/google
// @ PRIVATE
export const LINK_GOOGLE = async (data: { tokenRes: any }): Promise<any> => {
  const { tokenRes } = data;
  try {
    const userDocId = localStorage.getItem('user_doc_id');
    const params = new URLSearchParams(tokenRes).toString();

    const response = await fetch(
      `${config.baseUrl}/o-auth/link/google?${params}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userDocId }),
      }
    );

    const json = await response.json();

    if (response.status === 201) {
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ o-auth/link/github
// @ PRIVATE
export const LINK_GITHUB = async (code: string): Promise<any> => {
  try {
    const userDocId = localStorage.getItem('user_doc_id');

    const response = await fetch(
      `${config.baseUrl}/o-auth/link/github?code=${code}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userDocId }),
      }
    );

    const json = await response.json();

    if (response.status === 200) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ POST
// @ o-auth/link/facebook
// @ PRIVATE
export const LINK_FACEBOOK = async (code: string): Promise<any> => {
  try {
    const userDocId = localStorage.getItem('user_doc_id');

    const response = await fetch(
      `${config.baseUrl}/o-auth/link/facebook?code=${code}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userDocId }),
      }
    );

    const json = await response.json();

    if (response.status === 200) {
      // Saving token to localStorage
      localStorage.setItem('access_token', json.access_token);
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};

// @ DELETE
// @ o-auth/unlink
// @ PRIVATE
export const UNLINK_PROVIDER = async (data: {
  provider: OAuthProvider;
}): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(
      `${config.baseUrl}/o-auth/unlink/${data.provider}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await response.json();

    if (response.status === 200) {
      return {
        ok: response.ok,
      };
    }

    return {
      ok: false,
      status: json.statusCode,
      errorMessage: json.message,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
