import React from 'react';
import { IOffersFormatted } from '../../../../../context/extraction/TaskMethods/interfaces';
import { MetaTag } from '../../../../../types';
import { MetaTable } from '../MetaTable';
import { Price } from './Price';
import { MetaSectionCnt } from './styledComponents';

interface Props {
  price: IOffersFormatted;
  metaTags?: [MetaTag, string][];
}

export const MetaSection: React.FC<Props> = ({ price, metaTags = [] }) => (
  <MetaSectionCnt>
    <Price {...price} />
    {metaTags.length ? <MetaTable metaTags={metaTags} /> : null}
  </MetaSectionCnt>
);
