import { TaskStatusType } from '../../types';
import { IFilter } from './Pagination/interfaces';

const getQueryEntryForField = (
  name: string,
  index: number,
  value: string | boolean | TaskStatusType
): string => {
  return `filter[${name}][${index}]=${encodeURIComponent(`${value}`)}&`;
};

export const serializeFilter = (filters: IFilter[]): string => {
  let queryString = '';

  filters.forEach(({ name, options }) => {
    if (!name || !options) {
      return;
    }

    let optionsArray = options;
    if (options.includes('no feedback')) {
      optionsArray = [];
    }

    optionsArray.forEach((value, index) => {
      queryString += getQueryEntryForField(name, index, value);
    });
  });

  queryString = queryString.slice(0, -1);

  return queryString;
};
