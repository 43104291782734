import React from 'react';
import { usePayment } from '../../../../../context/payment/PaymentCtx';

export const ThreeDSecureModal = (): JSX.Element => {
  const { url, isThreeDSModalOpen: isOpen } = usePayment();

  return (
    <div title="3D Secure" style={{ display: isOpen ? 'block' : 'none' }}>
      <iframe
        src={url}
        id="three-d-secure-iframe"
        frameBorder="0"
        scrolling="no"
        // onLoad={() => setThreeDSModalOpen(false)}
        style={{
          width: '116px',
          height: '356px',
          // padding: '0',
          margin: 'auto',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
        }}
      />
    </div>
  );
};
