import styled from 'styled-components';
import { flex } from '../../styles/mixins';
import { breakpoints } from '../../styles/variables';

export const ExtractionWrapper = styled.div`
  padding: 32px;
  ${flex({ jc: 'space-between' })}

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 32px 16px 24px 32px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 24px 16px 0;
  }
`;
