import styled from 'styled-components';
import { flex, textOverflow } from '../../../styles/mixins';
import { colours } from '../../../styles/variables';

export const BadgeCnt = styled.div`
  ${flex({ ai: 'center' })}

  color: ${colours.muted};
  cursor: pointer;

  overflow: hidden;
  ${textOverflow()}

  &:hover {
    color: ${colours.primary};
  }
`;
