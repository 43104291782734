import React, { MouseEventHandler } from 'react';
import { divideWithSpace } from '../../../../tools/formatString';
import { IBillingPlan } from '../../../../types';
import { CardLabel } from './styledComponents';

interface Props {
  plan: IBillingPlan;
  isSelected: boolean;
  clickHandler: MouseEventHandler;
}

export const PlanCard = (props: Props) => {
  const { plan, isSelected, clickHandler } = props;

  return (
    <CardLabel onClick={clickHandler} className={isSelected ? 'selected' : ''}>
      <span>{divideWithSpace(plan.requestsLimit)}</span>
      <span>requests</span>
      <span>
        {plan.price.currency === 'usd' && '$'}
        {plan.price.amount}
      </span>
      {plan.price.discount && <span>Save {plan.price.discount}%</span>}
    </CardLabel>
  );
};
