import React, { ChangeEventHandler } from 'react';
import { Checkbox, Cnt, Label } from './styledComponents';

interface Props {
  value: string;
  handleChange: ChangeEventHandler;
  isChecked: boolean;
}

export const ToggleSwitch = (props: Props) => {
  const { value, handleChange, isChecked } = props;

  return (
    <Cnt>
      <Checkbox
        type="checkbox"
        name="checked"
        id={value}
        value={value}
        onChange={handleChange}
        checked={isChecked}
      />
      <Label htmlFor={value} />
    </Cnt>
  );
};
