import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const Login = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <path
        d="M6.5 5.5V2.5C6.5 1.948 6.948 1.5 7.5 1.5H14.5C15.052 1.5 15.5 1.948 15.5 2.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H7.5C6.948 15.5 6.5 15.052 6.5 14.5V11.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path d="M11.5 8.5H0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9 5.5L12 8.5L9 11.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgCnt>
  );
};
