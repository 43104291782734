import React from 'react';

interface Props {
  BodyComponents: React.FC<any>[];
}

export const BodyRow: React.FC<Props> = ({ BodyComponents }) => (
  <tr>
    {BodyComponents.map((BodyComponent, index) => <BodyComponent key={index} />)}
  </tr>
);
