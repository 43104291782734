import styled from 'styled-components';
import { breakpoints, colours } from '../../../../../../styles/variables';

export const Wrapper = styled.div`
  padding: 15px 16px;
  border-bottom: 1px solid ${colours.lightGray};
  margin-bottom: 1px;

  &:first-of-type {
    flex-grow: 1;
  }
  &:not(:first-of-type) {
    width: calc(118px + 16px);
    padding-left: 32px;
  }

  &.focused {
    border-bottom: 2px solid ${colours.lightGray};
    margin-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    &:not(:first-of-type) {
      width: 102px;
      padding-left: 16px;
    }
  }
  @media screen and (max-width: 855px) {
    &:first-of-type {
      width: 100%;
    }
    &:not(:first-of-type) {
      width: 50%;
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    &:first-of-type {
      flex-grow: 1;
    }
    &:not(:first-of-type) {
      width: calc(118px + 16px);
      padding-left: 0;
    }
  }
  @media screen and (max-width: 455px) {
    &:first-of-type {
      width: 100%;
    }
    &:not(:first-of-type) {
      width: 50%;
      padding-left: 16px;
    }
    &:last-of-type {
      padding-left: 25px;
    }
  }
`;
