import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const Help = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <g className="top">
        <path d="M5.6 0.5H8H9.9" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g className="right">
        <path
          d="M10 0.5C12.209 0.5 14 2.291 14 4.5V7.265"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 12.5H12C13.657 12.5 15 11.157 15 9.5C15 7.843 13.657 6.5 12 6.5H11V12.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g className="left">
        <path
          d="M1.5 7.265V4.5C1.5 2.291 3.291 0.5 5.5 0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 12.5H3.5C1.843 12.5 0.5 11.157 0.5 9.5C0.5 7.843 1.843 6.5 3.5 6.5H4.5V12.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 15.5H4.5C3.395 15.5 2.5 14.605 2.5 13.5V12.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_800">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgCnt>
  );
};
