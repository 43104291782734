import React from 'react';
import { ProviderProps } from '../types';
import { useUserData } from '../user/UserData';
import { ChartsProvider } from './Charts';
import { DashboardProvider } from './DashboardCtx';
import { PaginationProvider } from './Pagination';

export const DashboardProviders: React.FC<ProviderProps> = ({ children }) => {
  const { isAdmin } = useUserData();

  return isAdmin ? (
    <ChartsProvider>
      <PaginationProvider>
        <DashboardProvider>
          {children}
        </DashboardProvider>
      </PaginationProvider>
    </ChartsProvider>
  ) : (
    <>{children}</>
  );
};
