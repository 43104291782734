import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const ApiSettings = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <g clipPath="url(#clip0_929_2076)" className="top">
        <path
          d="M15.4996 1.09473L13.4746 3.12073"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4754 8.06991C14.1318 7.41349 14.5006 6.52321 14.5006 5.59491C14.5006 4.6666 14.1318 3.77632 13.4754 3.11991C12.819 2.46349 11.9287 2.09473 11.0004 2.09473C10.0721 2.09473 9.1818 2.46349 8.52539 3.11991L7.52539 4.11991L12.5004 9.0949L13.4754 8.06991Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip0_929_2076)" className="bottom">
        <path
          d="M0.5 16.0944L2.525 14.0684"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.499 8.09473L5 9.59473"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 10.0947L7 11.5947"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.52518 9.11973C1.86877 9.77614 1.5 10.6664 1.5 11.5947C1.5 12.523 1.86877 13.4133 2.52518 14.0697C3.18159 14.7261 4.07187 15.0949 5.00018 15.0949C5.92848 15.0949 6.81877 14.7261 7.47518 14.0697L8.47518 13.0697L3.50018 8.09473L2.52518 9.11973Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_929_2076">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.594727)"
          />
        </clipPath>
      </defs>
    </SvgCnt>
  );
};
