import styled, { css } from 'styled-components';
import { colours } from '../../../styles/variables';
import { IFeedbackGiven } from '../interfaces/feedback-given.interface';

export const Wrapper = styled.div<IFeedbackGiven>`
  svg path {
    stroke: ${colours.danger};
  }

  ${({ isFeedbackGiven }) => {
    if (!isFeedbackGiven) {
      return;
    }

    return css`
      svg path {
        stroke: ${colours.gray3};
      }
    `;
  }}

  &:hover svg path {
    stroke: ${colours.primary};
  }
`;
