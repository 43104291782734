import styled from 'styled-components';
import { glare } from '../../../styles/keyframes';
import { flex } from '../../../styles/mixins';
import { defaultBorder } from '../../../styles/placeholders';
import { colours } from '../../../styles/variables';

export const BadgeCnt = styled.div`
  min-width: 100px;
  max-width: 120px;
  height: 56px;
  padding: 8px 12px;
  ${defaultBorder}
  
  flex-grow: 1;
  ${flex({ dir: 'column', jc: 'space-between' })}

  & + * {
    margin-left: 8px;
  }

  &.loading {
    background: linear-gradient(
      135deg,
      ${colours.lighterGray}50 40%,
      ${colours.white}50 60%,
      ${colours.lighterGray}50 80%
    );
    background-size: 200% 200%;
    animation: ${glare} 5000ms ease-in-out infinite;
  }

  &[role='active'] {
    cursor: pointer;

    & > div svg {
      margin-left: 10px;
      path {
        fill: ${colours.gray2};
      }
    }

    &:hover {
      background-color: ${colours.primary};
      & > span,
      & > div span {
        color: ${colours.white};
      }
      & > div svg {
        path {
          fill: ${colours.white};
        }
      }
    }
  }

  &:hover {
    background-color: ${colours.gray}75;

    & > span {
      color: ${colours.primary};
    }
  }

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${colours.fontDark};
  }

  & > div span {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.44px;
    color: #959595;
  }
`;
