import styled from 'styled-components';
import { rotate360, spin } from '../../../../../../styles/keyframes';
import { flex } from '../../../../../../styles/mixins';
import { breakpoints, colours } from '../../../../../../styles/variables';

export const FooterCnt = styled.footer`
  margin: 40px 0 0;
  ${flex({ jc: 'center', gap: 40 })}
  gap: 40px;

  div:first-of-type {
    ${flex({ ai: 'center', gap: 13 })}
    gap: 13px;
    flex-grow: 1;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    text-decoration: none;
    color: ${colours.muted};

    svg path {
      fill: ${colours.muted}90;
    }
    svg:hover path {
      fill: ${colours.muted};
    }
  }

  @media screen and (max-width: 1265px) {
    margin-top: 32px;
  }

  @media screen and (max-width: ${breakpoints.xss}) {
    ${flex({ dir: 'column', ai: 'center', gap: 32 })}
    gap: 32px;
  }
`;

export const BtnCnt = styled.div`
  ${flex({ ai: 'center', gap: 16 })}
  gap: 16px;

  svg {
    height: 43px;
    width: 43px;
    animation: ${rotate360} 1000ms ease-in-out infinite;

    circle {
      stroke-linecap: round;
      animation: ${spin} 1000ms ease-in-out infinite;
    }
  }
`;
