import React, { FC, createContext, useContext, useState } from 'react';
import { DashboardTabType, ExtractionTabType, IUICtx } from './interfaces';

const UICtx = createContext<IUICtx>({} as IUICtx);

export const useUI = () => useContext(UICtx);

export const UIProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [configTimeout, setConfigTimeout] = useState<number>(0);
  const [tab, setTab] = useState<ExtractionTabType>('Visual');
  const [dashboardTab, setDashboardTab] = useState<DashboardTabType>('Visual');

  return (
    <UICtx.Provider
      value={{
        configsTimeout: {
          configTimeout,
          setConfigTimeout,
        },
        tab,
        setTab,
        dashboardTab,
        setDashboardTab,
      }}
    >
      {children}
    </UICtx.Provider>
  );
};
