import styled, { css } from 'styled-components';
import { dropGradually } from '../../../styles/keyframes';
import { flex } from '../../../styles/mixins';
import { breakpoints } from '../../../styles/variables';
import { border, thFont } from '../styles';

export const VisualCnt = styled.div`
  // ↓ for gradual dropping effect
  ${({ title }) => {
    return css`
      max-height: 0;
      display: none;
      padding: 0;

      &.opened {
        display: block;
        padding: unset;
        animation: ${dropGradually} ${100 * (title ? +title : 0)}ms ease-in-out
          forwards;
      }
    `;
  }}// ↑ for gradual dropping effect
`;

export const Row = styled.div`
  // ↓ for gradual dropping effect
  ${({ title }) => {
    return css`
      max-height: 0;
      opacity: 0;

      &.opened {
        animation: ${dropGradually} 100ms ease-in-out
          ${100 * (title ? +title : 0)}ms forwards;
      }
    `;
  }}
  // ↑ for gradual dropping effect

  border: ${border};
  border-top: 1px solid transparent;
  &:first-of-type {
    border-top: ${border};
  }
  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  ${flex({})}

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 20px;
    ${flex({ dir: 'column' })}

    gap: 12px;
  }
`;

export const Cell = styled.div`
  width: 159px;
  min-width: 159px;
  padding: 23px;
  line-height: 24px;

  color: #1b1b1b;
  & + * {
    width: 100%;
    border-left: ${border};
  }

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;

    & + * {
      border-left: none;
    }
  }
}
`;

export const Heading = styled.h6`
  ${thFont}
`;

export const List = styled.ul`
  margin: -2px 0 0 23px;
  list-style-type: '・';

  @media screen and (max-width: ${breakpoints.md}) {
    margin: 0;
    list-style: none;
  }
`;
