import React from 'react';
import { Empty } from '../../../../components/Empty';
import { Cell, Wrapper } from './styles';

export const Placeholder: React.FC = () => (
  <Wrapper>
    <Cell>
      <Empty name="task history" />
    </Cell>
  </Wrapper>
);
