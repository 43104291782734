import styled from 'styled-components';
import { rotate360 } from '../../../styles/keyframes';
import { colours } from '../../../styles/variables';

export const SvgCnt = styled.svg`
  &:hover {
    g {
      transform-origin: 50%;
      animation: ${rotate360} 600ms ease-in-out alternate;

      path {
        fill: ${colours.primary};
      }
    }
  }
`;
