import styled from 'styled-components';
import { breakpoints, colours } from '../../styles/variables';

export const ShadowCnt = styled.div`
  height: fit-content;
  width: 449px;
  min-width: 449px;
  border-radius: 8px;
  padding: 40px;
  /* margin: 10vh auto 0; // 10% gap on top */
  margin: auto;

  background: ${colours.white};
  box-shadow: 0px 4px 8px ${colours.shadow}, 0px 6px 12px ${colours.shadow},
    0px 1px 16px ${colours.shadow};

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    min-width: unset;
    padding: 40px 16px;
    margin: 0;
    box-shadow: none;
  }
`;
