import React from 'react';
import { HoveredIconProps } from '../interfaces';
import { SvgCnt } from './styledComponents';

export const Extraction = (props: HoveredIconProps) => {
  return (
    <SvgCnt
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.state}
    >
      <g clipPath="url(#clip0_929_2016)">
        <path
          d="M11.5 5.09473L8 1.09473L4.5 5.09473H6.5V11.0947H9.5V5.09473H11.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M12.5 7.09473H14.5C15.052 7.09473 15.5 7.54273 15.5 8.09473V15.0947C15.5 15.6467 15.052 16.0947 14.5 16.0947H1.5C0.948 16.0947 0.5 15.6467 0.5 15.0947V8.09473C0.5 7.54273 0.948 7.09473 1.5 7.09473H3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_929_2016">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.594727)"
          />
        </clipPath>
      </defs>
    </SvgCnt>
  );
};
