import config from '../../config';
import { IGetExtractionsPayload } from '../../interfaces/get-extractions-payload.interface';
import { IExtractionsForTableWithPagination } from './interfaces/extractions-for-table-with-paginations.interface';
import { serializeFilter } from './utils';
import { createUrlWithParams } from '../../tools/urls/createUrlWithParams';
import { IPeriod } from '../../types';

// @ GET
// @ dashboard/extractions
// @ ADMIN
export const GET_EXTRACTIONS = async ({
  page,
  sort,
  limit = 100,
  startDate,
  endDate,
  filters,
}: IGetExtractionsPayload): Promise<IExtractionsForTableWithPagination | void> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const path = new URL(`${config.baseUrl}/dashboard/extractions`);
    path.searchParams.append('page', `${page}`);
    path.searchParams.append('limit', `${limit}`);
    path.searchParams.append('startDate', `${startDate}`);

    if (endDate) {
      path.searchParams.append('endDate', `${endDate}`);
    }

    if (sort) {
      path.searchParams.append('sort', `${sort}`);
    }

    const filterQueryString = serializeFilter(filters);
    const pathString = `${path.toString()}&${filterQueryString}`;

    const response = await fetch(pathString, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    const json = await response.json();

    return json;
  } catch (error) {
    console.error(error);
  }
};

// @ GET
// @ dashboard/metrics
// @ ADMIN
export const GET_METRICS = async ({
  startDate,
  endDate,
}: IPeriod): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const encodedUrl = createUrlWithParams(
      `${config.baseUrl}/dashboard/metrics`,
      endDate ? { startDate, endDate } : { startDate }
    );

    const response = await fetch(encodedUrl, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
