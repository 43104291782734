import React from 'react';
import { StatusType } from '../../../types';

interface Props {
  status: StatusType;
  hideLoading?: boolean;
  hideSuccess?: boolean;
}

export const Spinner: React.FC<Props> = ({ status, hideLoading, hideSuccess }) => {
  // isLoading? & isSuccess?
  const switcher = {
    true: 'block',
    false: 'none',
    undefined: 'none',
  };

  return (
    <>
      <svg
        name="spinner"
        style={{ display: switcher[`${!hideLoading && status === 'loading'}`] }}
      >
        <circle
          cx={24 / 2}
          cy={24 / 2}
          r={24 / 2 - 3}
          fill="none"
          stroke="#5578F6"
          strokeWidth="3"
        ></circle>
      </svg>
      <svg
        name="loading-ok"
        style={{ display: switcher[`${!hideSuccess && status === 'success'}`] }}
        fill="none"
      >
        <path
          d="M2 11L10.7273 19.7273L28.1818 2.27273"
          stroke="#5578F6"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
