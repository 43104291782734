import styled from 'styled-components';
import { flex } from '../../../../../styles/mixins';
import { breakpoints } from '../../../../../styles/variables';

export const TableSectionCnt = styled.section`
  margin: 40px 0;
  ${flex({ dir: 'column' })}

  & > *:not(:first-of-type) {
    margin-top: 32px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 25px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 32px;
  }
`;
