import React from 'react';
import { toUpperCaseFirstChar } from '../../tools/formatString';
import { Cnt } from './styledComponents';
import emptyIcon from '../../assets/icons/empty.svg';

export const Empty = ({ name }: { name: string }) => {
  return (
    <Cnt>
      <img src={emptyIcon} alt="" />
      <span>{toUpperCaseFirstChar(name)} empty</span>
    </Cnt>
  );
};
