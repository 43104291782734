import config from '../../config';

// @ POST
// @ /logs
// @ PRIVATE
export const WRITE_LOG = async (data: any): Promise<any> => {
  try {
    const response = await fetch(`${config.baseUrl}/logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
