import { SingleArrow } from './SingleArrow';
import { DoubleArrow } from './DoubleArrow';
import { TableFilter } from './TableFilter';
import { Chart } from './Chart';
import { Extraction } from './navigation/Extraction';
import { User } from './navigation/User';
import { Payment } from './navigation/Payment';
import { ApiSettings } from './navigation/ApiSettings';
import { Help } from './navigation/Help';
import { Login } from './navigation/Login';
import { Logout } from './navigation/Logout';
import { Gmail } from './socials/Gmail';
import { Whatsapp } from './socials/Whatsapp';
import { Telegram } from './socials/Telegram';
import { Messenger } from './socials/Messenger';
import { Github } from './socials/Github';
import { Npm } from './socials/Npm';
import { Postman } from './socials/Postman';
import { Repeat } from './Repeat';
import { StripeLogo } from './StripeLogo';
import UI from './ui';
import Logos from './logos';

export default {
  SingleArrow,
  DoubleArrow,
  TableFilter,
  Extraction,
  User,
  Payment,
  ApiSettings,
  Help,
  Login,
  Logout,
  Gmail,
  Whatsapp,
  Telegram,
  Messenger,
  Github,
  Npm,
  Postman,
  Chart,
  Repeat,
  StripeLogo,
  UI,
  Logos,
};
