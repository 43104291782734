import styled from 'styled-components';
import { flex } from '../../../styles/mixins';
import noSelect from '../../../styles/placeholders/no-select';

export const Wrapper = styled.div`
  padding: 4px 8px;
  ${flex({ ai: 'center' })}
`;

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  margin-left: 24px;

  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  ${noSelect}
`;
