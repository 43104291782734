/* eslint-disable no-param-reassign */
import React, { ReactNode, useEffect, useState } from 'react';
import { Tooltip } from './styles';

type PositionType = 'top' | 'right' | 'bottom' | 'left';

interface Props {
  children: ReactNode | string;
  text: string;
  position?: PositionType;
}

const createPositionSwitcher = (
  parentElWidth: number,
  parentElHeight: number,
  tooltipElWidth: number,
  tooltipElHeight: number
) => {
  return {
    top: {
      top: '',
      left: '',
    },
    right: {
      top: '',
      left: '',
    },
    bottom: {
      top: `${parentElHeight / 2 + 16.5}px`,
      left: `${-(tooltipElWidth - parentElWidth) / 2}px`,
    },
    left: {
      top: `${-(parentElHeight - tooltipElHeight) / 2 - 16}px`,
      left: `${-(tooltipElWidth - parentElWidth + 32)}px`,
    },
  };
};

const getElementPosition = (
  tooltipEl: HTMLElement,
  position?: PositionType
) => {
  const { parentElement } = tooltipEl;
  if (!parentElement) {
    return;
  }

  const positionSwitcher = createPositionSwitcher(
    parentElement.offsetWidth,
    parentElement.offsetHeight,
    tooltipEl.offsetWidth,
    tooltipEl.offsetHeight
  );

  const { top, left } = positionSwitcher[`${position || 'bottom'}`];

  [tooltipEl.style.top, tooltipEl.style.left] = [top, left];
};

const setTooltipPosition = (position?: PositionType) => {
  const tooltipEls = [
    ...document.getElementsByClassName('tooltip'),
  ] as HTMLElement[];

  tooltipEls.forEach((tooltipEl) => {
    getElementPosition(tooltipEl, position);
  });
};

export const Hover: React.FC<Props> = ({ children, text, position }) => {
  const [isShown, setShown] = useState(false);

  const switcher = {
    true: 'block',
    false: 'none',
  };
  // overrided to be 'none' on small screens by !important

  useEffect(() => {
    setTooltipPosition(position);
  }, [isShown]);

  return (
    <div
      onMouseOver={() => setShown(true)}
      onTouchStart={() => setShown(true)}
      onMouseOut={() => setShown(false)}
      onTouchEnd={() => setShown(false)}
    >
      {children}
      <Tooltip className="tooltip" style={{ display: switcher[`${isShown}`] }}>
        <span>{text}</span>
      </Tooltip>
    </div>
  );
};
