import styled, { css } from 'styled-components';
import { Button } from '../../../../components/buttons/Button';
import { flex } from '../../../../styles/mixins';
import { breakpoints, colours } from '../../../../styles/variables';

const mutedFont = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${colours.muted};
`;

const grid1Col = css`
  grid-template-areas:
    'proxies'
    'pagination'
    'scrolling'
    'timer'
    'webhooks';
  grid-template-columns: 1fr;
`;

const grid2Cols = css`
  grid-template-areas:
    'proxies scrolling'
    'pagination timer'
    'webhooks webhooks';
  grid-template-columns: 1fr 1fr;
`;

const rowLayout = css`
  & > * {
    width: calc((100% - 56px) / 2);
    float: inline-start;
  }
  & > * + * {
    margin-top: 0;
    margin-left: 56px;
  }
`;

const colLayout = css`
  & > * {
    width: 100%;
    float: none;
  }
  & > * + * {
    margin-top: 16px;
    margin-left: 0;
  }
`;

// Headings
export const Heading = styled.h3`
  margin-top: 62px; // # pp

  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 41px;
  }
`;

export const Subheading = styled.p`
  margin-top: 4px;
  ${mutedFont}

  a {
    display: inline;
  }
`;
// Inputs' container & wrappers
export const InputsCnt = styled.div`
  padding-top: 32px;
  margin-right: 0;

  display: grid;
  ${grid2Cols}
  gap: 16px;

  @media screen and (max-width: 1000px) {
    padding-top: 24px;
    ${grid1Col}
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    ${grid2Cols}
  }

  @media screen and (max-width: 600px) {
    ${grid1Col}
  }
`;

export const InputBlock = styled.div`
  padding: 23px 24px 24px;
  border-radius: 16px;
  background-color: ${colours.gray};

  ${flex({ ai: 'flex-start' })}
  & > div:last-child {
    margin-left: 16px;
  }

  & label {
    // # pp toggle switch
    position: relative;
    top: 5px;
    left: 2px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;

    & label {
      margin-right: 6px;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding-right: 23px;
  }
`;

export const NumberInputBlock = styled(InputBlock)`
  padding: 24px 24px 24px 19px; // # pp inconsistent Figma
  flex-flow: column nowrap;

  p {
    margin-top: 16px;
  }
`;

export const WebhooksInputBlock = styled.div`
  width: 100%;
  padding: 23px 24px 24px;
  border-radius: 16px;
  background-color: ${colours.gray};

  grid-area: webhooks;
  ${colLayout}
`;

// Texts
export const Label = styled.span`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: ${colours.fontDark};
`;

export const Text = styled.p`
  margin-top: 4px;
  ${mutedFont}
`;
// Text breaks
export const Br = styled.br`
  @media screen and (max-width: 1210px) {
    display: none;
  }
`;
// Buttons
export const BtnGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-flow: row nowrap;

  button:first-of-type {
    width: 135px;
  }
`;

export const ResetBtn = styled(Button)`
  /* color: ${colours.muted}; */
  /* background-color: transparent; */
  white-space: nowrap;

  &:hover {
    /* color: ${colours.fontDark};
    background-color: transparent; */
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 136px;
  }
`;
