import styled from 'styled-components';
import { fieldBaseStyle } from './styles';

export const Textarea = styled.textarea`
  min-height: 103px;
  ${fieldBaseStyle}
  /* resize: none; */
  overflow-y: hidden;

  &:focus {
    margin-bottom: 1px;
  }
`;
