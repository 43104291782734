import React from 'react';
import { Button, Heading, Wrapper } from './styles';
import arrowDownIcon from '../../../../../../assets/icons/down-arrow-show-more.svg';
import { ClassFeedbackBtn } from '../../../../../../features/ClassFeedbackBtn';
import { TooltipBtnWrapper } from '../../../styles';

const checkboxes = ['wrong field data', 'invalid data type', 'empty field'];

interface Props {
  heading: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Title: React.FC<Props> = ({ heading, isOpen, setIsOpen }) => (
  <Wrapper
    // onMouseOver={() => setOpen(true)}
    // onMouseLeave={() => setOpen(false)}
    onClick={() => setIsOpen(!isOpen)}
    onTouchEnd={() => setIsOpen(!isOpen)}
  >
    {heading ? (
      <>
        <Heading title={heading} className={isOpen ? 'isOpen' : 'isClosed'}>
          {heading}
        </Heading>
        {/* <Button>
          <img
            className={isOpen ? 'isTurned' : ''}
            src={arrowDownIcon}
            alt=""
          />
        </Button> */}
      </>
    ) : (
      <Heading>Title N/D</Heading>
    )}
    <TooltipBtnWrapper>
      <ClassFeedbackBtn
        isShown
        classType="title"
        checkboxes={checkboxes}
      />
    </TooltipBtnWrapper>
  </Wrapper>
);
