import { IHistoryItem } from '../../context/extraction/History/interfaces';

export const findMissingItems = (
  prevArr: IHistoryItem[],
  newArr: IHistoryItem[]
): IHistoryItem[] => {
  if (!prevArr.length) {
    return [];
  }

  const missingItems: IHistoryItem[] = [];
  prevArr.forEach((prevItem) => {
    if (!newArr.some((someItem) => someItem._id === prevItem._id)) {
      console.debug(
        `pushing task ${prevItem._id} to missing tasks to display on Visual tab`
      );
      missingItems.push(prevItem);
    }
  });

  return missingItems;
};
