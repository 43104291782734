import React, { useEffect, useState } from 'react';
import { useTaskData } from '../../../context/extraction/TaskData';
import { delay } from '../../../tools/dev';
import { LoadBarCnt, LoadBarLoaded } from './styledComponents';

export const LoadBar = () => {
  const { isTaskInit } = useTaskData();
  const [scaler, setScaler] = useState<number>(0);
  const [transformOrigin, setTransformOrigin] = useState<string>('left');

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isTaskInit !== undefined) {
      if (isTaskInit) {
        setTransformOrigin('left');
        intervalId = setInterval(() => {
          setScaler((prev) => prev + 0.1);
        }, 1000);
      } else {
        setScaler(1);
        delay(500).finally(() => {
          setTransformOrigin('right');
          delay(500).finally(() => {
            setScaler(0);
          });
        });
      }
    }

    return () => clearInterval(intervalId);
  }, [isTaskInit]);

  return (
    <LoadBarCnt>
      <LoadBarLoaded
        style={{ transform: `scaleX(${scaler})`, transformOrigin }}
      />
    </LoadBarCnt>
  );
};
