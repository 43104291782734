import React, {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../Input';
import { Cnt, Feedback, FloatingLabel } from './styledComponents';
import { Link } from '../../links/Link';
import { delay } from '../../../tools/dev';
import { Textarea } from '../Textarea';

type AutoCompleteType =
  | 'name'
  | 'email'
  | 'current-password'
  | 'new-password'
  | 'street-address'
  | 'tel'
  | 'organization'
  | 'off'
  | 'url';

interface Props {
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  type: 'email' | 'password' | 'text' | 'tel' | 'url';
  autoComplete?: AutoCompleteType;
  name: string;
  placeholder?: string;
  validate?: (value: string) => string | undefined;
  classProp?: boolean | undefined;
  showBtn?: boolean;
  disabled?: boolean;
  isTextarea?: boolean;
  handleKeyDown?: KeyboardEventHandler;
  labelCustomStyles?: object;
  inputCustomStyles?: object;
  feedbackCustomStyles?: object;
}

export const NativeFloatingLabelInput = (props: Props) => {
  const {
    value,
    setValue,
    validate,
    name,
    placeholder,
    classProp,
    showBtn,
    handleKeyDown,
    isTextarea,
    labelCustomStyles,
    inputCustomStyles,
    feedbackCustomStyles,
  } = props;
  const updatedProps = { ...props };
  delete updatedProps.placeholder;

  const navigate = useNavigate();
  const inputEl = useRef(null);

  const [error, setError] = useState<string>('');
  const [isFocused, setFocused] = useState<boolean>(false || !!value);

  useEffect(() => {
    delay(500).then(() => {
      // && navigator.userAgent.includes('Chrome')
      if (inputEl.current) {
        const bgColor = window.getComputedStyle(
          inputEl.current
        ).backgroundColor;
        if (!['rgb(255, 255, 255)', 'rgba(0, 0, 0, 0)'].includes(bgColor)) {
          // console.log(window.getComputedStyle(inputEl.current).backgroundColor);
          setFocused(true);
        }
      }
    });
  }, [inputEl]);

  const handleBlur: FocusEventHandler = (e) => {
    const val = (e.target as HTMLInputElement).value;
    if (!val) {
      setFocused(false);
      return setError('');
    }
    if (validate) {
      const err = validate(val);
      if (err) setError(err);
      else setError('');
    }
  };

  const handleChange = (e: ChangeEvent) => {
    const val = (e.target as HTMLInputElement).value;
    setValue(val);
  };

  const componentProps = {
    // ...field,
    ...updatedProps,
    value,
    onChange: handleChange,
    onInput: () => setFocused(true),
    onFocus: () => setFocused(true),
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    ref: inputEl,
    className: `${classProp}`,
  };

  return (
    <Cnt>
      {isTextarea ? (
        <Textarea {...componentProps} style={inputCustomStyles || {}} />
      ) : (
        <Input {...componentProps} style={inputCustomStyles || {}} />
      )}
      <FloatingLabel
        htmlFor={name}
        className={isFocused ? 'isFocused' : ''}
        style={labelCustomStyles || {}}
      >
        {placeholder}
      </FloatingLabel>
      {error && (
        <Feedback style={feedbackCustomStyles || {}}>
          {error}
          {showBtn && (
            <>
              &nbsp;
              <Link onClick={() => navigate('..')}>Log in</Link>
            </>
          )}
        </Feedback>
      )}
    </Cnt>
  );
};
