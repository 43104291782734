/* eslint-disable operator-linebreak */
import React, { FC, MouseEventHandler, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cnt, IconCnt, Text } from './styledComponents';

interface Props {
  navTo?: string;
  disabled: boolean;
  Icon: FC<any>;
  children: string | React.ReactNode;
  clickHandler?: MouseEventHandler;
  keywords?: string[];
}

export const MenuItem = (props: Props) => {
  const { navTo, disabled, Icon, clickHandler, children, keywords } = props;

  const [isHovered, setHovered] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const curLocation =
    location.pathname.length > 1 ? location.pathname.slice(1) : '/';

  return (
    <Cnt
      onClick={(e) => {
        if (clickHandler) clickHandler(e);
        if (navTo) navigate(navTo);
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      disabled={disabled}
      className={
        curLocation === navTo ||
        (keywords && keywords.some((word) => curLocation.includes(word)))
          ? 'isCurrentLocation'
          : ''
      }
    >
      <IconCnt>
        <Icon state={isHovered ? 'hovered' : ''} />
      </IconCnt>
      <Text>{children}</Text>
    </Cnt>
  );
};
