import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toUpperCaseFirstChar } from '../../../../../tools/formatString';
import { SelectCnt, Dropdown, SelectWrapper, Dates } from './styles';
import dropdownIcon from '../../../../../assets/icons/down-arrow-dropdown.svg';
import fromToIcon from '../../../../../assets/icons/arrow-from-to.svg';
import { useDashboard } from '../../../../../context/dashboard/DashboardCtx';
import 'react-datepicker/dist/react-datepicker.css';

const oneHour = 60 * 60 * 1000;
const oneDay = 24 * oneHour;
const options = [
  { key: 'last 1 hour', delta: oneHour },
  { key: 'last 4 hours', delta: 4 * oneHour },
  { key: 'last 24 hours', delta: 24 * oneHour },
  { key: 'last 7 days', delta: 7 * oneDay },
  { key: 'last 14 days', delta: 14 * oneDay },
  { key: 'last 30 days', delta: 30 * oneDay },
  { key: 'last 60 days', delta: 60 * oneDay },
] as const;

type OptionKeyType = typeof options[number]['key'] | 'custom';

interface IOptionToRender {
  key: OptionKeyType;
}

interface Props {
  selected: OptionKeyType;
}

export const DateSelect: React.FC<Props> = ({ selected }) => {
  const { startDate, setStartDate, endDate, setEndDate } = useDashboard();

  // UI (style)
  const [isFocused, setFocused] = useState(false);
  const [newSelected, setNewSelected] = useState<OptionKeyType>(selected);
  const [showInputs, setShowInputs] = useState(false);

  const commonProps = {
    closeOnScroll: true,
    dateFormat: 'dd/MM/yyyy',
  };

  const handlePress = (key: OptionKeyType): void => {
    setNewSelected(key);
    setFocused(false);
  };

  useEffect(() => {
    setShowInputs(newSelected === 'custom');

    const optionSelected = options.find(({ key }) => key === newSelected);
    if (optionSelected) {
      setStartDate(Date.now() - optionSelected.delta);
    }
  }, [newSelected]);

  const optionsToRender: IOptionToRender[] = [...options, { key: 'custom' }];

  return (
    <SelectWrapper>
      <div
        onMouseOver={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        onTouchEnd={() => setFocused(!isFocused)}
        onScrollCapture={() => setFocused(false)}
        style={{ position: 'relative' }}
      >
        <SelectCnt
          className={isFocused ? 'focused' : ''}
          defaultValue={selected}
        >
          <span title={newSelected}>{toUpperCaseFirstChar(newSelected)}</span>
          <img src={dropdownIcon} alt="" />
        </SelectCnt>

        {isFocused && (
          <Dropdown>
            {optionsToRender.map(({ key }) => (
              <span
                title={key}
                key={key}
                onClick={() => handlePress(key)}
                onTouchEnd={() => handlePress(key)}
              >
                {toUpperCaseFirstChar(key)}
              </span>
            ))}
          </Dropdown>
        )}
      </div>

      {showInputs && (
        <Dates>
          <DatePicker
            {...commonProps}
            selected={new Date(startDate || Date.now())}
            onChange={(date: Date) => setStartDate(date.getTime())}
            placeholderText="Start date"
          />
          <img src={fromToIcon} alt="" />
          <DatePicker
            {...commonProps}
            selected={new Date(endDate || Date.now())}
            onChange={(date: Date) => setEndDate(date.getTime() + oneDay)}
            placeholderText="End date"
          />
        </Dates>
      )}
    </SelectWrapper>
  );
};
