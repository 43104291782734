import React, { FC, createContext, useContext, useState } from 'react';
import { ISelected } from '../../../components/inputFields/Select';
import { IPagination } from '../../../interfaces/pagination.interface';

const PaginationCtx = createContext<IPagination>({} as IPagination);

export const useExtractionPagination = () => useContext(PaginationCtx);

export const PaginationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);

  const sortOptions = [
    {
      name: 'latest first',
      value: -1,
    },
    {
      name: 'oldest first',
      value: 1,
    },
  ];
  const [sort, setSort] = useState<ISelected>(sortOptions[0]);

  return (
    <PaginationCtx.Provider
      value={{
        page,
        setPage,
        maxPage,
        setMaxPage,
        sort,
        sortOptions,
        setSort,
      }}
    >
      {children}
    </PaginationCtx.Provider>
  );
};
