import config from '../../config';

// @ POST
// @ token/rotate
// @ PRIVATE
export const ROTATE_TOKEN = async (): Promise<any> => {
  try {
    const apiToken = localStorage.getItem('api_token');

    const response = await fetch(
      `${config.baseUrl}/token/rotate?token=${apiToken}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await response.json();

    return {
      ok: response.ok,
      status: json.statusCode || response.status,
      statusText: json.message || response.statusText,
      data: json || null,
    };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
