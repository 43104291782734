import React from 'react';
import { TaskMethodsProvider } from './TaskMethods';
import { HistoryProvider } from './History';
import { PaginationProvider } from './Pagination';
import { RequestDataProvider } from './RequestData';
import { StatsProvider } from './Stats';
import { TaskDataProvider } from './TaskData';
import { TokenProvider } from './Token';
import { UtilsProvider } from './Utils';
import { ProviderProps } from '../types';

export const ExtractionProviders: React.FC<ProviderProps> = ({ children }) => (
  <PaginationProvider>
    <TaskDataProvider>
      <RequestDataProvider>
        <HistoryProvider>
          <UtilsProvider>
            <TaskMethodsProvider>
              <StatsProvider>
                <TokenProvider>{children}</TokenProvider>
              </StatsProvider>
            </TaskMethodsProvider>
          </UtilsProvider>
        </HistoryProvider>
      </RequestDataProvider>
    </TaskDataProvider>
  </PaginationProvider>
);
