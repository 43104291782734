import { IFeedback } from '../../types';
import config from '../../config';

// @ POST
// @ /support/feedback
// @ PRIVATE
export const SEND_FEEDBACK = async (data: IFeedback): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const response = await fetch(`${config.baseUrl}/support`, {
      method: 'POST',
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return { ok: response.ok };
  } catch (err) {
    return {
      ok: false,
      errorMessage: typeof err === 'string' ? err : 'Fetching error',
    };
  }
};
