import styled from 'styled-components';
import { flex, textOverflow } from '../../../../styles/mixins';
import { removeScrollbar } from '../../../../styles/placeholders';
import { breakpoints, colours } from '../../../../styles/variables';

export const Cnt = styled.div`
  width: 100%;
  margin-top: 32px;
  ${flex({ ai: 'flex-end' })}

  div {
    min-height: 100%;
    letter-spacing: 0;
    ${flex({ dir: 'column', jc: 'space-between' })}
    overflow-x: hidden;

    span:first-child {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #263238;
      opacity: 0.72;
    }

    span:last-child {
      min-width: 100%;
      border-radius: 4px;
      white-space: nowrap;
      overflow-x: scroll;
      ${removeScrollbar}
      ${textOverflow()}
      cursor: pointer;

      font-size: 16px;
      line-height: 19px;
      color: #1b1b1b;
    }
  }

  button {
    padding: 12px 16px;
    margin-left: 24px;
    ${flex({ jc: 'center' })}
  }

  &:hover {
    div {
      span:last-child {
        color: ${colours.white};
        background-color: ${colours.primary};
      }
    }
  }

  &.clicked {
    user-select: none;
    pointer-events: none;

    div {
      span:last-child {
        color: ${colours.white};
        background-color: ${colours.lightGray};
      }
    }
  }

  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 10px;
  }

  @media screen and (max-width: ${breakpoints.smMd}) {
    button {
      height: 32px;
      padding: 8px 12px;
      border-radius: 4px;
      margin-left: 8px;

      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 40px; // # pp

    button {
      height: 32px;
      border-radius: 4px;
    }
  }
`;
